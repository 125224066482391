import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { UnassignedMember } from '@reports/models/unassigned-member';
import { environment } from 'src/environments/environment';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { QueryResponse } from '@shared/models/query-response';
import { LazyLoadEvent } from 'primeng/api';
import { FilterRequest } from '@shared/models/filter-request';

@Injectable({
  providedIn: 'root'
})
export class UnassignedMembersService {
  private readonly getAllUnassignedMembersEndPoint: string;

  constructor(private http: HttpClient) {
    this.getAllUnassignedMembersEndPoint = environment.apiUrls.getAllUnassignedMembers;
  }

  getUnassignedMembers(
    filter: LazyLoadEvent
  ): Observable<QueryResponse<UnassignedMember>> {
    const request: FilterRequest = {
      filter
    };
    return this.http
      .post<QueryResponse<UnassignedMember>>(this.getAllUnassignedMembersEndPoint, request, {
        headers: AzureSubscriptionHeader
      })
      .pipe(shareReplay(1));
  }
}
