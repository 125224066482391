<p-dialog
  appendTo="body"
  [modal]="true"
  [(visible)]="visible"
  [closable]="false"
  [style]="{ width: '500px' }"
>
  <p-header> Edit Clinic </p-header>
  <form [formGroup]="pccFormGroup" (ngSubmit)="onSubmit()" class="pcc-edit-form">
    <div class="pcc-attributes">
      <mat-form-field [style.width.px]="200" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>PCC Id</mat-label>
        <input matInput type="text" formControlName="pccId" readonly />
      </mat-form-field>
      <mat-form-field [style.width.px]="450" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>PCC Name</mat-label>
        <input readonly matInput type="text" formControlName="pccName" readonly />
      </mat-form-field>
      <mat-form-field [style.width.px]="200" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Effective Date</mat-label>
        <input matInput type="date" formControlName="startDate" (change)="dateCheck('startDate')" />
        <mat-error *ngIf="startDate?.errors?.required"> Effective Date cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="200" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Effective Time</mat-label>
        <input matInput type="time" formControlName="startTime" (change)="dateCheck('startTime')" />
        <mat-error *ngIf="startTime?.errors?.required"> Effective Time cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="200" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Term Date</mat-label>
        <input matInput type="date" formControlName="endDate" (change)="dateCheck('endDate')" />
        <mat-error *ngIf="endDate?.errors?.required"> Term Date cannot be blank </mat-error>
        <mat-error
          *ngIf="pccFormGroup.errors?.notGreater && (pccFormGroup.touched || pccFormGroup.dirty)"
        >
          Term Date must take place after Effective Date
        </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="200" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Term Time</mat-label>
        <input matInput type="time" formControlName="endTime" (change)="dateCheck('endTime')" />
        <mat-error *ngIf="endTime?.errors?.required"> Term Time cannot be blank </mat-error>
      </mat-form-field>
      <mat-checkbox
        class="p-mr-3 p-mt-4 p-flew-row"
        color="primary"
        [checked]="pccEx"
        (change)="toggleActiveCheckbox($event)"
        >Exclude from PCC Auto-Assign
      </mat-checkbox>
    </div>
    <div class="form-action-buttons p-m-lg-6 p-d-flex p-jc-center">
      <button mat-raised-button type="button" (click)="cancelModal()" class="p-mr-2">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="pccFormGroup.pristine">
        Save
      </button>
    </div>
  </form>
</p-dialog>
