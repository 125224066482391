import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class RoleCheck {

  constructor(private _msalService: MsalService) { }

  userHasRole(roleName: string[] )  {
    if (this._msalService.instance.getActiveAccount()) {
      const userRoles = (this._msalService.instance.getActiveAccount()?.idTokenClaims as any).roles;
      const allowedRoles = roleName;
      const matchingRoles = userRoles.filter((x: any) => allowedRoles.includes(x));
      return matchingRoles.length > 0;
    }else{
      return false;
    }
  }
}