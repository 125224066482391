<div class="header">
  <h2>View AltProv Results</h2>
  <div>
    <mat-form-field appearance="outline" class="date-options">
      <mat-label>Date</mat-label>
      <mat-select [(value)]="this.selectedDate" required>
        <mat-option
          id="dateOption"
          *ngFor="let opt of altProvResultsDateOptions"
          [value]="opt.completionTime"
          (onSelectionChange)="onDateSelectionChange($event)"
        >
          {{ opt.type + ' - ' + opt.id + ' - ' + this.getLocalDate(opt.completionTime) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<p-table
  #dt
  [value]="altProvResults"
  *ngIf="this.selectedDate"
  [lazy]="true"
  (onLazyLoad)="loadAltProvResultsLazy($event)"
  [lazyLoadOnInit]="!querySessionManager.currentSession"
  [loading]="loadingTable"
  paginatorDropdownAppendTo="body"
  sortMode="single"
  sortField="id"
  dataKey="id"
  [columns]="selectedColumns"
  stateStorage="local"
  stateKey="altprov-results-list-filters"
  (onStateRestore)="onStateRestore($event)"
  (onColReorder)="onColumnReorder()"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="pagingSizes"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rows]="pagingSizes[0]"
  [rowHover]="true"
  [first]="0"
  [totalRecords]="totalItems"
  [reorderableColumns]="true"
  [autoLayout]="true"
  styleClass="p-datatable-gridlines p-datatable-striped"
>
  <ng-template pTemplate="caption">
    <h2>AltProv Results</h2>
    <div class="p-d-flex p-jc-between">
      <p-multiSelect
        class="selected-columns"
        [options]="allColumns"
        optionDisabled="static"
        [(ngModel)]="selectedColumns"
        optionLabel="shortName"
        selectedItemsLabel="{0} columns selected"
        placeholder="Choose Columns"
        appendTo="body"
      >
      </p-multiSelect>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('altprov-results-list-filters')"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-excel"
        class="p-button-success p-mr-2"
        pTooltip="Export as XLS"
        label="Export"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="exportExcel()"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="expansion">
        <!-- Expansion -->
      </th>
      <th *ngFor="let column of columns" [pSortableColumn]="column.field" pReorderableColumn>
        <span class="reorderable-table-header">
          <span [attr.title]="column.longName">{{ column.shortName }}</span>
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th>
        <!-- Expansion -->
      </th>
      <th *ngFor="let column of columns">
        <ng-container *ngIf="column.type === 'date'; else standardFilter">
          <p-columnFilter
            type="date"
            [field]="column.field"
            display="menu"
            matchMode="dateBefore"
            class="p-ml-auto"
          ></p-columnFilter>
        </ng-container>
      
        <ng-template #standardFilter>
          <app-column-filter
            [type]="column.type"
            [field]="column.field"
            [filterMatchMode]="column.filterMatchMode || 'contains'"
            [options]="column.options ? column.options : []"
          ></app-column-filter>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-altProvResult
    let-columns="columns"
    let-expanded="expanded"
    let-index="rowIndex"
  >
    <tr [pReorderableRow]="index">
      <td>
        <button
          type="button"
          pButton
          [pTooltip]="expanded ? 'Collapse Alternate Providers' : 'View Alternate Providers'"
          tooltipPosition="bottom"
          [pRowToggler]="altProvResult"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          style="height: 100%; width: 100%"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>
      <td *ngFor="let column of columns">
        <app-column-value
          [value]="altProvResult[column.field]"
          [arrayDisplayValue]="column.arrayDisplayValue"
          [routing]="
            column.routing &&
            (!_array.isArray(altProvResult[column.field]) ||
              altProvResult[column.field].length === 1)
              ? getRoute(column.field, index - first)
              : []
          "
        ></app-column-value>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-altProvResult>
    <tr>
      <td colspan="1" class="row-expansion-offset"></td>
      <td colspan="5">
        <div>
          <p-table [value]="altProvResult.altProv" datakey="id" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                <th>Type</th>
                <th>Number</th>
                <th>Alternate Provider</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-assignment>
              <tr>
                <td>
                  <span class="expansion-values">{{ assignment.Type }}</span>
                </td>
                <td>
                  <app-column-value
                    [value]="assignment.number || ''"
                    [routing]="
                      assignment.Type === 'Rule'
                        ? ['rules', 'edit', assignment.Id]
                        : assignment.Type === 'Member Exception'
                        ? ['memberExceptions', assignment.Id]
                        : assignment.Type === 'PCC Exception'
                        ? ['pccExceptions', assignment.Id]
                        : ['']
                    "
                  >
                  </app-column-value>
                </td>
                <td>
                  <span class="expansion-values">{{ assignment.alternateProviderName }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns?.length">There were no matches to the current filter(s).</td>
    </tr>
  </ng-template>
</p-table>
