import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { AlternateProvider } from '@shared/models/alternate-provider';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AltProviderService {
  private readonly getAlternateProvidersEndpoint: string;

  constructor(private http: HttpClient) {
    this.getAlternateProvidersEndpoint = environment.apiUrls.getAlternateProviders;
  }

  getAlternateProviders(): Observable<Array<AlternateProvider>> {
    return this.http
      .get<Array<AlternateProvider>>(this.getAlternateProvidersEndpoint, {
        headers: AzureSubscriptionHeader
      })
      .pipe(
        tap(results => {
          results.sort((a, b) => {
            const left = a.alternateProviderName;
            const right = b.alternateProviderName;

            return left.localeCompare(right);
          });
        })
      );
  }
}
