import { Component, ElementRef, OnInit, Self, ViewChild } from '@angular/core';
import { CareSystem } from '../../models/care-system';
import { CareSystemsService } from '../../services/care-systems.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-care-systems-list',
  templateUrl: './care-systems-list.component.html',
  styleUrls: ['./care-systems-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class CareSystemsListComponent implements OnInit {
  careSystems!: CareSystem[];
  careSystem!: CareSystem;
  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  @ViewChild('dt1') table!: Table;
  
  activeCheck: boolean = true;
  inactiveCheck: boolean = true;
  selectedCareSystemData?: CareSystem;
  cols: any[] = [];
  allActiveStatuses = ['true', 'false'];
  allStatuses = this.allActiveStatuses.map(status => {
    return { label: status, value: status };
  });

  constructor(
    private careSystemsService: CareSystemsService,
    @Self() private readonly clearFilterService: ClearFilterService
  ) {}

  ngOnInit(): void {
    this.getAllCareSystems();
  }

  // UI - utility methods
  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  CheckActiveCheckToggle() {
    console.log(`checkActiveCheckToggle: ${this.activeCheck}, ${this.inactiveCheck}`);
  }

  // CRUD medthods
  getAllCareSystems() {
    this.loadingTable = true;
    this.careSystemsService.getAllCareSystems().subscribe({
      next: careSystems => {
        this.careSystems = careSystems;
        this.careSystems.forEach(careSystem => {
          careSystem.createdDate = new Date(careSystem.createdDate!);
          careSystem.modifiedDate = new Date(careSystem.modifiedDate!);
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
