<h1 mat-dialog-title *ngIf="header" class="header">{{ header }}</h1>
<div mat-dialog-content>
  <p *ngIf="body" class="body" [innerHTML]="body"></p>
</div>
<div mat-dialog-actions align="end">
  <button *ngIf="displayCancel" mat-button (click)="cancel()">{{ cancelText }}</button>
  <button mat-button cdkFocusInitial (click)="confirm()" [mat-dialog-close]="selection">
    {{ confirmText }}
  </button>
</div>
