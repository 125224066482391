// tslint:disable: no-angle-bracket-type-assertion
// tslint:disable: whitespace

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemberMaintenanceService } from '@memberMaintenance/services/member-maintenance.service';
import { CriterionValue } from '@rules/models/criterion-value';
import { RuleCriterion } from '@rules/models/rule-criterion';
import { CareSystemService } from '@rules/services/care-system-service/care-system.service';
import { RulesService } from '@rules/services/rules-service/rules.service';
import { CountiesService } from '@shared/services/counties-service/counties.service';
import { tap } from 'rxjs/operators';
import { HealthStatusCodesService } from 'src/app/healthStatusCodes/services/health-status-codes.service';

export interface RuleValueSelectionDialogOptions {
  sourceCollectionIndex: number;
  value: RuleCriterion;
}

@Component({
  selector: 'app-rule-value-selection-dialog',
  templateUrl: './rule-value-selection-dialog.component.html',
  styleUrls: ['./rule-value-selection-dialog.component.scss']
})
export class RuleValueSelectionDialogComponent implements OnInit {
  readonly identifierFilterType: string;
  readonly criteriaName: string;
  selectedProducts: Set<string>;

  loading = true;

  gridData: CriterionValue[] = [];

  selectedRows: CriterionValue[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly launchData: any,
    private readonly rulesService: RulesService,
    private readonly countiesService: CountiesService,
    private readonly careSystemService: CareSystemService,
    private readonly memberMaintenanceService: MemberMaintenanceService,
    private readonly healthStatusCodesService: HealthStatusCodesService
  ) {
    this.identifierFilterType = this.getFilterType();
    this.criteriaName = this.launchData.criterion.criteriaName;
    this.selectedProducts = this.launchData.selectedProducts;
  }

  ngOnInit(): void {
    switch (this.criteriaName) {
      case 'Care System':
        this.careSystemService
          .getCareSystems()
          .pipe(
            tap(results => {
              const careSystems = results.filter(x => x.active === true);

              this.gridData = careSystems.map(
                x =>
                  <CriterionValue>{
                    identifier: x.careSystemName,
                    name: x.careSystemName,
                    description: x.careSystemDescription
                  }
              );

              this.loading = false;
            })
          )
          .subscribe();
        break;
      case 'Member County':
      case 'PCC County':
        this.countiesService
          .getAllCounties()
          .pipe(
            tap(results => {
              const counties =
                this.criteriaName === 'Member County'
                  ? results.filter(
                      cnty => cnty.active === true && cnty.state === 'MN' && cnty.forMember === true
                    )
                  : results.filter(
                      cnty => cnty.active === true && cnty.state === 'MN' && cnty.forPCC === true
                    );

              this.gridData = counties.map(
                x =>
                  <CriterionValue>{
                    identifier: x.countyCode,
                    name: x.countyName,
                    description: x.state
                  }
              );

              this.loading = false;
            })
          )
          .subscribe();
        break;
      case 'Health Status Code':
        this.healthStatusCodesService
          .getAllHealthStatusCodes()
          .pipe(
            tap(results => {
              const today = new Date();
              const connectSelected = this.selectedProducts.has('CONNECT');
              const connectPlusSelected = this.selectedProducts.has('CONNECT+MEDICARE');

              const healthStatusCodes = results.filter(
                x =>
                  new Date(x.periodBeginDate) < today &&
                  new Date(x.periodEndDate) > today &&
                  ((connectSelected && x.isValidConnect) ||
                    (connectPlusSelected && x.isValidConnectPlus))
              );

              this.gridData = healthStatusCodes.map(
                x =>
                  <CriterionValue>{
                    identifier: x.healthStatusCode,
                    name: x.healthStatusCode,
                    description: x.healthStatusDescription
                  }
              );

              this.loading = false;
            })
          )
          .subscribe();
        break;
      case 'Member Living Status':
        this.memberMaintenanceService
          .getAllMemberLivingStatusCodes()
          .pipe(
            tap(results => {
              const livingStatusCodes = results.filter((x: any) => x.active === true);

              this.gridData = livingStatusCodes.map(
                x =>
                  <CriterionValue>{
                    identifier: x.livingStatusCode,
                    name: x.livingStatusCode,
                    description: x.livingStatusDescription
                  }
              );

              this.loading = false;
            })
          )
          .subscribe();
        break;
      case 'Contract Number':
        this.gridData = this.rulesService.getContractIds();
        this.loading = false;

        break;
      default: {
        this.gridData = [];
        this.loading = false;
        break;
      }
    }

    this.selectedRows = this.launchData.criterion.values;
  }

  private getFilterType(): string {
    return 'numeric';
  }
}
