import { ChangeDetectorRef, Component, OnInit, Self, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { AlternateProvider } from '../../models/alternate-provider';
import { AlternateProviderViewModel } from '../../models/alternate-provider-view-model';
import { AlternateProvidersService } from '../../services/alternate-providers.service';
import { isEmpty } from '@shared/validators/isEmpty';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { MsalService } from '@azure/msal-angular';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-alternate-providers-list',
  templateUrl: './alternate-providers-list.component.html',
  styleUrls: ['./alternate-providers-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class AlternateProvidersListComponent implements OnInit {
  alternateProviders!: AlternateProvider[];

  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  @ViewChild('dt1') dataTable!: Table;

  activeCheck = true;
  inactiveCheck = true;
  selectedAlternateProvidersData?: AlternateProvider;

  cols: any[] = [];
  allActiveStatuses = ['true', 'false'];
  allStatuses = this.allActiveStatuses.map(status => {
    return { label: status, value: status };
  });

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private alternateProvidersService: AlternateProvidersService,
    private cdRef: ChangeDetectorRef,
    private authService: MsalService,
    private roleCheck: RoleCheck,
    @Self() private readonly clearFilterService: ClearFilterService
  ) {}

  userHasEdit(){
    return this.roleCheck.userHasRole(['AltProvAdmin','AltProvListMaintenanceEdit']);
  }
  
  ngOnInit(): void {
    this.refreshDataTable();
    this.getAllAlternateProviders();
  }

  private refreshDataTable() {
    this.alternateProvidersService.refreshNeeded$.subscribe(() => {
      this.getAllAlternateProviders();
    });
  }

  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  CheckActiveCheckToggle() {
    console.log(`checkActiveCheckToggle: ${this.activeCheck}, ${this.inactiveCheck}`);
  }

  getAllAlternateProviders() {
    this.loadingTable = true;
    this.alternateProvidersService.getAllAlternateProviders().subscribe({
      next: alternateProviders => {
        this.alternateProviders = alternateProviders;
        this.alternateProviders.forEach(alternateProviders => {
          alternateProviders.createdDate = new Date(
            alternateProviders.createdDate!
          ).toLocaleDateString();
          alternateProviders.modifiedDate = new Date(
            alternateProviders.modifiedDate!
          ).toLocaleDateString();
          alternateProviders.effectiveEndDate =
            alternateProviders.effectiveEndDate != null
              ? new Date(alternateProviders.effectiveEndDate)
              : alternateProviders.periodEndDate != null
              ? new Date(alternateProviders.periodEndDate)
              : null;
          alternateProviders.effectiveStartDate =
            alternateProviders.effectiveStartDate != null
              ? new Date(alternateProviders.effectiveStartDate)
              : alternateProviders.periodBeginDate != null
              ? new Date(alternateProviders.periodBeginDate)
              : null;
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  addAlternateProvider() {
    this.alternateProvidersService.getAlternateProvider('0').subscribe(result => {
      this.selectedAlternateProvidersData = result;
    });
    this.displayEditDialog = true;
  }

  editAlternateProvider(alternateProvidersData: AlternateProviderViewModel) {
    const editAlternateProvider: AlternateProvider = {
      id: alternateProvidersData.id,
      alternateProviderNBR: !isEmpty(alternateProvidersData.alternateProviderNBR)
        ? alternateProvidersData.alternateProviderNBR
        : '',
      alternateProviderName:
        alternateProvidersData.alternateProviderLongName ??
        alternateProvidersData.alternateProviderName ??
        alternateProvidersData.alternateProviderShortName,
      active: alternateProvidersData.active,
      modifiedDate: alternateProvidersData.modifiedDate,
      modifiedUser: this.userName, 
      createdUser: alternateProvidersData.createdUser,
      createdDate: alternateProvidersData.createdDate,
      periodBeginDate:
        alternateProvidersData.periodBeginDate?.toLocaleString() ??
        alternateProvidersData.effectiveStartDate?.toLocaleString(),
      periodEndDate:
        alternateProvidersData.periodEndDate?.toLocaleString() ??
        alternateProvidersData.effectiveEndDate?.toLocaleString(),
      alternateProviderCategoryCode: alternateProvidersData.alternateProviderCategoryCode,
      alternateProviderFIPSCode: alternateProvidersData.alternateProviderFIPSCode,
      alternateProviderIdType: alternateProvidersData.alternateProviderIdType,
      alternateProviderIdentifier: alternateProvidersData.alternateProviderIdentifier,
      alternateProviderType: alternateProvidersData.alternateProviderType,
      alternateProviderStateCode: alternateProvidersData.alternateProviderStateCode
    };

    this.selectedAlternateProvidersData = { ...editAlternateProvider };
    this.displayEditDialog = true;
  }

  updateAlternateProvider(selectedAlternateProviderData: AlternateProvider) {
    this.displayEditDialog = false;
    this.getAllAlternateProviders();
    this.cdRef.detectChanges();
  }

  deleteAlternateProvider(alternateProviderData: AlternateProvider) {
    if (
      confirm(`Do you really want to delete this AlternateProvider? ${alternateProviderData.id}`)
    ) {
      this.alternateProvidersService.deleteAlternateProvider(alternateProviderData.id!).subscribe({
        next: () => this.onSaveComplete(alternateProviderData.id!),
        error: err => (this.errorMessage = err)
      });
      console.error(this.errorMessage);

      this.onSaveComplete(alternateProviderData.id!);
    }
  }

  onSaveComplete(id: string): void {
    console.log('deleted id: ', id);
    this.alternateProviders = this.alternateProviders.filter(hc => hc.id !== id);
  }

  private dateformat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
    //hour: "2-digit",
    //minute: "2-digit",
    //second: "2-digit"
  };

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
  }
}
