import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SpinnerOverlayComponent } from '@shared/components/spinner-overlay/spinner-overlay.component';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService implements OnDestroy {
  private overlayRef?: OverlayRef;
  private readonly _subscriptions: Subscription[] = [];

  // https://christianlydemann.com/four-ways-to-create-loading-spinners-in-an-angular-app/
  constructor(private readonly overlay: Overlay, private readonly router: Router) {
    const routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.overlayRef) {
        this.overlayRef.detach();
      }
    });

    this._subscriptions.push(routerSub);
  }

  public show() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
    const component = this.overlayRef.attach(spinnerOverlayPortal);
  }

  public hide() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  ngOnDestroy(): void {
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
  }
}
