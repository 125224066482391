import { Component, Inject, OnInit } from '@angular/core';
import { FilterMatchMode, MenuItem, PrimeNGConfig, Translation } from 'primeng/api';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest
} from '@azure/msal-browser';
import { RoleCheck } from '@shared/guards/roleCheck';
import TranslationsEn from '@shared/util/translations-en';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  navExpanded = true;
  title = 'AltProv';
  items!: MenuItem[];
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private roleCheck: RoleCheck,
    private config: PrimeNGConfig
  ) {}

  getName(): string {
    if (this.authService.instance.getActiveAccount() == null) {
      return 'unknown';
    }

    return this.authService.instance.getActiveAccount()?.username!;
  }

  ngOnInit(): void {
    this.config.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        'isBlank' // Custom filter
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      date: [FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER]
    };

    // Load from one-off class
    this.config.setTranslation(TranslationsEn);

    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.buildMenu();
        this.setLoginDisplay();
      });

    this.buildMenu();
    this.setLoginDisplay();
  }

  buildMenu() {
    this.items = [
      this.roleCheck.userHasRole([
        'AltProvAdmin',
        'AltProvEditRules',
        'AltProvReadRules',
        'AltProvEditMemberExceptions',
        'AltProvReadMemberExceptions',
        'AltProvEditPCCExceptions',
        'AltProvReadPCCExceptions'
      ])
        ? {
            label: 'Rules & Exceptions',
            items: [
              this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvEditRules', 'AltProvReadRules'])
                ? { label: 'Rules', icon: 'pi pi-fw pi-eye', routerLink: ['/rules/list'] }
                : { visible: false },
              this.roleCheck.userHasRole([
                'AltProvAdmin',
                'AltProvEditPCCExceptions',
                'AltProvReadPCCExceptions'
              ])
                ? {
                    label: 'PCC Exceptions',
                    icon: 'pi pi-fw pi-eye',
                    routerLink: ['/pccExceptions']
                  }
                : { visible: false },
              this.roleCheck.userHasRole([
                'AltProvAdmin',
                'AltProvEditMemberExceptions',
                'AltProvReadMemberExceptions'
              ])
                ? {
                    label: 'Member Exceptions',
                    icon: 'pi pi-fw pi-eye',
                    routerLink: ['/memberExceptions']
                  }
                : { visible: false }
            ]
          }
        : { visible: false },

      this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvMemberEdit', 'AltProvMemberRead'])
        ? {
            label: 'Member Maintenance',
            items: [
              { label: 'View Members', icon: 'pi pi-fw pi-eye', routerLink: ['/members/list'] }
            ]
          }
        : { visible: false },

      this.roleCheck.userHasRole([
        'AltProvAdmin',
        'AltProvEditPCCExceptions',
        'AltProvReadPCCExceptions'
      ])
        ? {
            label: 'Providers',
            items: [
              {
                label: 'Clinic Closure & Merger',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/providers/clinic-update']
              },
              {
                label: 'Clinic Maintenance',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/providers/pcc-maintenance']
              }
            ]
          }
        : { visible: false },

      this.roleCheck.userHasRole([
        'AltProvAdmin',
        'AltProvListMaintenanceEdit',
        'AltProvListMaintenanceRead'
      ])
        ? {
            label: 'Administration',
            items: [
              { label: 'Care System', icon: 'pi pi-fw pi-eye', routerLink: ['/caresystems'] },
              { label: 'Products', icon: 'pi pi-fw pi-eye', routerLink: ['/products'] },

              {
                label: 'Alternate Providers',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/alternateproviders']
              },
              {
                label: 'Counties',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/counties']
              },
              {
                label: 'Living Status Codes',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/livingstatuscodes']
              },
              {
                label: 'Health Status Codes',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/healthstatuscodes']
              },
              {
                label: 'Exception Source Codes',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/exceptionsources']
              }
            ]
          }
        : { visible: false },

      this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvResults'])
        ? {
            label: 'Reports',
            items: [
              {
                label: 'View AltProv Results',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/altProvResults']
              },
              {
                label: 'View Unassigned Members',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/unassigned-members']
              },
              {
                label: 'View Members Multi Assigned',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/membersMultiAssigned']
              },
              {
                label: 'View Roster Variance Report',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/rosterVarianceReportList']
              }
            ]
          }
        : { visible: false },

      this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvRosterCLS', 'AltProvPublishRosterCLS'])
        ? {
            label: 'Rosters',
            items: [
              {
                label: 'View Rosters',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/rosters']
              }
            ]
          }
        : { visible: false },

      this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvHealthStatusImport'])
        ? {
            label: 'File Uploads',
            items: [
              {
                label: 'Member Health Status Codes',
                icon: 'pi pi-fw pi-eye',
                routerLink: ['/healthstatuscodesuploads']
              }
            ]
          }
        : { visible: false }
    ];
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/'
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: '/'
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
