import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { HealthStatusCodesHistoryService } from '../../services/health-status-codes-history.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Table } from 'primeng/table';
import { exportDataToExcel } from '@shared/util/excel-exporter';
import { MatDialog } from '@angular/material/dialog';
//import { HealthStatusCodeDialogComponent } from '../health-status-code-dialog/health-status-code-dialog.component';
import { assessmentStatuses } from '../../assets/assessment-statuses';
import { interpretationNeedLevels } from '../../assets/interpretation-need-levels';
import { interpretationNeedTypes } from '../../assets/interpretation-need-types';
import { observationStatusReasons } from '../../assets/observation-status-reasons';
import { BaseView } from '@shared/components/Base/base-view';
import { MsalService } from '@azure/msal-angular';
import { RoleCheck } from '@shared/guards/roleCheck';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { ActivatedRoute } from '@angular/router';
import { HealthStatusCodeHistory } from '@shared/models/health-status-code-history';

@Component({
  selector: 'app-health-status-codes-history-list',
  templateUrl: './health-status-codes-history-list.component.html',
  styleUrls: ['./health-status-codes-history-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class HealthStatusCodesHistoryListComponent extends BaseView implements OnInit {
  healthStatusCodesHistory: HealthStatusCodeHistory[] = [];

  loadingTable = true;
  errorMessage = '';
  currentDateTime = new Date();
  assessmentStatuses: Array<string> = assessmentStatuses;
  interpretationNeedLevels: Array<string> = interpretationNeedLevels;
  interpretationNeedTypes: Array<string> = interpretationNeedTypes;
  observationStatusReasons: Array<string> = observationStatusReasons;

  @ViewChild('dt') dataTable!: Table;


  

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private dialog: MatDialog,
    private healthStatusCodesHistoryService: HealthStatusCodesHistoryService,
      private authService: MsalService,
      private roleCheck: RoleCheck,
    @Self() private readonly clearFilterService: ClearFilterService
  ) {
    super();
  }

  userHasEdit(){
    return this.roleCheck.userHasRole(['AltProvAdmin','AltProvListMaintenanceEdit']);
  }
  
  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.getAllHealthStatusCodesHistory(params.healthStatusCode);
    });
  }

  getAllHealthStatusCodesHistory(healthStatusCode: string): void {
    this.loadingTable = true;

    this.subs.sink = this.healthStatusCodesHistoryService.getHealthStatusCodeHistory(healthStatusCode).subscribe({
      next: healthStatusCodesHist => {
        this.healthStatusCodesHistory = healthStatusCodesHist;
        this.healthStatusCodesHistory.forEach(hsc => {
          hsc.createdDate = new Date(hsc.createdDate);
          hsc.modifiedDate = new Date(hsc.modifiedDate);
          hsc.periodBeginDate =
            hsc.periodBeginDate != null ? new Date(hsc.periodBeginDate) : '9999-12-31T00:00:00.000';
          hsc.periodEndDate =
            hsc.periodEndDate != null ? new Date(hsc.periodEndDate) : '9999-12-31T00:00:00.000';
          hsc.active = this.getActive(hsc.periodBeginDate as string, hsc.periodEndDate as string);
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  getActive(start: string, end: string): boolean {
    return this.currentDateTime > new Date(start) && this.currentDateTime < new Date(end);
  }

  exportExcel(): void {
    exportDataToExcel(this.healthStatusCodesHistory, 'HealthStatusCodes');
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
  }
}
