import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Rule } from '@rules/models/rule';

import { RulesService } from '@rules/services/rules-service/rules.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RuleResolver implements Resolve<Rule | null> {
  constructor(private rulesService: RulesService, private router: Router) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Rule | null> {
    const id = route.params.id as string;

    const result = this.rulesService
      .getRuleById(id)
      .pipe(tap(rule => this.handleRuleNavigation(rule)))
      .toPromise();

    return await result;
  }

  private handleRuleNavigation(rule: Rule | null): void {
    if (!rule) {
      // Pop up an error too...
      this.router.navigate(['/rules/list']);
    }
  }
}
