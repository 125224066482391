import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DateValidator } from '../../../shared/validators/date-validator/date.validator';
import { getDateStringAsUTC, getLocalDateFromUTCDateString } from '@shared/util/date-formatter';
import { DatePart } from '@shared/enums/date-part';
import { EditPcc } from '../pcc-edit/edit-pcc';
import { PCC } from '@shared/models/pcc';
import { PccService } from '@shared/services/pcc-service/pcc.service';
import { SpinnerOverlayService } from '@shared/services/spinner-overlay-service/spinner-overlay.service';

@Component({
  selector: 'app-pcc-edit',
  templateUrl: './pcc-edit.component.html',
  styleUrls: ['./pcc-edit.component.scss']
})
export class PccEditComponent implements OnInit {
  @Input() visible = false;
  @Input() myPccData!: PCC;
  @Input() pccs!: PCC[];
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<PCC>();

  loading = false;
  pccEx = false;
  pccFormGroup!: FormGroup;

  get pccId() {
    return this.pccFormGroup.get('pccId') as FormControl;
  }
  get pccName() {
    return this.pccFormGroup.get('pccName') as FormControl;
  }
  get startDate() {
    return this.pccFormGroup.get('startDate') as FormControl;
  }
  get endDate() {
    return this.pccFormGroup.get('endDate') as FormControl;
  }
  get startTime() {
    return this.pccFormGroup.get('startTime') as FormControl;
  }
  get endTime() {
    return this.pccFormGroup.get('endTime') as FormControl;
  }
  get pccExclusion() {
    return this.pccFormGroup.get('pccExclusion') as FormControl;
  }

  constructor(
    private readonly fb: FormBuilder,
    private pccService: PccService,
    private readonly spinnerService: SpinnerOverlayService
  ) {}

  ngOnInit(): void {
    this.pccFormGroup = this.fb.group(
      {
        pccId: this.myPccData?.pccId,
        pccName: this.myPccData?.pccName,
        startDate:
          this.myPccData?.effectiveDate != null
            ? getLocalDateFromUTCDateString(
                new Date(this.myPccData?.effectiveDate).toISOString(),
                DatePart.Date
              )
            : '',
        endDate:
          this.myPccData?.termDate != null
            ? getLocalDateFromUTCDateString(
                new Date(this.myPccData?.termDate).toISOString(),
                DatePart.Date
              )
            : '',
        startTime:
          this.myPccData?.effectiveDate != null
            ? getLocalDateFromUTCDateString(
                new Date(this.myPccData?.effectiveDate).toISOString(),
                DatePart.Time
              )
            : '',
        endTime:
          this.myPccData?.termDate != null
            ? getLocalDateFromUTCDateString(
                new Date(this.myPccData?.termDate).toISOString(),
                DatePart.Time
              )
            : '',
        pccExclusion: this.myPccData?.pccExclusion
      },
      <AbstractControlOptions>{
        validator: DateValidator.greaterThan
      }
    );

    this.pccEx = this.pccExclusion.value;
  }

  onSubmit() {
    if (this.pccFormGroup.valid) {
      this.updatePCC(this.pccFormGroup.value);
    }
  }

  updatePCC(pcc: PCC) {
    this.spinnerService.show();
    var effective = this.myPccData?.effectiveDate;
    if (this.startDate.dirty || this.startTime.dirty) {
      effective = this.startDate.value
        ? getDateStringAsUTC(this.startDate.value + 'T' + this.startTime.value)
        : null;
    }

    var term = this.myPccData?.termDate;
    if (this.endDate.dirty || this.endTime.dirty) {
      term = this.endDate.value
        ? getDateStringAsUTC(this.endDate.value + 'T' + this.endTime.value)
        : null;
    }

    let updatedPCC: EditPcc = {
      id: this.myPccData?.pmiId,
      specialityEffectiveDate: effective,
      locationEffectiveDate: effective,
      specialityTermDate: term,
      locationTermDate: term,
      pccExclusionFlag: this.pccEx
    };
    this.pccService.updatePCC(updatedPCC).subscribe(res => {
      this.spinnerService.hide();
      if (res.status == '200') this.saveEvent.emit(this.pccFormGroup.value);
      else this.cancelModal();
    });
  }

  cancelModal() {
    this.cancelEvent.emit(this.visible);
  }

  toggleActiveCheckbox($event: { checked: boolean }) {
    if ($event.checked === true) this.pccEx = true;
    else this.pccEx = false;
    this.pccFormGroup.markAsDirty();
    this.pccFormGroup.updateValueAndValidity();
  }

  dateCheck(controlName: string) {
    if (controlName == 'startDate') {
      this.pccFormGroup.controls.startTime.setValidators(Validators.required);
      this.pccFormGroup.controls.endDate.setValidators(Validators.required);
      this.pccFormGroup.controls.startTime.updateValueAndValidity();
      this.pccFormGroup.controls.endDate.updateValueAndValidity();
    }
    if (controlName == 'endDate') {
      this.pccFormGroup.controls.endTime.setValidators(Validators.required);
      this.pccFormGroup.controls.startDate.setValidators(Validators.required);
      this.pccFormGroup.controls.endTime.updateValueAndValidity();
      this.pccFormGroup.controls.startDate.updateValueAndValidity();
    }
    if (controlName == 'startTime') {
      this.pccFormGroup.controls.startDate.setValidators(Validators.required);
      this.pccFormGroup.controls.endDate.setValidators(Validators.required);
      this.pccFormGroup.controls.startDate.updateValueAndValidity();
      this.pccFormGroup.controls.endDate.updateValueAndValidity();
    }
    if (controlName == 'endTime') {
      this.pccFormGroup.controls.endDate.setValidators(Validators.required);
      this.pccFormGroup.controls.startDate.setValidators(Validators.required);
      this.pccFormGroup.controls.endDate.updateValueAndValidity();
      this.pccFormGroup.controls.startDate.updateValueAndValidity();
    }
  }
}
