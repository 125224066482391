import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { ActivatedRoute, ResolveEnd, ResolveStart, Router, Event } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { PccExceptionHistoryParam } from '@pccExceptions/models/pcc-exception-hist-param';
import { PccExceptionHistory } from '@pccExceptions/models/pcc-exception-history';
import { PccExceptionHistoryService } from '@pccExceptions/services/pcc-exception-history.service';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { ProductService } from '@shared/services/product-service/product.service';
import { Table } from 'primeng/table';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-pcc-exception-history-list',
  templateUrl: './pcc-exception-history-list.component.html',
  styleUrls: ['./pcc-exception-history-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class PccExceptionHistoryListComponent implements OnInit {
  pccExceptionHistory: PccExceptionHistory[] = [];
  private loadingDetailsPage = false;
  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  altProvs: string[] = [];
  allProducts: string[] = [];

  @ViewChild('dt') dataTable!: Table;

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private pccExceptionHistoryService: PccExceptionHistoryService,
    private readonly productService: ProductService,
    private readonly altProviderService: AltProviderService,
    private readonly router: Router,
    private authService: MsalService,
    @Self() private readonly clearFilterService: ClearFilterService
  ) {
    this.router.events.subscribe(this.handleRouterEvent.bind(this));
  }

  ngOnInit(): void {
    this.loadingTable = true;

    forkJoin([
      this.altProviderService.getAlternateProviders(),
      this.productService.getAllProducts()
    ])
      .pipe(
        tap(([altProvs, products]) => {
          this.altProvs = [
            ...new Set(
              altProvs
                .filter(x => x.active)
                .map(y => y.alternateProviderName)
                .sort()
            )
          ];
          this.allProducts = [
            ...new Set(
              products
                .filter(x => x.active)
                .map(y => y.productName ?? '')
                .sort()
            )
          ];
        }),
        take(1)
      )
      .subscribe();

    this.route.params.subscribe(params => {
      this.getPccExceptionHistory(params.pccExceptionNumber);
    });
  }

  getPccExceptionHistory(pccExceptionNum: string) {
    this.loadingTable = true;
    let pccException = new PccExceptionHistoryParam(Number(pccExceptionNum));

    this.pccExceptionHistoryService.getPccExceptionHistoryByNumber(pccException).subscribe({
      next: pccExceptionHist => {
        this.pccExceptionHistory = pccExceptionHist;
        this.pccExceptionHistory.forEach(pccExceptionHist => {
          pccExceptionHist.createdDate = pccExceptionHist.createdDate ? new Date(pccExceptionHist.createdDate) : null;
          pccExceptionHist.effectiveEndDate = pccExceptionHist.effectiveEndDate ? new Date(pccExceptionHist.effectiveEndDate) : null;
          pccExceptionHist.effectiveStartDate = pccExceptionHist.effectiveStartDate ? new Date(pccExceptionHist.effectiveStartDate) : null;
          pccExceptionHist.lastModifiedDate = pccExceptionHist.lastModifiedDate ? new Date(pccExceptionHist.lastModifiedDate) : null;
        });
        this.loadingTable = false;
      },
      error: (err: string) => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  private handleRouterEvent(event: Event): void {
    const alreadyLoading = this.loadingDetailsPage;

    if (event instanceof ResolveStart && !alreadyLoading) {
      this.loadingTable = true;
      this.loadingDetailsPage = true;
    }

    if (event instanceof ResolveEnd && alreadyLoading) {
      this.loadingTable = false;
      this.loadingDetailsPage = false;
    }
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
  }
}
