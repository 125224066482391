import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-column-filter, [app-column-filter]',
  templateUrl: './column-filter.component.html'
})
export class ColumnFilterComponent {
  @Input() type = '';

  @Input() field = '';

  @Input() filterMatchMode = '';

  @Input() options: Array<string> = [];
}
