import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-column-value, [app-column-value]',
  templateUrl: './column-value.component.html',
  styleUrls: ['./column-value.component.scss']
})
export class ColumnValueComponent implements OnInit {
  @Input() value: any = '';

  @Input() arrayDisplayValue = '';

  @Input() routing: Array<string> = [];

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    if (this.value === '9999-12-31T00:00:00Z' || this.value === '12/31/9999 00:00:00') {
      this.value = '';
    }

    if (this.arrayDisplayValue && Array.isArray(this.value)) {
      this.value = this.value.map(x => x[this.arrayDisplayValue]);
    }
  }

  onClick(): void {
    if (!this.routing) {
      return;
    }

    this.router.navigate(this.routing);
  }
}
