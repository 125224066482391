import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UploadHealthStatusCode } from '../../models/upload-healthStatus-code';
import { HealthstatuscodesUploadService } from '../../services/healthstatuscodes-upload.service';

@Component({
  selector: 'app-upload-file-detail',
  templateUrl: './upload-file-detail.component.html',
  styleUrls: ['./upload-file-detail.component.scss']
})
export class UploadFileDetailComponent implements OnInit {
  @Input() visible = false;
  @Input() mySelectedHsCodeUploadData?: UploadHealthStatusCode;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<UploadHealthStatusCode>();

  loading = false;
  memberHsUploadFormGroup!: FormGroup;
  errorMessage = '';
  private _seed = 100;
  get seed(): number {
    return this._seed++;
  }

  constructor(
    private readonly fb: FormBuilder,
    private memberhscodesService: HealthstatuscodesUploadService
  ) {}

  ngOnInit(): void {
    this.memberHsUploadFormGroup = this.fb.group({
      id: '',
      filename: '',
      status: '',
      errors: '',
      uploadDate: '',
      createdUser: '',
      lastUploadDate: '',
      modifiedUser: ''
    });
  }

  ClearForm() {
    this.memberHsUploadFormGroup.markAsUntouched();
    this.memberHsUploadFormGroup.reset();
    this.initializeFormGroup();
  }

  private adjustDate() {
    return 'T12:34:56.7890123Z';
  }

  initializeFormGroup() {
    this.memberHsUploadFormGroup.setValue({
      id: '',
      filename: '',
      status: '',
      errors: '',
      uploadDate: '',
      createdUser: '',
      lastUploadDate: '',
      modifiedUser: ''
    });
  }

  cancelModal() {
    this.ClearForm();
    this.cancelEvent.emit(this.visible);
  }
}
