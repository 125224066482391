import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequest } from '@shared/models/filter-request';
import { QueryResponse } from '@shared/models/query-response';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { take } from 'rxjs/operators';
import { Roster } from '@reports/models/roster';

@Injectable({
  providedIn: 'root'
})
export class RosterService {
  private readonly filterRosterEndpoint: string;
  private readonly getRosterPublishDisallowedEndpoint: string;
  private readonly publishRosterEndpoint: string;

  constructor(private http: HttpClient) {
    this.filterRosterEndpoint = environment.apiUrls.filterRoster;
    this.getRosterPublishDisallowedEndpoint = environment.apiUrls.getRosterPublishDisallowed;
    this.publishRosterEndpoint = environment.apiUrls.publishRoster;
  }

  getRoster(filter: LazyLoadEvent, sessionId: string | null): Observable<QueryResponse<Roster>> {
    const request: FilterRequest = {
      querySessionId: sessionId,
      filter
    };

    return this.http
      .post<QueryResponse<Roster>>(this.filterRosterEndpoint, request, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }

  getRosterPublishDisallowed(): Observable<any> {
    return this.http
      .get<any>(this.getRosterPublishDisallowedEndpoint, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }

  publishRoster(): Observable<any> {
    const request = {};

    return this.http
      .post<any>(this.publishRosterEndpoint, request, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }
}
