<div class="header">
  <mat-spinner class="loading" *ngIf="isLoading"></mat-spinner>
  <h2>Roster Variance Detail Page</h2><br>
  <h3>{{this.altProv}} <br> {{this.product}}</h3>
</div>
<p-table #dt [value]="varianceReportDetailList" [loading]="loadingTable" [paginatorDropdownAppendTo]="dt"
  sortMode="single" sortField="id" dataKey="id" [paginator]="true" [showPageLinks]="false" [showFirstLastIcon]="false"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]" [showCurrentPageReport]="true" [rows]="20" [rowHover]="true"
  [columns]="columns" [first]="0" styleClass="p-datatable-gridlines p-datatable-striped"
  stateKey="clinic-maintenance-filters" stateStorage="local"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let column of columns" [pSortableColumn]="column.propertyName">
        <span class="table-header">
          <span [attr.title]="column.longName">{{ column.longName }}</span>
          <p-sortIcon [field]="column.propertyName"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th *ngFor="let column of columns" [ngStyle]="{ width: '100px' }">
        <ng-template [ngIf]="column.type == 'change'" [ngIfElse]="standardFilter">
          <p-columnFilter [field]="column.propertyName" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any"
                [showClear]="true">
                <ng-template let-option pTemplate="item">
                  {{option.label}}
                </ng-template>
              </p-dropdown>
            </ng-template>

          </p-columnFilter>
        </ng-template>
        <ng-template #standardFilter>
          <p-columnFilter #standardFilter [type]="column.type" [field]="column.propertyName"
            [ngStyle]="{ width: '50px' }"></p-columnFilter>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-varianceReportDetail let-expanded="expanded">
    <tr class="table-row-link-pointer">
      <td>
        {{varianceReportDetail['memberId']}}
        <button *ngIf="varianceReportDetail['memberId'] != ''" type="button" mat-button pTooltip="member detail link"
          (click)="gotoLink('/members/' + varianceReportDetail['memberId'])">
          <mat-icon>link</mat-icon>
        </button>
      </td>
      <td>
        {{varianceReportDetail['rule']}}
        <button *ngIf="varianceReportDetail['ruleId'] != ''" type="button" mat-button pTooltip="rule detail link"
          (click)="gotoLink('/rules/edit/' + varianceReportDetail['ruleId'])">
          <mat-icon>link</mat-icon>
        </button>
      </td>
      <td>
        {{varianceReportDetail['pccException']}}
        <button *ngIf="varianceReportDetail['pccException'] != ''" type="button" mat-button
          pTooltip="PCC Exception Detail Popup"
          (click)="openPccExceptionDetail(varianceReportDetail['pccExceptionId'])">
          <mat-icon>filter_none</mat-icon>
        </button>
      </td>
      <td>
        {{varianceReportDetail['memberException']}}
        <button *ngIf="varianceReportDetail['memberExceptionId'] != ''" type="button" mat-button
          pTooltip="Member Exception Detail Popup"
          (click)="openMemberExceptionDetail(varianceReportDetail['memberExceptionId'])">
          <mat-icon>filter_none</mat-icon>
        </button>
      </td>
      <td>{{varianceReportDetail['change']}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns.length">There were no matches to the current filter(s).</td>
    </tr>
  </ng-template>
</p-table>
