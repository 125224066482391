import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PccPickerComponent } from '@shared/components/pcc-picker/pcc-picker.component';
import { PCC } from '@shared/models/pcc';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { GenericDialogLaunchOptions } from '@shared/components/generic-dialog/generic-dialog-launch-options';
import { PccService } from '@shared/services/pcc-service/pcc.service';
import { CloseClinic } from './close-clinic';
import { SpinnerOverlayService } from '@shared/services/spinner-overlay-service/spinner-overlay.service';

@Component({
  selector: 'app-clinic-update',
  templateUrl: './clinic-update.component.html',
  styleUrls: ['./clinic-update.component.scss']
})
export class ClinicUpdateComponent implements OnInit, OnDestroy {
  clinicFormGroup: FormGroup;
  selectedPCC1!: PCC;
  selectedPCC2!: PCC;
  pmiId1: any;
  pmiId2: any;
  product: string = '';

  get pcc1() {
    return this.clinicFormGroup.get('pcc1') as AbstractControl;
  }
  get pccName1() {
    return this.clinicFormGroup.get('pccName1') as AbstractControl;
  }
  get pcc2() {
    return this.clinicFormGroup.get('pcc2') as AbstractControl;
  }
  get pccName2() {
    return this.clinicFormGroup.get('pccName2') as AbstractControl;
  }

  constructor(
    private readonly fb: FormBuilder,
    public dialog: MatDialog,
    private readonly PCCService: PccService,
    private readonly spinnerService: SpinnerOverlayService
  ) {
    this.clinicFormGroup = this.fb.group({
      pcc1: ['', [Validators.required]],
      pccName1: [''],
      pcc2: ['', [Validators.required]],
      pccName2: ['']
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  openPCCPicker(pickerId: string): void {
    const termed = pickerId == '1' ? true : false;
    const selectedPmiId = this.pmiId1 ? this.pmiId1 : this.pmiId2;
    const dialogRef = this.dialog.open(PccPickerComponent, {
      autoFocus: true,
      minHeight: '450px',
      minWidth: 600,
      data: { selectedPmiId: selectedPmiId, selectedProduct: this.product, termed: termed }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      if (pickerId == '1') {
        this.selectedPCC1 = result;
        this.clinicFormGroup.patchValue({
          pcc1: result.pccId,
          pccName1: result.pccName
        });
        this.pmiId1 = result.pmiId;
        this.product = result.product;
      }
      if (pickerId == '2') {
        this.selectedPCC2 = result;
        this.clinicFormGroup.patchValue({
          pcc2: result.pccId,
          pccName2: result.pccName
        });
        this.pmiId2 = result.pmiId;
        this.product = result.product;
      }
    });
  }

  resetForm(): void {
    window.location.reload();
  }

  displayMessage(header: string, msg: string): void {
    this.dialog.open(GenericDialogComponent, {
      width: '500px',
      data: {
        header: header,
        body: msg
      } as GenericDialogLaunchOptions
    });
  }

  mergeClinic(): void {
    if (this.pmiId1 && this.pmiId2) {
      this.spinnerService.show();
      const clinic: CloseClinic = {
        targetId: this.pmiId2,
        mergeFromId: this.pmiId1,
        closureType: 'merger'
      };

      this.PCCService.closeClinic(clinic).subscribe(resp => {
        this.spinnerService.hide();
        if (resp.status == '200') {
          this.displayMessage('', 'Clinic Merged !');
        }
        this.spinnerService.hide();
        this.resetForm();
      });
    }
  }

  closeClinic(): void {
    if (this.pmiId1 && this.pmiId2) {
      this.spinnerService.show();
      const clinic: CloseClinic = {
        targetId: this.pmiId2,
        mergeFromId: this.pmiId1,
        closureType: 'closure'
      };

      this.PCCService.closeClinic(clinic).subscribe(resp => {
        this.spinnerService.hide();
        if (resp.status == '200') {
          this.displayMessage('', 'Clinic Closed !');
        }
        this.spinnerService.hide();
        this.resetForm();
      });
    }
  }

  onSubmit(): void {}
}
