import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogLaunchOptions } from './generic-dialog-launch-options';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html'
})
export class GenericDialogComponent {
  readonly header: string;
  readonly body: string;
  cancelText: string;
  confirmText: string;
  displayCancel: boolean;

  selection = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GenericDialogLaunchOptions,
    private dialogRef: MatDialogRef<GenericDialogComponent>
  ) {
    this.header = this.data.header;
    this.body = this.data.body;
    this.confirmText = this.data.confirmText ?? 'OK';
    this.cancelText = this.data.cancelText ?? 'Cancel';
    this.displayCancel = this.data.displayCancel ?? true;

    if (!this.header && !this.body) {
      this.dialogRef.close();
    }
  }

  cancel(): void {
    this.selection = false;
    this.dialogRef.close(this.selection);
  }

  confirm(): void {
    this.selection = true;
    this.dialogRef.close(this.selection);
  }
}
