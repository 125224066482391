import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ListboxModule } from 'primeng/listbox';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  exports: [
    DialogModule,
    DropdownModule,
    CalendarModule,
    InputTextareaModule,
    RippleModule,
    CheckboxModule,
    DynamicDialogModule,
    InputTextModule,
    MultiSelectModule,
    TableModule,
    ProgressSpinnerModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    ButtonModule,
    ListboxModule,
    TooltipModule,
    InputNumberModule
  ],
})
export class PrimengModule {}
