import FileSaver from 'file-saver';

export function exportDataToExcel(excelData: Array<any>, name: string): void {
  import('xlsx').then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(excelData);
    const workbook = {
      Sheets: { [name]: worksheet },
      SheetNames: [name]
    };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, name + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}
