import { Component, OnInit, Self, SkipSelf, ViewChild } from '@angular/core';
import { Rule } from '@rules/models/rule';
import { RulesService } from '@rules/services/rules-service/rules.service';
import { ResolveEnd, ResolveStart, Router, Event } from '@angular/router';
import { RuleCriterion } from '@rules/models/rule-criterion';
import { take, tap } from 'rxjs/operators';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { ProductsService } from 'src/app/products/services/products.service';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { exportDataToExcel } from '@shared/util/excel-exporter';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { MsalService } from '@azure/msal-angular';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-rules-list',
  templateUrl: './rules-list.component.html',
  styleUrls: ['./rules-list.component.scss'],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class RulesListComponent implements OnInit {
  private loadingDetailsPage = false;

  @ViewChild(Table) table!: Table;

  pagingSizes = [20, 40, 60, 80, 100];
  loadingTable = true;
  rules: Rule[] = [];
  rows = this.pagingSizes[0];
  totalRules = 0;
  altProvs: string[] = [];
  allProducts: string[] = [];
  currentDateTime = new Date();

  constructor(
    private dialog: MatDialog,
    private readonly router: Router,
    @Self() private readonly clearFilterService: ClearFilterService,
    private readonly rulesService: RulesService,
    private readonly productsService: ProductsService,
    private readonly alternateProviderService: AltProviderService,
    private authService: MsalService
  ) {
    this.router.events.subscribe(this.handleRouterEvent.bind(this));
  }

  ngOnInit(): void {
    this.loadingTable = true;

    const userRoles = (this.authService.instance.getActiveAccount()?.idTokenClaims as any).roles;
    
    forkJoin([
      this.rulesService.getRules(),
      this.alternateProviderService.getAlternateProviders(),
      this.productsService.getAllProducts()
    ])
      .pipe(
        tap(([rules, altProvs, products]) => {
          this.rules = rules;
          this.rules?.forEach(
            x => (
              x.active = this.getActive(x.effectiveStartDate as string, x.effectiveEndDate as string),
              x.effectiveStartDate = new Date(x.effectiveStartDate!),
              x.effectiveEndDate = new Date(x.effectiveEndDate!)
            )
          );
          this.rules?.sort((x, y) => x.ruleNumber - y.ruleNumber);
          this.totalRules = rules?.length;
          this.altProvs = altProvs.filter(x => x.active).map(y => y.alternateProviderName);
          this.allProducts = products.filter(x => x.active).map(y => y.productName ?? '');

          this.loadingTable = false;
        }),
        take(1)
      )
      .subscribe();
  }

  getActive(start: string, end: string): boolean {
    return this.currentDateTime > new Date(start) && this.currentDateTime < new Date(end);
  }

  getFormattedProducts(products: string[]): string {
    return products?.join('\r\n');
  }

  getCriteriaValues(criteria: RuleCriterion): string {
    const overflowThreshold = 5;
    const values = criteria?.values?.slice(0, overflowThreshold).map(val => val.name);
    let valuesText = values.join(', ');

    if (criteria.values.length > overflowThreshold) {
      valuesText += '...';
    }

    return valuesText;
  }

  exportExcel(): void {
    const excelData: any[] = this.formatExcelData();

    exportDataToExcel(excelData, 'Rules');
  }

  private formatExcelData(): Array<any> {
    return this.rules
      .map(x => ({
        RuleId: x.ruleNumber,
        Name: x.name,
        Priority: x.priority,
        AlternateProvider: x.altProv.alternateProviderName,
        EffectiveStartDate: x.effectiveStartDate,
        EffectiveEndDate: x.effectiveEndDate,
        Products: x.products.join(', '),
        Criteria: x.criteria
          .map(
            y =>
              y.criteriaName +
              ' ' +
              y.operator +
              ': ' +
              y.values.map(z => z.name + ', ').toString() +
              '\n'
          )
          .toString(),
        ChangeReason: x.changeReason,
        Notes: x.notes
      }))
      .sort((x, y) => x.RuleId - y.RuleId);
  }

  private handleRouterEvent(event: Event): void {
    const alreadyLoading = this.loadingDetailsPage;

    if (event instanceof ResolveStart && !alreadyLoading) {
      this.loadingTable = true;
      this.loadingDetailsPage = true;
    }

    if (event instanceof ResolveEnd && alreadyLoading) {
      this.loadingTable = false;
      this.loadingDetailsPage = false;
    }
  }

  public clearFilter(storageKey: string): void {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
