import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RuleDetailsComponent } from '@rules/components/rule-details/rule-details.component';
import { RulesListComponent } from '@rules/components/rules-list/rules-list.component';
import { RuleResolver } from '@rules/resolvers/rule-resolver/rule.resolver';
import { PccExceptionListComponent } from '@pccExceptions/components/pcc-exception-list/pcc-exception-list.component';
import { PccExceptionEntryComponent } from '@pccExceptions/components/pcc-exception-entry/pcc-exception-entry.component';
import { MemberMaintenanceListComponent } from '@memberMaintenance/components/member-maintenance-list/member-maintenance-list.component';
import { HealthStatusCodeDialogComponent } from './healthStatusCodes/components/health-status-code-dialog/health-status-code-dialog.component';
import { HealthStatusCodesListComponent } from './healthStatusCodes/components/health-status-codes-list/health-status-codes-list.component';
import { MemberMaintenanceDetailComponent } from '@memberMaintenance/components/member-maintenance-detail/member-maintenance-detail/member-maintenance-detail.component';
import { MemberExceptionListComponent } from './memberExceptions/components/member-exception-list/member-exception-list.component';
import { CareSystemsListComponent } from './careSystems/components/care-systems-list/care-systems-list.component';
import { ProductsListComponent } from './products/components/products-list/products-list.component';
import { ProductsAddComponent } from './products/components/products-add/products-add.component';
import { LivingStatusCodesListComponent } from './livingStatusCodes/components/living-status-codes-list/living-status-codes-list.component';
import { LivingStatusCodesAddComponent } from './livingStatusCodes/components/living-status-codes-add/living-status-codes-add.component';
import { ExceptionSourcesListComponent } from './exceptionSources/components/exception-sources-list/exception-sources-list.component';
import { ExceptionSourcesAddComponent } from './exceptionSources/components/exception-sources-add/exception-sources-add.component';
import { AlternateProvidersListComponent } from './alternateProviders/components/alternate-providers-list/alternate-providers-list.component';
import { AlternateProvidersAddComponent } from './alternateProviders/components/alternate-providers-add/alternate-providers-add.component';
import { CountiesListComponent } from './counties/components/counties-list/counties-list.component';
import { CountyEditComponent } from './counties/components/county-edit/county-edit.component';
import { AltProvResultsListComponent } from './reports/altprov-results/altprov-results-list.component';
import { UnassignedMembersListComponent } from './reports/unassigned-members/unassigned-members-list.component';
import { MembersMultiAssignedListComponent } from '@reports/members-multi-assigned/members-multi-assigned-list.component';
import { MemberExceptionDetailComponent } from '@memberExceptions/components/member-exception-detail/member-exception-detail.component';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './home/home.component';
import { HealthstatuscodesUploadListComponent } from './uploads/memberHealthStatusCodesUploads/components/member-healthstatuscodes-upload-list/healthstatuscodes-upload-list.component';
import { RosterListComponent } from 'src/app/rosters/roster-list.component';
import { RoleGuard } from '@shared/guards/roleGuard';
import { ClinicUpdateComponent } from './providers/components/clinic-update/clinic-update.component';
import { PccMaintenanceComponent } from './providers/components/pcc-maintenance/pcc-maintenance.component';
import { PccEditComponent } from './providers/components/pcc-edit/pcc-edit.component';
import { HealthStatusCodesHistoryListComponent } from './healthStatusCodesHistory/components/health-status-codes-history-list/health-status-codes-history-list.component';
import { RosterVarianceListComponent } from '@reports/roster-variance/roster-variance-list/roster-variance-list.component';
import { RosterVarianceDetailComponent } from '@reports/roster-variance/roster-variance-detail/roster-variance-detail.component';
import { VarianceReportDetailResolver } from '@reports/resolver/variance-report/variance-report-detail.resolver';
import { RuleHistoryComponent } from '@rules/components/rule-history/rule-history.component';
import { AlternateProvidersHistoryComponent } from './alternateProviders/components/alternate-providers-history/alternate-providers-history.component';
import { MemberExceptionHistoryComponent } from './memberExceptions/components/member-exception-history/member-exception-history.component';
import { PccExceptionHistoryListComponent } from '@pccExceptions/components/pcc-exception-history-list/pcc-exception-history-list.component';
import { MemberMaintenanceHistoryListComponent } from '@memberMaintenance/components/member-maintenance-history-list/member-maintenance-history-list.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'rules/list',
    component: RulesListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvEditRules', 'AltProvReadRules'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'rules/edit/:id',
    component: RuleDetailsComponent,
    resolve: { rule: RuleResolver },
    data: { roles: ['AltProvAdmin', 'AltProvEditRules', 'AltProvResults'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'rules/add',
    component: RuleDetailsComponent,
    data: { roles: ['AltProvAdmin', 'AltProvEditRules'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'members/list',
    component: MemberMaintenanceListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvMemberEdit', 'AltProvMemberRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'members/:id',
    component: MemberMaintenanceDetailComponent,
    data: { roles: ['AltProvAdmin', 'AltProvMemberEdit', 'AltProvResults'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'memberExceptions',
    component: MemberExceptionListComponent,
    data: {
      roles: [
        'AltProvAdmin',
        'AltProvEditMemberExceptions',
        'AltProvReadMemberExceptions',
        'AltProvResults'
      ]
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'pccExceptions',
    component: PccExceptionListComponent,
    data: {
      roles: [
        'AltProvAdmin',
        'AltProvEditPCCExceptions',
        'AltProvReadPCCExceptions',
        'AltProvResults'
      ]
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'healthStatusCodes',
    component: HealthStatusCodesListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'healthstatuscodes',
    component: HealthStatusCodesListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'healthstatuscodeshistory/:healthStatusCode',
    component: HealthStatusCodesHistoryListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'caresystems',
    component: CareSystemsListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'products',
    component: ProductsListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'livingstatuscodes',
    component: LivingStatusCodesListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'exceptionsources',
    component: ExceptionSourcesListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'alternateproviders',
    component: AlternateProvidersListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'counties',
    component: CountiesListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'altProvResults',
    component: AltProvResultsListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvResults'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'altProvResults/:ruleNumber',
    component: AltProvResultsListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvResults'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'membersMultiAssigned',
    component: MembersMultiAssignedListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvResults'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'unassigned-members',
    component: UnassignedMembersListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvResults'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'rosterVarianceReportList',
    component: RosterVarianceListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvResults'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'rosterVarianceReportDetail/:id1/:id2/:id3/:id4',
    component: RosterVarianceDetailComponent,
    resolve: { rosterVarianceDetail: VarianceReportDetailResolver },
    data: { roles: ['AltProvAdmin', 'AltProvResults'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  { path: 'login-failed', redirectTo: 'products' },
  {
    path: 'healthstatuscodesuploads',
    component: HealthstatuscodesUploadListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvHealthStatusImport'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'rosters',
    component: RosterListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvRosterCLS'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'providers/clinic-update',
    component: ClinicUpdateComponent,
    data: { roles: ['AltProvAdmin', 'AltProvEditPCCExceptions'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'providers/pcc-maintenance',
    component: PccMaintenanceComponent,
    data: { roles: ['AltProvAdmin', 'AltProvReadPCCExceptions', 'AltProvEditPCCExceptions'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'rulehistory/:ruleNumber',
    component: RuleHistoryComponent,
    data: { roles: ['AltProvAdmin', 'AltProvEditRules', 'AltProvReadRules'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'altprovhistory/:id',
    component: AlternateProvidersHistoryComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'memberexceptionhistory/:exceptionNumber',
    component: MemberExceptionHistoryComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceRead', 'AltProvListMaintenanceEdit'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'pccexceptionhistory/:pccExceptionNumber',
    component: PccExceptionHistoryListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'memberhistory/:id',
    component: MemberMaintenanceHistoryListComponent,
    data: { roles: ['AltProvAdmin', 'AltProvListMaintenanceEdit', 'AltProvListMaintenanceRead'] },
    canActivate: [MsalGuard, RoleGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
