// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  defaultScope: 'api://2e84698b-22d2-4ca0-8b62-5a004764a5ae/altprovstg_users',
  clientId: 'c5a411da-c1ed-4d84-b9f0-7a78d05e41bf',
  redirectUri: 'https://altprovstg.ucare.org',
  subscriptionKey: '328a21218bc7439793c682500285c55a',
  apiUrls: {
    getRules: 'https://lzapimstg.ucare.org/altprov/rules/all',
    getRuleById: 'https://lzapimstg.ucare.org/altprov/RuleById/',
    getChangeReasons: 'https://lzapimstg.ucare.org/altprov/getchangereasons',
    saveRule: 'https://lzapimstg.ucare.org/altprov/rules/save',
    getRuleHistoryById: 'https://lzapimstg.ucare.org/altprov/getrulehistory',
    getAlternateProviders: 'https://lzapimstg.ucare.org/altprov/getalternateproviders',
    saveAlternateProvider: 'https://lzapimstg.ucare.org/altprov/savealternateproviders',
    getAltProvHistory: 'https://lzapimstg.ucare.org/altprov/getaltprovhistory',
    getRuleHistoryId: 'https://lzapimstg.ucare.org/altprov/getrulehistory',
    getAllCounties: 'https://lzapimstg.ucare.org/altprov/counties',
    saveCounty: 'https://lzapimstg.ucare.org/altprov/savecounties',
    getAllPccExceptions: 'https://lzapimstg.ucare.org/altprov/pccExceptions',
    getPccExceptionById: 'https://lzapimstg.ucare.org/altprov/pccException/',
    getPccExceptionHistoryByNumber:
      'https://lzapimstg.ucare.org/altprov/getpccexceptionhistorybynumber',
    savePccException: 'https://lzapimstg.ucare.org/altprov/pccException/upsert',
    deletePccException: 'https://lzapimstg.ucare.org/altprov/pccException/delete',
    getAllHealthStatusCodes: 'https://lzapimstg.ucare.org/altprov/gethealthstatuscodes',
    getAllHealthStatusCodesHistory:
      'https://lzapimstg.ucare.org/altprov/gethealthstatuscodeshistory',
    getAllProducts: 'https://lzapimstg.ucare.org/altprov/getproducts',
    saveProducts: 'https://lzapimstg.ucare.org/altprov/saveproducts',
    saveHealthStatusCode: 'https://lzapimstg.ucare.org/altprov/saveHealthStatusCodes',
    getMemberById: 'https://lzapimstg.ucare.org/altprov/getmemberbyid',
    saveMember: 'https://lzapimstg.ucare.org/altprov/membersave',
    filterMembers: 'https://lzapimstg.ucare.org/altprov/FilterMembers',
    getMemberHistoryById: 'https://lzapimstg.ucare.org/altprov/getmemberhistorybyid',
    filterPCCs: 'https://lzapimstg.ucare.org/altprov/pcclocations',
    filterMemberExceptions: 'https://lzapimstg.ucare.org/altprov/FilterMemberExceptions',
    getMemberExceptionById: 'https://lzapimstg.ucare.org/altprov/memberException/',
    saveMemberException: 'https://lzapimstg.ucare.org/altprov/memberException/upsert',
    getAllCareSystems: 'https://lzapimstg.ucare.org/altprov/getcaresystems',
    getAllLivingStatusCodes: 'https://lzapimstg.ucare.org/altprov/getlivingstatuscodes',
    saveLivingStatusCode: 'https://lzapimstg.ucare.org/altprov/savelivingstatuscodes',
    getAllExceptionSources: 'https://lzapimstg.ucare.org/altprov/getexceptionsources',
    saveExceptionSource: 'https://lzapimstg.ucare.org/altprov/saveexceptionsources',
    getMemberExceptionByMemberId:
      'https://lzapimstg.ucare.org/altprov/memberException/memberID',
    getAllUnassignedMembers: 'https://lzapimstg.ucare.org/altprov/FilterMemberUnassigned',
    filterAltProvResults: 'https://lzapimstg.ucare.org/altprov/FilterMemberHistory',
    getRosterPublishDateInfo:
      'https://lzapimstg.ucare.org/altprov/getAltProvRosterPublishDateInfo',
    filterRosterVarianceReport:
      'https://lzapimstg.ucare.org/altprov/FilterRosterVarianceReport',
    filterRosterVarianceDetail:
      'https://lzapimstg.ucare.org/altprov/FilterRosterVarianceDetail',
    filterMembersMultiAssigned:
      'https://lzapimstg.ucare.org/altprov/FilterMembersMultiAssigned',
    getAllHealthStatusCodeUploads:
      'https://lzapimstg.ucare.org/altprov/MemberHsCodesUploader',
    upLoadMemberHsCodes: 'https://lzapimstg.ucare.org/altprov/MemberHsCodesUploader',
    getAllHsImportsHistories:
      'https://lzapimstg.ucare.org/altprov/GetAllMemberHsImportHistories',
    getFileFromBlobStorage: 'https://lzapimstg.ucare.org/altprov/FileDownLoader',
    filterRoster: 'https://lzapimstg.ucare.org/altprov/FilterRoster',
    getRosterPublishDisallowed:
      'https://lzapimstg.ucare.org/altprov/getRosterPublishDisallowed',
    publishRoster:
      'https://lzapimstg.ucare.org/altprov/RosterGeneratorOrchestrator_PublishRoster',
    getAltProvResultDatesEndpoint:
      'https://lzapimstg.ucare.org/altprov/getAltProvResultDates',
    closeClinic: 'https://lzapimstg.ucare.org/altprov/ManuallyCloseClinicTrigger',
    editPCC: 'https://lzapimstg.ucare.org/altprov/UpsertPcc',
    getMemberExceptionHistory:
      'https://lzapimstg.ucare.org/altprov/getmemberexceptionhistory'
  },
  production: false,
  useInMemoryDatabase: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
