import { Component, OnInit, Self, SkipSelf, ViewChild } from '@angular/core';
import { RuleHistoryService } from '@rules/services/rule-history-service/rule-history.service';
import { ResolveEnd, ResolveStart, Router, Event, ActivatedRoute } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { ProductsService } from 'src/app/products/services/products.service';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { MsalService } from '@azure/msal-angular';
import { Table } from 'primeng/table';
import { RuleHistory } from '@rules/models/rule-history';
import { RuleCriterion } from '@rules/models/rule-criterion';

@Component({
  selector: 'app-rule-history',
  templateUrl: './rule-history.component.html',
  styleUrls: ['./rule-history.component.scss'],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class RuleHistoryComponent implements OnInit {
  private loadingDetailsPage = false;

  @ViewChild(Table) table!: Table;

  pagingSizes = [20, 40, 60, 80, 100];
  loadingTable = true;
  rule: RuleHistory[] = [];
  rows = this.pagingSizes[0];
  totalRules = 0;
  altProvs: string[] = [];
  allProducts: string[] = [];
  currentDateTime = new Date();

  constructor(
    private readonly route: ActivatedRoute,
    private dialog: MatDialog,
    private readonly router: Router,
    @Self() private readonly clearFilterService: ClearFilterService,
    private readonly ruleHistoryService: RuleHistoryService,
    private readonly productsService: ProductsService,
    private readonly alternateProviderService: AltProviderService,
    private authService: MsalService
  ) {
    this.router.events.subscribe(this.handleRouterEvent.bind(this));
  }

  ngOnInit(): void {
    this.loadingTable = true;

    const userRoles = (this.authService.instance.getActiveAccount()?.idTokenClaims as any).roles;

    this.route.params.subscribe(params => {
      this.getRuleHistory(params.ruleNumber);
    }); 
  }
  
  getRuleHistory(ruleNumber: string) {
    forkJoin([
      this.ruleHistoryService.getRuleHistoryById(ruleNumber),
      this.alternateProviderService.getAlternateProviders(),
      this.productsService.getAllProducts()
    ])
      .pipe(
        tap(([ruleHistory, altProvs, products]) => {
          this.rule = ruleHistory;
          this.rule?.forEach(
            x => (
              x.active = this.getActive(x.effectiveStartDate as string, x.effectiveEndDate as string),
              x.effectiveEndDate = x.effectiveEndDate ? new Date(x.effectiveEndDate) : null,
              x.effectiveStartDate = x.effectiveStartDate ? new Date(x.effectiveStartDate) : null,
              x.updatedDate = x.updatedDate ? new Date(x.updatedDate) : null
          )
          );
          this.rule?.sort((x, y) => x.ruleNumber - y.ruleNumber);
          this.totalRules = ruleHistory?.length;
          this.altProvs = altProvs.filter(x => x.active).map(y => y.alternateProviderName);
          this.allProducts = products.filter(x => x.active).map(y => y.productName ?? '');

          this.loadingTable = false;
        }),
        take(1)
      )
      .subscribe();
  }

  getActive(start: string, end: string): boolean {
    return this.currentDateTime > new Date(start) && this.currentDateTime < new Date(end);
  }

  getFormattedProducts(products: string[]): string {
    return products?.join('\r\n');
  }

  getCriteriaValues(criteria: RuleCriterion): string {
    const overflowThreshold = 5;
    const values = criteria?.values?.slice(0, overflowThreshold).map(val => val.name);
    let valuesText = values.join(', ');

    if (criteria.values.length > overflowThreshold) {
      valuesText += '...';
    }

    return valuesText;
  }

  private handleRouterEvent(event: Event): void {
    const alreadyLoading = this.loadingDetailsPage;

    if (event instanceof ResolveStart && !alreadyLoading) {
      this.loadingTable = true;
      this.loadingDetailsPage = true;
    }

    if (event instanceof ResolveEnd && alreadyLoading) {
      this.loadingTable = false;
      this.loadingDetailsPage = false;
    }
  }

  public clearFilter(storageKey: string): void {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
