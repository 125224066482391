<p-table
  #dt
  [value]="pccs"
  [loading]="loadingTable"
  [paginatorDropdownAppendTo]="dt"
  sortMode="single"
  sortField="id"
  dataKey="id"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="pagingSizes"
  [showCurrentPageReport]="true"
  [rows]="pagingSizes[0]"
  [rowHover]="true"
  [columns]="cols"
  [first]="0"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateKey="clinic-maintenance-filters"
  stateStorage="local"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [autoLayout]="true"
>
  <ng-template pTemplate="caption">
    <h2>Clinic Maintenance</h2>
    <div class="p-formgroup-inline"></div>
    <div class="p-d-flex p-jc-between">
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('clinic-maintenance-filters')"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th [ngStyle]="{ width: '50px' }">
        <div class="p-d-flex p-jc-between p-ai-center"><!-- Empty space for edit column --></div>
      </th>
      <th *ngFor="let column of columns" [pSortableColumn]="column.propertyName">
        <span class="table-header">
          <span [attr.title]="column.longName">{{ column.shortName }}</span>
          <p-sortIcon [field]="column.propertyName"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th><!-- Empty space for edit column --></th>
      <th *ngFor="let column of columns" [ngStyle]="{ width: '150px' }">
        <ng-template [ngIf]="column.type == 'date'" [ngIfElse]="standardFilter">
          <p-columnFilter type="date" [field]="column.propertyName" matchMode="dateBefore" display="menu"></p-columnFilter>
        </ng-template>
        <ng-template #standardFilter>
          <p-columnFilter
            #standardFilter
            [type]="column.type"
            [field]="column.propertyName"
            [ngStyle]="{ width: '150px' }"
          ></p-columnFilter>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-pcc let-expanded="expanded">
    <tr class="table-row-link-pointer">
      <td>
        <button
          type="button"
          pButton
          pRipple
          title="Edit"
          icon="pi pi-pencil"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
          (click)="editClinicDetails(pcc)"
          [disabled]="!userHasEdit()"
        ></button>
      </td>
      <td *ngFor="let column of cols">
        <ng-container *ngIf="column.type === 'date'; else second">
          {{ pcc[column.propertyName] | date: 'MM/dd/yyyy' }}
        </ng-container>
        <ng-template #second>
          <ng-container *ngIf="column.type === 'boolean'; else default">
            <mat-checkbox [checked]="pcc[column.propertyName]" [disabled]="true"></mat-checkbox>
          </ng-container>
        </ng-template>
        <ng-template #default>
          {{ pcc[column.propertyName] }}
        </ng-template>
        <!-- {{
          column.type !== 'date'
            ? pcc[column.propertyName]
            : (pcc[column.propertyName] | date: 'MM/dd/yyyy')
        }} -->
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="cols.length">There were no matches to the current filter(s).</td>
    </tr>
  </ng-template>
</p-table>
<div></div>
<app-pcc-edit
  *ngIf="displayEditDialog"
  [visible]="displayEditDialog"
  [myPccData]="selectedPCC"
  [pccs]="pccs"
  (cancelEvent)="cancelModal($event)"
  (saveEvent)="updatePCC($event)"
></app-pcc-edit>
