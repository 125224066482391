import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { Product } from '@shared/models/product';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private readonly getAllProductsEndPoint: string;

  constructor(private http: HttpClient) {
    this.getAllProductsEndPoint = environment.apiUrls.getAllProducts;
  }

  getAllProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(this.getAllProductsEndPoint, {
      headers: AzureSubscriptionHeader
    });
  }
}
