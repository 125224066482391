<p-table
  #dt
  [value]="memberExceptionHistory"
  [loading]="loadingTable"
  [paginatorDropdownAppendTo]="dt"
  sortMode="single"
  sortField="id"
  dataKey="id"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="pagingSizes"
  [showCurrentPageReport]="true"
  [rows]="pagingSizes[0]"
  [rowHover]="true"
  [columns]="cols"
  [first]="0"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateKey="member-exception-history-filters"
  stateStorage="local"
  [autoLayout]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
>
  <ng-template pTemplate="caption">
    <h2>Member Exception History</h2>
    <div class="p-d-flex p-jc-between">
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('member-exception-history-filters')"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let column of columns" [pSortableColumn]="column.field">
        <span class="table-header">
          <span [attr.title]="column.longName">{{ column.shortName }}</span>
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th *ngFor="let column of columns" [ngStyle]="{ width: '150px' }">
        <ng-template [ngIf]="column.type == 'date'" [ngIfElse]="standardFilter">
          <p-columnFilter type="date" [field]="column.field" display="menu" matchMode="dateBefore" class="p-ml-auto"></p-columnFilter>
        </ng-template>
        <ng-template #standardFilter>
          <p-columnFilter
            #standardFilter
            [type]="column.type"
            [field]="column.field"
            [ngStyle]="{ width: '150px' }"
          ></p-columnFilter>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{ getValue(rowData, col) }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td style="text-align: center" [attr.colspan]="columns.length">No history records found.</td>
    </tr>
  </ng-template>
</p-table>
