<p-table
  #dt
  [value]="memberRecord"
  [loading]="loadingTable"
  paginatorDropdownAppendTo="body"
  sortMode="single"
  sortField="versionEndDate" [sortOrder] = "-1"
  dataKey="id"
  stateStorage="local"
  stateKey="member-history-list-filters"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rows]="20"
  [rowHover]="true"
  [first]="0"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [columns]="selectedColumns"
  (onStateRestore)="onStateRestore($event)"
  (onColReorder)="onColumnReorder()"
  [reorderableColumns]="true"
  [autoLayout]="true"
>
  <ng-template pTemplate="caption">
    <h2>Member Maintenance History</h2>
    <div class="p-d-flex p-jc-between">
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('member-history-list-filters')"
      ></button>
      <p-multiSelect
        [options]="allColumns"
        optionDisabled="static"
        [(ngModel)]="selectedColumns"
        optionLabel="shortName"
        selectedItemsLabel="{0} columns selected"
        placeholder="Choose Columns"
        appendTo="body"
      >
      </p-multiSelect>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let column of columns"
        [pSortableColumn]="column.field"
        pReorderableColumn
        [ngStyle]="{ width: '150px' }"
      >
        <span class="reorderable-table-header">
          <span [attr.title]="column.longName">{{ column.shortName }}</span>
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th *ngFor="let column of columns" [ngStyle]="{ width: '150px' }">
        <ng-template
          [ngIf]="column.type == 'multiselect' && column.type != 'text' && column.type != 'date' && column.type !='datetime'"
          [ngIfThen]="multiSelect"
        ></ng-template>
        <ng-template
          [ngIf]="column.type == 'text' && column.type != 'multiselect' && column.type != 'date' && column.type !='datetime'"
          [ngIfThen]="standardFilter"
        ></ng-template>
        <ng-template
          [ngIf]="column.type == 'date' && column.type != 'multiselect' && column.type != 'text' && column.type !='datetime'"
          [ngIfThen]="dateColumnFilter"
        ></ng-template>
        <ng-template
          [ngIf]="column.type == 'datetime' && column.type != 'multiselect' && column.type != 'text' && column.type !='date'"
          [ngIfThen]="dateTimeColumnFilter"
        ></ng-template>
        <ng-template
          [ngIf]="
            column.type == 'none' &&
            column.type != 'date' &&
            column.type != 'multiselect' &&
            column.type != 'text' &&
            column.type != 'datetime'
          "
          [ngIfThen]="noColumnFilter"
        ></ng-template>

        <ng-template #multiSelect>
          <p-columnFilter #multiSelect [field]="column.field" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="column.options"
                [optionLabel]="column.options"
                (onChange)="filter($event.value, column.field, 'in')"
                placeholder="Any"
                appendTo="body"
                styleClass="{ width: '200px' }"
                [virtualScroll]="true"
                [itemSize]="30"
              >
                <ng-template let-option pTemplate="item"
                  ><span [ngStyle]="{ width: '200px' }">{{ option }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </ng-template>
        <ng-template #standardFilter>
          <p-columnFilter
            #standardFilter
            [type]="column.type"
            [field]="column.field"
            [ngStyle]="{ width: '150px' }"
          ></p-columnFilter>
        </ng-template>
        <ng-template #dateColumnFilter
          ><p-columnFilter
            #dateColumnFilter
            type="date"
            [field]="column.field"
            display="menu"
            matchMode="dateBefore"
            [showAddButton]="false"
          ></p-columnFilter
        ></ng-template>
        <ng-template #dateTimeColumnFilter
          ><p-columnFilter
            #dateTimeColumnFilter
            type="date"
            [field]="column.field"
            display="menu"
            matchMode="dateBefore"
            [showAddButton]="false"
          ></p-columnFilter
        ></ng-template>
        <ng-template #noColumnFilter></ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-member let-columns="columns" let-expanded="expanded">
    <tr>
      <td *ngFor="let column of columns" [ngStyle]="{ width: '150px' }">
        <ng-container *ngIf="column.type === 'date'; else elseif1">
          <time [attr.datetime]="member[column.field]">{{
            member[column.field] | date: 'MM/dd/yyyy'
          }}</time>
        </ng-container>
        <ng-template #elseif1>
          <ng-container *ngIf="column.type === 'datetime'; else else1">
            <time [attr.datetime]="member[column.field]">{{
              member[column.field] | date: 'MM/dd/yyyy hh:mm:ss:SSSSSS'
            }}</time>
          </ng-container>
        </ng-template>
        <ng-template #else1>
          {{ member[column.field] }}
        </ng-template>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">There were no matches to the current filter(s).</td>
    </tr>
  </ng-template>
</p-table>
