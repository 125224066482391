<p-table
  #dt
  [value]="members"
  [lazy]="true"
  (onLazyLoad)="loadMembersLazy($event)"
  [loading]="loadingTable"
  paginatorDropdownAppendTo="body"
  sortMode="single"
  sortField="id"
  dataKey="id"
  stateStorage="local"
  stateKey="members-list-filters"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [showCurrentPageReport]="true"
  [rows]="20"
  [rowHover]="true"
  [first]="0"
  [totalRecords]="totalMembers"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [columns]="selectedColumns"
  (onStateRestore)="onStateRestore($event)"
  (onColReorder)="onColumnReorder()"
  [reorderableColumns]="true"
  [autoLayout]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
>
  <ng-template pTemplate="caption">
    <h2>Members</h2>
    <div class="p-d-flex p-jc-between">
      <button
        type="button"
        pButton
        icon="pi pi-plus"
        label="Add a Member"
        [routerLink]="['/members/add']"
        [disabled]="!userHasEdit()"
      ></button>
      <mat-checkbox
        class="p-mr-3 p-flew-row"
        color="primary"
        [checked]="showTermed"
        (change)="toggleTermedMembersCheckbox($event)"
        >Show Termed Members
      </mat-checkbox>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('members-list-filters')"
      ></button>
      <p-multiSelect
        [options]="allColumns"
        optionDisabled="static"
        [(ngModel)]="selectedColumns"
        optionLabel="shortName"
        selectedItemsLabel="{0} columns selected"
        placeholder="Choose Columns"
        appendTo="body"
      >
      </p-multiSelect>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns class="table-header">
    <tr>
      <!-- Edit-->
      <th style="width: 0px"></th>
      <th style="width: 0px"></th>
      <th
        *ngFor="let column of columns"
        [pSortableColumn]="column.field"
        pReorderableColumn
        [ngStyle]="{ width: '150px' }"
      >
        <span class="reorderable-table-header">
          <span [attr.title]="column.longName">{{ column.shortName }}</span>
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <!-- Edit -->
      <th></th>
      <th></th>
      <th *ngFor="let column of columns" [ngStyle]="{ width: '150px' }">
        <ng-container [ngSwitch]="column.type">
          <ng-template [ngSwitchCase]="'multiselect'">
            <p-columnFilter [field]="column.field" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="column.options"
                  (onChange)="filter($event.value, column.field, 'in')"
                  placeholder="Any"
                  appendTo="body"
                  styleClass="{ width: '200px' }"
                  [virtualScroll]="true"
                  [itemSize]="30"
                >
                  <ng-template let-option pTemplate="item"
                    ><span [ngStyle]="{ width: '200px' }">{{ option }}</span></ng-template
                  >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </ng-template>
          <ng-template [ngSwitchCase]="'text'">
            <p-columnFilter
              type="text"
              [field]="column.field"
              matchMode="startsWith"
            ></p-columnFilter>
          </ng-template>
          <ng-template [ngSwitchCase]="'date'">
            <p-columnFilter
              type="date"
              [field]="column.field"
              display="menu"
              matchMode="dateBefore"
              [maxConstraints]="2"
            ></p-columnFilter>
          </ng-template>
          <ng-template ngSwitchDefault>
            <p-columnFilter [type]="column.type" [field]="column.field"></p-columnFilter>
          </ng-template>
        </ng-container>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-member let-columns="columns" let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          routerLink="/members/{{ member.id }}"
          pButton
          pTooltip="Edit Member"
          tooltipPosition="bottom"
          icon="pi pi-pencil"
          style="height: 100%"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>
      <td>
        <button
          type="button"
          routerLink="/memberhistory/{{ member.id }}"
          pButton
          pTooltip="View Member History"
          tooltipPosition="bottom"
          icon="pi pi-book"
          style="height: 100%"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>
      <td *ngFor="let column of columns" [ngStyle]="{ width: '150px' }">
        {{ getDisplayText(column, member) }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">There were no matches to the current filter(s).</td>
    </tr>
  </ng-template>
</p-table>
