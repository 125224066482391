<p-table
  #dt
  [value]="healthStatusCodesHistory"
  dataKey="id"
  stateStorage="local"
  stateKey="healthstatus-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [globalFilterFields]="['healthStatusCode', 'active', 'modifiedUser']"
  [autoLayout]="true"
  [resizableColumns]="true"
  responsiveLayout="scroll"
>
  <ng-template pTemplate="caption">
    <h2 class="header">Health Status Codes History</h2>
    <div class="p-formgroup-inline"></div>
    <div class="p-d-flex p-jc-between">
      <button
      type="button"
      pButton
      pRipple
      icon="pi pi-undo"
      class="p-button-outlined p-mr-2"
      pTooltip="Clear all filters"
      label="Clear Filters"
      iconPos="right"
      tooltipPosition="bottom"
      (click)="clearFilter('healthstatus-filters')"
    ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="table-header">
    <tr>
      <th pSortableColumn="healthStatusCode" pResizableColumn class="health-status-code">
        <div class="p-d-flex p-jc-between p-ai-center">
          Code
          <p-sortIcon field="healthStatusCode"></p-sortIcon>
        </div>
      </th>
      <th
        pSortableColumn="healthStatusDescription"
        pResizableColumn
        class="health-status-description"
      >
        <div class="p-d-flex p-jc-between p-ai-center">
          Description
          <p-sortIcon field="healthStatusDescription"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="assessmentStatus" pResizableColumn class="assessment-status">
        <div class="p-d-flex p-jc-between p-ai-center">
          Assessment Status
          <p-sortIcon field="assessmentStatus"></p-sortIcon>
        </div>
      </th>
      <th
        pSortableColumn="interpretationNeedLevel"
        pResizableColumn
        class="interpretation-need-level"
      >
        <div class="p-d-flex p-jc-between p-ai-center">
          Interpretation<br />Need Level
          <p-sortIcon field="interpretationNeedLevel"></p-sortIcon>
        </div>
      </th>
      <th
        pSortableColumn="interpretationNeedType"
        pResizableColumn
        class="interpretation-need-type"
      >
        <div class="p-d-flex p-jc-between p-ai-center">
          Interpretation<br />Need Type
          <p-sortIcon field="interpretationNeedType"></p-sortIcon>
        </div>
      </th>
      <th
        pSortableColumn="observationStatusReason"
        pResizableColumn
        class="observation-status-reason"
      >
        <div class="p-d-flex p-jc-between p-ai-center">
          Observation<br />Status Reason
          <p-sortIcon field="observationStatusReason"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="isValidConnect" pResizableColumn class="is-valid-connect">
        <div class="p-d-flex p-jc-between p-ai-center">
          Is Valid<br />for Connect
          <p-sortIcon field="isValidConnect"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="isValidConnectPlus" pResizableColumn class="is-valid-connect-plus">
        <div class="p-d-flex p-jc-between p-ai-center">
          Is Valid<br />for Connect+
          <p-sortIcon field="isValidConnectPlus"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="periodBeginDate" pResizableColumn class="period-begin-date">
        <div class="p-d-flex p-jc-between p-ai-center">
          Period Begin<br />Date
          <p-sortIcon field="periodBeginDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="periodEndDate" pResizableColumn class="period-end-date">
        <div class="p-d-flex p-jc-between p-ai-center">
          Period End<br />Date
          <p-sortIcon field="periodEndDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="active" pResizableColumn class="active">
        <div class="p-d-flex p-jc-between p-ai-center">
          Active
          <p-sortIcon field="active"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedDate" pResizableColumn class="modifiedDate">
        <div class="p-d-flex p-jc-between p-ai-center">
          Modified<br/>Date
          <p-sortIcon field="modifiedDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedUser" pResizableColumn class="modifiedUser">
        <div class="p-d-flex p-jc-between p-ai-center">
          Modified<br/>User
          <p-sortIcon field="modifiedUser"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr class="table-filter-row">
      <th>
        <div class="p-d-flex p-jc-between p-ai-center" style="width: 150px">
          <p-columnFilter
            type="text"
            field="healthStatusCode"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="healthStatusDescription"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="assessmentStatus" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="assessmentStatuses"
                (onChange)="filter($event.value, 'assessmentStatus', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="interpretationNeedLevel" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="interpretationNeedLevels"
                (onChange)="filter($event.value, 'interpretationNeedLevel', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="interpretationNeedType" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="interpretationNeedTypes"
                (onChange)="filter($event.value, 'interpretationNeedType', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="observationStatusReason" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="observationStatusReasons"
                (onChange)="filter($event.value, 'observationStatusReason', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="isValidConnect" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="[true, false]"
                (onChange)="filter($event.value, 'isValidConnect', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="isValidConnectPlus" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="[true, false]"
                (onChange)="filter($event.value, 'isValidConnectPlus', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="periodBeginDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="periodEndDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <p-columnFilter field="active" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="[
                { label: 'Active', value: true },
                { label: 'Inactive', value: false }
              ]"
              (onChange)="filter($event?.value, 'active', 'in')"
              placeholder="Any"
              appendTo="body"
            >
              <ng-template let-option pTemplate="item"
                ><span class="p-m1-1">{{ option.label }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="modifiedDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="modifiedUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-healthStatusCode let-expanded="expanded">
    <tr>
      <td>{{ healthStatusCode.healthStatusCode }}</td>
      <td class="description" pTooltip="{{ healthStatusCode.healthStatusDescription }}">
        {{ healthStatusCode.healthStatusDescription }}
      </td>
      <td>{{ healthStatusCode.assessmentStatus }}</td>
      <td>{{ healthStatusCode.interpretationNeedLevel }}</td>
      <td>{{ healthStatusCode.interpretationNeedType }}</td>
      <td>{{ healthStatusCode.observationStatusReason }}</td>
      <td>{{ healthStatusCode.isValidConnect }}</td>
      <td>{{ healthStatusCode.isValidConnectPlus }}</td>
      <td>
        <time [attr.datetime]="healthStatusCode.periodBeginDate">{{
          healthStatusCode?.periodBeginDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        <time [attr.datetime]="healthStatusCode.periodEndDate">{{
          healthStatusCode?.periodEndDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        {{ healthStatusCode.active ? 'Active' : 'Inactive' }}
      </td>
      <td>
        <time [attr.datetime]="healthStatusCode.modifiedDate">{{
          healthStatusCode?.modifiedDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        {{ healthStatusCode.modifiedUser }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-healthStatusCode>
    <tr style="background: ghostwhite">
      <td><span class="p-float-label" style="margin-top: 50px"></span></td>
      <td colspan="8">
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex"><b>Created User: </b> {{ healthStatusCode.createdUser }}</div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Created Date: </b> {{ healthStatusCode.createdDate | date: 'MM/dd/yyyy' }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Last Modified User: </b> {{ healthStatusCode.modifiedUser }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Last Modified Date: </b>
              {{ healthStatusCode.modifiedDate | date: 'MM/dd/yyyy' }}
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="13">There are no history to view.</td>
    </tr>
  </ng-template>
</p-table>
