import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

import { AlternateProvider } from '../models/alternate-provider';
import { environment } from '../../../environments/environment';
import { AzureSubscriptionHeader } from '../../shared/models/CosmosDB/azure-subscription-header';

@Injectable({
  providedIn: 'root'
})
export class AlternateProvidersService {
  private readonly getAllAlternateProviderEndPoint?: string;
  private readonly insertAlternateProviderEndPoint?: string;
  private readonly upsertAlternateProviderEndPoint?: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getAllAlternateProviderEndPoint = environment.apiUrls.getAlternateProviders;
    this.insertAlternateProviderEndPoint = environment.apiUrls.saveAlternateProvider;
    this.upsertAlternateProviderEndPoint = environment.apiUrls.saveAlternateProvider;
  }

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  getAllAlternateProviders(): Observable<AlternateProvider[]> {
    return this.http
      .get<AlternateProvider[]>(this.getAllAlternateProviderEndPoint!, {
        headers: AzureSubscriptionHeader
      })
      .pipe(shareReplay(1));
  }

  getAlternateProvider(id: string): Observable<AlternateProvider> {
    if (id === '0') {
      return of(this.initializeAlternateProvider());
    } else {
      const url = `${this.getAllAlternateProviderEndPoint!}/${id}`;
      return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
        map((data: any) => {
          return data;
        }),
        shareReplay(1)
      );
    }
  }

  createAlternateProvider(alternateProvider: AlternateProvider): Observable<AlternateProvider> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };

    return this.http
      .post<AlternateProvider>(
        this.insertAlternateProviderEndPoint!,
        alternateProvider,
        httpOptions
      )
      .pipe(
        tap(() => {
          this.refreshNeeded$.next();
        })
      );
  }

  upsertAlternateProvider(alternateProvider: AlternateProvider): Observable<AlternateProvider> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };

    return this.http
      .post<AlternateProvider>(
        this.upsertAlternateProviderEndPoint!,
        alternateProvider,
        httpOptions
      )
      .pipe(
        tap(() => {
          this.refreshNeeded$.next();
        })
      );
  }

  deleteAlternateProvider(id: string): Observable<{}> {
    const headers = AzureSubscriptionHeader;
    const url = `${this.getAllAlternateProviderEndPoint!}/${id}`;
    return this.http.delete<AlternateProvider>(url, { headers });
  }

  initializeAlternateProvider(): AlternateProvider {
    return {
      id: '0',
      alternateProviderNBR: null,
      //alternateProviderShortName: null,
      alternateProviderLongName: null,
      active: true,
      createdDate: null,
      createdUser: null,
      modifiedDate: null,
      modifiedUser: null
    };
  }
}
