import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DateValidator } from '@shared/validators/date-validator/date.validator';
import { LivingStatusCode } from '../../../shared/models/living-status-code';
import { LivingStatusCodeViewModel } from '../../models/status-code-view-model';
import { LivingStatusCodesService } from '../../services/living-status-codes.service';
import { isEmpty } from '@shared/validators/isEmpty';
import { formatDateString } from '@shared/util/date-formatter';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-living-status-codes-add',
  templateUrl: './living-status-codes-add.component.html',
  styleUrls: ['./living-status-codes-add.component.scss']
})
export class LivingStatusCodesAddComponent implements OnInit {
  inactiveCheck = true;
  isActive = true;

  @Input() visible = false;
  @Input() mySelectedLivingStatusCodeData?: LivingStatusCode;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<LivingStatusCode>();

  loading = false;
  livingStatusCodeFormGroup!: FormGroup;
  errorMessage = '';
  private _seed = 100;
  get seed(): number {
    return this._seed++;
  }

  get livingStatusCode() {
    return this.livingStatusCodeFormGroup.get('livingStatusCode') as FormControl;
  }
  get livingStatusDescription() {
    return this.livingStatusCodeFormGroup.get('livingStatusDescription') as FormControl;
  }
  get active() {
    return this.livingStatusCodeFormGroup.get('active') as FormControl;
  }
  get notes() {
    return this.livingStatusCodeFormGroup.get('notes') as FormControl;
  }
  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private readonly fb: FormBuilder,
    private livingStatusCodeService: LivingStatusCodesService,
    private authService: MsalService
  ) {}

  ngOnInit(): void {
    this.livingStatusCodeFormGroup = this.fb.group(
      {
        id: '',
        livingStatusCode: ['', [Validators.required]],
        livingStatusDescription: ['', [Validators.required]],
        notes: '',
        active: [''],
        createdDate: '',
        createdUser: '',
        modifiedDate: '',
        modifiedUser: ''
      },
      <AbstractControlOptions>{
        validator: DateValidator.greaterThan
      }
    );

    if (!(this.mySelectedLivingStatusCodeData?.id === '0')) {
      this.copyLivingStatusCode();
    } else this.ClearForm();
  }

  toggleActiveCheckbox($event: { checked: boolean }) {
    if ($event.checked === true) this.isActive = true;
    else this.isActive = false;
  }

  ClearForm() {
    this.livingStatusCodeFormGroup.markAsUntouched();
    this.livingStatusCodeFormGroup.reset();
    this.initializeFormGroup();
  }

  private convertDateTo(stringDateFormat: any): string | null {
    return typeof stringDateFormat === 'string' ? stringDateFormat.substring(0, 10) : null;
  }

  private adjustDate() {
    return 'T12:34:56.7890123Z';
  }

  copyLivingStatusCode() {
    this.isActive = this.mySelectedLivingStatusCodeData
      ? this.mySelectedLivingStatusCodeData.active
      : false;
    this.livingStatusCodeFormGroup.patchValue({
      id: this.mySelectedLivingStatusCodeData?.id,
      livingStatusCode: this.mySelectedLivingStatusCodeData?.livingStatusCode,
      livingStatusDescription: this.mySelectedLivingStatusCodeData?.livingStatusDescription,
      active: status,
      notes: this.mySelectedLivingStatusCodeData?.notes,
      createdDate:
        this.mySelectedLivingStatusCodeData?.createdDate != null
          ? formatDateString(this.mySelectedLivingStatusCodeData.createdDate.toLocaleString()) +
            this.adjustDate()
          : null,
      createdUser: this.mySelectedLivingStatusCodeData?.createdUser,
      modifiedDate:
        this.mySelectedLivingStatusCodeData?.modifiedDate != null
          ? formatDateString(this.mySelectedLivingStatusCodeData?.modifiedDate.toLocaleString())
          : null,
      modifiedUser: this.mySelectedLivingStatusCodeData?.modifiedUser
    });
  }

  initializeFormGroup() {
    this.livingStatusCodeFormGroup.setValue({
      id: '',
      livingStatusCode: '',
      livingStatusDescription: '',
      notes: '',
      active: '',
      createdDate: '',
      createdUser: '',
      modifiedDate: '',
      modifiedUser: ''
    });
  }

  onSubmit() {
    if (this.livingStatusCodeFormGroup.valid) {
      if (!this.livingStatusCodeFormGroup.get('id')?.value)
        this.insertLivingStatusCode(this.livingStatusCodeFormGroup.value);
      else this.updateLivingStatusCode(this.livingStatusCodeFormGroup.value);
    }
  }

  insertLivingStatusCode(livingStatusCode: LivingStatusCodeViewModel) {
    livingStatusCode.active = this.isActive;
    const newLivingStatusCode: LivingStatusCode = {
      livingStatusCode: livingStatusCode.livingStatusCode?.trim(),
      livingStatusDescription: livingStatusCode.livingStatusDescription?.trim(),
      active: livingStatusCode.active,
      notes: livingStatusCode.notes!.trim(),
      modifiedDate: livingStatusCode.modifiedDate != null ? livingStatusCode.modifiedDate : '',
      modifiedUser: !isEmpty(livingStatusCode.modifiedUser)
        ? livingStatusCode.modifiedUser
        : this.userName,
      createdDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      createdUser: !isEmpty(livingStatusCode.createdUser)
        ? livingStatusCode.createdUser
        : this.userName
    };

    this.livingStatusCodeService.createLivingStatusCode(newLivingStatusCode).subscribe();

    this.ClearForm();
    this.saveEvent.emit(newLivingStatusCode);
  }

  updateLivingStatusCode(livingStatusCode: LivingStatusCodeViewModel) {
    livingStatusCode.active = this.isActive;
    let updatedLivingStatusCode: LivingStatusCode = {
      id: livingStatusCode.id,
      livingStatusCode: livingStatusCode.livingStatusCode?.trim(),
      livingStatusDescription: livingStatusCode.livingStatusDescription || '',
      active: livingStatusCode.active,
      notes: livingStatusCode.notes!.trim(),
      modifiedDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      modifiedUser: this.userName,
      // //!isEmpty(livingStatusCode.modifiedUser)
      //   ? livingStatusCode.modifiedUser
      //   : 'plee5',
      createdDate: livingStatusCode.createdDate,
      createdUser: livingStatusCode.createdUser
    };

    this.livingStatusCodeService.upsertLivingStatusCode(updatedLivingStatusCode).subscribe();

    this.ClearForm();
    this.saveEvent.emit(updatedLivingStatusCode);
  }

  deleteLivingStatusCode(id: string) {
    this.livingStatusCodeService.deleteLivingStatusCode(id);
  }

  cancelModal() {
    this.ClearForm();
    this.cancelEvent.emit(this.visible);
  }
}
