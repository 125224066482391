import { formatDate } from '@angular/common';
import { DatePart } from '@shared/enums/date-part';

export function formatDateString(dateString: string): string {
  const d = new Date(dateString);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export const dateFormatForExport = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
} as Intl.DateTimeFormatOptions;

export function getLocalDateFromUTCDateString(date: string, timePart?: DatePart): string {
  if (!timePart) {
    timePart = DatePart.Entire;
  }

  const utcDate = new Date(date);
  const offset = utcDate.getTimezoneOffset() * 60 * 1000;
  const dateWithoutUTC = new Date(utcDate.getTime() - offset);

  switch (timePart) {
    case DatePart.Entire:
      return dateWithoutUTC.toISOString();
    case DatePart.Date:
      return dateWithoutUTC.toISOString().split('T')[0];
    case DatePart.Time:
      return dateWithoutUTC.toISOString().split('T')[1].substr(0, 5);
  }
}

export function getDateStringAsUTC(date: string): string {
  const utcDate = new Date(date);
  const dateWithUTC = utcDate.toISOString();

  return dateWithUTC;
}
