<p-table
  #dt
  [value]="memberExceptions"
  [lazy]="true"
  (onLazyLoad)="loadMembersLazy($event)"
  [loading]="loadingTable"
  paginatorDropdownAppendTo="body"
  sortMode="single"
  sortField="id"
  dataKey="id"
  [columns]="selectedColumns"
  stateStorage="local"
  stateKey="member-exception-list-filters"
  (onStateRestore)="onStateRestore($event)"
  (onColReorder)="onColumnReorder()"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="pagingSizes"
  [showCurrentPageReport]="true"
  [rows]="pagingSizes[0]"
  [rowHover]="true"
  [first]="0"
  [totalRecords]="totalItems"
  [reorderableColumns]="true"
  [autoLayout]="true"
  styleClass="p-datatable-gridlines p-datatable-striped"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
>
  <ng-template pTemplate="caption">
    <h2>Member Exceptions</h2>
    <div class="p-d-flex p-jc-between">
      <button
        type="button"
        (click)="addMemberExcepion()"
        pButton
        icon="pi pi-plus"
        label="Add an Exception"
        [disabled]="!userHasEdit()"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('member-exception-list-filters')"
      ></button>
      <p-multiSelect
        [options]="allColumns"
        optionDisabled="static"
        [(ngModel)]="selectedColumns"
        optionLabel="shortName"
        selectedItemsLabel="{0} columns selected"
        placeholder="Choose Columns"
        appendTo="body"
      >
      </p-multiSelect>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns class="table-header">
    <tr>
      <!-- Edit-->
      <th pReorderableColumnDisabled class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Redirect to History Page-->
      <th pReorderableColumnDisabled class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th *ngFor="let column of columns" [pSortableColumn]="column.field" pReorderableColumn>
        <span class="reorderable-table-header">
          <span [attr.title]="column.longName">{{ column.shortName }}</span>
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th>
        <!-- Empty space for Edit icon column -->
      </th>
      <th>
        <!-- Empty space for History icon column -->
      </th>
      <th *ngFor="let column of columns">
        <ng-container *ngIf="column.type == 'multiselect'; else dateOrStandardFilter">
          <p-columnFilter [field]="column.field" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="column.options"
                (onChange)="filter($event.value, column.field, 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item">
                  <span class="p-m1-1">{{ option }}</span>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </ng-container>
      
        <ng-template #dateOrStandardFilter>
          <ng-container *ngIf="column.type == 'date'; else standardFilter">
            <p-columnFilter type="date" [field]="column.field" display="menu" matchMode="dateBefore" class="p-ml-auto"></p-columnFilter>
          </ng-container>
      
          <ng-template #standardFilter>
            <p-columnFilter [type]="column.type" [field]="column.field"></p-columnFilter>
          </ng-template>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-memberException
    let-columns="columns"
    let-expanded="expanded"
    let-index="rowIndex"
  >
    <tr [pReorderableRow]="index">
      <td>
        <button
          type="button"
          pButton
          pTooltip="Edit Member Exception"
          tooltipPosition="bottom"
          icon="pi pi-pencil"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
          (click)="openMemberExceptionDetail(memberException['id'])"
          [disabled]="!userHasEdit()"
        ></button>
      </td>
      <td>
        <button
          type="button"
          pButton
          pTooltip="Redirect to History"
          tooltipPosition="bottom"
          icon="pi pi-book"
          routerLink="/memberexceptionhistory/{{ memberException['memberExceptionNumber'] }}"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>
      <td *ngFor="let column of columns">
        <ng-container *ngIf="column.type === 'date'; else default">
          {{ memberException[column.field] | date: 'MM/dd/yyyy' }}
        </ng-container>
        <ng-template #default>
          {{ memberException[column.field] }}
        </ng-template>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td>
        <!-- Icon column -->
      </td>
      <td style="text-align: center" [attr.colspan]="columns?.length">
        There were no matches to the current filter(s).
      </td>
    </tr>
  </ng-template>
</p-table>
