import { ChangeDetectorRef, Component, OnInit, Self, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ExceptionSourceCode } from '../../models/exception-source-code';
import { ExceptionSourceCodeViewModel } from '../../models/exception-source-view-model';
import { ExceptionSourcesService } from '../../services/exception-sources.service';
import { isEmpty } from '@shared/validators/isEmpty';
import { exportDataToExcel } from '@shared/util/excel-exporter';
import { dateFormatForExport } from '@shared/util/date-formatter';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { MsalService } from '@azure/msal-angular';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-exception-sources-list',
  templateUrl: './exception-sources-list.component.html',
  styleUrls: ['./exception-sources-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class ExceptionSourcesListComponent implements OnInit {
  exceptionSources!: ExceptionSourceCode[];

  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  @ViewChild('dt1') dataTable!: Table;

  activeCheck = true;
  inactiveCheck = true;
  selectedExceptionSourceCodeData?: ExceptionSourceCode;

  cols: any[] = [];
  allActiveStatuses = ['true', 'false'];
  allStatuses = this.allActiveStatuses.map(status => {
    return { label: status, value: status };
  });

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }
  
  constructor(
    private exceptionSourceCodesService: ExceptionSourcesService,
    private authService: MsalService,
    private roleCheck: RoleCheck,
    private cdRef: ChangeDetectorRef,
    @Self() private readonly clearFilterService: ClearFilterService
  ) {}

  userHasEdit(){
    return this.roleCheck.userHasRole(['AltProvAdmin','AltProvListMaintenanceEdit']);
  }

  ngOnInit(): void {
    this.exceptionSourceCodesService.refreshNeeded$.subscribe(() => {
      this.getAllExceptionSourceCodes();
    });

    this.getAllExceptionSourceCodes();
  }

  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  CheckActiveCheckToggle() {
    console.log(`checkActiveCheckToggle: ${this.activeCheck}, ${this.inactiveCheck}`);
  }

  getAllExceptionSourceCodes() {
    this.loadingTable = true;
    this.exceptionSourceCodesService.getAllExceptionSourceCodes().subscribe({
      next: exceptionSourceCodes => {
        this.exceptionSources = exceptionSourceCodes;
        this.exceptionSources.forEach(exceptionSourceCode => {
          exceptionSourceCode.createdDate = new Date(exceptionSourceCode.createdDate!);
          exceptionSourceCode.modifiedDate = new Date(exceptionSourceCode.modifiedDate!);
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  addExceptionSourceCode() {
    this.exceptionSourceCodesService.getExceptionSourceCode('0').subscribe(result => {
      this.selectedExceptionSourceCodeData = result;
    });
    this.displayEditDialog = true;
  }

  editLivingStatusCode(exceptionSourceCodeData: ExceptionSourceCodeViewModel) {
    const editLivingStatusCode: ExceptionSourceCode = {
      id: exceptionSourceCodeData.id,
      exceptionSourceCode: !isEmpty(exceptionSourceCodeData.exceptionSourceCode)
        ? exceptionSourceCodeData.exceptionSourceCode
        : 'A x',
      exceptionSourceDescription: exceptionSourceCodeData.exceptionSourceDescription,
      notes: exceptionSourceCodeData.notes,
      active: exceptionSourceCodeData.active,
      modifiedDate: exceptionSourceCodeData.modifiedDate,
      modifiedUser: this.userName,
      createdUser: exceptionSourceCodeData.createdUser,
      createdDate: exceptionSourceCodeData.createdDate
    };

    this.selectedExceptionSourceCodeData = { ...editLivingStatusCode };
    this.displayEditDialog = true;
  }

  updateLivingStatusCode(selectedExceptionSourceCodeData: ExceptionSourceCode) {
    this.displayEditDialog = false;
    this.getAllExceptionSourceCodes();
    this.cdRef.detectChanges();
  }

  deleteExceptionSourceCode(exceptionSourceCodeData: ExceptionSourceCode) {
    if (
      confirm(
        `Do you really want to delete this ExceptionSourceCode? ${exceptionSourceCodeData.id}`
      )
    ) {
      this.exceptionSourceCodesService
        .deleteExceptionSourceCode(exceptionSourceCodeData.id!)
        .subscribe({
          next: () => this.onSaveComplete(exceptionSourceCodeData.id!),
          error: err => (this.errorMessage = err)
        });
      console.error(this.errorMessage);

      this.onSaveComplete(exceptionSourceCodeData.id!);
    }
  }

  onSaveComplete(id: string): void {
    console.log('deleted id: ', id);
    this.exceptionSources = this.exceptionSources.filter(hc => hc.id !== id);
  }

  exportExcel(): void {
    const excelData: any[] = this.formatExcelData();

    exportDataToExcel(excelData, 'ExceptionSourceCodes');
  }

  private formatExcelData(): Array<any> {
    return this.exceptionSources.map((exceptionSourceCode: ExceptionSourceCode) => ({
      exceptionSourceCode: exceptionSourceCode.exceptionSourceCode,
      exceptionSourceDescription: exceptionSourceCode.exceptionSourceDescription,
      active: exceptionSourceCode.active ? 'Active' : 'Inactive',
      createdDate:
        exceptionSourceCode.createdDate != null
          ? new Date(exceptionSourceCode.createdDate).toLocaleDateString(
              'en-US',
              dateFormatForExport
            )
          : '',
      createdUser: exceptionSourceCode.createdUser,
      modifiedDate:
        exceptionSourceCode.modifiedDate != null
          ? new Date(exceptionSourceCode.modifiedDate).toLocaleDateString(
              'en-US',
              dateFormatForExport
            )
          : '',
      modifiedUser: exceptionSourceCode.modifiedUser
    }));
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
  }
}
