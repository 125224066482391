<div class="dialog-box-container" #MB id="ModalBody">
  <h3 mat-dialog-title>Select a PCC</h3>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <p-table #dt [value]="pccs" [loading]="loadingTable" [paginatorDropdownAppendTo]="dt" sortMode="single"
      sortField="id" dataKey="id" [paginator]="true" [showPageLinks]="false" [showFirstLastIcon]="false"
      [rowsPerPageOptions]="pagingSizes" [showCurrentPageReport]="true" [rows]="10" [rowHover]="true" [columns]="cols"
      [first]="0" styleClass="p-datatable-gridlines p-datatable-striped">
      <ng-template pTemplate="caption">
        <h2>PCCs</h2>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let column of columns" [pSortableColumn]="column.propertyName">
            <span class="table-header">
              <span [attr.title]="column.longName">{{ column.shortName }}</span>
              <p-sortIcon [field]="column.propertyName"></p-sortIcon>
            </span>
          </th>
        </tr>
        <tr>
          <th *ngFor="let column of columns" [ngStyle]="{ width: '150px' }">
            <ng-template [ngIf]="column.type == 'multiselect'" [ngIfElse]="standardFilter">
              <p-columnFilter [field]="column.propertyName" matchMode="in" [showMenu]="true">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [ngModel]="value" [options]="column.options"
                    (onChange)="filter($event.value, column.propertyName, 'in')" placeholder="Any"
                    styleClass="{ width: '200px' }" [virtualScroll]="true" [itemSize]="30">
                    <ng-template let-options pTemplate="item"><span [ngStyle]="{ width: '200px' }">{{ options }}</span>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </ng-template>
            <ng-template #standardFilter>
              <p-columnFilter #standardFilter [type]="column.type" [field]="column.propertyName"
                [ngStyle]="{ width: '150px' }"></p-columnFilter>
            </ng-template>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pcc let-expanded="expanded">
        <tr class="table-row-link-pointer" (click)="selectPCC(pcc)">
          <td *ngFor="let column of cols">{{ pcc[column.propertyName] }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="cols.length">There were no matches to the current filter(s).</td>
        </tr>
      </ng-template>
    </p-table>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancelClick()"><strong>Cancel</strong></button>
  </mat-dialog-actions>
</div>
