import { ChangeDetectorRef, Component, Self, ViewChild } from '@angular/core';
import { FilterMatchMode, FilterMetadata, LazyLoadEvent, PrimeNGConfig } from 'primeng/api';
import { Member } from '@memberMaintenance/models/member';
import { MemberMaintenanceService } from '@memberMaintenance/services/member-maintenance.service';
import { FilterService } from '@shared/services/filter-service/filter.service';
import { QuerySessionManagerService } from '@shared/services/query-session-manager-service/query-session-manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Table } from 'primeng/table';
import { RoleCheck } from '@shared/guards/roleCheck';
import { ColumnDefinition } from '@shared/models/column-definition';
import { Subscription } from 'rxjs';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { ProductService } from '@shared/services/product-service/product.service';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { OnDestroy } from '@angular/core';
import { getDateStringAsUTC, getLocalDateFromUTCDateString } from '@shared/util/date-formatter';
import { FilterService as PrimeNgFilterService } from 'primeng/api';
import { registerEmptyFilter } from '@shared/util/custom-filters';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-member-maintenance-list',
  templateUrl: './member-maintenance-list.component.html',
  styleUrls: ['./member-maintenance-list.component.scss'],
  providers: [
    ClearFilterService,
    { provide: BROWSER_STORAGE, useFactory: () => localStorage },
    DatePipe
  ]
})
export class MemberMaintenanceListComponent implements OnDestroy {
  private readonly querySessionManager: QuerySessionManagerService;

  private readonly subscriptions: Subscription[] = [];

  private readonly todayUtc = new Date().toISOString();

  private readonly doNotShowTermedFilter: FilterMetadata = {
    value: this.todayUtc,
    operator: 'and',
    matchMode: 'dateAfter'
  };

  @ViewChild(Table) table!: Table;

  pagingSizes = [20, 40, 60, 80, 100];

  selectedColumns!: ColumnDefinition[];
  allColumns!: ColumnDefinition[];

  loadingTable = true;
  members: Member[] = [];
  totalMembers = 0;
  showTermed: boolean = true;
  private previousLoadEvent: LazyLoadEvent | undefined = undefined;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
    private readonly memberMaintenanceService: MemberMaintenanceService,
    private readonly filterService: FilterService,
    private readonly primeNgFilterService: PrimeNgFilterService,
    private roleCheck: RoleCheck,
    private readonly alternateProviderService: AltProviderService,
    private readonly productService: ProductService,
    @Self() private readonly clearFilterService: ClearFilterService
  ) {
    const defaultColumns: ColumnDefinition[] = [
      {
        field: 'id',
        shortName: 'ID',
        longName: 'ID',
        type: 'text',
        static: true
      },
      {
        field: 'amisysId',
        shortName: 'Amisys ID',
        longName: 'Amisys ID',
        type: 'text',
        static: true
      },
      {
        field: 'healthRulesId',
        shortName: 'Health Rules ID',
        longName: 'Health Rules ID',
        type: 'text',
        static: true
      },
      { field: 'firstName', shortName: 'First Name', longName: 'First Name', type: 'text' },
      { field: 'lastName', shortName: 'Last Name', longName: 'Last Name', type: 'text' },
      {
        field: 'product',
        shortName: 'Product',
        longName: 'Product',
        type: 'multiselect',
        options: []
      },
      {
        field: 'altProvName',
        shortName: 'AltProv',
        longName: 'Alternate Provider Name',
        type: 'multiselect',
        options: []
      }
    ];

    const additionalColumns: ColumnDefinition[] = [
      {
        field: 'healthStatusCode',
        shortName: 'HS Code',
        longName: 'HS Code',
        type: 'text'
      },
      {
        field: 'pcc',
        shortName: 'PCC',
        longName: 'Primary Care Clinic',
        type: 'text'
      },
      {
        field: 'pccId',
        shortName: 'PCC ID',
        longName: 'Primary Care Clinic ID',
        type: 'text'
      },
      {
        field: 'county',
        shortName: 'County',
        longName: 'County',
        type: 'text'
      },
      {
        field: 'livingStatusCode',
        shortName: 'Living Status',
        longName: 'Living Status Code',
        type: 'text'
      },
      {
        field: 'coverageBeginDate',
        shortName: 'Coverage Begin Date',
        longName: 'Coverage Begin Date',
        type: 'date'
      },
      {
        field: 'coverageEndDate',
        shortName: 'Coverage End Date',
        longName: 'Coverage End Date',
        type: 'date'
      },
      {
        field: 'pccCareSystemCode',
        shortName: 'Care System Code',
        longName: 'Care System Code',
        type: 'text'
      }
    ];

    this.selectedColumns = defaultColumns;
    this.allColumns = defaultColumns.concat(additionalColumns);

    this.querySessionManager = new QuerySessionManagerService('member-maintenance-list');

    // Clear any cached sessions on page load
    this.querySessionManager.resetSession();
  }

  ngOnInit() {
    registerEmptyFilter(this.primeNgFilterService);

    const altProvSub = this.alternateProviderService.getAlternateProviders().subscribe(val => {
      const altProvs = val.map(ap => ap.alternateProviderName);
      this.setColumnOptions('altProvName', altProvs);
    });

    const productSub = this.productService.getAllProducts().subscribe(val => {
      const products = val.map(p => p.productName.toUpperCase());
      this.setColumnOptions('product', products);
    });

    this.subscriptions.push(altProvSub);
    this.subscriptions.push(productSub);

    //this.refreshDataTable();
  }

  ngAfterViewInit(): void {
    const columnChangeSub = this.table.tableService.columnsSource$.subscribe(() => {
      this.table.saveState();
    });

    this.subscriptions.push(columnChangeSub);
  }

  private refreshDataTable() {
    this.memberMaintenanceService.refreshNeeded$.subscribe(() => {
      this.ngOnInit();
      this.ngAfterViewInit();
    });
  }

  toggleTermedMembersCheckbox($event: { checked: boolean }): void {
    if (typeof $event.checked === 'boolean') {
      this.showTermed = $event.checked;

      if (!this.showTermed) {
        this.table.filterGlobal(this.todayUtc, 'dateAfter');
      } else {
        this.table.filterGlobal('', '');
      }
    }
  }

  userHasEdit() {
    return this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvMemberEdit']);
  }

  async loadMembersLazy(event: LazyLoadEvent): Promise<void> {
    this.loadingTable = true;

    // Blow away the query session every time unless we are paging forward/backward
    if (this.filterService.didChangePage(this.previousLoadEvent, event) === false) {
      this.querySessionManager.resetSession();
    }

    // Allow bute force paging
    if (
      event.filters &&
      event.rows != null &&
      event.rows > 0 &&
      !event.filters.altProvName &&
      !event.filters.product
    ) {
      event.first = Math.round(this.totalMembers / event.rows);
    }

    const clonedEvent = Object.assign({}, event);

    const selectedColumnKeys = this.selectedColumns.map(col => col.field);
    selectedColumnKeys.push('global'); // Allow global filtering

    for (const filterKey in clonedEvent.filters) {
      if (!selectedColumnKeys.includes(filterKey)) {
        // Remove any filters not included in the selected columns
        delete clonedEvent.filters[filterKey];
      }
    }

    const fetchSub = this.memberMaintenanceService
      .getMemberList(event, this.querySessionManager.currentSession)
      .subscribe(
        response => {
          this.members = response.results;
          this.totalMembers = response.totalResults;

          if (response.querySessionId) {
            this.querySessionManager.currentSession = response.querySessionId;
          }
        },
        (error: HttpErrorResponse) => {
          // If our query session ID was deleted,
          // clear it out and try again
          if (error.status === 404) {
            this.querySessionManager.resetSession();
            this.table._filter();
          }
        },
        () => {
          this.loadingTable = false;

          fetchSub.unsubscribe();
        }
      );

    this.previousLoadEvent = event;
  }

  onStateRestore(event: any) {
    const columnKeys = event.columnOrder;
    const columns = this.getFilterByKeyOrdered(columnKeys);

    this.selectedColumns = columns;
    this.cd.detectChanges();
  }

  onColumnReorder() {
    this.table.saveState();
  }

  getDisplayText(column: ColumnDefinition & any, member: Member & any): string {
    if (column.field == 'county') {
      return (
        member[column.field].toString()[0] +
        member[column.field].toLowerCase().slice(1, [member[column.field].length])
      );
    }

    switch (column.type) {
      case 'date':
        return this.datePipe.transform(member[column.field], 'MM/dd/yyyy')!;
      default:
        return member[column.field]?.trim();
    }
  }

  private getFilterByKeyOrdered(keys: string[]) {
    const result: any[] = [];

    for (let key of keys) {
      const column = this.allColumns.find(f => f.field == key);
      result.push(column);
    }

    return result;
  }

  private setColumnOptions(field: string, options: Array<any>): void {
    const selectedColumnsIndex = this.selectedColumns.findIndex(x => x.field === field);
    const allColumnsIndex = this.allColumns.findIndex(x => x.field === field);

    if (selectedColumnsIndex > 0) {
      this.selectedColumns[selectedColumnsIndex].options = options;
    }

    if (allColumnsIndex > 0) {
      this.allColumns[allColumnsIndex].options = options;
    }
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }

  ngOnDestroy(): void {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
