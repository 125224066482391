<p-dialog
  header="Add/Edit Alternate Provider"
  appendTo="body"
  [modal]="true"
  [(visible)]="visible"
  [closable]="false"
  [style]="{ width: '800px' }"
>
  <form
    [formGroup]="alternateProviderFormGroup"
    (ngSubmit)="onSubmit()"
    class=".exception-sources-add-form"
  >
    <div class="exception-sources-attributes">
      <mat-form-field [style.width.px]="220" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Alias</mat-label>
        <input matInput type="text" formControlName="alternateProviderNBR" maxlength="6" />
        <mat-error *ngIf="alternateProviderNBR?.errors?.required">
          Alias cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="200" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Participant Id</mat-label>
        <input matInput type="text" formControlName="alternateProviderIdentifier" />
        <mat-error *ngIf="alternateProviderIdentifier?.errors?.required">
          Participant Id cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Id Type</mat-label>
        <mat-select color="primary" formControlName="alternateProviderIdType">
          <mat-option *ngFor="let apid of idTypes" [value]="apid.name">{{ apid.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="alternateProviderIdType?.errors?.required">
          Id Type cannot be blank
        </mat-error>
      </mat-form-field>

      <mat-form-field [style.width.px]="655" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="alternateProviderLongName" />
        <mat-error *ngIf="alternateProviderName?.errors?.required">
          Name cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-checkbox
        class="p-mr-3 p-flew-row"
        color="primary"
        [checked]="isActive"
        (change)="toggleActiveCheckbox($event)"
        >Active
      </mat-checkbox>
      <mat-form-field [style.width.px]="170" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>County</mat-label>
        <mat-select color="primary" formControlName="alternateProviderFIPSCode">
          <mat-option *ngFor="let cn of counties" [value]="cn.countyFIPSCode">{{
            cn.countyName + '-' + cn.countyFIPSCode
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="alternateProviderFIPSCode?.errors?.required">
          County cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="170" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>State</mat-label>
        <mat-select color="primary" formControlName="alternateProviderStateCode">
          <mat-option *ngFor="let cnc of counties" [value]="cnc.state">{{ cnc.state }}</mat-option>
        </mat-select>
        <mat-error *ngIf="alternateProviderStateCode?.errors?.required">
          State cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="170" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Type</mat-label>
        <mat-select color="primary" formControlName="alternateProviderType">
          <mat-option *ngFor="let apt of types" [value]="apt.name">{{ apt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="alternateProviderType?.errors?.required">
          Type cannot be blank
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field [style.width.px]="170" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Period Begin Date</mat-label>
        <input matInput type="date" formControlName="effectiveStartDate" />
        <mat-error *ngIf="effectiveStartDate?.errors?.required">
          Start Date cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="170" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Period Begin Time</mat-label>
        <input matInput type="time" formControlName="effectiveStartTime" />
        <mat-error *ngIf="effectiveStartTime?.errors?.required">
          Start Time cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="170" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Period End Date</mat-label>
        <input matInput type="date" formControlName="effectiveEndDate" />
        <mat-error
          *ngIf="
            alternateProviderFormGroup.errors?.notGreater &&
            (alternateProviderFormGroup.touched || alternateProviderFormGroup.dirty)
          "
        >
          End Date must take place after Start Date
        </mat-error>
        <mat-error *ngIf="effectiveEndDate?.errors?.required"> End Date cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="170" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Period End Time</mat-label>
        <input matInput type="time" formControlName="effectiveEndTime" />
        <mat-error *ngIf="effectiveEndTime?.errors?.required"> End Time cannot be blank </mat-error>
      </mat-form-field>
      <input type="hidden" formControlName="id" />
      <input type="hidden" formControlName="createdDate" />
      <input type="hidden" formControlName="createdUser" />
      <input type="hidden" formControlName="modifiedDate" />
      <input type="hidden" formControlName="modifiedUser" />
      <input type="hidden" formControlName="alternateProviderCategoryCode" />
    </div>
    <div class="form-action-buttons p-mt-6">
      <button mat-raised-button type="button" (click)="cancelModal()" class="p-mr-2">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="loading">Save</button>
    </div>
  </form>
</p-dialog>
