import { Component, Input } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Product } from '@shared/models/product';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-checkbox',
  templateUrl: './product-checkbox.component.html',
  styleUrls: ['./product-checkbox.component.scss']
})
export class ProductCheckboxComponent {
  @Input() product!: Product;

  selected = false;
  selectionChange = new Subject<boolean>();
  disabled = false;
  visible = true;

  onProductChange(e: MatCheckboxChange): void {
    this.selectionChange.next(e.checked);
  }
}
