import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { LivingStatusCode } from '../../../shared/models/living-status-code';
import { LivingStatusCodeViewModel } from '../../models/status-code-view-model';
import { LivingStatusCodesService } from '../../services/living-status-codes.service';
import { isEmpty } from '@shared/validators/isEmpty';
import { exportDataToExcel } from '@shared/util/excel-exporter';
import { dateFormatForExport } from '@shared/util/date-formatter';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { MsalService } from '@azure/msal-angular';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-living-status-codes-list',
  templateUrl: './living-status-codes-list.component.html',
  styleUrls: ['./living-status-codes-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class LivingStatusCodesListComponent implements OnInit {
  livingStatusCodes!: LivingStatusCode[];

  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  @ViewChild('dt1') dataTable!: Table;

  activeCheck = true;
  inactiveCheck = true;
  selectedLivingStatusCodeData?: LivingStatusCode;

  cols: any[] = [];
  allActiveStatuses = ['true', 'false'];
  allStatuses = this.allActiveStatuses.map(status => {
    return { label: status, value: status };
  });

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    @Self() private readonly clearFilterService: ClearFilterService,
    private LivingStatusCodesService: LivingStatusCodesService,
    private authService: MsalService,
    private roleCheck: RoleCheck
  ) {}

  userHasEdit(){
    return this.roleCheck.userHasRole(['AltProvAdmin','AltProvListMaintenanceEdit']);
  }

  ngOnInit(): void {
    this.LivingStatusCodesService.refreshNeeded$.subscribe(() => {
      this.getAllLivingStatusCodes();
    });
    this.getAllLivingStatusCodes();
  }

  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  CheckActiveCheckToggle() {
    console.log(`checkActiveCheckToggle: ${this.activeCheck}, ${this.inactiveCheck}`);
  }

  getAllLivingStatusCodes() {
    this.loadingTable = true;
    this.LivingStatusCodesService.getAllLivingStatusCodes().subscribe({
      next: livingStatusCodes => {
        this.livingStatusCodes = livingStatusCodes;
        this.livingStatusCodes.forEach(livingStatusCode => {
          livingStatusCode.createdDate = new Date(livingStatusCode.createdDate!);
          livingStatusCode.modifiedDate = new Date(livingStatusCode.modifiedDate!);
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  addLivingStatusCode() {
    this.LivingStatusCodesService.getLivingStatusCode('0').subscribe(result => {
      this.selectedLivingStatusCodeData = result;
    });
    this.displayEditDialog = true;
  }

  editLivingStatusCode(livingStatusCodeData: LivingStatusCodeViewModel) {
    const editLivingStatusCode: LivingStatusCode = {
      id: livingStatusCodeData.id,
      livingStatusCode: !isEmpty(livingStatusCodeData.livingStatusCode)
        ? livingStatusCodeData.livingStatusCode?.trim()
        : '',
      livingStatusDescription: livingStatusCodeData.livingStatusDescription?.trim(),
      notes: livingStatusCodeData.notes,
      active: livingStatusCodeData.active,
      modifiedDate: livingStatusCodeData.modifiedDate,
      modifiedUser: this.userName, //livingStatusCodeData.modifiedUser,
      createdUser: livingStatusCodeData.createdUser,
      createdDate: livingStatusCodeData.createdDate
    };

    this.selectedLivingStatusCodeData = { ...editLivingStatusCode };
    this.displayEditDialog = true;
  }

  updateLivingStatusCode(selectedLivingStatusCodeData: LivingStatusCode) {
    this.displayEditDialog = false;
    this.getAllLivingStatusCodes();
  }

  deleteLivingStatusCode(livingStatusCodeData: LivingStatusCode) {
    if (confirm(`Do you really want to delete this LivingStatusCode? ${livingStatusCodeData.id}`)) {
      this.LivingStatusCodesService.deleteLivingStatusCode(livingStatusCodeData.id!).subscribe({
        next: () => this.onSaveComplete(livingStatusCodeData.id!),
        error: err => (this.errorMessage = err)
      });
      console.error(this.errorMessage);

      this.onSaveComplete(livingStatusCodeData.id!);
    }
  }

  onSaveComplete(id: string): void {
    console.log('deleted id: ', id);
    this.livingStatusCodes = this.livingStatusCodes.filter(hc => hc.id !== id);
  }

  exportExcel(): void {
    const excelData: any[] = this.formatExcelData();

    exportDataToExcel(excelData, 'LivingStatusCodes');
  }

  private formatExcelData(): Array<any> {
    return this.livingStatusCodes.map((livingStatusCode: LivingStatusCode) => ({
      LivingStatusCode: livingStatusCode.livingStatusCode,
      livingStatusCodesDescription: livingStatusCode.livingStatusDescription,
      active: livingStatusCode.active ? 'Active' : 'Inactive',
      createdDate:
        livingStatusCode.createdDate != null
          ? new Date(livingStatusCode.createdDate).toLocaleDateString('en-US', dateFormatForExport)
          : '',
      createdUser: livingStatusCode.createdUser,
      modifiedDate:
        livingStatusCode.modifiedDate != null
          ? new Date(livingStatusCode.modifiedDate).toLocaleDateString('en-US', dateFormatForExport)
          : '',
      modifiedUser: livingStatusCode.modifiedUser
    }));
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
  }
}
