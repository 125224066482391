import { Component, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
//import { DateValidator } from '../../../healthStatusCodes/validators/date.validator';
import { DateValidator } from '@shared/validators/date-validator/date.validator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MemberException } from '@memberExceptions/models/member-exception';
import { Product } from '@shared/models/product';

import { PccPickerComponent } from '@shared/components/pcc-picker/pcc-picker.component';
import { MemberExceptionService } from '@memberExceptions/services/member-exception.service';
import { ProductService } from '@shared/services/product-service/product.service';
import { SpinnerOverlayService } from '@shared/services/spinner-overlay-service/spinner-overlay.service';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { AlternateProvider } from '@shared/models/alternate-provider';
import { Member } from '@memberMaintenance/models/member';
import { ExceptionSourceCode } from '@shared/models/exception-source-code';
import { HttpErrorResponse } from '@angular/common/http';
import { NoticeDialogComponent } from '@shared/components/notice-dialog/notice-dialog.component';
import { map, switchMap, finalize, take, tap } from 'rxjs/operators';
import { getDateStringAsUTC, getLocalDateFromUTCDateString } from '@shared/util/date-formatter';
import { BaseView } from '@shared/components/Base/base-view';
import { DatePart } from '@shared/enums/date-part';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { RoleCheck } from '@shared/guards/roleCheck';
@Component({
  selector: 'app-member-exception-detail',
  templateUrl: './member-exception-detail.component.html',
  styleUrls: ['./member-exception-detail.component.scss']
})
export class MemberExceptionDetailComponent extends BaseView implements OnInit {
  products: Product[] = [];
  altProvs: AlternateProvider[] = [];
  exceptionSources: ExceptionSourceCode[] = new Array<ExceptionSourceCode>();
  member!: Member;
  routeId = '';

  selectedMemberException = {} as MemberException;

  loading = false;
  memberExceptionFormGroup!: FormGroup;
  errorMessage = '';

  _mySelectedId!: string;
  get mySelectedId() {
    return this._mySelectedId;
  }

  set mySelectedId(value: string) {
    this._mySelectedId = value;
  }
  myMemberExceptions: MemberException[] = [];

  get memberId() {
    return this.memberExceptionFormGroup.get('memberId') as AbstractControl;
  }
  get lastName() {
    return this.memberExceptionFormGroup.get('lastName') as AbstractControl;
  }
  get firstName() {
    return this.memberExceptionFormGroup.get('firstName') as AbstractControl;
  }
  get pccId() {
    return this.memberExceptionFormGroup.get('pccId') as AbstractControl;
  }
  get pccName() {
    return this.memberExceptionFormGroup.get('pccName') as AbstractControl;
  }
  get memberExceptionNumber() {
    return this.memberExceptionFormGroup.get('memberExceptionNumber') as AbstractControl;
  }
  get productName() {
    return this.memberExceptionFormGroup.get('product') as AbstractControl;
  }
  get altProv() {
    return this.memberExceptionFormGroup.get('altProv') as AbstractControl;
  }
  get sourceId() {
    return this.memberExceptionFormGroup.get('sourceId') as AbstractControl;
  }
  get sourceName() {
    return this.memberExceptionFormGroup.get('sourceName') as AbstractControl;
  }
  get effectiveStartDate() {
    return this.memberExceptionFormGroup.get('effectiveStartDate') as AbstractControl;
  }
  get effectiveStartTime() {
    return this.memberExceptionFormGroup.get('effectiveStartTime') as AbstractControl;
  }
  get effectiveEndDate() {
    return this.memberExceptionFormGroup.get('effectiveEndDate') as AbstractControl;
  }
  get effectiveEndTime() {
    return this.memberExceptionFormGroup.get('effectiveEndTime') as AbstractControl;
  }
  get note() {
    return this.memberExceptionFormGroup.get('notes') as AbstractControl;
  }
  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  minDate: any;
  maxDate: any;

  constructor(
    public dialog: MatDialog,
    private readonly fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private productService: ProductService,
    private readonly route: ActivatedRoute,
    private altProviderService: AltProviderService,
    private readonly spinnerService: SpinnerOverlayService,
    private memberExceptionService: MemberExceptionService,
    public dialogRef: MatDialogRef<MemberExceptionDetailComponent>,
    private roleCheck: RoleCheck,
    private authService: MsalService,
    @Inject(MAT_DIALOG_DATA) private readonly data: any
  ) {
    super();
    this.dateAdapter.setLocale('en-US');
  }

  userHasEdit() {
    return this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvEditMemberExceptions']);
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.routeId = this.route.snapshot.params.id;
    this.mySelectedId = this.data.memberExceptionId ?? this.routeId;

    const today = new Date().toISOString();

    this.memberExceptionFormGroup = this.fb.group(
      {
        memberId: [
          '0000000',
          [Validators.required, Validators.minLength(8), Validators.pattern('^[0-9]*$')]
        ],
        lastName: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        memberExceptionNumber: '',
        productName: ['', [Validators.required]],
        altProv: ['', [Validators.required]],
        effectiveStartDate: [
          getLocalDateFromUTCDateString(today, DatePart.Date),
          Validators.compose([
            Validators.required,
            DateValidator.monthStartBetween,
            DateValidator.dayStartBetween
          ])
        ],
        effectiveStartTime: [
          getLocalDateFromUTCDateString(today, DatePart.Time),
          Validators.compose([Validators.required, DateValidator.startNextTimestamp])
        ],
        effectiveEndDate: ['9999-12-31', [Validators.required]],
        effectiveEndTime: ['00:00', [Validators.required]],
        sourceId: '',
        sourceName: ['', [Validators.required]],
        createdDate: '',
        createdUser: '',
        modifiedDate: '',
        modifiedUser: '',
        note: '',
        id: ''
      },
      <AbstractControlOptions>{
        validator: DateValidator.greaterThan
      }
    );

    this.subs.sink = forkJoin([
      this.productService.getAllProducts(),
      this.altProviderService.getAlternateProviders(),
      this.memberExceptionService.getAllExceptionSourceCodes()
    ])
      .pipe(
        tap(([allProducts, allAltProviders, allExceptionSources]) => {
          this.products = allProducts.filter(x => x.active);
          this.altProvs = allAltProviders
            .filter(y => y.active)
            .map(activeAltProv => ({
              alternateProviderIdentifier: activeAltProv.alternateProviderIdentifier,
              alternateProviderIdType: activeAltProv.alternateProviderIdType,
              alternateProviderNBR: activeAltProv.alternateProviderNBR,
              alternateProviderName: activeAltProv.alternateProviderName,
              periodBeginDate: activeAltProv.periodBeginDate,
              periodEndDate: activeAltProv.periodEndDate
            }));
          this.exceptionSources = allExceptionSources.filter(z => z.active);
        }),
        take(1)
      )
      .subscribe({
        error: err => {
          this.errorMessage = err;
        }
      });

    if (this.mySelectedId) {
      this.getMemberExceptionById(this.mySelectedId);
      this.memberExceptionFormGroup.controls['memberId'].disable();
    } else {
      this.clearForm();
    }
  }

  getMemberExceptionById(id: string): void {
    this.subs.sink = this.memberExceptionService.getMemberExceptionById(id).subscribe({
      next: response => {
        this.selectedMemberException = response;

        this.copyMemberException();
        this.spinnerService.hide();
      },
      error: err => {
        this.spinnerService.hide();
        this.errorMessage = err;
      }
    });
  }

  private openInformationalUserDialog(header = 'Error', message = 'Invalid'): void {
    this.dialog
      .open(NoticeDialogComponent, {
        width: '300px',
        data: {
          header,
          body: message
        }
      })
      .afterClosed();
  }

  getMemberById(id: string): void {
    this.spinnerService.show();
    const todayDate = new Date();
    const memberId$ = this.memberExceptionService.getMember(id);
    let listOfMemberException: MemberException[] = [];
    let isDuplicated = false;
    this.subs.sink = memberId$
      .pipe(
        switchMap(member =>
          this.memberExceptionService.getMemberExceptionbyMemberId(member.id).pipe(
            map(memberExceptions => {
              return {
                member,
                memberExceptions
              };
            })
          )
        )
      )
      .subscribe({
        next: allResults => {
          this.member = allResults.member;
          listOfMemberException = allResults.memberExceptions.value;
          if (
            listOfMemberException.length !== 0 &&
            listOfMemberException.find(x => x.memberId === id) &&
            listOfMemberException.find(y => new Date(y.effectiveEndDate) >= todayDate)
          ) {
            isDuplicated = true;
          }

          if (isDuplicated) {
            this.openInformationalUserDialog(
              'Error',
              'MemberException is already present for this Member!'
            );
            this.memberExceptionFormGroup.patchValue({
              memberId: this.member?.id,
              firstName: '',
              lastName: ''
            });
          } else {
            this.memberExceptionFormGroup.patchValue({
              memberId: this.member?.id,
              firstName: this.member?.firstName,
              lastName: this.member?.lastName,
              productName: this.member?.product
            });
          }
          this.spinnerService.hide();
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.openInformationalUserDialog('Error', 'Invalid Member ID!');
            this.memberExceptionFormGroup.patchValue({
              memberId: '',
              firstName: '',
              lastName: ''
            });
          }
          this.spinnerService.hide();
          isDuplicated = false;
        }
      });
  }

  clearForm(): void {
    this.memberExceptionFormGroup.markAsUntouched();
    this.memberExceptionFormGroup.reset();
    this.initializeFormGroup();

    this.spinnerService.hide();
  }

  copyMemberException(): void {
    this.memberExceptionFormGroup.patchValue({
      id: this.selectedMemberException.id,
      memberId: this.selectedMemberException.memberId,
      firstName: this.selectedMemberException.firstName,
      lastName: this.selectedMemberException.lastName,
      memberExceptionNumber: this.selectedMemberException.memberExceptionNumber,
      productName: this.selectedMemberException.productName,
      altProv: this.selectedMemberException.altProv,
      effectiveStartDate: getLocalDateFromUTCDateString(
        this.selectedMemberException.effectiveStartDate.toLocaleString(),
        DatePart.Date
      ),
      effectiveStartTime: getLocalDateFromUTCDateString(
        this.selectedMemberException.effectiveStartDate.toLocaleString(),
        DatePart.Time
      ),
      effectiveEndDate: getLocalDateFromUTCDateString(
        this.selectedMemberException.effectiveEndDate.toLocaleString(),
        DatePart.Date
      ),
      effectiveEndTime: getLocalDateFromUTCDateString(
        this.selectedMemberException.effectiveEndDate.toLocaleString(),
        DatePart.Time
      ),
      note: this.selectedMemberException?.note,
      createdDate: this.selectedMemberException.createdDate,
      createdUser: this.selectedMemberException.createdUser,
      lastModifiedDate: this.selectedMemberException.modifiedDate,
      lastModifiedUser: this.selectedMemberException.modifiedDate, // TODO: correct this when we get auth
      sourceId: this.selectedMemberException.sourceId,
      sourceName: this.selectedMemberException.sourceName
    });
    console.log('this.memberExceptionFormGroup', this.memberExceptionFormGroup);
  }

  compareProperty(x: any, y: any): boolean {
    return x.alternateProviderName === y.alternateProviderName;
  }

  initializeFormGroup(): void {
    const today = new Date().toISOString();
    this.memberExceptionFormGroup.setValue({
      memberId: '',
      lastName: '',
      firstName: '',
      memberExceptionNumber: '',
      productName: '',
      altProv: '',
      effectiveStartDate: getLocalDateFromUTCDateString(today, DatePart.Date),
      effectiveStartTime: getLocalDateFromUTCDateString(today, DatePart.Time),
      effectiveEndDate: '9999-12-31',
      effectiveEndTime: '00:00',
      sourceId: '',
      sourceName: '',
      createdDate: '',
      createdUser: '',
      modifiedDate: '',
      modifiedUser: '',
      note: '',
      id: ''
    });
  }

  onSubmit(): void {
    if (this.memberExceptionFormGroup.valid) {
      this.upsertMemberException(this.memberExceptionFormGroup.value);
    }
  }

  upsertMemberException(memberException: MemberException): void {
    this.spinnerService.show();
    const today = new Date().toISOString();
    if (memberException.id) {
      this.selectedMemberException.createdDate = memberException.createdDate;
      this.selectedMemberException.createdUser = memberException.createdUser;
    } else {
      this.selectedMemberException.createdDate = today;
      this.selectedMemberException.createdUser = this.userName;
    }
    this.selectedMemberException.memberId = this.memberId.value;
    this.selectedMemberException.firstName = memberException.firstName;
    this.selectedMemberException.lastName = memberException.lastName;
    this.selectedMemberException.productName = memberException.productName;
    this.selectedMemberException.altProv = memberException.altProv;
    this.selectedMemberException.note = memberException.note;
    this.selectedMemberException.sourceId =
      this.exceptionSources.find(x => x.exceptionSourceCode === memberException.sourceName)
        ?.exceptionSourceDescription ?? '';
    this.selectedMemberException.sourceName = memberException.sourceName;
    (this.selectedMemberException.effectiveEndDate = getDateStringAsUTC(
      this.effectiveEndDate.value + 'T' + this.effectiveEndTime.value
    )),
      (this.selectedMemberException.effectiveStartDate = getDateStringAsUTC(
        this.effectiveStartDate.value + 'T' + this.effectiveStartTime.value
      )),
      (this.selectedMemberException.modifiedDate = today);
    this.selectedMemberException.modifiedUser = this.userName;
    if (this.mySelectedId) {
      this.selectedMemberException.memberExceptionNumber = memberException.memberExceptionNumber ;
    }

    const thisSubs = this.memberExceptionService
      .upsertMemberException(this.selectedMemberException)
      .subscribe({
        next: () => {
          if (!this.routeId) {
            this.clearForm();
            this.dialogRef.close();
          }

          this.spinnerService.hide();
        },
        error: err => {
          this.spinnerService.hide();
          this.errorMessage = err;
        },
        complete: () => {
          this.subs.sink = thisSubs;
        }
      });
  }

  openPCCPicker(): void {
    const dialogRef = this.dialog.open(PccPickerComponent, {
      autoFocus: true,
      minHeight: '450px',
      minWidth: 600
    });
    this.subs.sink = dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.memberExceptionFormGroup.patchValue({
          pccId: result.pccId,
          pccName: result.pccName
        });
      }
    });
  }
}
