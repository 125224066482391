import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  OnInit,
  Self
} from '@angular/core';
import { ColumnDefinition } from '@shared/models/column-definition';
import { FilterService } from '@shared/services/filter-service/filter.service';
import { QuerySessionManagerService } from '@shared/services/query-session-manager-service/query-session-manager.service';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { MemberException } from '../../models/member-exception';
import { MemberExceptionService } from '../../services/member-exception.service';

import { MatDialog } from '@angular/material/dialog';
import { MemberExceptionDetailComponent } from '../member-exception-detail/member-exception-detail.component';
import { RoleCheck } from '@shared/guards/roleCheck';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { ProductService } from '@shared/services/product-service/product.service';

@Component({
  selector: 'app-member-exception-list',
  templateUrl: './member-exception-list.component.html',
  styleUrls: ['./member-exception-list.component.scss'],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class MemberExceptionListComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly querySessionManager: QuerySessionManagerService;

  private readonly subscriptions: Subscription[] = [];

  @ViewChild(Table) table!: Table;

  selectedColumns: ColumnDefinition[];
  allColumns: ColumnDefinition[];

  pagingSizes = [20, 40, 60, 80, 100];

  loadingTable = true;
  memberExceptions: MemberException[] = [];
  totalItems = 0;

  private previousLoadEvent: LazyLoadEvent | undefined = undefined;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly memberExceptionService: MemberExceptionService,
    private readonly alternateProviderService: AltProviderService,
    private readonly filterService: FilterService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private readonly productService: ProductService,
    private readonly dialog: MatDialog,
    private roleCheck: RoleCheck
  ) {
    const defaultColumns: ColumnDefinition[] = [
      {
        field: 'memberExceptionNumber',
        shortName: 'Member Exception ID',
        longName: 'Member Exception ID',
        type: 'text',
        static: true
      },
      {
        field: 'memberId',
        shortName: 'Member ID',
        longName: 'Member ID',
        type: 'text',
        static: true
      },
      { field: 'firstName', shortName: 'First Name', longName: 'First Name', type: 'text' },
      { field: 'lastName', shortName: 'Last Name', longName: 'Last Name', type: 'text' },
      {
        field: 'altProvNumber',
        shortName: 'AltProv ID',
        longName: 'Alternate Provider ID',
        type: 'text'
      },
      {
        field: 'altProvName',
        shortName: 'AltProv',
        longName: 'Alternate Provider Name',
        type: 'multiselect',
        options: []
      },
      {
        field: 'product',
        shortName: 'Product',
        longName: 'Product',
        type: 'multiselect',
        options: []
      }
    ];

    const additionalColumns: ColumnDefinition[] = [
      {
        field: 'pmiNumber',
        shortName: 'PMI Number',
        longName: 'Personal Master Index Number',
        type: 'text'
      },
      {
        field: 'pccId',
        shortName: 'PCC ID',
        longName: 'Primary Care Clinic ID',
        type: 'text'
      },
      {
        field: 'pccName',
        shortName: 'PCC',
        longName: 'Primary Care Clinic Name',
        type: 'text'
      },
      {
        field: 'sourceId',
        shortName: 'Source ID',
        longName: 'Source ID',
        type: 'text'
      },
      {
        field: 'sourceName',
        shortName: 'Source Name',
        longName: 'Source Name',
        type: 'text'
      },
      {
        field: 'effectiveStartDate',
        shortName: 'Effective Start Date',
        longName: 'Effective Start Date',
        type: 'date'
      },
      {
        field: 'effectiveEndDate',
        shortName: 'Effective End Date',
        longName: 'Effective End Date',
        type: 'date'
      }
    ];

    this.selectedColumns = defaultColumns;
    this.allColumns = defaultColumns.concat(additionalColumns);

    this.querySessionManager = new QuerySessionManagerService('member-exception-list');

    // Clear any cached sessions on page load
    this.querySessionManager.resetSession();
  }

  userHasEdit() {
    return this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvEditMemberExceptions']);
  }

  ngOnInit() {
    const altProvSub = this.alternateProviderService.getAlternateProviders().subscribe(val => {
      const altProvs = val.map(ap => ap.alternateProviderName);
      this.setColumnOptions('altProvName', altProvs);
    });

    const productSub = this.productService.getAllProducts().subscribe(val => {
      const products = val.map(p => p.productName.toUpperCase());
      this.setColumnOptions('product', products);
    });

    this.subscriptions.push(altProvSub);
    this.subscriptions.push(productSub);
  }

  ngAfterViewInit(): void {
    const columnChangeSub = this.table.tableService.columnsSource$.subscribe(() => {
      this.table.saveState();
    });

    this.subscriptions.push(columnChangeSub);
  }

  addMemberExcepion() {
    this.openMemberExceptionDetail('');
  }
  openMemberExceptionDetail(memberId: string) {
    let dialogRef = this.dialog.open(MemberExceptionDetailComponent, {
      autoFocus: true,
      width: '1000px',
      maxHeight: '900px',
      panelClass: 'custom-dialog-container',
      data: { memberExceptionId: memberId }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.querySessionManager.resetSession();
      this.table._filter();
      window.location.reload();
    });

    //https://stackoverflow.com/questions/40077150/how-to-programmatically-trigger-refresh-primeng-datatable-when-a-button-is-click
    // dialogRef
    //   .afterClosed()
    //   .pipe(switchMap(() => of(this.updateTable())))
    //   .subscribe();
  }

  // updateTable() {
  //   this.querySessionManager.resetSession();
  //   this.table._filter();
  //   this.isTableVisible = false;
  //   setTimeout(() => (this.isTableVisible = true), 600);
  // }

  async loadMembersLazy(event: LazyLoadEvent): Promise<void> {
    this.loadingTable = true;

    // Blow away the query session every time unless we are paging forward/backward
    if (this.filterService.didChangePage(this.previousLoadEvent, event) === false) {
      this.querySessionManager.resetSession();

      //event.sortField = 'memberExceptionNumber';
    }

    const clonedEvent = Object.assign({}, event);

    const selectedColumnKeys = this.selectedColumns.map(col => col.field);

    for (const filterKey in clonedEvent.filters) {
      if (!selectedColumnKeys.includes(filterKey)) {
        // Remove any filters not included in the selected columns
        delete clonedEvent.filters[filterKey];
      }
    }

    const fetchSub = this.memberExceptionService
      .getMemberExceptionsList(clonedEvent, this.querySessionManager.currentSession)
      .subscribe(
        response => {
          this.memberExceptions = response.results;
          this.totalItems = response.totalResults;

          if (response.querySessionId) {
            this.querySessionManager.currentSession = response.querySessionId;
          }
        },
        (error: HttpErrorResponse) => {
          // If our query session ID was deleted,
          // clear it out and try again
          if (error.status === 404) {
            this.querySessionManager.resetSession();
            this.table._filter();
          }
        },
        () => {
          this.loadingTable = false;

          fetchSub.unsubscribe();
        }
      );

    this.previousLoadEvent = event;
  }

  onStateRestore(event: any) {
    const columnKeys = event.columnOrder;
    const columns = this.getFilterByKeyOrdered(columnKeys);

    this.selectedColumns = columns;
    this.cd.detectChanges();
  }

  onColumnReorder() {
    this.table.saveState();
  }

  private getFilterByKeyOrdered(keys: string[]) {
    const result: any[] = [];

    for (let key of keys) {
      const column = this.allColumns.find(f => f.field == key);
      result.push(column);
    }

    return result;
  }

  private setColumnOptions(field: string, options: Array<any>): void {
    const selectedColumnsIndex = this.selectedColumns.findIndex(x => x.field === field);
    const allColumnsIndex = this.allColumns.findIndex(x => x.field === field);

    if (selectedColumnsIndex > 0) {
      this.selectedColumns[selectedColumnsIndex].options = options;
    }

    if (allColumnsIndex > 0) {
      this.allColumns[allColumnsIndex].options = options;
    }
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }

  ngOnDestroy(): void {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
