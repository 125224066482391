import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notice-dialog',
  templateUrl: './notice-dialog.component.html',
  styleUrls: ['./notice-dialog.component.scss']
})
export class NoticeDialogComponent {
  readonly header: string;
  readonly body: string;
  confirmText: string;

  selection = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<NoticeDialogComponent>
  ) {
    this.header = this.data.header;
    this.body = this.data.body;
    this.confirmText = this.data.confirmText ?? 'Ok';

    if (!this.header && !this.body) {
      this.dialogRef.close();
    }
  }

  confirm(): void {
    this.selection = true;
    this.dialogRef.close(this.selection);
  }
}
