<p-table
  #dt1
  [value]="unassignedMembers"
  [lazy]="true"
  (onLazyLoad)="getAllUnassignedMembers($event)"
  [totalRecords]="totalMembers"
  [first]="0"
  dataKey="id"
  stateStorage="local"
  stateKey="unassigned-filters"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="pagingSizes"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rows]="pagingSizes[0]"
  [autoLayout]="true"
  [rowHover]="true"
  paginatorDropdownAppendTo="body"
  sortMode="single"
  sortField="id"
  [columns]="cols"
  [globalFilterFields]="['effectiveDate', 'endDate']"
>
  <ng-template pTemplate="caption">
    <H2>Unassigned Members</H2>
    <div class="p-formgroup-inline"></div>
    <div class="p-d-flex p-jc-between">
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('unassigned-filters')"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-excel"
        (click)="exportExcel()"
        class="p-button-success p-mr-2"
        pTooltip="Export as XLS"
        label="Export"
        iconPos="right"
        tooltipPosition="bottom"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th [ngStyle]="{ width: '50px' }">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>

      <th pSortableColumn="id" [ngStyle]="{ width: '200px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Member Id
          <p-sortIcon field="id"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="programNBR" [ngStyle]="{ width: '200px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Product
          <p-sortIcon field="programNBR"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="firstName" [ngStyle]="{ width: '250px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          First Name
          <p-sortIcon field="firstName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="lastName" [ngStyle]="{ width: '250px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Last Name
          <p-sortIcon field="lastName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="county">
        <div class="p-d-flex p-jc-between p-ai-center">
          County
          <p-sortIcon field="county"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="careSystemCode">
        <div class="p-d-flex p-jc-between p-ai-center">
          Care System Code
          <p-sortIcon field="careSystemCode"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="healthStatusCode">
        <div class="p-d-flex p-jc-between p-ai-center">
          Health Status Code
          <p-sortIcon field="healthStatusCode"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="pccId">
        <div class="p-d-flex p-jc-between p-ai-center">
          PCC Id
          <p-sortIcon field="pccId"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="pccName">
        <div class="p-d-flex p-jc-between p-ai-center">
          PCC Name
          <p-sortIcon field="pccName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="effectiveDate">
        <div class="p-d-flex p-jc-between p-ai-center">
          Effective Date
          <p-sortIcon field="effectiveDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="endDate">
        <div class="p-d-flex p-jc-between p-ai-center">
          End Date
          <p-sortIcon field="endDate"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr>
      <th><div class="p-d-flex p-jc-between p-ai-center"></div></th>

      <th [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="id"
            [showClearButton]="false"
            [ngStyle]="{ width: '170px' }"
          ></p-columnFilter>
        </div>
      </th>
      <th [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="programNBR" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="allProducts"
                (onChange)="
                  filter(
                    $event?.value ? $event?.value : '',
                    'products',
                    'in'
                  )
                "
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th [ngStyle]="{ width: '230px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="firstName"
            [showClearButton]="false"
            [ngStyle]="{ width: '230px' }"
          ></p-columnFilter>
        </div>
      </th>
      <th [ngStyle]="{ width: '230px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="lastName"
            [showClearButton]="false"
            [ngStyle]="{ width: '230px' }"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter type="text" field="county" [showClearButton]="false"></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="careSystemCode"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="healthStatusCode"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter type="text" field="pccId" [showClearButton]="false"></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter type="text" field="pccName" [showClearButton]="false"></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="effectiveDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="endDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-member let-expanded="expanded">
    <tr>
      <td></td>
      <td>
        <div class="p-button p-button-link" [routerLink]="['/members/', member.id]">
          {{ member.id }}
        </div>
      </td>
      <td>
        {{ member.programNBR }}
      </td>
      <td>{{ member.firstName }}</td>
      <td>{{ member.lastName }}</td>
      <td>{{ member.county }}</td>
      <td>{{ member.careSystemCode }}</td>
      <td>{{ member.healthStatusCode }}</td>
      <td>{{ member.pccId }}</td>
      <td>{{ member.pccName }}</td>
      <td>
        <time [attr.datetime]="member.effectiveDate">{{
          member?.effectiveDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        <time [attr.datetime]="member.endDate">{{ member?.endDate | date: 'MM/dd/yyyy' }}</time>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="3">This unassigned members is empty.</td>
    </tr>
  </ng-template>
</p-table>
