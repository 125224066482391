import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RulesListComponent } from '@rules/components/rules-list/rules-list.component';
import { RouterModule } from '@angular/router';
import { RuleDetailsComponent } from '@rules/components/rule-details/rule-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RuleCriteriaSelectorComponent } from '@rules/components/rule-criteria-selector/rule-criteria-selector.component';
import { ProductSelectionListComponent } from '@rules/components/product-selection/product-selection-list.component';
import { RuleValueSelectionDialogComponent } from '@rules/components/rule-value-selection-dialog/rule-value-selection-dialog.component';
import { PccExceptionListComponent } from './pccExceptions/components/pcc-exception-list/pcc-exception-list.component';
import { PccExceptionEntryComponent } from './pccExceptions/components/pcc-exception-entry/pcc-exception-entry.component';
import { MaterialModule } from '@shared/modules/material/material.module';
import { PrimengModule } from '@shared/modules/primeng/primeng.module';
import { SpinnerOverlayComponent } from '@shared/components/spinner-overlay/spinner-overlay.component';
import { MemberMaintenanceListComponent } from '@memberMaintenance/components/member-maintenance-list/member-maintenance-list.component';
import { HealthStatusCodeDialogComponent } from './healthStatusCodes/components/health-status-code-dialog/health-status-code-dialog.component';
import { HealthStatusCodesListComponent } from './healthStatusCodes/components/health-status-codes-list/health-status-codes-list.component';
import { MemberMaintenanceDetailComponent } from './memberMaintenance/components/member-maintenance-detail/member-maintenance-detail/member-maintenance-detail.component';
import { ProductCheckboxComponent } from '@rules/components/product/product-checkbox.component';
import { CareSystemsListComponent } from './careSystems/components/care-systems-list/care-systems-list.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ListboxModule } from 'primeng/listbox';
import { LivingStatusCodesListComponent } from './livingStatusCodes/components/living-status-codes-list/living-status-codes-list.component';
import { LivingStatusCodesAddComponent } from './livingStatusCodes/components/living-status-codes-add/living-status-codes-add.component';
import { ProductsListComponent } from './products/components/products-list/products-list.component';
import { ProductsAddComponent } from './products/components/products-add/products-add.component';
import { MemberExceptionListComponent } from './memberExceptions/components/member-exception-list/member-exception-list.component';
import { MemberExceptionDetailComponent } from './memberExceptions/components/member-exception-detail/member-exception-detail.component';
import { PccPickerComponent } from '@shared/components/pcc-picker/pcc-picker.component';
import { ExceptionSourcesAddComponent } from './exceptionSources/components/exception-sources-add/exception-sources-add.component';
import { ExceptionSourcesListComponent } from './exceptionSources/components/exception-sources-list/exception-sources-list.component';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { NoticeDialogComponent } from './shared/components/notice-dialog/notice-dialog.component';
import { AlternateProvidersListComponent } from './alternateProviders/components/alternate-providers-list/alternate-providers-list.component';
import { AlternateProvidersAddComponent } from './alternateProviders/components/alternate-providers-add/alternate-providers-add.component';
import { CountyEditComponent } from './counties/components/county-edit/county-edit.component';
import { CountiesListComponent } from './counties/components/counties-list/counties-list.component';
import { AltProvResultsListComponent } from './reports/altprov-results/altprov-results-list.component';
import { UnassignedMembersListComponent } from './reports/unassigned-members/unassigned-members-list.component';
import { ColumnValueComponent } from '@shared/components/column-value/column-value.component';
import { ColumnFilterComponent } from '@shared/components/column-filter/column-filter.component';
import { MembersMultiAssignedListComponent } from '@reports/members-multi-assigned/members-multi-assigned-list.component';
import { HealthstatuscodesUploadListComponent } from './uploads/memberHealthStatusCodesUploads/components/member-healthstatuscodes-upload-list/healthstatuscodes-upload-list.component';
import { UploadFileDetailComponent } from './uploads/memberHealthStatusCodesUploads/components/upload-files/upload-file-detail.component';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RosterListComponent } from 'src/app/rosters/roster-list.component';
import { EndpointInterceptor } from '@shared/services/endpoint-interceptor';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './home/home.component';
import { ClinicUpdateComponent } from './providers/components/clinic-update/clinic-update.component';
import { PccMaintenanceComponent } from './providers/components/pcc-maintenance/pcc-maintenance.component';
import { PccEditComponent } from './providers/components/pcc-edit/pcc-edit.component';
import { HealthStatusCodesHistoryListComponent } from './healthStatusCodesHistory/components/health-status-codes-history-list/health-status-codes-history-list.component';
//import { RosterVarianceComponent } from '@reports/roster-variance/roster-variance.component';
import { RosterVarianceDetailComponent } from './reports/roster-variance/roster-variance-detail/roster-variance-detail.component';
import { RosterVarianceListComponent } from './reports/roster-variance/roster-variance-list/roster-variance-list.component';
import { RosterVarianceDifferencePipe } from './reports/pipe/roster-variance-difference.pipe';
import { DatetimeLocalIsoDirective } from '@shared/directives/datetime-local-iso/datetime-local-iso.directive';
import { MemberExceptionHistoryComponent } from './memberExceptions/components/member-exception-history/member-exception-history.component';
import { RuleHistoryComponent } from '@rules/components/rule-history/rule-history.component';
import { AlternateProvidersHistoryComponent } from './alternateProviders/components/alternate-providers-history/alternate-providers-history.component';
import { PccExceptionHistoryListComponent } from './pccExceptions/components/pcc-exception-history-list/pcc-exception-history-list.component';
import { MemberMaintenanceHistoryListComponent } from './memberMaintenance/components/member-maintenance-history-list/member-maintenance-history-list.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: 'https://login.microsoftonline.com/ucare.org',
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  //protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set(environment.apiUrls.getRules, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getRuleById, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getChangeReasons, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveRule, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAlternateProviders, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveAlternateProvider, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllCounties, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveCounty, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllPccExceptions, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getPccExceptionById, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getPccExceptionHistoryByNumber, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.savePccException, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.deletePccException, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllHealthStatusCodes, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllProducts, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveProducts, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveHealthStatusCode, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getMemberById, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveMember, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.filterMembers, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.filterPCCs, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.filterMemberExceptions, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getMemberExceptionById, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveMemberException, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllCareSystems, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllLivingStatusCodes, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveLivingStatusCode, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllExceptionSources, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.saveExceptionSource, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getMemberExceptionByMemberId, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.getAllUnassignedMembers, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.filterAltProvResults, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.filterMembersMultiAssigned, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.filterRoster, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.publishRoster, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllHealthStatusCodeUploads, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.upLoadMemberHsCodes, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAllHsImportsHistories, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.getFileFromBlobStorage, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getRosterPublishDisallowed, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.getAltProvResultDatesEndpoint, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.closeClinic, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.editPCC, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getRuleHistoryById, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getAltProvHistory, [environment.defaultScope]);
  protectedResourceMap.set(environment.apiUrls.getMemberExceptionHistory, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.getAllHealthStatusCodesHistory, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.getMemberHistoryById, [environment.defaultScope]);

  protectedResourceMap.set(environment.apiUrls.getRosterPublishDateInfo, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.filterRosterVarianceReport, [
    environment.defaultScope
  ]);
  protectedResourceMap.set(environment.apiUrls.filterRosterVarianceDetail, [
    environment.defaultScope
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: [environment.defaultScope]
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    RulesListComponent,
    RuleDetailsComponent,
    RuleCriteriaSelectorComponent,
    ProductCheckboxComponent,
    ProductSelectionListComponent,
    RuleValueSelectionDialogComponent,
    RuleHistoryComponent,
    PccExceptionListComponent,
    PccExceptionEntryComponent,
    SpinnerOverlayComponent,
    MemberExceptionListComponent,
    MemberMaintenanceListComponent,
    MemberMaintenanceDetailComponent,
    HealthStatusCodeDialogComponent,
    HealthStatusCodesListComponent,
    CareSystemsListComponent,
    ProductsAddComponent,
    ProductsListComponent,
    LivingStatusCodesAddComponent,
    LivingStatusCodesListComponent,
    PccPickerComponent,
    MemberExceptionListComponent,
    MemberExceptionDetailComponent,
    ExceptionSourcesAddComponent,
    ExceptionSourcesListComponent,
    GenericDialogComponent,
    NoticeDialogComponent,
    AlternateProvidersListComponent,
    AlternateProvidersAddComponent,
    CountyEditComponent,
    CountiesListComponent,
    AltProvResultsListComponent,
    MembersMultiAssignedListComponent,
    UnassignedMembersListComponent,
    RosterListComponent,
    ColumnValueComponent,
    ColumnFilterComponent,
    HomeComponent,
    HealthstatuscodesUploadListComponent,
    UploadFileDetailComponent,
    ClinicUpdateComponent,
    PccMaintenanceComponent,
    PccEditComponent,
    HealthStatusCodesHistoryListComponent,
    RosterVarianceDetailComponent,
    RosterVarianceListComponent,
    RosterVarianceDifferencePipe,
    DatetimeLocalIsoDirective,
    MemberExceptionHistoryComponent,
    AlternateProvidersHistoryComponent,
    PccExceptionHistoryListComponent,
    MemberMaintenanceHistoryListComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MaterialModule,
    PrimengModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ListboxModule,
    MsalModule,
    ListboxModule,
    FileUploadModule,
    RadioButtonModule
  ],
  exports: [RouterModule],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
