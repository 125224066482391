import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CareSystem } from '../models/care-system';
import { environment } from '../../../environments/environment';
import { AzureSubscriptionHeader } from '../../shared/models/CosmosDB/azure-subscription-header';

@Injectable({
  providedIn: 'root'
})
export class CareSystemsService {
  private readonly getAllCareSystemsEndPoint?: string;

  constructor(private http: HttpClient) {
    this.getAllCareSystemsEndPoint = environment.apiUrls.getAllCareSystems;
  }

  getAllCareSystems(): Observable<CareSystem[]> {
    return this.http.get<CareSystem[]>(this.getAllCareSystemsEndPoint!, {
      headers: AzureSubscriptionHeader
    });
  }

  initializeCareSystem(): CareSystem {
    return {
      id: '0',
      careSystemName: null,
      careSystemDescription: null,
      active: null,
      createdDate: null,
      createdUser: null,
      modifiedDate: null,
      modifiedUser: null
    };
  }
}
