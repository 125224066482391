<p-table
  #dt1
  [value]="products"
  dataKey="id"
  stateStorage="local"
  stateKey="products-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [globalFilterFields]="['healthStatusCode', 'active', 'modifiedUser']"
  [autoLayout]="true"
>
  <ng-template pTemplate="caption">
    <H2>Products</H2>
    <div class="p-d-flex p-jc-between">
      <button
        type="button"
        (click)="addProduct()"
        pButton
        icon="pi pi-plus"
        label="Add a Product"
        [disabled]="!userHasEdit()"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('products-filters')"
      ></button>
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="table-header">
    <tr>
      <!-- Edit-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th pSortableColumn="productName" class="medium-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Product Name
          <p-sortIcon field="productName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="active" pResizableColumn class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Active
          <p-sortIcon field="active"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="createdDate" pResizableColumn class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created<br />Date
          <p-sortIcon field="createdDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="createdUser" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created<br />User
          <p-sortIcon field="createdUser"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Modified<br />Date
          <p-sortIcon field="modifiedDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedUser" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Updated<br />User
          <p-sortIcon field="modifiedUser"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr>
      <th><div class="p-d-flex p-jc-between p-ai-center"></div></th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="productName"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="active" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="[
                  { label: 'Active', value: true },
                  { label: 'Inactive', value: false }
                ]"
                (onChange)="filter($event?.value, 'active', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option.label }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="createdDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="createdUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="modifiedDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="modifiedUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          title="Edit"
          icon="pi pi-pencil"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
          (click)="editProduct(product)"
          [disabled]="!userHasEdit()"
        ></button>
      </td>
      <td
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px"
        pTooltip="{{ product.productName }}"
      >
        {{ product.productName }}
      </td>
      <td>
        {{ product.active ? 'Active' : 'Inactive' }}
      </td>
      <td>
        <time [attr.datetime]="product.createdDate">{{
          product?.createdDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ product.createdUser }}</td>
      <td>
        <time [attr.datetime]="product.modifiedDate">{{
          product?.modifiedDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ product.modifiedUser }}</td>
    </tr>
  </ng-template>
</p-table>
<div></div>
<app-products-add
  *ngIf="displayEditDialog"
  [visible]="displayEditDialog"
  [myProductData]="selectedProductData"
  [products]="products"
  (cancelEvent)="cancelModal($event)"
  (saveEvent)="updateProduct($event)"
></app-products-add>
