import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Member } from '@memberMaintenance/models/member';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { ExceptionSourceCode } from '@shared/models/exception-source-code';
import { FilterRequest } from '@shared/models/filter-request';
import { QueryResponse } from '@shared/models/query-response';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MEbyMemberID, MemberException } from '@memberExceptions/models/member-exception';

@Injectable({
  providedIn: 'root'
})
export class MemberExceptionService {
  private readonly getMemberExceptionByIdEndPoint?: string;
  private readonly upsertMemberExceptionEndPoint?: string;
  private readonly getMemberByIdEndPoint?: string;
  private readonly filterMemberExceptionsEndpoint: string;
  private readonly getAllExceptionSourceCodesEndPoint: string;
  private readonly getMemberExceptionByMemberId?: string;

  constructor(private http: HttpClient) {
    this.getMemberExceptionByIdEndPoint = environment.apiUrls.getMemberExceptionById;
    this.upsertMemberExceptionEndPoint = environment.apiUrls.saveMemberException;
    this.getMemberByIdEndPoint = environment.apiUrls.getMemberById;
    this.filterMemberExceptionsEndpoint = environment.apiUrls.filterMemberExceptions;
    this.getAllExceptionSourceCodesEndPoint = environment.apiUrls.getAllExceptionSources;
    this.getMemberExceptionByMemberId = environment.apiUrls.getMemberExceptionByMemberId;
  }

  getMember(id: string): Observable<Member> {
    const url = `${this.getMemberByIdEndPoint!}/${id}`;
    return this.http.get<Member>(url, { headers: AzureSubscriptionHeader });
  }

  getAllExceptionSourceCodes(): Observable<ExceptionSourceCode[]> {
    return this.http.get<ExceptionSourceCode[]>(this.getAllExceptionSourceCodesEndPoint!, {
      headers: AzureSubscriptionHeader
    });
  }

  getMemberExceptionbyMemberId(memberId: string): Observable<MEbyMemberID> {
    const url = `${this.getMemberExceptionByMemberId}`;
    const headers = AzureSubscriptionHeader;
    const body = {
      memberId: memberId
    };
    return this.http.post<MEbyMemberID>(url, body, { headers });
  }

  getMemberExceptionById(id: string): Observable<MemberException> {
    const url = `${this.getMemberExceptionByIdEndPoint!}${id}`;
    return this.http.get<MemberException>(url, { headers: AzureSubscriptionHeader }).pipe(take(1));
  }

  upsertMemberException(memberException: MemberException) {
    const headers = AzureSubscriptionHeader;
    const url = `${this.upsertMemberExceptionEndPoint!}`;
    return this.http.post<MemberException>(url, memberException, { headers });
  }

  getMemberExceptionsList(
    filter: LazyLoadEvent,
    sessionId: string | null
  ): Observable<QueryResponse<MemberException>> {
    const request: FilterRequest = {
      querySessionId: sessionId,
      filter: filter
    };

    return this.http.post<QueryResponse<MemberException>>(
      this.filterMemberExceptionsEndpoint,
      request,
      {
        headers: AzureSubscriptionHeader
      }
    );
  }
}
