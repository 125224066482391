<p-dialog
  header="Edit County"
  appendTo="body"
  [modal]="true"
  [(visible)]="visible"
  [closable]="false"
  [style]="{ width: '800px' }"
>
  <form [formGroup]="countyFormGroup" (ngSubmit)="onSubmit()" class="county-edit-form">
    <div class="county-attributes">
      <mat-form-field [style.width.px]="200" appearance="outline" class="p-mr-3">
        <mat-label>County FIPS Code</mat-label>
        <input matInput type="text" formControlName="countyFIPSCode" readonly />
      </mat-form-field>
      <mat-form-field [style.width.px]="200" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>County Code</mat-label>
        <input matInput type="text" formControlName="countyCode" readonly />
      </mat-form-field>
      <mat-form-field [style.width.px]="250" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>County Name</mat-label>
        <input matInput type="text" formControlName="countyName" readonly />
      </mat-form-field>
      <mat-form-field [style.width.px]="60" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>State</mat-label>
        <input matInput type="text" formControlName="state" readonly />
      </mat-form-field>
      <mat-checkbox
        class="p-mr-5 p-flew-row"
        color="primary"
        [checked]="isForPCC"
        (change)="toggleForPCCCheckbox($event)"
        >For PCC
      </mat-checkbox>
      <mat-checkbox
        class="p-mr-5 p-flew-row"
        color="primary"
        [checked]="isForMember"
        (change)="toggleForMemberCheckbox($event)"
        >For Member
      </mat-checkbox>
      <mat-form-field [style.width.px]="85" appearance="outline" class="p-mr-5 p-flew-row">
        <mat-label>Risk Pop</mat-label>
        <input matInput type="text" formControlName="riskPop" readonly />
      </mat-form-field>
      <mat-checkbox
        class="p-mr-5 p-flew-row"
        color="primary"
        [checked]="isActive"
        (change)="toggleActiveCheckbox($event)"
        disabled
        >Active
      </mat-checkbox>
      <input type="hidden" formControlName="id" />
      <input type="hidden" formControlName="createdDate" />
      <input type="hidden" formControlName="createdUser" />
      <input type="hidden" formControlName="modifiedDate" />
      <input type="hidden" formControlName="modifiedUser" />
    </div>
    <div class="form-action-buttons p-mt-6">
      <button mat-raised-button type="button" (click)="cancelModal()" class="p-mr-2">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="loading">Save</button>
    </div>
  </form>
</p-dialog>
