<div class="header">
  <h2>View Roster Variance Report</h2>
  <div>
    <mat-form-field appearance="outline" class="date-options">
      <mat-label>Date From</mat-label>
      <mat-select [(value)]="this.selectedDateFrom" (selectionChange)="updateOtherSelection($event.value)" required>
        <mat-option id="dateOption" *ngFor="let opt of rosterSelections" [value]="opt.completionTime">
          {{ this.getLocalDate(opt.completionTime) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="date-options">
      <mat-label>Date To</mat-label>
      <mat-select [(value)]="this.selectedDateTo" required>
        <mat-option id="dateOption2" *ngFor="let opt2 of rosterSelectionsTrim" [value]="opt2.completionTime">
          {{ this.getLocalDate(opt2.completionTime) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="primary-row">
    <!--{{this.selectedDate}}: {{this.selectedDate2}} -->
    <button type="button" mat-raised-button color="primary" pTooltip="Loading may take some time"
      (click)="getVarianceReport()">
      Get Variance Report
    </button>
  </div>
</div>
<br>
<p-table #dt *ngIf="this.showTable" [value]="rosterData" [loading]="loadingTable" [paginatorDropdownAppendTo]="dt"
  sortMode="single" sortField="id" dataKey="id" [paginator]="true" [showPageLinks]="false" [showFirstLastIcon]="false"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]" [showCurrentPageReport]="true" [rows]="20" [rowHover]="true"
  [columns]="columns" [first]="0" styleClass="p-datatable-gridlines p-datatable-striped"
  stateKey="clinic-maintenance-filters" stateStorage="local" [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" paginatorDropdownAppendTo="body">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th [ngStyle]="{ width: '50px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          <!-- Empty space for edit column -->
        </div>
      </th>
      <th *ngFor="let column of columns" [pSortableColumn]="column.propertyName">
        <span class="table-header">
          <span [attr.title]="column.longName">{{ column.longName }}</span>
          <p-sortIcon [field]="column.propertyName"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th>
        <!-- Empty space for edit column -->
      </th>
      <th *ngFor="let column of columns" [ngStyle]="{ width: '150px' }">
        <ng-template [ngIf]="column.type == 'noshow'" [ngIfElse]="standardFilter">
        </ng-template>
        <ng-template #standardFilter>
          <p-columnFilter #standardFilter [type]="column.type" [field]="column.propertyName"
            [ngStyle]="{ width: '150px' }"></p-columnFilter>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-varianceReportData>
    <tr class="table-row-link-pointer">
      <td>
        <a *ngIf="varianceReportData['difference'] != '0'" [routerLink]="['/rosterVarianceReportDetail',
                            this.fromID, this.toID,
                            varianceReportData['careCoordinator'],
                            varianceReportData['program']]" target="_blank">
          <mat-icon>link</mat-icon>
        </a>
      </td>
      <td>{{varianceReportData['careCoordinator']}}</td>
      <td>{{varianceReportData['program']}}</td>
      <td>{{varianceReportData['fromDateCount']}}</td>
      <td>{{varianceReportData['toDateCount']}}</td>
      <td>
        {{varianceReportData['difference'] | rosterVarianceDifference}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns.length">There were no matches to the current filter(s).</td>
    </tr>
  </ng-template>
</p-table>
