import { FilterService } from "primeng/api";

export function registerEmptyFilter(filterService: FilterService) {
    const customFilterName = 'is-empty';

    filterService.register(customFilterName, (value: string, filter: string): boolean => {
        if (filter === undefined || filter === null || filter.trim() === '') {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return value.toString() === filter.toString();
    });
}