import { ChangeDetectorRef, Component, OnInit, Self, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PccException } from '@pccExceptions/models/pcc-exception';
import { PccExceptionService } from '@pccExceptions/services/pcc-exception.service';
import { ProductService } from '@shared/services/product-service/product.service';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { dateFormatForExport } from '@shared/util/date-formatter';
import { exportDataToExcel } from '@shared/util/excel-exporter';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { forkJoin, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { PccExceptionEntryComponent } from '../pcc-exception-entry/pcc-exception-entry.component';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { RoleCheck } from '@shared/guards/roleCheck';
import { Table } from 'primeng/table';
import { GenericDialogLaunchOptions } from '@shared/components/generic-dialog/generic-dialog-launch-options';

@Component({
  selector: 'app-pcc-exception-list',
  templateUrl: './pcc-exception-list.component.html',
  styleUrls: ['./pcc-exception-list.component.scss'],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class PccExceptionListComponent implements OnInit {
  pccExceptions!: PccException[];

  displayEditDialog = false;
  errorMessage = '';

  loadingTable = true;

  @ViewChild(Table) table!: Table;

  selectedId: string | null = null;

  selectedPccExceptionData!: PccException | null;

  altProvs: string[] = [];
  allProducts: string[] = [];

  constructor(
    private pccExceptionService: PccExceptionService,
    private readonly productService: ProductService,
    private readonly altProviderService: AltProviderService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private cdRef: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private roleCheck: RoleCheck
  ) {}

  userHasEdit(){
    return this.roleCheck.userHasRole(['AltProvAdmin','AltProvEditPCCExceptions']);
  }

  ngOnInit(): void {
    this.getAllPccExceptions();

    forkJoin([
      this.altProviderService.getAlternateProviders(),
      this.productService.getAllProducts()
    ])
      .pipe(
        tap(([altProvs, products]) => {
          this.altProvs = [
            ...new Set(
              altProvs
                .filter(x => x.active)
                .map(y => y.alternateProviderName)
                .sort()
            )
          ];
          this.allProducts = [
            ...new Set(
              products
                .filter(x => x.active)
                .map(y => y.productName ?? '')
                .sort()
            )
          ];
        }),
        take(1)
      )
      .subscribe();
  }

  addPccException() {
    this.openPccExceptionDetail('');
  }

  openPccExceptionDetail(pccId: string) {
    let dialogRef = this.dialog.open(PccExceptionEntryComponent, {
      autoFocus: true,
      width: '1000px',
      maxHeight: '900px',
      panelClass: 'custom-dialog-container',
      data: { pccExceptionId: pccId }
    });

    dialogRef
      .afterClosed()
      .pipe(switchMap(() => of(this.getAllPccExceptions())))
      .subscribe();

    this.cdRef.detectChanges();
  }

  getFormattedProducts(products: string[]): string {
    return products?.join('\r\n');
  }

  getActive(start: string, end: string): boolean {
    const today = new Date();
    return today > new Date(start) && today < new Date(end);
  }

  getAllPccExceptions() {
    this.loadingTable = true;
    this.pccExceptionService.getPccExceptions().subscribe({
      next: allPccExceptions => {
        this.pccExceptions = allPccExceptions;
        this.pccExceptions?.forEach(
          x => (
            x.active = this.getActive(x.effectiveStartDate as string, x.effectiveEndDate as string),
            x.effectiveStartDate = new Date(x.effectiveStartDate!),
            x.effectiveEndDate = new Date(x.effectiveEndDate!)
          )
        );

        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  deletePccException(pccExceptionData: PccException) {
    this.dialog
      .open(GenericDialogComponent, {
        width: '500px',
        data: {
          header: 'Confirm',
          body: `Do you really want to term this PCC Exception ID ${pccExceptionData.pccExceptionNumber}?`
        } as GenericDialogLaunchOptions
      })
      .afterClosed()
      .subscribe(x => {
        if (x !== true) {
          return;
        }

        this.pccExceptionService
          .deletePccException(pccExceptionData.id!)
          .pipe(switchMap(() => of(this.getAllPccExceptions())))
          .subscribe();
      });
  }

  exportExcel(): void {
    const excelData: any[] = this.formatExcelData();

    exportDataToExcel(excelData, 'pccExceptions');
  }

  private formatExcelData(): Array<any> {
    return this.pccExceptions.map((entry: PccException) => ({
      exceptionId: entry.pccExceptionNumber,
      altProv: entry.altProv.alternateProviderName,
      pccId: entry.pccId,
      pccName: entry.pccName,
      product: entry.product,
      createdDate:
        entry.createdDate != null
          ? new Date(entry.createdDate).toLocaleDateString('en-US', dateFormatForExport)
          : '',
      createdUser: entry.createdUser,
      effectiveStartDate:
        entry.effectiveStartDate != null
          ? new Date(entry.effectiveStartDate).toLocaleDateString('en-US', dateFormatForExport)
          : '',
      effectiveEndDate:
        entry.effectiveEndDate != null
          ? new Date(entry.effectiveEndDate).toLocaleDateString('en-US', dateFormatForExport)
          : '',
      lastModifiedDate:
        entry.lastModifiedDate != null
          ? new Date(entry.lastModifiedDate).toLocaleDateString('en-US', dateFormatForExport)
          : '',
      lastModifiedUser: entry.lastModifiedUser,
      note: entry.note
    }));
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
