import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { RuleHistory } from '@rules/models/rule-history';

@Injectable({
  providedIn: 'root'
})
export class RuleHistoryService {
  private readonly getRuleHistorybyIdEndPoint: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getRuleHistorybyIdEndPoint = environment.apiUrls.getRuleHistoryById;
  }

  get refreshNeeded$(): Subject<void> {
    return this._refreshNeeded$;
  }

  getRuleHistoryById(RuleNumber: string): Observable<RuleHistory[]> {
    const url = `${this.getRuleHistorybyIdEndPoint}/${RuleNumber}`;
    return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
      map((data: any) => {
        return data;
      }),
      shareReplay(1)
    );
  }
}
