import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { HealthStatusCode } from '../../shared/models/health-status-code';
import { environment } from 'src/environments/environment';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';

@Injectable({
  providedIn: 'root'
})
export class HealthStatusCodesService {
  private readonly getAllHealthStatusCodesEndPoint: string;
  private readonly upsertHealthStatusCodeEndPoint: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getAllHealthStatusCodesEndPoint = environment.apiUrls.getAllHealthStatusCodes;
    this.upsertHealthStatusCodeEndPoint = environment.apiUrls.saveHealthStatusCode;
  }

  get refreshNeeded$(): Subject<void> {
    return this._refreshNeeded$;
  }

  getAllHealthStatusCodes(): Observable<HealthStatusCode[]> {
    return this.http
      .get<HealthStatusCode[]>(this.getAllHealthStatusCodesEndPoint, {
        headers: AzureSubscriptionHeader
      })
      .pipe(shareReplay(1));
  }

  getHealthStatusCode(id: string): Observable<HealthStatusCode> {
    const url = `${this.getAllHealthStatusCodesEndPoint}/${id}`;
    return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
      map((data: any) => {
        return data;
      }),
      shareReplay(1)
    );
  }

  upsertHealthStatusCode(healthStatusCode: HealthStatusCode): Observable<HealthStatusCode> {
    const httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };

    return this.http
      .post<HealthStatusCode>(this.upsertHealthStatusCodeEndPoint, healthStatusCode, httpOptions)
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }
}
