import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequest } from '@shared/models/filter-request';
import { QueryResponse } from '@shared/models/query-response';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AltProvResult } from '@reports/models/altprov-result';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { take } from 'rxjs/operators';
import { AltProvResultDate } from '@reports/models/altprov-result-date';

@Injectable({
  providedIn: 'root'
})
export class AltProvResultsService {
  private readonly filterAltProvResultsEndpoint: string;
  private readonly getAltProvResultDatesEndpoint: string;

  constructor(private http: HttpClient) {
    this.filterAltProvResultsEndpoint = environment.apiUrls.filterAltProvResults;
    this.getAltProvResultDatesEndpoint = environment.apiUrls.getAltProvResultDatesEndpoint;
  }

  getAllAltProvResults(
    filter: LazyLoadEvent,
    sessionId: string | null
  ): Observable<QueryResponse<AltProvResult>> {
    const request: FilterRequest = {
      querySessionId: sessionId,
      filter
    };

    return this.http
      .post<QueryResponse<AltProvResult>>(this.filterAltProvResultsEndpoint, request, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }

  getAltProvResultDates(): Observable<Array<AltProvResultDate>> {
    return this.http
      .get<Array<AltProvResultDate>>(this.getAltProvResultDatesEndpoint, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }
}
