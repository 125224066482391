<p-table
  #dt
  [value]="membersMultiAssigned"
  [lazy]="true"
  (onLazyLoad)="loadMemberMultiAssignedLazy($event)"
  [lazyLoadOnInit]="!querySessionManager.currentSession"
  [loading]="loadingTable"
  paginatorDropdownAppendTo="body"
  sortMode="single"
  sortField="id"
  dataKey="id"
  [columns]="selectedColumns"
  stateStorage="local"
  stateKey="member-multi-assigned-list-filters"
  (onStateRestore)="onStateRestore($event)"
  (onColReorder)="onColumnReorder()"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="pagingSizes"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rows]="pagingSizes[0]"
  [rowHover]="true"
  [first]="0"
  [totalRecords]="totalItems"
  [reorderableColumns]="true"
  styleClass="p-datatable-gridlines p-datatable-striped"
>
  <ng-template pTemplate="caption">
    <h2>Members Multi Assigned</h2>
    <div class="p-d-flex p-jc-between">
      <p-multiSelect
        class="selected-columns"
        [options]="allColumns"
        optionDisabled="static"
        [(ngModel)]="selectedColumns"
        optionLabel="shortName"
        selectedItemsLabel="{0} columns selected"
        placeholder="Choose Columns"
        appendTo="body"
      >
      </p-multiSelect>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('member-multi-assigned-list-filters')"
      ></button>
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="expansion">
        <!-- Expansion -->
      </th>
      <th *ngFor="let column of columns" [pSortableColumn]="column.field" pReorderableColumn>
        <span class="reorderable-table-header">
          <span [attr.title]="column.longName">{{ column.shortName }}</span>
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th>
        <!-- Expansion -->
      </th>
      <th *ngFor="let column of columns">
        <ng-container *ngIf="column.type === 'date'; else standardFilter">
          <p-columnFilter
            type="date"
            [field]="column.field"
            display="menu"
            matchMode="dateBefore"
            class="p-ml-auto"
          ></p-columnFilter>
        </ng-container>
      
        <ng-template #standardFilter>
          <app-column-filter
            [type]="column.type"
            [field]="column.field"
            [filterMatchMode]="column.filterMatchMode || 'contains'"
            [options]="column.options ? column.options : []"
          ></app-column-filter>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-memberMultiAssigned
    let-columns="columns"
    let-expanded="expanded"
    let-index="rowIndex"
  >
    <tr [pReorderableRow]="index">
      <td>
        <button
          type="button"
          pButton
          [pTooltip]="expanded ? 'Collapse Alternate Providers' : 'View Alternate Providers'"
          tooltipPosition="bottom"
          [pRowToggler]="memberMultiAssigned"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          style="height: 100%; width: 100%"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>
      <td *ngFor="let column of columns">
        <app-column-value
          [value]="memberMultiAssigned[column.field]"
          [arrayDisplayValue]="column.arrayDisplayValue"
          [routing]="column.routing ? getRoute(column.field, index - first) : []"
        ></app-column-value>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-memberMultiAssigned>
    <tr>
      <td colspan="1" class="row-expansion-offset"></td>
      <td colspan="5">
        <div>
          <p-table
            [value]="memberMultiAssigned.alternateProvider"
            datakey="id"
            styleClass="p-datatable-sm"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Type</th>
                <th>Number</th>
                <th>Alternate Provider</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-assignment>
              <tr>
                <td>
                  <span class="expansion-values">{{ assignment.Type }}</span>
                </td>
                <td>
                  <app-column-value
                    [value]="assignment.number || ''"
                    [routing]="
                      assignment.Type === 'Rule'
                        ? ['rules', 'edit', assignment.Id]
                        : assignment.Type === 'Member Exception'
                        ? ['memberExceptions', assignment.Id]
                        : assignment.Type === 'PCC Exception'
                        ? ['pccExceptions', assignment.Id]
                        : ['']
                    "
                  >
                  </app-column-value>
                </td>
                <td>
                  <span class="expansion-values">{{ assignment.alternateProviderName }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns?.length">There were no matches to the current filter(s).</td>
    </tr>
  </ng-template>
</p-table>
