import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequest } from '@shared/models/filter-request';
import { QueryResponse } from '@shared/models/query-response';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//import { AltProvResult } from '@reports/models/altprov-result';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { take } from 'rxjs/operators';
import { AltProvResultDate } from '@reports/models/altprov-result-date';
import { VarainceData } from '@reports/models/variance-report';
import { VarianceReportDetail } from '../models/variance-report-detail';

@Injectable({
  providedIn: 'root'
})
export class RosterVarianceService {
  private readonly filterVarianceReportEndpoint: string;
  private readonly filterVarianceDetailEndpoint: string;
  private readonly getAltProvResultDatesEndpoint: string;

  constructor(private http: HttpClient) {
    this.filterVarianceReportEndpoint = environment.apiUrls.filterRosterVarianceReport;
    this.filterVarianceDetailEndpoint = environment.apiUrls.filterRosterVarianceDetail;
    this.getAltProvResultDatesEndpoint = environment.apiUrls.getRosterPublishDateInfo;
  }

  getAllRosterVarianceReport(
    filter: LazyLoadEvent,
    sessionId: string | null
  ): Observable<QueryResponse<VarainceData>> {
    const request: FilterRequest = {
      querySessionId: sessionId,
      filter
    };

    return this.http
      .post<QueryResponse<VarainceData>>(this.filterVarianceReportEndpoint, request, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }

  getAllRosterVarianceDetail(
    filter: LazyLoadEvent,
    sessionId: string | null
  ): Observable<Array<VarianceReportDetail>> {
    const request: FilterRequest = {
      querySessionId: sessionId,
      filter
    };

    return this.http
      .post<Array<VarianceReportDetail>>(this.filterVarianceDetailEndpoint, request, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }

  getAltProvResultDates(): Observable<Array<AltProvResultDate>> {
    return this.http
      .get<Array<AltProvResultDate>>(this.getAltProvResultDatesEndpoint, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }
}
