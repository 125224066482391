<mat-spinner class="loading" *ngIf="loading"></mat-spinner>
<form
  [formGroup]="rulesFormGroup"
  *ngIf="!loading"
  (ngSubmit)="onSubmit()"
  class="rule-details-form"
>
  <fieldset>
    <legend>
      <h2>Define AltProv Rule</h2>
    </legend>
    <div class="rule-attributes">
      <mat-form-field *ngIf="isEditMode" appearance="outline">
        <mat-label>Rule Id</mat-label>
        <input matInput formControlName="ruleId" [readonly]="true" [value]="ruleId" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-form-field-roleName">
        <mat-label>Rule Name</mat-label>
        <input
          matInput
          type="text"
          (change)="ruleNameChanged($event)"
          maxLength="255"
          formControlName="ruleName"
          required
        />
        <mat-error *ngIf="ruleName?.errors?.required"> Rule Name cannot be blank </mat-error>
        <mat-error *ngIf="ruleName.errors?.duplicateName && (ruleName.touched || ruleName.dirty)">
          Rule Name must be unique
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Rule Priority</mat-label>
        <input
          matInput
          type="string"
          formControlName="rulePriority"
          [matAutocomplete]="priorityAuto"
          min="1"
          max="7"
          required
        />
        <mat-autocomplete autoActiveFirstOption #priorityAuto="matAutocomplete">
          <mat-option *ngFor="let priority of filteredPriorityOptions | async" [value]="priority">{{
            priority
          }}</mat-option>
        </mat-autocomplete>
        <mat-hint>1-7</mat-hint>
        <mat-error
          *ngIf="
            (rulePriority?.errors?.min || rulePriority?.errors?.max) &&
            (rulePriority?.touched || rulePriority?.dirty)
          "
        >
          Priority must fall within 1 and 7
        </mat-error>
        <mat-error *ngIf="rulePriority?.errors?.required"> Priority cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Alt Prov</mat-label>
        <input
          matInput
          [matAutocomplete]="altProvsAuto"
          formControlName="altProv"
          required
          class="alt-prov"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #altProvsAuto="matAutocomplete"
          [displayWith]="displayProperty"
        >
          <mat-option
            [matTooltip]="altProv.alternateProviderName"
            [matTooltipPosition]="'right'"
            *ngFor="let altProv of filteredAltProvOptions | async"
            [value]="altProv"
            >{{ altProv.alternateProviderName }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="altProv?.errors?.required"> Must choose an Alternate Provider </mat-error>
        <mat-error *ngIf="altProv?.errors?.altProvSelection">
          Please choose a valid Alternate Provider
        </mat-error>
      </mat-form-field>
    </div>
    <div class="rule-attributes">
      <mat-form-field appearance="outline">
        <mat-label>Start Date</mat-label>
        <input
          matInput
          type="date"
          formControlName="startDate"
          min="{{ minDate | date: 'yyyy-MM-dd' }}"
          max="{{ maxDate | date: 'yyyy-MM-dd' }}"
          required
        />
        <mat-error *ngIf="startDate?.errors?.required"> Start Date cannot be blank </mat-error>
        <mat-error *ngIf="startDate?.errors?.ruleStartNextMonth">
          Start Date cannot begin before the current month.</mat-error
        >
        <mat-error *ngIf="startDate?.errors?.ruleStartDay"
          >Start Day cannot begin before the current day.</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Start Time</mat-label>
        <input matInput type="time" formControlName="startTime" required />
        <mat-error *ngIf="startTime?.errors?.required"> Start Time cannot be blank </mat-error>
        <mat-error *ngIf="startTime?.errors?.nameStartNextTimeStamp">
          Start Time cannot before the current time
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End Date</mat-label>
        <input
          matInput
          type="date"
          formControlName="endDate"
          min="{{ minDate | date: 'yyyy-MM-dd' }}"
          max="{{ maxDate | date: 'yyyy-MM-dd' }}"
          required
        />
        <mat-error
          *ngIf="
            rulesFormGroup.errors?.notGreater && (rulesFormGroup.touched || rulesFormGroup.dirty)
          "
        >
          End Date must take place after Start Date
        </mat-error>
        <mat-error *ngIf="endDate?.errors?.required"> End Date cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End Time</mat-label>
        <input matInput type="time" formControlName="endTime" required />
        <mat-error *ngIf="endTime?.errors?.required"> End Time cannot be blank </mat-error>
      </mat-form-field>
      <div class="term-rule">
        <button
          *ngIf="isEditMode"
          mat-raised-button
          color="accent"
          type="button"
          (click)="termRule()"
        >
          Term Rule
        </button>
      </div>
    </div>

    <fieldset>
      <legend>
        <h3>Product</h3>
      </legend>
      <app-product-selection-list
        formControlName="products"
        [displayRequiredValidationError]="hasSubmitted"
        (lastProductRemoved)="onLastProductRemoved($event)"
        (connectOrConnectPlusRemoved)="onConnectOrConnectPlusRemoved($event)"
      >
      </app-product-selection-list>
    </fieldset>

    <fieldset>
      <legend>
        <h3>Rule Criteria</h3>
      </legend>
      <app-rule-criteria-selector [products]="products.value" formControlName="criteria">
      </app-rule-criteria-selector>
    </fieldset>
  </fieldset>

  <mat-form-field *ngIf="isEditMode" appearance="outline">
    <mat-label>Change Reason</mat-label>
    <mat-select
      class="change-reason"
      formControlName="changeReason"
      (selectionChange)="onChangeChangeReason($event)"
      [required]="isEditMode"
    >
      <mat-option *ngFor="let reason of changeReasons" value="{{ reason.changeReasonCode }}">
        {{ reason.changeReasonCode }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="changeReason?.errors?.required">Must choose a Change Reason</mat-error>
  </mat-form-field>

  <div>
    <mat-form-field *ngIf="isEditMode" appearance="outline" class="notes">
      <mat-label>Notes</mat-label>
      <textarea
        matInput
        type="text"
        formControlName="notes"
        [maxLength]="1000"
        [required]="changeReason.value === 'Void'"
      ></textarea>
      <mat-error *ngIf="notes?.errors?.required && changeReason.value === 'Void'"
        >Notes cannot be blank when "Void" is selected</mat-error
      >
    </mat-form-field>
  </div>

  <div class="form-action-buttons">
    <div class="primary-row">
      <button mat-raised-button type="button" [routerLink]="['/rules/list']">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="loading && !userHasEdit()"
      >
        Save
      </button>
    </div>
  </div>
</form>
