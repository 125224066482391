<form [formGroup]="clinicFormGroup" (ngSubmit)="onSubmit()" class="clinic-update-form">
  <fieldset>
    <legend>
      <h2>Clinic Closure & Merger</h2>
    </legend>
    <fieldset>
      <div class="clinic-update-container">
        <mat-form-field
          appearance="outline"
          matTooltip="Click the 'Select Source PCC' button to edit this value."
          matTooltipPosition="above"
        >
          <mat-label>PCC</mat-label>
          <input matInput type="text" formControlName="pcc1" readonly="true" />
          <mat-error *ngIf="pcc1?.errors?.required"> Source PCC cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          matTooltip="Click the 'Select Source PCC' button to edit this value."
          matTooltipPosition="above"
        >
          <mat-label>PCC Name</mat-label>
          <input matInput type="text" formControlName="pccName1" readonly="true" />
        </mat-form-field>
        <div class="pcc-button">
          <button type="button" mat-raised-button color="primary" (click)="openPCCPicker('1')">
            Select Source PCC
          </button>
        </div>
        <div class="clinic-update-container">
          <mat-form-field
            appearance="outline"
            matTooltip="Click the 'Select Target PCC' button to edit this value."
            matTooltipPosition="above"
          >
            <mat-label>PCC</mat-label>
            <input matInput type="text" formControlName="pcc2" readonly="true" />
            <mat-error *ngIf="pcc2?.errors?.required"> Target PCC cannot be blank </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            matTooltip="Click the 'Select Target PCC' button to edit this value."
            matTooltipPosition="above"
          >
            <mat-label>PCC Name</mat-label>
            <input matInput type="text" formControlName="pccName2" readonly="true" />
          </mat-form-field>
          <div class="pcc-button">
            <button type="button" mat-raised-button color="primary" (click)="openPCCPicker('2')">
              Select Target PCC
            </button>
          </div>
        </div>
      </div>
      <div class="form-action-buttons">
        <div class="primary-row">
          <button mat-raised-button color="primary" type="submit" (click)="mergeClinic()">
            Merge Clinic
          </button>
          <button mat-raised-button color="primary" type="submit" (click)="closeClinic()">
            Close Clinic
          </button>
          <button mat-raised-button type="button" (click)="resetForm()">Clear</button>
        </div>
      </div>
    </fieldset>
  </fieldset>
</form>
