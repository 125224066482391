<div class="header">
  <h2>Publish All Rosters</h2>
  <button
    class="publish-button"
    type="button"
    mat-raised-button
    color="primary"
    (click)="publishRoster()"
    [disabled]="!userHasEdit()"
  >
    Publish All Rosters
  </button>

  <h2>View Roster Data</h2>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Product</mat-label>
      <mat-select [(value)]="this.selectedProduct" required>
        <mat-option
          id="productOption"
          *ngFor="let opt of productOptions"
          [value]="opt"
          (onSelectionChange)="onAltProvOrProductChange($event)"
        >
          {{ opt }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="altprov-selection">
      <mat-label>Alternate Provider</mat-label>
      <mat-select [(value)]="this.selectedAltProv" required>
        <mat-option
          id="altProvOption"
          *ngFor="let opt of altProvOptions"
          [value]="opt"
          (onSelectionChange)="onAltProvOrProductChange($event)"
        >
          {{ opt }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<p-table
  #dt
  [value]="roster"
  *ngIf="this.selectedProduct && this.selectedAltProv"
  [lazy]="true"
  (onLazyLoad)="loadRosterLazy($event)"
  [lazyLoadOnInit]="false"
  [loading]="loadingTable"
  paginatorDropdownAppendTo="body"
  sortMode="single"
  sortField="id"
  dataKey="id"
  [columns]="selectedColumns"
  stateStorage="local"
  stateKey="roster-list-filters"
  (onStateRestore)="onStateRestore($event)"
  (onColReorder)="onColumnReorder()"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="false"
  [rowsPerPageOptions]="pagingSizes"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rows]="pagingSizes[0]"
  [rowHover]="true"
  [first]="0"
  [totalRecords]="totalItems"
  [reorderableColumns]="true"
  [autoLayout]="true"
  styleClass="p-datatable-gridlines p-datatable-striped"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-between">
      <p-multiSelect
        class="selected-columns"
        [options]="allColumns"
        optionDisabled="static"
        [(ngModel)]="selectedColumns"
        optionLabel="shortName"
        selectedItemsLabel="{0} columns selected"
        placeholder="Choose Columns"
        appendTo="body"
      >
      </p-multiSelect>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let column of columns" [pSortableColumn]="column.field" pReorderableColumn>
        <span class="reorderable-table-header">
          <span [attr.title]="column.longName">{{ column.shortName }}</span>
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th *ngFor="let column of columns">
        <app-column-filter
          [type]="column.type"
          [field]="column.field"
          [filterMatchMode]="column.filterMatchMode || 'contains'"
          [options]="column.options ? column.options : []"
        ></app-column-filter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rosterResult let-columns="columns" let-index="rowIndex">
    <tr [pReorderableRow]="index">
      <td *ngFor="let column of columns">
        <app-column-value
          [value]="rosterResult[column.field]"
          [arrayDisplayValue]="column.arrayDisplayValue"
        ></app-column-value>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns?.length">There were no matches to the current filter(s).</td>
    </tr>
  </ng-template>
</p-table>
