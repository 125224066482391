import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PccExceptionHistory } from '@pccExceptions/models/pcc-exception-history';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PccExceptionHistoryService {
  private readonly getPccExceptionHistoryByNumberEndPoint: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getPccExceptionHistoryByNumberEndPoint =
      environment.apiUrls.getPccExceptionHistoryByNumber;
  }

  get refreshNeeded$(): Subject<void> {
    return this._refreshNeeded$;
  }

  getPccExceptionHistoryByNumber(
    pccExceptionHistory: PccExceptionHistory
  ): Observable<PccExceptionHistory[]> {
    let httpOptions = {
      headers: AzureSubscriptionHeader
    };
    return this.http
      .post<PccExceptionHistory>(
        this.getPccExceptionHistoryByNumberEndPoint!,
        pccExceptionHistory,
        httpOptions
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        shareReplay(1)
      );
  }
}
