import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

import { County } from '../models/county';
import { environment } from '../../../environments/environment';
import { AzureSubscriptionHeader } from '../../shared/models/CosmosDB/azure-subscription-header';

@Injectable({
  providedIn: 'root'
})
export class CountiesService {
  private readonly getAllCountiesEndPoint?: string;
  private readonly upsertCountyEndPoint?: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getAllCountiesEndPoint = environment.apiUrls.getAllCounties;
    this.upsertCountyEndPoint = environment.apiUrls.saveCounty;
  }

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  getAllCounties(): Observable<County[]> {
    return this.http
      .get<County[]>(this.getAllCountiesEndPoint!, {
        headers: AzureSubscriptionHeader
      })
      .pipe(shareReplay(1));
  }

  getCounty(id: string): Observable<County> {
    if (id === '0') {
      return of(this.initializeCounty());
    } else {
      const url = `${this.getAllCountiesEndPoint!}/${id}`;
      return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
        map((data: any) => {
          return data;
        }),
        shareReplay(1)
      );
    }
  }

  upsertCounty(county: County): Observable<County> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };

    return this.http.post<County>(this.upsertCountyEndPoint!, county, httpOptions).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }

  initializeCounty(): County {
    return {
      id: '0',
      countyFIPSCode: null,
      countyCode: null,
      countyName: null,
      state: null,
      riskPop: null,
      forPCC: true,
      forMember: true,
      active: true,
      createdDate: null,
      createdUser: null,
      modifiedDate: null,
      modifiedUser: null
    };
  }
}
