<p-dialog
  appendTo="body"
  [modal]="true"
  [(visible)]="visible"
  [closable]="false"
  [style]="{ width: '800px' }"
>
  <p-header> Add/Edit Product </p-header>
  <form [formGroup]="productFormGroup" (ngSubmit)="onSubmit()" class="product-add-form">
    <legend>Product</legend>
    <div class="product-attributes">
      <mat-form-field [style.width.px]="350" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Product Name</mat-label>
        <input matInput type="text" formControlName="productName" />
        <mat-error *ngIf="productName?.errors?.required"> Product Name cannot be blank </mat-error>
      </mat-form-field>
      <mat-checkbox
        class="p-mr-3 p-flew-row"
        color="primary"
        [checked]="isActive"
        (change)="toggleActiveCheckbox($event)"
        >Active
      </mat-checkbox>
      <mat-form-field [style.width.px]="260" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Mutual Exclusions</mat-label>
        <mat-select [formControl]="mutualExclusives" multiple>
          <mat-option
            class="p-mr-3 p-flew-row"
            *ngFor="let product of matControlProducts"
            [value]="product.label"
            [attr.selected]="product.selected"
            >{{ product.value }}</mat-option
          >
        </mat-select></mat-form-field
      >
    </div>
    <input type="hidden" formControlName="id" />
    <input type="hidden" formControlName="createdDate" />
    <input type="hidden" formControlName="createdUser" />
    <input type="hidden" formControlName="modifiedDate" />
    <input type="hidden" formControlName="modifiedUser" />
    <input type="hidden" formControlName="mutualExclusives" />
    <div class="form-action-buttons p-m-lg-6">
      <button mat-raised-button type="button" (click)="cancelModal()" class="p-mr-2">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="loading">Save</button>
    </div>
  </form>
</p-dialog>
