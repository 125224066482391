<p-table
  #dt
  [value]="pccExceptionHistory"
  dataKey="id"
  stateStorage="local"
  stateKey="pcc-exceptions-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  paginatorDropdownAppendTo="body"
  responsiveLayout="scroll"
  [resizableColumns]="true"
>
  <ng-template pTemplate="caption">
    <h2>PCC Exception History</h2>
    <div class="p-d-flex p-jc-between">
      <button type="button" hidden></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('pcc-exceptions-filters')"
      ></button>
      <button type="button" hidden></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="pccExceptionNumber" [ngStyle]="{ width: '150px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Exception<br />ID
          <p-sortIcon field="pccExceptionNumber"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="altProv.alternateProviderName" [ngStyle]="{ width: '300px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Alt<br />Provider
          <p-sortIcon field="altProv.alternateProviderName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="pccId" [ngStyle]="{ width: '190px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          PCC ID
          <p-sortIcon field="pccId"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="pccName" [ngStyle]="{ width: '300px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          PCC<br />Name
          <p-sortIcon field="pccName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="product" [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Product
          <p-sortIcon field="product"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="effectiveStartDate" [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Effective<br />Start<br />Date
          <p-sortIcon field="effectiveStartDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="effectiveEndDate" [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Effective<br />End<br />Date
          <p-sortIcon field="effectiveEndDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="product" [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created User
          <p-sortIcon field="product"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="effectiveEndDate" [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created Date
          <p-sortIcon field="effectiveEndDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="product" [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Last Modified User
          <p-sortIcon field="product"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="effectiveEndDate" [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Last Modified Date
          <p-sortIcon field="effectiveEndDate"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr>
      <th [ngStyle]="{ width: '150px' }">
        <p-columnFilter
          type="text"
          field="pccExceptionNumber"
          [showOperator]="false"
          matchMode="contains"
          [showAddButton]="false"
        >
        </p-columnFilter>
      </th>
      <th [ngStyle]="{ width: '300px' }">
        <p-columnFilter field="altProv.alternateProviderName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="altProvs"
              (onChange)="filter($event.value, 'altProv', 'in')"
              placeholder="Any"
              appendTo="body"
            >
              <ng-template let-option pTemplate="item"
                ><span class="p-m1-1">{{ option }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="pccId"
          [showMatchModes]="false"
          [showOperator]="false"
          matchMode="contains"
          [showAddButton]="false"
        ></p-columnFilter>
      </th>
      <th [ngStyle]="{ width: '300px' }">
        <p-columnFilter
          type="text"
          field="pccName"
          [showMatchModes]="false"
          [showOperator]="false"
          matchMode="contains"
          [showAddButton]="false"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="product" matchMode="contains" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="allProducts"
              (onChange)="
                filter(
                  $event?.value ? $event?.value : '',
                  'getFormattedProducts(products)',
                  'contains'
                )
              "
              placeholder="Any"
              appendTo="body"
            >
              <ng-template let-option pTemplate="item"
                ><span class="p-m1-1">{{ option }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="effectiveStartDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="effectiveEndDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="createdUser"
          [showMatchModes]="false"
          [showOperator]="false"
          matchMode="contains"
          [showAddButton]="false"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="createdDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="lastModifiedUser"
          [showMatchModes]="false"
          [showOperator]="false"
          matchMode="contains"
          [showAddButton]="false"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="lastModifiedDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-pccException let-expanded="expanded">
    <tr>
      <td>
        <div>{{ pccException.pccExceptionNumber }}</div>
      </td>
      <td
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px"
        pTooltip="{{ pccException.altProv.alternateProviderName }}"
      >
        {{ pccException.altProv.alternateProviderName }}
      </td>
      <td>{{ pccException.pccId }}</td>
      <td
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px"
        pTooltip="{{ pccException.pccName }}"
      >
        {{ pccException.pccName }}
      </td>
      <td>{{ pccException.product }}</td>
      <td>
        <time [attr.datetime]="pccException.effectiveStartDate">{{
          pccException.effectiveStartDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        <time [attr.datetime]="pccException.effectiveEndDate">{{
          pccException.effectiveEndDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ pccException.createdUser }}</td>
      <td>
        <time [attr.datetime]="pccException.effectiveStartDate">{{
          pccException.createdDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ pccException.lastModifiedUser }}</td>
      <td>
        <time [attr.datetime]="pccException.effectiveStartDate">{{
          pccException.lastModifiedDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
    </tr>
  </ng-template>
</p-table>
