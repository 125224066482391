import { Inject, Injectable, InjectionToken, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage
});

@Injectable({
  providedIn: 'root'
})
export class ClearFilterService {
  constructor(@Inject(BROWSER_STORAGE) public storage: Storage) {}

  public removeStorageFilterByKey(storageKey: string, table: Table) {
    this.storage.removeItem(storageKey);
    table.reset();
  }

  public set(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  public clear() {
    this.storage.clear();
  }
}
