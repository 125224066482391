import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

import { Product } from '../models/product';
import { environment } from '../../../environments/environment';
import { AzureSubscriptionHeader } from '../../shared/models/CosmosDB/azure-subscription-header';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private readonly getAllProductsEndPoint?: string;
  private readonly insertProductEndPoint?: string;
  private readonly upsertProductEndPoint?: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getAllProductsEndPoint = environment.apiUrls.getAllProducts;
    this.insertProductEndPoint = environment.apiUrls.saveProducts;
    this.upsertProductEndPoint = environment.apiUrls.saveProducts;
  }

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  getAllProducts(): Observable<Product[]> {
    return this.http
      .get<Product[]>(this.getAllProductsEndPoint!, {
        headers: AzureSubscriptionHeader
      })
      .pipe(shareReplay(1));
  }

  getProduct(id: string): Observable<Product> {
    if (id === '0') {
      return of(this.initializeProduct());
    } else {
      const url = `${this.getAllProductsEndPoint!}/${id}`;
      return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
        map((data: any) => {
          return data;
        }),
        shareReplay(1)
      );
    }
  }

  createProduct(product: Product): Observable<Product> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };

    return this.http.post<Product>(this.insertProductEndPoint!, product, httpOptions).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }

  upsertProduct(product: Product): Observable<Product> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };

    return this.http.post<Product>(this.upsertProductEndPoint!, product, httpOptions).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }

  deleteProduct(id: string): Observable<{}> {
    const headers = AzureSubscriptionHeader;
    const url = `${this.getAllProductsEndPoint!}/${id}`;
    return this.http.delete<Product>(url, { headers });
  }

  initializeProduct(): Product {
    return {
      id: '0',
      productName: null,
      active: true,
      createdDate: null,
      createdUser: null,
      modifiedDate: null,
      modifiedUser: null
    };
  }
}
