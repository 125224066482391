<p-table
  #dt
  [value]="pccExceptions"
  dataKey="id"
  rowExpandMode="single"
  stateStorage="local"
  stateKey="pcc-exceptions-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  paginatorDropdownAppendTo="body"
  [autoLayout]="true"
>
  <ng-template pTemplate="caption">
    <h2>PCC Exception</h2>
    <div class="p-d-flex p-jc-between">
      <button
        type="button"
        (click)="addPccException()"
        pButton
        icon="pi pi-plus"
        label="Add an Exception"
        [disabled]="!userHasEdit()"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('pcc-exceptions-filters')"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-excel"
        label="Export"
        (click)="exportExcel()"
        class="p-button-success p-mr-2"
        pTooltip="XLS"
        tooltipPosition="bottom"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="table-header">
    <tr>
      <!-- Expansion Chevron-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Edit-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Delete-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Redirect to History Page-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>    
      <th pSortableColumn="pccExceptionNumber">
        <div class="p-d-flex p-jc-between p-ai-center">
          Exception<br />ID
          <p-sortIcon field="pccExceptionNumber"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="altProv.alternateProviderName" class="medium-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Alt<br />Provider
          <p-sortIcon field="altProv.alternateProviderName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="pccId" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          PCC ID
          <p-sortIcon field="pccId"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="pccName" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          PCC<br />Name
          <p-sortIcon field="pccName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="product" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Product
          <p-sortIcon field="product"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="effectiveStartDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Effective<br />Start<br />Date
          <p-sortIcon field="effectiveStartDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="effectiveEndDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Effective<br />End<br />Date
          <p-sortIcon field="effectiveEndDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="active">
        <span class="table-header">
          <span title="Active">Active</span>
          <p-sortIcon field="active"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <!-- Expansion Chevron -->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Edit -->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Delete -->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Redirect to History Page-->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="pccExceptionNumber"
          [showOperator]="false"
          matchMode="contains"
          [showAddButton]="false"
        >
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="altProv.alternateProviderName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="altProvs"
              (onChange)="filter($event.value, 'altProv', 'in')"
              placeholder="Any"
              appendTo="body"
            >
              <ng-template let-option pTemplate="item"
                ><span class="p-m1-1">{{ option }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="pccId"
          [showMatchModes]="false"
          [showOperator]="false"
          matchMode="contains"
          [showAddButton]="false"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="pccName"
          [showMatchModes]="false"
          [showOperator]="false"
          matchMode="contains"
          [showAddButton]="false"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="product" matchMode="contains" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="allProducts"
              (onChange)="
                filter(
                  $event?.value ? $event?.value : '',
                  'getFormattedProducts(products)',
                  'contains'
                )
              "
              placeholder="Any"
              appendTo="body"
            >
              <ng-template let-option pTemplate="item"
                ><span class="p-m1-1">{{ option }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="effectiveStartDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="effectiveEndDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="active" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="[
                { label: 'Active', value: true },
                { label: 'Inactive', value: false }
              ]"
              (onChange)="filter($event?.value, 'active', 'in')"
              placeholder="Any"
              appendTo="body"
            >
              <ng-template let-option pTemplate="item"
                ><span class="p-m1-1">{{ option.label }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-pccException let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          [pTooltip]="expanded ? 'Collapse Summary' : 'View Other Fields'"
          tooltipPosition="bottom"
          [pRowToggler]="pccException"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          style="height: 100%; width: 100%"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>
      <td>
        <button
          type="button"
          (click)="openPccExceptionDetail(pccException.id)"
          pButton
          pTooltip="Edit PccException"
          tooltipPosition="bottom"
          icon="pi pi-pencil"
          style="height: 100%; width: 100%"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
          [disabled]="!userHasEdit()"
        ></button>
      </td>
      <td>
        <button
          type="button"
          (click)="deletePccException(pccException)"
          pButton
          pTooltip="Term PccException"
          tooltipPosition="bottom"
          icon="pi pi-times"
          style="height: 100%; width: 100%"
          class="p-button-rounded p-button-text p-button-plain"
          [disabled]="!userHasEdit()"
        ></button>
      </td>
      <td>
        <button
          type="button"
          pButton
          pRipple
          title="Redirect to History"
          icon="pi pi-book"
          style="height: 100%; width: 100%"
          routerLink="/pccexceptionhistory/{{ pccException.pccExceptionNumber }}"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>
      <td>
        <div>{{ pccException.pccExceptionNumber }}</div>
      </td>
      <td>{{ pccException.altProv.alternateProviderName }}</td>
      <td>{{ pccException.pccId }}</td>
      <td>{{ pccException.pccName }}</td>
      <td>{{ pccException.product }}</td>
      <td>
        <time [attr.datetime]="pccException.effectiveStartDate">{{
          pccException.effectiveStartDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        <time [attr.datetime]="pccException.effectiveEndDate">{{
          pccException.effectiveEndDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        {{ pccException.active ? 'Active' : 'Inactive' }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-pccException>
    <tr style="background: ghostwhite">
      <td><span class="p-float-label" style="margin-top: 50px"></span></td>
      <td colspan="8">
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex"><b>Created User: </b> {{ pccException.createdUser }}</div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Created Date: </b> {{ pccException.createdDate | date: 'MM/dd/yyyy' }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Last Modified User: </b> {{ pccException.lastModifiedUser }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Last Modified Date: </b> {{ pccException.lastModifiedDate | date: 'MM/dd/yyyy' }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <div class="p-d-flex"><b>NOTE: </b> {{ pccException.note }}</div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
