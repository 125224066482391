import { HttpErrorResponse } from '@angular/common/http';
import { Component, ChangeDetectorRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ColumnDefinition } from '@shared/models/column-definition';
import { FilterService } from '@shared/services/filter-service/filter.service';
import { QuerySessionManagerService } from '@shared/services/query-session-manager-service/query-session-manager.service';
import { FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { BaseView } from '@shared/components/Base/base-view';
import { ProductsService } from 'src/app/products/services/products.service';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { RosterService } from '@reports/services/roster.service';
import { Roster } from '@reports/models/roster';
import { RoleCheck } from '@shared/guards/roleCheck';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { GenericDialogLaunchOptions } from '@shared/components/generic-dialog/generic-dialog-launch-options';

@Component({
  selector: 'app-roster-list',
  templateUrl: './roster-list.component.html',
  styleUrls: ['./roster-list.component.scss']
})
export class RosterListComponent extends BaseView implements OnInit, AfterViewInit {
  public readonly querySessionManager: QuerySessionManagerService;

  @ViewChild(Table, { static: false }) table!: Table;

  selectedProduct = '';
  selectedAltProv = '';
  productOptions: Array<string> = [];
  altProvOptions: Array<string> = [];

  selectedColumns: ColumnDefinition[] = [];
  allColumns: ColumnDefinition[] = [];
  first = 0;
  pagingSizes = [20, 40, 60, 80, 100];

  loadingTable = true;
  roster: Roster[] = [];
  totalItems = 0;

  private previousLoadEvent: LazyLoadEvent | undefined = undefined;

  constructor(
    public dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
    private readonly filterService: FilterService,
    private readonly productsService: ProductsService,
    private readonly rosterService: RosterService,
    private readonly alternateProvidersService: AltProviderService,
    private roleCheck: RoleCheck
  ) {
    super();

    this.initColumnDefinitions();

    this.querySessionManager = new QuerySessionManagerService('roster-list');

    // Clear any cached sessions on page load
    this.querySessionManager.resetSession();
  }

  userHasEdit(){
    return this.roleCheck.userHasRole(['AltProvAdmin','AltProvPublishRosterCLS']);
  }

  ngOnInit(): void {
    forkJoin([
      this.alternateProvidersService.getAlternateProviders(),
      this.productsService.getAllProducts()
    ])
      .pipe(
        tap(([altProvs, products]) => {
          this.altProvOptions = altProvs.filter(x => x.active).map(y => y.alternateProviderName);
          this.productOptions = products.filter(x => x.active).map(y => y.productName ?? '');

          this.setColumnOptions('altProv', this.altProvOptions);
          this.setColumnOptions('product', this.productOptions);
        }),
        take(1)
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    const columnChangeSub = this.table.tableService.columnsSource$.subscribe(() => {
      this.table.saveState();
    });

    this.subs.add(columnChangeSub);
  }

  async loadRosterLazy(event: LazyLoadEvent): Promise<void> {
    if (!this.selectedAltProv || !this.selectedProduct) {
      this.loadingTable = false;
      return;
    }

    this.loadingTable = true;
    this.first = event?.first ?? 0;

    // Blow away the query session every time unless we are paging forward/backward
    if (this.filterService.didChangePage(this.previousLoadEvent, event) === false) {
      this.querySessionManager.resetSession();
    }

    const clonedEvent = Object.assign({}, event);

    // tslint:disable-next-line: forin
    for (const filterKey in clonedEvent.filters) {
      const selectedColumn = this.selectedColumns.find(x => x.field === filterKey);

      if (!selectedColumn) {
        delete clonedEvent.filters[filterKey];
      } else {
        if (filterKey === 'product') {
          clonedEvent.filters[filterKey].value = this.selectedProduct;
        }
        if (filterKey === 'altProvName') {
          clonedEvent.filters[filterKey].value = this.selectedAltProv;
        }
      }
    }

    // tslint:disable-next-line: no-non-null-assertion
    clonedEvent.filters!.versionEnd = {
      matchMode: 'dateIs',
      value: '9999-12-31T00:00:00'
    } as FilterMetadata;

    const fetchSub = this.rosterService
      .getRoster(clonedEvent, this.querySessionManager.currentSession)
      .subscribe(
        response => {
          this.roster = response.results;
          this.totalItems = response.totalResults;

          if (response.querySessionId) {
            this.querySessionManager.currentSession = response.querySessionId;
          }
        },
        (error: HttpErrorResponse) => {
          // If our query session ID was deleted,
          // clear it out and try again
          if (error.status === 404) {
            this.querySessionManager.resetSession();
            this.table._filter();
          }
        },
        () => {
          this.loadingTable = false;

          fetchSub.unsubscribe();
        }
      );

    this.previousLoadEvent = event;
  }

  onAltProvOrProductChange(e: MatOptionSelectionChange): void {
    e.source.id === 'productOption'
      ? (this.selectedProduct = e.source.value)
      : (this.selectedAltProv = e.source.value);

    if (this.selectedProduct && this.selectedAltProv) {
      const newEvent: LazyLoadEvent = {
        first: 0,
        rows: 20,
        filters: {
          ['product']: { matchMode: 'equals', value: this.selectedProduct },
          ['altProvName']: { matchMode: 'equals', value: this.selectedAltProv }
        }
      };

      this.loadRosterLazy(newEvent);
    }
  }

  publishRoster(): void {
    const rosterPublishSub = this.rosterService
      .getRosterPublishDisallowed()
      .pipe(switchMap(x => (!x.value[0] ? of(this.publish()) : of(this.openErrorDialog()))))
      .subscribe();

    this.subs.add(rosterPublishSub);

    // TODO: show the status/results of publish?
  }

  openErrorDialog(): void {
    this.dialog.open(GenericDialogComponent, {
      width: '500px',
      data: {
        header: 'Error',
        body: 'Unable to publish rosters due to unassigned or multi-assigned members.'
      } as GenericDialogLaunchOptions
    });
  }

  publish(): void {
    const dialogReference = this.dialog.open(GenericDialogComponent, {
      width: '500px',
      data: {
        header: 'Confirmation',
        body: 'Are you sure you want to publish rosters?',
        confirmText: 'Confirm'
      } as GenericDialogLaunchOptions
    });

    dialogReference.afterClosed().subscribe(result => {
      if (result) {
        this.subs.add(this.rosterService.publishRoster().subscribe());
      }
    });
  }

  onStateRestore(event: any): void {
    const columnKeys = event.columnOrder;
    const columns = this.getFilterByKeyOrdered(columnKeys);

    this.selectedColumns = columns;
    this.cd.detectChanges();
  }

  onColumnReorder(): void {
    this.table.saveState();
  }

  private getFilterByKeyOrdered(keys: string[]): Array<ColumnDefinition> {
    const result: any[] = [];

    for (const key of keys) {
      const column = this.allColumns.find(f => f.field === key);
      result.push(column);
    }

    return result;
  }

  private initColumnDefinitions(): void {
    const defaultColumns: ColumnDefinition[] = [
      {
        field: 'product',
        shortName: 'Product',
        longName: 'Product',
        type: 'text',
        filterMatchMode: 'in',
        options: []
      },
      {
        field: 'altProvAlias',
        shortName: 'Alternate Provider Alias',
        longName: 'Alternate Provider Alias',
        type: 'text',
        static: true
      },
      {
        field: 'altProvName',
        shortName: 'Alternate Provider Name',
        longName: 'Alternate Provider Name',
        type: 'text',
        static: true
      },
      {
        field: 'altProvLastMonth',
        shortName: 'Alternate Provider Last Month',
        longName: 'Alternate Provider Last Month',
        type: 'text'
      },
      {
        field: 'changeStatuses',
        shortName: 'Change Statuses',
        longName: 'Change Statuses',
        type: 'text'
      },
      { field: 'pccId', shortName: 'PCC Id', longName: 'PCC Id', type: 'text' },
      {
        field: 'memberId',
        shortName: 'PMI',
        longName: 'PMI',
        type: 'text',
        routing: true,
        static: true
      }
    ];

    const additionalColumns: ColumnDefinition[] = [
      { field: 'pccName', shortName: 'PCC Name', longName: 'PCC Name', type: 'text' },
      {
        field: 'memberNumber',
        shortName: 'Member Number',
        longName: 'Member Number',
        type: 'text'
      },
      {
        field: 'amisysId',
        shortName: 'Amisys Id',
        longName: 'Amisys Id',
        type: 'text'
      },
      { field: 'lastName', shortName: 'Last Name', longName: 'Last Name', type: 'text' },
      { field: 'firstName', shortName: 'First Name', longName: 'First Name', type: 'text' },
      { field: 'gender', shortName: 'Gender', longName: 'Gender', type: 'text' },
      { field: 'birthDate', shortName: 'Birth Date', longName: 'Birth Date', type: 'text' },
      { field: 'covgEnd', shortName: 'End Date', longName: 'End Date', type: 'text' },
      {
        field: 'livingStatus',
        shortName: 'Living Status',
        longName: 'Living Status',
        type: 'text'
      },
      { field: 'healthstat', shortName: 'Health Status', longName: 'Health Status', type: 'text' },
      {
        field: 'addressLine1',
        shortName: 'Address Line 1',
        longName: 'Address Line 1',
        type: 'text'
      },
      {
        field: 'addressLine2',
        shortName: 'Address Line 2',
        longName: 'Address Line 2',
        type: 'text'
      },
      { field: 'addressCity', shortName: 'City', longName: 'City', type: 'text' },
      { field: 'addressState', shortName: 'State', longName: 'State', type: 'text' },
      { field: 'addressZip', shortName: 'ZIP Code', longName: 'ZIP Code', type: 'text' },
      { field: 'addressCounty', shortName: 'County Name', longName: 'County Name', type: 'text' },
      { field: 'phone', shortName: 'Phone Number', longName: 'Phone Number', type: 'text' },
      { field: 'waiverStatus', shortName: 'Waiver Status', longName: 'Waiver Status', type: 'text' }
    ];

    this.selectedColumns = defaultColumns;
    this.allColumns = defaultColumns.concat(additionalColumns);
  }

  private setColumnOptions(field: string, options: Array<any>): void {
    const selectedColumnsIndex = this.selectedColumns.findIndex(x => x.field === field);
    const allColumnsIndex = this.allColumns.findIndex(x => x.field === field);

    if (selectedColumnsIndex > 0) {
      this.selectedColumns[selectedColumnsIndex].options = options;
    }

    if (allColumnsIndex > 0) {
      this.allColumns[allColumnsIndex].options = options;
    }
  }
}
