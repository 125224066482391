import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Product } from '@shared/models/product';
import { ProductService } from '@shared/services/product-service/product.service';
import { tap } from 'rxjs/operators';
import { ProductCheckboxComponent } from '../product/product-checkbox.component';
import * as productData from '../../../../../data/products.json';

@Component({
  selector: 'app-product-selection-list',
  templateUrl: './product-selection-list.component.html',
  styleUrls: ['./product-selection-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductSelectionListComponent),
      multi: true
    }
  ]
})
export class ProductSelectionListComponent implements ControlValueAccessor, OnInit {
  @Input() displayRequiredValidationError = false;
  @Output() lastProductRemoved = new EventEmitter<string>();
  @Output() connectOrConnectPlusRemoved = new EventEmitter<string>();

  altProvProducts = JSON.parse(JSON.stringify(productData));

  @ViewChild('productContainer', { read: ViewContainerRef })
  productContainer!: ViewContainerRef;

  private _selectedProducts = new Set<string>();

  products: Array<Product> = [];
  productComponents: Array<ProductCheckboxComponent> = [];

  private onChangeFunction = (_: Set<string>) => {};
  private onTouchedFunction = () => {};

  get selectedProducts(): Set<string> {
    return this._selectedProducts;
  }

  set selectedProducts(products: Set<string>) {
    this._selectedProducts = products;
    this.onChange(products);
  }

  get noSelectionMade(): boolean {
    return this.selectedProducts?.size < 1;
  }

  constructor(
    private readonly productService: ProductService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.productService
      .getAllProducts()
      .pipe(
        tap(x => {
          this.products = x.filter(
            y =>
              y.active === true &&
              this.altProvProducts.default.some(
                (z: any) => z.productName === y.productName && z.isAltProvProduct
              )
          );

          this.createProductComponents();
        })
      )
      .subscribe();
  }

  createProductComponents(): void {
    if (!this.products) {
      return;
    }

    this.products.forEach((product, index) => {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(ProductCheckboxComponent);

      const componentRef = this.productContainer?.createComponent(componentFactory);

      if (componentRef) {
        componentRef.instance.product = product;
        componentRef.instance.selectionChange.subscribe((x: boolean) => {
          this.onProductCheckboxChange(x, product.productName);
        });
      }

      this.productComponents[index] = componentRef?.instance;
    });

    this.selectedProducts.forEach(productName => {
      const selectedComponent = this.productComponents.find(
        x => x.product.productName === productName
      );

      if (selectedComponent) {
        selectedComponent.selected = true;
        selectedComponent.selectionChange.next(true);
      }
    });
  }

  writeValue(obj: any): void {
    const val = obj as Set<string>;

    if (val) {
      this._selectedProducts = new Set(val);
    }
  }

  registerOnChange(fn: (productNames: Set<string>) => void): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFunction = fn;
  }

  onProductCheckboxChange(selected: boolean, productName: string): void {
    if (selected) {
      this.selectedProducts.add(productName);
    } else {
      this.selectedProducts.delete(productName);
    }

    if (this.selectedProducts.size === 0) {
      this.lastProductRemoved.emit(productName);
    } else if (!selected && (productName === 'CONNECT' || productName === 'CONNECT+MEDICARE')) {
      this.connectOrConnectPlusRemoved.emit(productName);
    }

    let exclusives = this.products.find(x => x.productName === productName)
      ?.mutualExclusives as string[];

    if (exclusives) {
      exclusives = exclusives.map(x => x.toLowerCase());
      const excludedComponents = this.productComponents.filter(x =>
        exclusives.includes(x.product.productName.toLowerCase())
      );

      if (selected) {
        excludedComponents.forEach(xcomponent => {
          xcomponent.disabled = true;
        });
      } else {
        // This ensures we don't disable products that are excluded by a different selection
        excludedComponents.forEach(xcomponent => {
          if (
            !this.products
              .filter(x => this.selectedProducts.has(x.productName))
              .some(y => y.mutualExclusives.some(z => z === xcomponent.product.productName))
          ) {
            xcomponent.disabled = false;
          }
        });
      }
    }

    this.onChange(this.selectedProducts);
  }

  shouldEnable(product: Product): boolean {
    for (const excluded of product.mutualExclusives) {
      if (this.selectedProducts.has(excluded)) {
        return false;
      }
    }

    return true;
  }

  shouldCheck(productName: string): boolean {
    return this.selectedProducts.has(productName);
  }

  private onChange(productNames: Set<string>): void {
    let flattened = '';
    let cnt = 0;

    for (const product of productNames) {
      ++cnt;
      flattened += product.toString();
      if (cnt < productNames.size) {
        flattened += ', ';
      }
    }

    this.onChangeFunction(new Set(productNames));
  }

  onTouched(): void {
    this.onTouchedFunction();
  }
}
