import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { AltProvResultDate } from '@reports/models/altprov-result-date';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/products/services/products.service';
import { ColumnDefinition } from '@shared/models/column-definition';
import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { QuerySessionManagerService } from '@shared/services/query-session-manager-service/query-session-manager.service';
import { RosterVarianceService } from '@reports/services/roster-variance.service';
import { VarainceData } from '@reports/models/variance-report';

@Component({
  selector: 'app-roster-variance-list',
  templateUrl: './roster-variance-list.component.html',
  styleUrls: ['./roster-variance-list.component.scss']
})
export class RosterVarianceListComponent implements OnInit {
  public readonly querySessionManager: QuerySessionManagerService;

  selectedDateFrom = '';
  selectedDateTo = '';
  fromID: string | undefined = '';
  toID: string | undefined = '';
  selectedAltProv = '';
  selectedProduct = '';
  selectedColumns: ColumnDefinition[] = [];
  allColumns: ColumnDefinition[] = [];
  ruleNumberFromRoute!: any;
  rosterSelections: Array<AltProvResultDate> = [];
  rosterSelections2: Array<AltProvResultDate> = [];
  rosterSelectionsTrim: Array<AltProvResultDate> = [];

  showTable = false;
  loadingTable = false;
  rosterData: Array<VarainceData> = [];
  columns: any[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly productsService: ProductsService,
    private readonly rosterVarianceService: RosterVarianceService
  ) {
    this.columns = [
      {
        propertyName: 'careCoordinator',
        shortName: 'careCoordinator',
        longName: 'Care Coordinator',
        type: 'text'
      },
      { propertyName: 'program', shortName: 'program', longName: 'Product', type: 'text' },
      {
        propertyName: 'fromDateCount',
        shortName: 'lastMonthMembers',
        longName: 'Date From: Members',
        type: 'noshow'
      },
      {
        propertyName: 'toDateCount',
        shortName: 'thisMonthMembers',
        longName: 'Date To: Members',
        type: 'noshow'
      },
      {
        propertyName: 'difference',
        shortName: 'difference',
        longName: 'Difference',
        type: 'noshow'
      }
    ];

    this.querySessionManager = new QuerySessionManagerService('altprov-variance-list');

    //Clear any cached sessions on page load
    this.querySessionManager.resetSession();
  }

  ngOnInit(): void {
    this.ruleNumberFromRoute = this.activatedRoute.snapshot.paramMap.get('ruleNumber');

    forkJoin([
      this.productsService.getAllProducts(),
      this.rosterVarianceService.getAltProvResultDates()
    ])
      .pipe(
        tap(([products, dates]) => {
          const productOptions = products.filter(x => x.active).map(y => y.productName ?? '');

          this.rosterSelections = dates;
          this.rosterSelections2 = JSON.parse(JSON.stringify(this.rosterSelections));
          this.rosterSelections2.push({
            id: '0',
            completionTime: '9999-12-31T06:00:00.000000Z'
          } as AltProvResultDate);

          this.rosterSelections = this.rosterSelections.sort((x, y) =>
            x.completionTime > y.completionTime ? -1 : y.completionTime > x.completionTime ? 1 : 0
          );

          this.rosterSelections2 = this.rosterSelections2.sort((x, y) =>
            x.completionTime > y.completionTime ? -1 : y.completionTime > x.completionTime ? 1 : 0
          );

          this.setColumnOptions('product', productOptions);
          this.setColumnOptions('assignedBy', ['Rule', 'PCC Exception', 'Member Exception']);
        }),
        take(1)
      )
      .subscribe();
  }

  getLocalDate(dateString: string): string {
    if (dateString === '9999-12-31T06:00:00.000000Z') {
      return 'CURRENT RECORD';
    }

    return formatDate(dateString, 'short', 'en-US');
  }

  private setColumnOptions(field: string, options: Array<any>): void {
    const selectedColumnsIndex = this.selectedColumns.findIndex(x => x.field === field);
    const allColumnsIndex = this.allColumns.findIndex(x => x.field === field);

    if (selectedColumnsIndex > 0) {
      this.selectedColumns[selectedColumnsIndex].options = options;
    }

    if (allColumnsIndex > 0) {
      this.allColumns[allColumnsIndex].options = options;
    }
  }

  getVarianceReport(): void {
    this.showTable = true;
    this.fromID = this.rosterSelections.find(x => {
      return x.completionTime === this.selectedDateFrom;
    })?.id;
    this.toID = this.rosterSelections2.find(x => {
      return x.completionTime === this.selectedDateTo;
    })?.id;
    console.log(this.selectedDateFrom, this.fromID, this.selectedDateTo, this.toID);

    if (this.selectedDateFrom === '' || this.selectedDateTo === '') {
      this.showTable = false;
      return;
    }

    let newEvent: LazyLoadEvent = {};
    if (this.selectedDateFrom) {
      newEvent = {
        first: 0,
        rows: 80000,
        filters: {
          compareFrom: { matchMode: 'dateIs', value: this.selectedDateFrom },
          compareTo: { matchMode: 'dateIs', value: this.selectedDateTo },
          compareFromID: { matchMode: 'Equals', value: this.fromID },
          compareToID: { matchMode: 'Equals', value: this.toID }
        }
      };
    }

    this.loadingTable = true;
    const fetchSub = this.rosterVarianceService
      .getAllRosterVarianceReport(newEvent, this.querySessionManager.currentSession)
      .subscribe(
        response => {
          this.loadingTable = false;
          this.rosterData = response.results;
          //console.log(this.rosterData);
        },
        (error: HttpErrorResponse) => {
          this.loadingTable = false;
          // If our query session ID was deleted,
          // clear it out and try again
          if (error.status === 404) {
            //this.querySessionManager.resetSession();
            //this.table._filter();
          }
        },
        () => {
          this.loadingTable = false;
          fetchSub.unsubscribe();
        }
      );
  }

  updateOtherSelection(event: string) {
    this.showTable = false;
    this.rosterSelectionsTrim = this.rosterSelections2.filter(
      x => new Date(x.completionTime) > new Date(event)
    );
  }
}
