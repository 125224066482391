import { OnDestroy, Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PCC } from '@shared/models/pcc';
import { PccService } from '@shared/services/pcc-service/pcc.service';

@Component({
  selector: 'app-pcc-picker',
  templateUrl: './pcc-picker.component.html',
  styleUrls: ['./pcc-picker.component.scss']
})
export class PccPickerComponent implements OnInit, OnDestroy {
  pagingSizes = [20, 40, 60, 80, 100];

  allColumns!: any[];

  loadingTable = true;
  pccs: PCC[] = [];
  cols: any[];
  totalPCCs = 0;
  selectedPmiId: any;
  selectedProduct: any;
  termed: boolean;

  private get currentSession(): string | null {
    return window.sessionStorage.getItem('pcc_list_session');
  }
  private set currentSession(sessionId: string | null) {
    if (!sessionId) {
      window.sessionStorage.removeItem('pcc_list_session');
    } else {
      window.sessionStorage.setItem('pcc_list_session', sessionId!);
    }
  }

  constructor(
    public dialogRef: MatDialogRef<PccPickerComponent>,
    public dialog: MatDialog,
    private pccService: PccService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.cols = [
      { propertyName: 'pccId', shortName: 'ID', longName: 'Provider ID', type: 'text' },
      { propertyName: 'pccName', shortName: 'Name', longName: 'PCC Name', type: 'text' },
      {
        propertyName: 'product',
        shortName: 'Product',
        longName: 'Product',
        type: 'multiselect',
        options: []
      },
      {
        propertyName: 'pccDescription',
        shortName: 'Care System',
        longName: 'Care System',
        type: 'text'
      },
      { propertyName: 'pccCode', shortName: 'Code', longName: 'Code', type: 'text' },
      {
        propertyName: 'siteSpecialty',
        shortName: 'Specialty',
        longName: 'Specialty',
        type: 'text'
      },
      { propertyName: 'county', shortName: 'County', longName: 'County', type: 'text' },
      { propertyName: 'taxId', shortName: 'Tax ID', longName: 'Tax ID', type: 'text' },
      { propertyName: 'pmiId', shortName: 'Site Addr ID', longName: 'Site Addr ID', type: 'text' }
    ];

    this.allColumns = this.cols;
    this.selectedPmiId = this.data.selectedPmiId;
    this.selectedProduct = this.data.selectedProduct;
    this.termed = this.data.termed;
  }

  ngOnInit(): void {
    this.loadPCCs();
  }

  ngOnDestroy(): void {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  selectPCC(pcc: PCC) {
    this.dialogRef.close(pcc);
  }

  async loadPCCs() {
    this.loadingTable = true;

    this.pccService.getPCCList().subscribe(
      response => {
        this.pccs = response;
        this.loadingTable = false;
        this.filterPCCs();
      },
      async _ => {
        this.resetSession();
      }
    );
  }

  private filterPCCs() {
    if (!this.termed) {
      this.pccs = this.pccs.filter(data => {
        var date = data.termDate == null ? null : new Date(data.termDate);
        var curDate = new Date();
        return date == null || date > curDate;
      });
    }

    if (this.selectedProduct) {
      this.pccs = this.pccs.filter(data => {
        return data.product === this.selectedProduct;
      });
    }

    if (this.selectedPmiId) {
      this.pccs = this.pccs.filter(data => {
        return data.pmiId != this.selectedPmiId;
      });
    }
  }

  private resetSession(): void {
    this.currentSession = null;
  }
}
