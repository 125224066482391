import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from '@memberMaintenance/models/member';
import { MemberMaintenanceService } from '@memberMaintenance/services/member-maintenance.service';
import { PccPickerComponent } from '@shared/components/pcc-picker/pcc-picker.component';
import { HealthStatusCode } from '@shared/models/health-status-code';
import { DatePart } from '@shared/enums/date-part';
import { PCC } from '@shared/models/pcc';
import { Product } from '@shared/models/product';
import { ProductService } from '@shared/services/product-service/product.service';
import { SpinnerOverlayService } from '@shared/services/spinner-overlay-service/spinner-overlay.service';
import { getLocalDateFromUTCDateString } from '@shared/util/date-formatter';
import { DateValidator } from '@shared/validators/date-validator/date.validator';
import * as statesCounties from '../../../../../assets/state-counties.json'; // TODO: use counties endpoint instead
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { pccAssignmentMethods } from '@memberMaintenance/components/assets/pcc-assign-methods';
import { toTitleCase } from '@shared/util/toTitleCase';
import { GenericDialogLaunchOptions } from '@shared/components/generic-dialog/generic-dialog-launch-options';
import { LivingStatusCode } from '@shared/models/living-status-code';
import { MsalService } from '@azure/msal-angular';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-member-maintenance-detail',
  templateUrl: './member-maintenance-detail.component.html',
  styleUrls: ['./member-maintenance-detail.component.scss']
})
export class MemberMaintenanceDetailComponent implements OnInit, OnDestroy {
  private readonly _endOfTime = new Date(9999, 11, 31).toISOString();

  membersFormGroup: FormGroup;
  statesCountiesList: any = (statesCounties as any).default;
  statesList: any = Object.keys(this.statesCountiesList);
  countiesList: any;
  healthStatusCodes!: HealthStatusCode[];
  livingStatusCodes!: LivingStatusCode[];
  products!: Product[];
  member!: Member;
  errorMessage = '';
  loading = true;
  selectedPCC!: PCC;
  isNewMember: boolean = false;
  pccAssignmentMethods: Array<string> = pccAssignmentMethods;

  get memberId() {
    return this.membersFormGroup.get('memberId') as AbstractControl;
  }
  get firstName() {
    return this.membersFormGroup.get('firstName') as AbstractControl;
  }
  get middleName() {
    return this.membersFormGroup.get('middleName') as AbstractControl;
  }
  get lastName() {
    return this.membersFormGroup.get('lastName') as AbstractControl;
  }
  get dob() {
    return this.membersFormGroup.get('dob') as AbstractControl;
  }
  get gender() {
    return this.membersFormGroup.get('gender') as AbstractControl;
  }
  get address1() {
    return this.membersFormGroup.get('address1') as AbstractControl;
  }
  get address2() {
    return this.membersFormGroup.get('address2') as AbstractControl;
  }
  get address3() {
    return this.membersFormGroup.get('address3') as AbstractControl;
  }
  get city() {
    return this.membersFormGroup.get('city') as AbstractControl;
  }
  get city3() {
    return this.membersFormGroup.get('city3') as AbstractControl;
  }
  get state() {
    return this.membersFormGroup.get('state') as AbstractControl;
  }
  get state3() {
    return this.membersFormGroup.get('state3') as AbstractControl;
  }
  get zip() {
    return this.membersFormGroup.get('zip') as AbstractControl;
  }
  get zip3() {
    return this.membersFormGroup.get('zip3') as AbstractControl;
  }
  get county() {
    return this.membersFormGroup.get('county') as AbstractControl;
  }
  get county3() {
    return this.membersFormGroup.get('county3') as AbstractControl;
  }
  get countyOverride() {
    return this.membersFormGroup.get('countyOverride') as AbstractControl;
  }
  get pcc() {
    return this.membersFormGroup.get('pcc') as AbstractControl;
  }
  get pccName() {
    return this.membersFormGroup.get('pccName') as AbstractControl;
  }
  get product() {
    return this.membersFormGroup.get('product') as AbstractControl;
  }
  get healthStatus() {
    return this.membersFormGroup.get('healthStatus') as AbstractControl;
  }
  get nameSpan(){
    return this.membersFormGroup.get('nameSpan') as FormGroup;
  }
  get nameStartDate() {
    return this.nameSpan.get('startDate') as AbstractControl;
  }
  get nameEndDate() {
    return this.nameSpan.get('endDate') as AbstractControl;
  }
  get healthRulesId() {
    return this.membersFormGroup.get('healthRulesId') as AbstractControl;
  }
  get addressSpan(){
    return this.membersFormGroup.get('addressSpan') as FormGroup;
  }
  get addressStartDate() {
    return this.addressSpan.get('startDate') as AbstractControl;
  }
  get addressEndDate() {
    return this.addressSpan.get('endDate') as AbstractControl;
  }
  get coverageSpan(){
    return this.membersFormGroup.get('coverageSpan') as FormGroup;
  }
  get productStartDate() {
    return this.coverageSpan.get('startDate') as AbstractControl;
  }
  get productEndDate() {
    return this.coverageSpan.get('endDate') as AbstractControl;
  }
  get pccSpan(){
    return this.membersFormGroup.get('pccSpan') as FormGroup;
  }
  get pccStartDate() {
    return this.pccSpan.get('startDate') as AbstractControl;
  }
  get pccEndDate() {
    return this.pccSpan.get('endDate') as AbstractControl;
  }
  get healthStatusSpan(){
    return this.membersFormGroup.get('healthStatusSpan') as FormGroup;
  }
  get healthStatusStartDate() {
    return this.healthStatusSpan.get('startDate') as AbstractControl;
  }
  get healthStatusEndDate() {
    return this.healthStatusSpan.get('endDate') as AbstractControl;
  }
  get pccAssignmentMethod() {
    return this.membersFormGroup.get('pccAssignmentMethod') as AbstractControl;
  }
  get assessmentDate() {
    return this.membersFormGroup.get('assessmentDate') as AbstractControl;
  }
  get livingStatus() {
    return this.membersFormGroup.get('livingStatus') as AbstractControl;
  }
  get livingStatusSpan(){
    return this.membersFormGroup.get('livingStatusSpan') as FormGroup;
  }
  get livingStatusStartDate() {
    return this.livingStatusSpan.get('startDate') as AbstractControl;
  }
  get livingStatusEndDate() {
    return this.livingStatusSpan.get('endDate') as AbstractControl;
  }
  get careSystemCode() {
    return this.membersFormGroup.get('pccCareSystemCode') as AbstractControl;
  }
  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  readonly minDate: Date;
  readonly maxDate: Date;
  pccTaxId: any;
  pccSiteSpecialty: any;
  pccCareSystemCode: any;
  pccCareSystemDescr: any;
  pccCounty: any;
  pccNPIUMPI: any;
  pccPmiId: any;
  editMember: boolean = true;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private productService: ProductService,
    private authService: MsalService,
    private memberMaintenanceService: MemberMaintenanceService,
    private readonly spinnerService: SpinnerOverlayService,
    public dialog: MatDialog,
    private roleCheck: RoleCheck
  ) {
    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth(), 1);
    this.maxDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const todayUtc = today.toISOString();
    
    this.membersFormGroup = this.fb.group(
      {
        memberId: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        middleName: [''],
        lastName: ['', [Validators.required]],
        dob: ['', [Validators.required]],
        gender: ['', [Validators.required]],
        address1: ['', [Validators.required]],
        address2: [''],
        address3: [''],
        city: ['', [Validators.required]],
        city3: [''],
        state: ['', [Validators.required]],
        state3: [''],
        zip: ['', [Validators.required, Validators.pattern('[0-9]{5}')]],
        zip3: [''],
        county: ['', [Validators.required]],
        county3: [''],
        countyOverride: [''],
        pcc: [''],
        pccName: [''],
        product: ['', [Validators.required]],
        healthStatus: [''],
        nameSpan: this.fb.group ({
          startDate: ['', [Validators.required]],
          endDate: ['', [Validators.required]],
        },
        {
          validator: DateValidator.greaterThan
        } as AbstractControlOptions
        ),
        healthRulesId: ['', [Validators.required]],
        addressSpan: this.fb.group ({
          startDate: ['', [Validators.required]],
          endDate: ['', [Validators.required]],
        },
        {
          validator: DateValidator.greaterThan
        } as AbstractControlOptions
        ),
        coverageSpan: this.fb.group ({
          startDate: ['', [Validators.required]],
          endDate: ['', [Validators.required]] 
        },
        {
          validator: DateValidator.greaterThan
        } as AbstractControlOptions
        ),
        pccSpan: this.fb.group ({
          startDate: [''],
          endDate: [''],
        },
        {
          validator: DateValidator.greaterThan
        } as AbstractControlOptions
        ),
        healthStatusSpan: this.fb.group ({
          startDate: [''],
          endDate: [''],
        },
        {
          validator: DateValidator.greaterThan
        } as AbstractControlOptions
        ),
        pccAssignmentMethod: [''],
        assessmentDate: [''],
        livingStatus: [''],
        livingStatusSpan: this.fb.group ({
          startDate: [''],
          endDate: [''],
        },
        {
          validator: DateValidator.greaterThan
        } as AbstractControlOptions
        ),
        careSystemCode: ['']
      },
      
    );
  }

  userHasEdit() {
    return this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvMemberEdit']);
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.getAllHealthStatusCodes();
    this.getAllLivingStatusCodes();
    this.getAllProducts();

    this.route.params.subscribe(params => {
      if (params.id == 'add') {
        this.isNewMember = true;
        this.prepAddNew();
      } else {
        this.getMemberById(params.id);
      }
    });
  }

  ngOnDestroy(): void {}

  prepAddNew() {
    this.editMember = false;
    //this.healthStatus?.setValue('NU');
    // this.healthStatusStartDate?.setValue(
    //   getLocalDateFromUTCDateString(new Date().toISOString(), DatePart.Date)
    // );
    // this.healthStatusStartTime?.setValue(
    //   getLocalDateFromUTCDateString(new Date().toISOString(), DatePart.Time)
    // );
    // this.healthStatusEndDate?.setValue(
    //   getLocalDateFromUTCDateString(new Date(9999, 11, 31).toISOString(), DatePart.Date)
    // );
    // this.healthStatusEndTime?.setValue(
    //   getLocalDateFromUTCDateString(new Date(9999, 11, 31, 24, 0, 0).toISOString(), DatePart.Time)
    // );
    this.spinnerService.hide();
    this.loading = false;
  }

  productChanged() {
    if (this.product.value == 'CONNECT+MEDICARE' || this.product.value == 'CONNECT') {
      this.membersFormGroup.controls.healthStatus.setValidators(Validators.required);
      this.membersFormGroup.controls.assessmentDate.setValidators(Validators.required);
      this.healthStatusStartDate.setValidators(Validators.required);
      this.healthStatusEndDate.setValidators(Validators.required);
      this.membersFormGroup.controls.healthStatus.updateValueAndValidity();
      this.membersFormGroup.controls.assessmentDate.updateValueAndValidity();
      this.healthStatusStartDate.updateValueAndValidity();
      this.healthStatusEndDate.updateValueAndValidity();
    } else {
      this.membersFormGroup.controls.healthStatus.setValidators(null);
      this.membersFormGroup.controls.assessmentDate.setValidators(null);
      this.healthStatusStartDate.setValidators(null);
      this.healthStatusEndDate.setValidators(null);
      this.membersFormGroup.controls.healthStatus.updateValueAndValidity();
      this.membersFormGroup.controls.assessmentDate.updateValueAndValidity();
      this.healthStatusStartDate.updateValueAndValidity();
      this.healthStatusEndDate.updateValueAndValidity();
      this.membersFormGroup.controls.healthStatus.reset();
      this.membersFormGroup.controls.assessmentDate.reset();
    }
  }

  updateDates(control: string) {
    const today = new Date();
    const firstNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1).toISOString();
    const firstThisMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString();

    switch (control) {
      case 'name':
        this.nameStartDate?.setValue(firstThisMonth);
        this.nameEndDate?.setValue(this._endOfTime);
        break;
      case 'address':
        this.addressStartDate?.reset();
        this.addressEndDate?.setValue(this._endOfTime);
        break;
      case 'product':
        this.productStartDate?.reset();
        this.productEndDate?.setValue(this._endOfTime);
        break;
      case 'hscode':
        this.healthStatusStartDate?.setValue(firstThisMonth);
        this.healthStatusEndDate?.setValue(this._endOfTime);
        this.assessmentDate?.setValue(today);
        break;
      case 'lscode':
        this.livingStatusStartDate?.setValue(firstThisMonth);
        this.livingStatusEndDate?.setValue(this._endOfTime);
        break;
      case 'dob':
        this.dob?.setValue(this.member?.dob);
        break;
    }
  }

  getMemberById(id: string) {
    this.memberMaintenanceService.getMember(id).subscribe({
      next: params => {
        this.member = params;

        this.membersFormGroup.patchValue({
          id: this.member?.id,
          memberId: this.member?.id,
          firstName: this.member?.firstName,
          middleName: this.member?.middleName,
          lastName: this.member?.lastName,
          dob: this.member?.dob ?? '',
          gender: this.member?.gender,
          address1: this.member?.address1,
          address2: this.member?.address2,
          address3: this.member?.address3,
          city: this.member?.city,
          city3: this.member?.city3,
          state: this.member?.state,
          state3: this.member?.state3,
          zip: this.member?.zip.slice(0, 5),
          zip3: this.member?.zip3.slice(0, 5),
          county: toTitleCase(this.member?.county),
          county3: toTitleCase(this.member?.county3),
          countyOverride: toTitleCase(this.member?.countyOverride),
          pcc: this.member?.pcc,
          pccPmiId: this.member?.pccPmiId,
          pccName: this.member?.pccName,
          product: this.member?.product,
          healthStatus: this.member?.healthStatus,
          careSystemCode: this.member?.pccCareSystemCode,
          nameSpan:{
            startDate: this.member?.memberNameBeginDate ?? '',
            endDate: this.member?.memberNameEndDate ?? '',
          },
          healthRulesId: this.member?.healthRulesId,
          addressSpan:{
            startDate: this.member?.memberAddressBeginDate ?? '',
            endDate: this.member?.memberAddressEndDate ?? '',
          },
          coverageSpan:{
            startDate: this.member?.memberProductBeginDate ?? '',
            endDate: this.member?.memberProductEndDate ?? '',
          },
          pccSpan:{
            startDate: this.member?.memberPCCBeginDate ?? '',
            endDate: this.member?.memberPCCEndDate ?? '',
          },
          healthStatusSpan:{
            startDate: this.member?.memberHealthStatusBeginDate ?? '',
            endDate: this.member?.memberHealthStatusEndDate ?? '',
          },
          pccAssignmentMethod: this.member?.pccAssignmentMethod,
          assessmentDate: this.member?.assessmentDate ?? '',
          livingStatus: this.member?.livingStatusCode,
          livingStatusSpan:{
            startDate: this.member?.livingStatusCodeBeginDate ?? '',
            endDate: this.member?.livingStatusCodeEndDate ?? '',
          }
        });
        this.pccTaxId = this.member?.pccTaxId;
        this.pccSiteSpecialty = this.member?.pccSiteSpecialty;
        this.pccCareSystemCode = this.member?.pccCareSystemCode;
        this.pccCareSystemDescr = this.member?.pccCareSystemDescr;
        this.pccCounty = this.member?.county;
        this.pccNPIUMPI = this.member?.pccNPIUMPI;
        this.countiesList = this.statesCountiesList[this.member.state];
        this.pccCounty = this.member?.pccCounty;
        this.pccNPIUMPI = this.member?.pccNPIUMPI;
        this.pccPmiId = this.member?.pccPmiId;
        this.loading = false;
        this.spinnerService.hide();
        this.productChanged();
      },
      error: err => {
        this.spinnerService.hide();
        this.router.navigate(['/members/list']);
      }
    });
  }
 
  getAllHealthStatusCodes(): void {
    this.memberMaintenanceService.getAllHealthStatusCodes().subscribe({
      next: healthStatusCodes => {
        this.healthStatusCodes = healthStatusCodes;
      },
      error: err => (this.errorMessage = err)
    });
  }

  getAllLivingStatusCodes(): void {
    this.memberMaintenanceService.getAllLivingStatusCodes().subscribe({
      next: livingStatusCodes => {
        this.livingStatusCodes = livingStatusCodes;
      },
      error: err => (this.errorMessage = err)
    });
  }

  getAllProducts(): void {
    this.productService.getAllProducts().subscribe({
      next: products => {
        this.products = products;
      },
      error: err => (this.errorMessage = err)
    });
  }

  onStateSelectionChange(): void {
    this.countiesList = this.statesCountiesList[this.state.value];
    this.addressStartDate?.setValue(
      getLocalDateFromUTCDateString(new Date().toISOString(), DatePart.Date)
    );
    this.addressEndDate?.setValue(
      getLocalDateFromUTCDateString(new Date(9999, 11, 30).toISOString(), DatePart.Date)
    );
  }

  openPCCPicker(): void {
    const dialogRef = this.dialog.open(PccPickerComponent, {
      autoFocus: true,
      minHeight: '450px',
      minWidth: 600,
      data: { selectedPmiId: '', selectedProduct: this.product.value, termed: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.selectedPCC = result;
        this.membersFormGroup.patchValue({
          pcc: result.pccId,
          pccName: result.pccName,
          careSystemCode: result.pccCode
        });
        this.pccTaxId = result.taxId;
        this.pccSiteSpecialty = result.siteSpecialty;
        this.pccCareSystemCode = result.pccCode;
        this.pccPmiId = result.pmiId;
        this.pccCareSystemDescr = result.careSystemDescription;
        this.pccCounty = result.county;
        this.pccNPIUMPI = result.npi_umpi;
        const today = new Date();
        const firstThisMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString();
        this.pccStartDate?.setValue(firstThisMonth);
        this.pccEndDate?.setValue(this._endOfTime);
        this.pccAssignmentMethod?.setValue('');
      }
    });
  }

  private getMember(): Member {
    let filteredhsCodes = this.healthStatusCodes.filter(
      x => x.healthStatusCode == this.healthStatus.value
    ) as HealthStatusCode[];
    let hsCode = filteredhsCodes[0];
    const newDay = new Date();
    const todayDate = new Date();
    const firstThisMonth = new Date(newDay.getFullYear(), newDay.getMonth(), 1).toISOString();
    //console.log('hsCode',hsCode);
    return {
      id: this.memberId.value,
      firstName: this.firstName.value,
      middleName: this.middleName.value,
      lastName: this.lastName.value,
      dob: this.dob.value,
      gender: this.gender.value,
      address1: this.address1.value,
      address2: this.address2.value,
      city: this.city.value,
      state: this.state.value,
      zip: this.zip.value.toString(),
      county: this.county.value,
      countyOverride: this.countyOverride.value,
      pcc: this.pcc.value,
      pccPmiId: this.pccPmiId,
      pccName: this.pccName.value,
      product: this.product.value,
      healthStatus: this.healthStatus.value,
      pccTaxId: this.pccTaxId,
      pccSiteSpecialty: this.pccSiteSpecialty,
      pccCareSystemCode: this.pccCareSystemCode,
      pccCareSystemDescr: this.pccCareSystemDescr,
      healthRulesId: this.healthRulesId.value.toString(),
      memberAddressBeginDate: this.addressStartDate.value ?? '',
      memberAddressEndDate: this.addressEndDate.value ?? '',
      memberHealthStatusBeginDate: this.healthStatusStartDate.value ?? '',
      memberHealthStatusEndDate: this.healthStatusEndDate.value ?? '',
      memberNameBeginDate: this.nameStartDate.value,
      memberNameEndDate: this.nameEndDate.value ?? '',
      memberPCCBeginDate: this.pccStartDate.value ?? '',
      memberPCCEndDate: this.pccEndDate.value ?? '',
      memberProductBeginDate: this.productStartDate.value ?? '',
      memberProductEndDate: this.productEndDate.value ?? '',
      isNewMember: this.isNewMember,
      pccCounty: this.pccCounty,
      pccNPIUMPI: this.pccNPIUMPI,
      pccAssignmentMethod: this.pccAssignmentMethod.value,
      healthStatusDescription: hsCode ? hsCode.healthStatusDescription : '',
      assessmentStatus: hsCode ? hsCode.assessmentStatus : '',
      interpretationNeedLevel: hsCode ? hsCode.interpretationNeedLevel : '',
      interpretationNeedType: hsCode ? hsCode.interpretationNeedType : '',
      observationStatusReason: hsCode ? hsCode.observationStatusReason : '',
      assessmentDate: hsCode && this.assessmentDate.value ? this.assessmentDate.value : '',
      livingStatusCode: this.livingStatus.value,
      livingStatusCodeBeginDate: this.livingStatusStartDate.value ?? firstThisMonth,
      livingStatusCodeEndDate: this.livingStatusEndDate.value ?? this._endOfTime,
      createdUser: this.member?.createdUser ?? this.userName,
      createdDate: this.member?.createdDate ?? todayDate.toISOString(),
      modifiedUser: this.userName,
      modifiedDate: todayDate.toISOString()
    } as Member;
  }

  assignmentMethodRequired() {
    if (this.pcc.value != '' && this.pcc.value != null) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit(): void {
    this.member = this.getMember();

    if (!this.membersFormGroup.invalid) {
      this.spinnerService.show();
      this.loading = true;

      this.memberMaintenanceService.saveMember(this.member).subscribe(res => {
        if (!!res) {
          this.spinnerService.hide();
          this.loading = false;
          this.dialog.open(GenericDialogComponent, {
            width: '500px',
            data: {
              header: 'Error',
              body: res
            } as GenericDialogLaunchOptions
          });
        } else {
          this.spinnerService.hide();
          this.loading = false;
          this.dialog.open(GenericDialogComponent, {
            width: '500px',
            data: {
              header: '',
              body: 'Member Saved Successfully!'
            } as GenericDialogLaunchOptions
          });
          this.router.navigate(['/members/list']);
        }
      });
    }    
  }
}
