import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { PCC } from '@shared/models/pcc';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CloseClinic } from 'src/app/providers/components/clinic-update/close-clinic';
import { EditPcc } from 'src/app/providers/components/pcc-edit/edit-pcc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PccService {
  private readonly filterPCCsEndpoint: string;
  private readonly closeClinicEndpoint: string;
  private readonly editPccEndpoint: string;

  constructor(private http: HttpClient) {
    this.filterPCCsEndpoint = environment.apiUrls.filterPCCs;
    this.closeClinicEndpoint = environment.apiUrls.closeClinic;
    this.editPccEndpoint = environment.apiUrls.editPCC;
  }

  getPCCList(): Observable<PCC[]> {
    return this.http.get<PCC[]>(this.filterPCCsEndpoint, { headers: AzureSubscriptionHeader });
  }

  closeClinic(clinic: CloseClinic): Observable<any> {
    return this.http.post<CloseClinic>(this.closeClinicEndpoint, clinic, {
      headers: AzureSubscriptionHeader,
      observe: 'response'
    });
  }

  updatePCC(pcc: EditPcc): Observable<any> {
    return this.http.post<EditPcc>(this.editPccEndpoint, pcc, {
      headers: AzureSubscriptionHeader,
      observe: 'response'
    });
  }
}

export interface QueryResponse<T> {
  querySessionId: string;
  pageNumber: number;
  totalResults: number;
  results: T[];
}
