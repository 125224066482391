<form
  [formGroup]="memberExceptionFormGroup"
  (ngSubmit)="onSubmit()"
  class="memberException-entry-form"
>
  <h2 mat-dialog-title>Add/Edit Member Exception</h2>
  <fieldset>
    <div class="member-attributes-container">
      <mat-form-field appearance="outline">
        <mat-label>Member Id</mat-label>
        <input #memberID matInput type="text" formControlName="memberId" />
        <mat-error *ngIf="memberId.errors?.required"> Member ID cannot be blank </mat-error>
        <mat-error *ngIf="memberId.errors?.minlength">
          Member ID is number with 8 digits.
        </mat-error>
        <mat-error *ngIf="memberId.errors?.pattern"> Member ID is number only </mat-error>
      </mat-form-field>
      <mat-form-field class="member-exception-input-nopick" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput type="text" formControlName="lastName" readonly="true" />
        <mat-error *ngIf="lastName?.errors?.required"> Last Name cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field class="member-exception-input-nopick" appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput type="text" formControlName="firstName" readonly="true" />
        <mat-error *ngIf="firstName?.errors?.required"> First Name cannot be blank </mat-error>
      </mat-form-field>
      <div class="primary-row">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="getMemberById(memberID.value)"
        >
          Check/Update Member ID
        </button>
      </div>
      <mat-form-field class="member-exception-input-nopick" appearance="outline">
        <mat-label>Member Exception Number</mat-label>
        <input matInput type="text" formControlName="memberExceptionNumber" readonly="true"/>
        <mat-error *ngIf="memberExceptionNumber?.errors?.required">
          Member Exception Number cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Alternate Provider</mat-label>
        <mat-select [compareWith]="compareProperty" color="primary" formControlName="altProv">
          <mat-option
            *ngFor="let ap of altProvs"
            [matTooltip]="ap.alternateProviderName"
            [matTooltipPosition]="'right'"
            [value]="ap"
            >{{ ap.alternateProviderName }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="altProv?.errors?.required">
          Alternate Provider cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field class="member-exception-input-nopick" appearance="outline">
        <mat-label>Product</mat-label>
        <input matInput type="text" formControlName="productName" />
        <mat-error *ngIf="productName?.errors?.required">Product cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Exception Source</mat-label>
        <mat-select color="primary" formControlName="sourceName" name="type">
          <mat-option
            *ngFor="let source of exceptionSources"
            [value]="source.exceptionSourceCode"
            >{{ source.exceptionSourceCode }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="sourceName?.errors?.required"> Must choose a Exception Source </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Effective Start Date</mat-label>
        <input matInput type="date" formControlName="effectiveStartDate" />
        <mat-error *ngIf="effectiveStartDate?.errors?.required">
          Start Date cannot be blank
        </mat-error>
        <mat-error *ngIf="effectiveStartDate?.errors?.startNextMonth">
          Start Date cannot begin before the current month.</mat-error
        >
        <mat-error *ngIf="effectiveStartDate?.errors?.startDay"
          >Start Day cannot begin before the current day.</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Effective Start Time</mat-label>
        <input matInput type="time" formControlName="effectiveStartTime" />
        <mat-error *ngIf="effectiveStartTime?.errors?.required">
          Start Time cannot be blank
        </mat-error>
        <mat-error *ngIf="effectiveStartTime?.errors?.nameStartNextTimeStamp">
          Start Time cannot before the current time
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Effective End Date</mat-label>
        <input matInput type="date" formControlName="effectiveEndDate" />
        <mat-error
          *ngIf="
            memberExceptionFormGroup.errors?.notGreater &&
            (memberExceptionFormGroup.touched || memberExceptionFormGroup.dirty)
          "
        >
          End Date must take place after Start Date
        </mat-error>
        <mat-error *ngIf="effectiveEndDate?.errors?.required"> End Date cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Effective End Time</mat-label>
        <input matInput type="time" formControlName="effectiveEndTime" />
        <mat-error *ngIf="effectiveEndTime?.errors?.required"> End Time cannot be blank </mat-error>
      </mat-form-field>
    </div>
  </fieldset>
  <fieldset>
    <div>
      <mat-form-field appearance="standard" class="example-full-width">
        <mat-label>Note</mat-label>
        <textarea
          matInput
          formControlName="note"
          matAutosizeMinRows="5"
          [maxLength]="1000"
        ></textarea>
      </mat-form-field>
    </div>
  </fieldset>
  <div class="form-action-buttons">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!userHasEdit()">
      Save
    </button>
  </div>
</form>
