<p-dialog
  header="Add/Edit Living Status Code"
  appendTo="body"
  [modal]="true"
  [(visible)]="visible"
  [closable]="false"
  [style]="{ width: '800px' }"
>
  <form
    [formGroup]="livingStatusCodeFormGroup"
    (ngSubmit)="onSubmit()"
    class="living-status-codes-add-form"
  >
    <div class="living-status-codes-attributes">
      <mat-form-field [style.width.px]="120" appearance="outline" class="p-mr-3">
        <mat-label>Living Status Code</mat-label>
        <input matInput type="text" formControlName="livingStatusCode" />
        <mat-error *ngIf="livingStatusCode?.errors?.required">
          Living Status Code cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="450" appearance="outline" class="p-mr-3 p-flew-row">
        <mat-label>Description</mat-label>
        <input matInput type="text" formControlName="livingStatusDescription" />
        <mat-error *ngIf="livingStatusDescription?.errors?.required">
          Description cannot be blank
        </mat-error>
      </mat-form-field>
      <mat-checkbox
        class="p-mr-3 p-flew-row"
        color="primary"
        [checked]="isActive"
        (change)="toggleActiveCheckbox($event)"
        >Active
      </mat-checkbox>
      <input type="hidden" formControlName="id" />
      <input type="hidden" formControlName="createdDate" />
      <input type="hidden" formControlName="createdUser" />
      <input type="hidden" formControlName="modifiedDate" />
      <input type="hidden" formControlName="modifiedUser" />
      <div>
        <mat-form-field appearance="standard" class="example-full-width">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="notes" matAutosizeMinRows="5"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="form-action-buttons p-mt-6">
      <button mat-raised-button type="button" (click)="cancelModal()" class="p-mr-2">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="loading">Save</button>
    </div>
  </form>
</p-dialog>
