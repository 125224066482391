import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { County } from '@shared/models/county';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountiesService {
  private readonly getCountiesEndPoint: string;

  constructor(private http: HttpClient) {
    this.getCountiesEndPoint = environment.apiUrls.getAllCounties;
  }

  getAllCounties(): Observable<County[]> {
    return this.http.get<County[]>(this.getCountiesEndPoint, { headers: AzureSubscriptionHeader });
  }
}
