import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { Observable, of, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExceptionSourceCode } from '../models/exception-source-code';

@Injectable({
  providedIn: 'root'
})
export class ExceptionSourcesService {
  private readonly getAllExceptionSourceCodesEndPoint?: string;
  private readonly insertExceptionSourceCodeEndPoint?: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getAllExceptionSourceCodesEndPoint = environment.apiUrls.getAllExceptionSources;
    this.insertExceptionSourceCodeEndPoint = environment.apiUrls.saveExceptionSource;
  }

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  getAllExceptionSourceCodes(): Observable<ExceptionSourceCode[]> {
    return this.http
      .get<ExceptionSourceCode[]>(this.getAllExceptionSourceCodesEndPoint!, {
        headers: AzureSubscriptionHeader
      })
      .pipe(shareReplay(1));
  }

  getExceptionSourceCode(id: string): Observable<ExceptionSourceCode> {
    if (id === '0') {
      return of(this.initializeExceptionSourceCode());
    } else {
      const url = `${this.getAllExceptionSourceCodesEndPoint!}/${id}`;
      return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
        map((data: any) => {
          return data;
        }),
        shareReplay(1)
      );
    }
  }

  createExceptionSourceCode(
    exceptionSourceCode: ExceptionSourceCode
  ): Observable<ExceptionSourceCode> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };
    return this.http
      .post<ExceptionSourceCode>(
        this.insertExceptionSourceCodeEndPoint!,
        exceptionSourceCode,
        httpOptions
      )
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  upsertExceptionSourceCode(
    exceptionSourceCode: ExceptionSourceCode
  ): Observable<ExceptionSourceCode> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };

    return this.http
      .post<ExceptionSourceCode>(
        this.insertExceptionSourceCodeEndPoint!,
        exceptionSourceCode,
        httpOptions
      )
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  deleteExceptionSourceCode(id: string): Observable<{}> {
    const headers = AzureSubscriptionHeader;
    const url = `${this.getAllExceptionSourceCodesEndPoint!}/${id}`;
    return this.http.delete<ExceptionSourceCode>(url, { headers }).pipe(shareReplay());
  }

  initializeExceptionSourceCode(): ExceptionSourceCode {
    return {
      id: '0',
      exceptionSourceCode: null,
      exceptionSourceDescription: null,
      notes: null,
      active: true,
      createdDate: null,
      createdUser: null,
      modifiedDate: null,
      modifiedUser: null
    };
  }
}
