import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UploadHealthStatusCode } from '../models/upload-healthStatus-code';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';

@Injectable({
  providedIn: 'root'
})
export class HealthstatuscodesUploadService {
  private readonly upLoadMemberHsCodesEndPoint?: string;
  private readonly getAllHsImportsHistoriesEndPoint?: string;
  private readonly getFileFromBlogStorageEndPoint?: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.upLoadMemberHsCodesEndPoint = environment.apiUrls.upLoadMemberHsCodes;
    this.getAllHsImportsHistoriesEndPoint = environment.apiUrls.getAllHsImportsHistories;
    this.getFileFromBlogStorageEndPoint = environment.apiUrls.getFileFromBlobStorage;
  }

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  getHealthStatusCodeUploads() {
    return this.http
      .get<UploadHealthStatusCode[]>(this.getAllHsImportsHistoriesEndPoint!, {
        headers: AzureSubscriptionHeader
      })
      .pipe(shareReplay(1));
  }

  postFileToBlobStorage(fileData: FormData): Observable<any> {
    /* let httpOptions = {
      headers: AzureSubscriptionHeader,
      Content-Type: multipart/form-data
    }; */
    let httpOptions = new HttpHeaders({
      ['Ocp-Apim-Subscription-Key']: environment.subscriptionKey
    });
    return this.http
      .post(this.upLoadMemberHsCodesEndPoint!, fileData, { headers: httpOptions })
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  downLoadFileFromBlobStorage(filename: string): Observable<any> {
    return this.http
      .post(`${this.getFileFromBlogStorageEndPoint!}/${filename}`, null, {
        headers : AzureSubscriptionHeader
      })
  } 
}
