export const observationStatusReasons: string[] = [
  'Refusal',
  'Initial/Annual Assessment',
  'Physical Disability',
  'Brain Injury',
  'Developmentaly Delayed',
  'Mixture',
  'Mental Health',
  'Waiver',
  'No Response'
];
