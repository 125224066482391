import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogLaunchOptions } from '@shared/components/generic-dialog/generic-dialog-launch-options';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class EndpointInterceptor implements HttpInterceptor {
  private readonly MaxRetryCount = 2;

  private dialogRef?: MatDialogRef<GenericDialogComponent>;

  constructor(private readonly dialog: MatDialog) {}

  // TODO: send the error to remote logging infrastructure
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      retry(this.MaxRetryCount),
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse) {
          const errorMessage = this.createErrorMessage(err);

          try {
            if (this.dialogRef) {
              this.dialogRef!.close();
            }

            this.dialogRef = this.dialog.open(GenericDialogComponent, {
              data: {
                header: 'Error',
                body: errorMessage,
                displayCancel: false
              } as GenericDialogLaunchOptions
            });
          } catch (e) {
            console.error('Error opening HTTP error dialog');
          }
        }

        return throwError(err);
      })
    );
  }

  private createErrorMessage(response: HttpErrorResponse): string {
    // 0 usually refers to a CORS error
    if (response.status === 0) {
      return response.statusText;
    }

    const defaultErrorMessage = response.error?.message;

    // APIM responses typically return here
    if (defaultErrorMessage) {
      return defaultErrorMessage;
    }

    if (response.status === 400) {
      return 'Bad request. If the problem persists, please contact support.';
    }

    if (response.status === 401) {
      return 'You are not authorized to access data required to load this page.';
    }

    if (response.status == 403) {
      return 'Forbidden.';
    }

    if (response.status == 404) {
      return 'Item not found.';
    }

    if (response.status === 408) {
      return 'Operation timed out. Please try again or refresh the page.';
    }

    if (response.status === 429) {
      const tryAgainMs =
        response.headers.get('Retry-After') || response.headers.get('x-ms-retry-after-ms');

      if (tryAgainMs) {
        const tryAgain = Number(tryAgainMs) * 1000;
        return `You have been rate limited. Please try again after ${tryAgain} second(s).`;
      } else {
        return 'You have been rate limited. Please wait some time and try again.';
      }
    }

    if (response.status === 500) {
      return 'Server error. Please try again or refresh the page.';
    }

    if (response.status == 503) {
      return 'Server unavailable. Please contact your system administrator.';
    }

    return 'Unhandled error.';
  }
}
