import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateValidator } from '@shared/validators/date-validator/date.validator';
import { Rule } from '@rules/models/rule';
import { RuleCriterion } from '@rules/models/rule-criterion';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { RulesService } from '@rules/services/rules-service/rules.service';
import { SpinnerOverlayService } from '@shared/services/spinner-overlay-service/spinner-overlay.service';
import { RuleCriteriaSelectorComponent } from '../rule-criteria-selector/rule-criteria-selector.component';
import { AlternateProvider } from '@shared/models/alternate-provider';
import { ChangeReason } from '@rules/models/change-reason';
import { MatSelectChange } from '@angular/material/select';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { ProductSelectionListComponent } from '../product-selection/product-selection-list.component';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { getDateStringAsUTC, getLocalDateFromUTCDateString } from '@shared/util/date-formatter';
import { DatePart } from '@shared/enums/date-part';
import { ProductChangeWarnings } from '@shared/enums/product-change-warnings';
import { MsalService } from '@azure/msal-angular';
import { GenericDialogLaunchOptions } from '@shared/components/generic-dialog/generic-dialog-launch-options';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-rule-details',
  templateUrl: './rule-details.component.html',
  styleUrls: ['./rule-details.component.scss']
})
export class RuleDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(RuleCriteriaSelectorComponent)
  ruleCriteriaSelectorComponent!: RuleCriteriaSelectorComponent;

  @ViewChild(ProductSelectionListComponent)
  productSelectionListComponent!: ProductSelectionListComponent;

  private readonly dispose = new Subject<void>();
  private _id?: string;

  allRules: Rule[] = [];
  alternateProviders: Array<AlternateProvider> = [];
  changeReasons: Array<ChangeReason> = [];

  loading = true;
  hasSubmitted = false;
  rulesFormGroup: FormGroup;
  createdDate = '';

  altProvOptions: Array<AlternateProvider> = [];
  filteredAltProvOptions: Observable<Array<AlternateProvider>> = of([]);

  readonly priorityOptions = ['1', '2', '3', '4', '5', '6', '7'];
  filteredPriorityOptions: Observable<Array<string>> = of([]);

  get isEditMode(): boolean {
    return this.editMode === true;
  }

  get isAddMode(): boolean {
    return this.editMode === false;
  }

  get ruleId(): AbstractControl {
    return this.rulesFormGroup.get('ruleId') as AbstractControl;
  }

  get ruleName(): AbstractControl {
    return this.rulesFormGroup.get('ruleName') as AbstractControl;
  }

  get rulePriority(): AbstractControl {
    return this.rulesFormGroup.get('rulePriority') as AbstractControl;
  }

  get altProv(): AbstractControl {
    return this.rulesFormGroup.get('altProv') as AbstractControl;
  }

  get startDate(): AbstractControl {
    return this.rulesFormGroup.get('startDate') as AbstractControl;
  }

  get startTime(): AbstractControl {
    return this.rulesFormGroup.get('startTime') as AbstractControl;
  }

  get endDate(): AbstractControl {
    return this.rulesFormGroup.get('endDate') as AbstractControl;
  }

  get endTime(): AbstractControl {
    return this.rulesFormGroup.get('endTime') as AbstractControl;
  }

  get products(): AbstractControl {
    return this.rulesFormGroup.get('products') as AbstractControl;
  }

  get selectedProducts(): Array<string> {
    return this.products.value as Array<string>;
  }

  get criteria(): AbstractControl {
    return this.rulesFormGroup.get('criteria') as AbstractControl;
  }

  get criteriaValues(): RuleCriterion[] {
    return this.criteria.value as RuleCriterion[];
  }

  get changeReason(): AbstractControl {
    return this.rulesFormGroup.get('changeReason') as AbstractControl;
  }

  get notes(): AbstractControl {
    return this.rulesFormGroup.get('notes') as AbstractControl;
  }

  get createdUser(): AbstractControl {
    return this.rulesFormGroup.get('createdUser') as AbstractControl;
  }

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  private editMode = false;
  minDate: any;
  maxDate: any;

  constructor(
    private dialog: MatDialog,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly rulesService: RulesService,
    private readonly spinnerService: SpinnerOverlayService,
    private readonly altProviderService: AltProviderService,
    private authService: MsalService,
    private roleCheck: RoleCheck
  ) {
    const today = new Date().toISOString();
    const newDay = new Date();
    const firstNextMonth = new Date(newDay.getFullYear(), newDay.getMonth() + 1, 1).toISOString();
    this.rulesFormGroup = this.fb.group(
      {
        ruleId: [undefined],
        ruleName: ['', [Validators.required]],
        rulePriority: ['', [Validators.min(1), Validators.max(7), Validators.required]],
        altProv: ['', [Validators.required, this.altProvSelection.bind(this)]],
        startDate: [
          getLocalDateFromUTCDateString(firstNextMonth, DatePart.Date),
          Validators.compose([Validators.required, DateValidator.ruleStartBetween])
        ],
        startTime: [
          getLocalDateFromUTCDateString(firstNextMonth, DatePart.Time),
          Validators.compose([Validators.required, DateValidator.startNextTimestamp])
        ],
        endDate: ['9999-12-31', [Validators.required]],
        endTime: ['00:00', [Validators.required]],
        changeReason: [''],
        notes: [''],
        products: [new Set<string>()],
        criteria: [[]],
        createdUser: ['']
      },
      {
        validator: DateValidator.greaterThan
      } as AbstractControlOptions
    );
  }

  restrictDates(): void {
    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth(), 1);
    this.maxDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  }

  userHasEdit() {
    return this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvEditRules']);
  }

  ngOnInit(): void {
    this.restrictDates();
    forkJoin([
      this.altProviderService.getAlternateProviders(),
      this.rulesService.getRules(),
      this.rulesService.getChangeReasons()
    ])
      .pipe(
        tap(([altProvs, rules, changeReasons]) => {
          this.altProvOptions = altProvs
            .filter(altProv => altProv.active)
            .map(activeAltProv => ({
              alternateProviderIdentifier: activeAltProv.alternateProviderIdentifier,
              alternateProviderIdType: activeAltProv.alternateProviderIdType,
              alternateProviderNBR: activeAltProv.alternateProviderNBR,
              alternateProviderName: activeAltProv.alternateProviderName,
              periodBeginDate: activeAltProv.periodBeginDate,
              periodEndDate: activeAltProv.periodEndDate
            }));
          this.filteredAltProvOptions = of(this.altProvOptions);

          this.allRules = rules;

          this.changeReasons = changeReasons.filter(x => x.active);

          this.loading = false;
        }),
        take(1)
      )
      .subscribe();

    this.route.data.pipe(takeUntil(this.dispose)).subscribe(data => {
      if (data.rule) {
        this.setFormRule(data.rule);

        this.editMode = true;
        this.createdDate = data.rule.createdDate;

        this.rulesFormGroup.controls.changeReason.setValidators(Validators.required);

        // This forces validation when in edit mode
        this.rulesFormGroup.markAllAsTouched();

        this.rulesFormGroup.controls.changeReason.markAsUntouched();
        this.rulesFormGroup.controls.notes.markAsUntouched();
      } else {
        this.editMode = false;
      }
    });

    this.filteredAltProvOptions = this.altProv.valueChanges.pipe(
      startWith(this.altProv.value),
      map(value =>
        this.altProvOptions.filter(
          option =>
            option.alternateProviderName.toLowerCase() === value.alternateProviderName.toLowerCase()
        )
      )
    );

    this.filteredPriorityOptions = this.rulePriority.valueChanges.pipe(
      startWith(''),
      map(value => this.priorityOptions.filter(option => option.includes(value)))
    );
  }

  onLastProductRemoved(e: string): void {
    this.interceptProductChange(e, ProductChangeWarnings.LastProductRemoved);
  }

  onConnectOrConnectPlusRemoved(e: string): void {
    this.interceptProductChange(e, ProductChangeWarnings.ConnectOrConnectPlusRemoved);
  }

  ruleNameChanged(e: any): void {
    this.checkForDuplicateRuleName(e.target?.value);
  }

  private checkForDuplicateRuleName(targetRuleName: string): void {
    if (this.allRules?.some(x => x.name?.toLowerCase() === targetRuleName?.toLowerCase())) {
      const ruleNameControl = this.rulesFormGroup.get('ruleName');

      ruleNameControl?.setErrors({ duplicateName: true });
      ruleNameControl?.markAsTouched();
    }
  }

  private altProvSelection(control: AbstractControl): ValidationErrors | undefined {
    return control.value &&
      this.altProvOptions.length > 0 &&
      !this.altProvOptions.some(
        x => x.alternateProviderName === control.value.alternateProviderName
      )
      ? { altProvSelection: true }
      : undefined;
  }

  termRule(): void {
    this.endDate?.setValue(getLocalDateFromUTCDateString(new Date().toISOString(), DatePart.Date));
    this.endTime?.setValue(getLocalDateFromUTCDateString(new Date().toISOString(), DatePart.Time));
  }

  validate(): boolean {
    return this.rulesFormGroup.valid && this.ruleCriteriaSelectorComponent?.validate();
  }

  onSubmit(): void {
    this.ruleCriteriaSelectorComponent.formGroup.markAllAsTouched();
    this.ruleCriteriaSelectorComponent.showCriterionErrorMessage = true;
    this.ruleCriteriaSelectorComponent.showCriterionRequiredMessage = true;

    if (!this.validate()) {
      return;
    }

    this.hasSubmitted = true;

    if (!this.rulesFormGroup.invalid && this.rulesFormGroup.touched && this.rulesFormGroup.dirty) {
      this.spinnerService.show();

      this.rulesService.save(this.getFormRule()).subscribe(res => {
        this.spinnerService.hide();

        if (res) {
          this.router.navigate(['/rules/list']);
        }
      });
    }
  }

  displayProperty(altProv: AlternateProvider): string {
    return altProv.alternateProviderName;
  }

  private setFormRule(rule: Rule): void {
    this._id = rule.id;
    this.ruleId.setValue(rule.ruleNumber);
    this.ruleName?.setValue(rule.name);
    this.rulePriority?.setValue(rule.priority);
    this.altProv?.setValue(rule.altProv);
    this.startDate?.setValue(getLocalDateFromUTCDateString(rule.effectiveStartDate as string, DatePart.Date));
    this.startTime?.setValue(getLocalDateFromUTCDateString(rule.effectiveStartDate as string, DatePart.Time));
    this.endDate?.setValue(getLocalDateFromUTCDateString(rule.effectiveEndDate as string, DatePart.Date));
    this.endTime?.setValue(getLocalDateFromUTCDateString(rule.effectiveEndDate as string, DatePart.Time));
    this.products?.setValue(new Set(rule.products));
    this.criteria?.setValue(rule.criteria);
    this.changeReason?.setValue(rule.changeReason);
    this.notes?.setValue(rule.notes);
    this.createdUser?.setValue(rule.createdUser);
  }

  private getFormRule(): Rule {
    return {
      id: this._id,
      ruleNumber: this.ruleId.value,
      name: this.ruleName.value,
      priority: +this.rulePriority.value,
      altProv: this.altProv.value,
      effectiveStartDate: getDateStringAsUTC(this.startDate.value + 'T' + this.startTime.value),
      effectiveEndDate: getDateStringAsUTC(this.endDate.value + 'T' + this.endTime.value),
      products: Array.from(this.selectedProducts),
      criteria: this.criteriaValues,
      changeReason: this.changeReason.value,
      notes: this.notes.value,
      createdDate: this.createdDate,
      createdUser: this.createdUser.value ? this.createdUser.value : this.userName,
      modifiedUser: this.userName
    } as Rule;
  }

  onChangeChangeReason(e: MatSelectChange): void {
    if (e.value === 'Void') {
      this.rulesFormGroup.controls.notes.setValidators([Validators.required]);
      this.rulesFormGroup.controls.notes.updateValueAndValidity();
    } else {
      this.rulesFormGroup.controls.notes.clearValidators();
      this.rulesFormGroup.controls.notes.updateValueAndValidity();
    }
  }

  private interceptProductChange(productName: string, typeOfChange: number): void {
    const hasRulesBuilt = this.criteriaValues.length > 0;

    if (hasRulesBuilt) {
      let dialogBody;
      if (typeOfChange === ProductChangeWarnings.LastProductRemoved) {
        dialogBody = 'If you continue, your criteria will also be removed.';
      } else if (typeOfChange === ProductChangeWarnings.ConnectOrConnectPlusRemoved) {
        // check here if warning is needed
        if (this.criteriaValues.some(x => x.criteriaName === 'Health Status Code')) {
          dialogBody = 'If you continue, your Health Status Code criteria will also be removed.';
        } else {
          return;
        }
      }

      this.dialog
        .open(GenericDialogComponent, {
          width: '500px',
          data: {
            header: 'Are you sure?',
            body: dialogBody
          } as GenericDialogLaunchOptions
        })
        .afterClosed()
        .subscribe(x => this.handleDialogClose(x, productName, typeOfChange));
    }
  }

  private handleDialogClose(
    selection: boolean,
    previousProductName: string,
    typeOfChange: number
  ): void {
    if (selection) {
      if (typeOfChange === ProductChangeWarnings.LastProductRemoved) {
        this.criteria.setValue([]);
        this.ruleCriteriaSelectorComponent?.formGroup.reset();
      } else if (typeOfChange === ProductChangeWarnings.ConnectOrConnectPlusRemoved) {
        const currentCriteria = this.criteria.value as RuleCriterion[];

        // there are at most 2 HSC criteria. Deleting one alters the indices.
        for (let i = 0; i < 2; i++) {
          const hscCriterionIndex = currentCriteria.findIndex(
            x => x.criteriaName === 'Health Status Code'
          );
          if (hscCriterionIndex > -1) {
            this.ruleCriteriaSelectorComponent.deleteCriterion(hscCriterionIndex);
          }
        }
      }
    } else {
      const prevProduct = new Set<string>();
      prevProduct.add(previousProductName);

      this.products.setValue(prevProduct, { emitEvent: false });

      // refresh the component
      const productComponent = this.productSelectionListComponent.productComponents.find(
        x => x.product.productName === previousProductName
      );

      if (productComponent) {
        productComponent.selected = true;
        productComponent.visible = false;
        setTimeout(() => (productComponent.visible = true), 0);
      }
    }
  }

  ngOnDestroy(): void {
    this.dispose.next();
    this.dispose.complete();
  }
}
