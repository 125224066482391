<p-table
  #dt1
  [value]="livingStatusCodes"
  dataKey="id"
  stateStorage="local"
  stateKey="livingstatus-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [globalFilterFields]="['livingStatusCode', 'active', 'modifiedUser']"
  [autoLayout]="true"
>
  <ng-template pTemplate="caption">
    <H2>Living Status Codes</H2>
    <div class="p-formgroup-inline"></div>
    <div class="p-d-flex p-jc-between">
      <button
        type="button"
        (click)="addLivingStatusCode()"
        pButton
        icon="pi pi-plus"
        label="Add a Living Status Code"
        [disabled]="!userHasEdit()"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('livingstatus-filters')"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-excel"
        (click)="exportExcel()"
        class="p-button-success p-mr-2"
        pTooltip="Export as XLS"
        label="Export"
        iconPos="right"
        tooltipPosition="bottom"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="table-header">
    <tr>
      <!-- Edit-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th pSortableColumn="livingStatusCode" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Status<br />Code
          <p-sortIcon field="livingStatusCode"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="livingStatusDescription" class="medium-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Description
          <p-sortIcon field="livingStatusDescription"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="active">
        <span class="p-d-flex p-jc-between p-ai-center">
          <span title="Active">Active</span>
          <p-sortIcon field="active"></p-sortIcon>
        </span>
      </th>

      <th pSortableColumn="notes" class="medium-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Notes
          <p-sortIcon field="notes"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="createdDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created<br />Date
          <p-sortIcon field="createdDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="createdUser" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created<br />User
          <p-sortIcon field="createdUser"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Modified<br />Date
          <p-sortIcon field="modifiedDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedUser" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Updated<br />User
          <p-sortIcon field="modifiedUser"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr>
      <!-- Edit -->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="livingStatusCode"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="livingStatusDescription"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="active" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="[
                  { label: 'Active', value: true },
                  { label: 'Inactive', value: false }
                ]"
                (onChange)="filter($event?.value, 'active', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option.label }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="notes"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="createdDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="createdUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="modifiedDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="modifiedUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-livingStatusCode let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          title="Edit"
          icon="pi pi-pencil"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
          (click)="editLivingStatusCode(livingStatusCode)"
          [disabled]="!userHasEdit()"
        ></button>
      </td>
      <!-- <td>
        <button type="button" pButton pRipple title="Delete" icon="pi pi-trash"
          class="p-button-rounded p-button-warning p-button-sm"
          (click)="deleteLivingStatusCode(LivingStatusCode)"></button>
        </td> -->
      <td>{{ livingStatusCode.livingStatusCode }}</td>
      <td
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px"
        pTooltip="{{ livingStatusCode.livingStatusDescription }}"
      >
        {{ livingStatusCode.livingStatusDescription }}
      </td>
      <td>
        {{ livingStatusCode.active ? 'Active' : 'Inactive' }}
      </td>
      <td
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px"
        pTooltip="{{ livingStatusCode.notes }}"
      >
        {{ livingStatusCode.notes }}
      </td>
      <td>
        <time [attr.datetime]="livingStatusCode.createdDate">{{
          livingStatusCode?.createdDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ livingStatusCode.createdUser }}</td>
      <td>
        <time [attr.datetime]="livingStatusCode.modifiedDate">{{
          livingStatusCode?.modifiedDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ livingStatusCode.modifiedUser }}</td>
    </tr>
  </ng-template>
</p-table>
<div></div>
<app-living-status-codes-add
  *ngIf="displayEditDialog"
  [visible]="displayEditDialog"
  [mySelectedLivingStatusCodeData]="selectedLivingStatusCodeData"
  (cancelEvent)="cancelModal($event)"
  (saveEvent)="updateLivingStatusCode($event)"
></app-living-status-codes-add>
