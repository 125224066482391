import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequest } from '@shared/models/filter-request';
import { QueryResponse } from '@shared/models/query-response';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { take } from 'rxjs/operators';
import { MemberMultiAssigned } from '@reports/models/member-multi-assigned';

@Injectable({
  providedIn: 'root'
})
export class MembersMultiAssignedService {
  private readonly filterMembersMultiAssignedEndpoint: string;

  constructor(private http: HttpClient) {
    this.filterMembersMultiAssignedEndpoint = environment.apiUrls.filterMembersMultiAssigned;
  }

  getAllMembersMultiAssigned(
    filter: LazyLoadEvent,
    sessionId: string | null
  ): Observable<QueryResponse<MemberMultiAssigned>> {
    const request: FilterRequest = {
      querySessionId: sessionId,
      filter
    };

    return this.http
      .post<QueryResponse<MemberMultiAssigned>>(this.filterMembersMultiAssignedEndpoint, request, {
        headers: AzureSubscriptionHeader
      })
      .pipe(take(1));
  }
}
