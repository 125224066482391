<p-table
  #dt1
  [value]="alternateProviders"
  dataKey="id"
  stateStorage="local"
  stateKey="altprov-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [globalFilterFields]="['alternateProviderNBR', 'active', 'modifiedUser']"
  [autoLayout]="true"
>
  <ng-template pTemplate="caption">
    <H2>Alternate Providers</H2>
    <div class="p-formgroup-inline"></div>
    <div class="p-d-flex p-jc-between">
      <button
        type="button"
        (click)="addAlternateProvider()"
        pButton
        icon="pi pi-plus"
        label="Add an Alternate Provider"
        [disabled]="!userHasEdit()"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('altprov-filters')"
      ></button>
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="table-header">
    <tr>
      <!-- Expansion Chevron-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Edit-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Redirect to History Page-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>

      <th pSortableColumn="alternateProviderNBR" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Alias
          <p-sortIcon field="alternateProviderNBR"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="alternateProviderName" class=".medium-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Alternate Provider Name
          <p-sortIcon field="alternateProviderLongName"></p-sortIcon>
        </div>
      </th>

      <th pSortableColumn="alternateProviderFIPSCode" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Alternate Provider FIPS Code
          <p-sortIcon field="alternateProviderFIPSCode"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="alternateProviderStateCode" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Alternate Provider State Code
          <p-sortIcon field="alternateProviderStateCode"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="active">
        <span class="p-d-flex p-jc-between p-ai-center">
          <span title="Active">Active</span>
          <p-sortIcon field="active"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="effectiveStartDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Period Begin<br />
          Date
          <p-sortIcon field="effectiveStartDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="effectiveEndDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Period End<br />
          Date
          <p-sortIcon field="effectiveEndDate"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr class="table-filter-row">
      <!-- Expansion Chevron -->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Edit -->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- Redirect to History Page-->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="alternateProviderNBR"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="alternateProviderName"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="alternateProviderFIPSCode"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="alternateProviderStateCode"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="active" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="[
                  { label: 'Active', value: true },
                  { label: 'Inactive', value: false }
                ]"
                (onChange)="filter($event?.value, 'active', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option.label }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="effectiveStartDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="effectiveEndDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-alternateProvider let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="alternateProvider"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>
      <td>
        <button
          type="button"
          pButton
          pRipple
          title="Edit"
          icon="pi pi-pencil"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
          (click)="editAlternateProvider(alternateProvider)"
          [disabled]="!userHasEdit()"
        ></button>
      </td>
      <td>
        <button
          type="button"
          pButton
          pRipple
          title="Redirect to History"
          icon="pi pi-book"
          routerLink="/altprovhistory/{{alternateProvider.id}}"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>

      <td>{{ alternateProvider.alternateProviderNBR }}</td>
      <td
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px"
        pTooltip="{{
          alternateProvider.alternateProviderLongName != null
            ? alternateProvider.alternateProviderLongName
            : alternateProvider.alternateProviderName
        }}"
      >
        {{
          alternateProvider.alternateProviderLongName != null
            ? alternateProvider.alternateProviderLongName
            : alternateProvider.alternateProviderName
        }}
      </td>

      <td>{{ alternateProvider.alternateProviderFIPSCode }}</td>
      <td>{{ alternateProvider.alternateProviderStateCode }}</td>
      <td>
        {{ alternateProvider.active ? 'Active' : 'Inactive' }}
      </td>
      <td>
        <time [attr.datetime]="alternateProvider.effectiveStartDate">{{
          alternateProvider?.effectiveStartDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>

      <td>
        <time [attr.datetime]="alternateProvider.effectiveEndDate">{{
          alternateProvider?.effectiveEndDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-alternateProvider>
    <tr style="background: ghostwhite">
      <td><span class="p-float-label" style="margin-top: 50px"></span></td>
      <td colspan="8">
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Alternate Provider Category Code: </b>
              {{ alternateProvider.alternateProviderCategoryCode }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>AlternateProvider Type: </b> {{ alternateProvider.alternateProviderType }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Alternate Provider Identifier: </b>
              {{ alternateProvider.alternateProviderIdentifier }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Alternate Provider Id Type: </b>
              {{ alternateProvider.alternateProviderIdType }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex"><b>Created User: </b> {{ alternateProvider.createdUser }}</div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Created Date: </b> {{ alternateProvider.createdDate | date: 'MM/dd/yyyy' }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Last Modified User: </b> {{ alternateProvider.modifiedUser }}
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-d-flex">
              <b>Last Modified Date: </b>
              {{ alternateProvider.modifiedDate | date: 'MM/dd/yyyy' }}
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<div></div>
<app-alternate-providers-add
  *ngIf="displayEditDialog"
  [visible]="displayEditDialog"
  [mySelectedalternateProviderData]="selectedAlternateProvidersData"
  (cancelEvent)="cancelModal($event)"
  (saveEvent)="updateAlternateProvider($event)"
></app-alternate-providers-add>
