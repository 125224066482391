import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CareSystem } from '@rules/models/care-system';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CareSystemService {
  private readonly getCareSystemsEndpoint: string;

  constructor(private readonly http: HttpClient) {
    this.getCareSystemsEndpoint = environment.apiUrls.getAllCareSystems;
  }

  public getCareSystems(): Observable<Array<CareSystem>> {
    return this.http.get<Array<CareSystem>>(this.getCareSystemsEndpoint, {
      headers: AzureSubscriptionHeader
    });
  }
}
