import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit, Self, ViewChild } from '@angular/core';
import { ResolveEnd, ResolveStart, Router, Event, ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MemberHistoryRecord } from '@memberMaintenance/models/member-history-record';
import { MemberHistoryService } from '@memberMaintenance/services/member-history.service';
import { RoleCheck } from '@shared/guards/roleCheck';
import { ColumnDefinition } from '@shared/models/column-definition';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { ProductService } from '@shared/services/product-service/product.service';
import { Table } from 'primeng/table';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-member-maintenance-history-list',
  templateUrl: './member-maintenance-history-list.component.html',
  styleUrls: ['./member-maintenance-history-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class MemberMaintenanceHistoryListComponent implements OnInit {
  private loadingDetailsPage = false;

  errorMessage = '';

  @ViewChild(Table) table!: Table;

  pagingSizes = [20, 40, 60, 80, 100];

  selectedColumns!: ColumnDefinition[];
  allColumns!: ColumnDefinition[];

  loadingTable = true;
  memberRecord: MemberHistoryRecord[] = [];

  altProvs: string[] = [];
  altProvsAlias: string[] = [];
  allProducts: string[] = [];

  constructor(
    private readonly cd: ChangeDetectorRef,
    private roleCheck: RoleCheck,
    private readonly productService: ProductService,
    private readonly altProviderService: AltProviderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private authService: MsalService,
    private memberHistoryService: MemberHistoryService,
    @Self() private readonly clearFilterService: ClearFilterService
  ) {
    this.router.events.subscribe(this.handleRouterEvent.bind(this));

    const defaultColumns: ColumnDefinition[] = [
      {
        field: 'id',
        shortName: 'ID',
        longName: 'ID',
        type: 'none',
        static: true
      },

      {
        field: 'healthRulesId',
        shortName: 'Health Rules ID',
        longName: 'Health Rules ID',
        type: 'none',
        static: true
      },

      {
        field: 'healthStatusCode',
        shortName: 'HS Code',
        longName: 'HS Code',
        type: 'text'
      },
      {
        field: 'product',
        shortName: 'Product',
        longName: 'Product',
        type: 'multiselect',
        options: []
      },
      {
        field: 'memberProductEndDate',
        shortName: 'Coverage End Date',
        longName: 'Coverage End Date',
        type: 'date'
      },
      {
        field: 'altProvAlias',
        shortName: 'AltProvAlias',
        longName: 'Alternate Provider Alias',
        type: 'multiselect',
        options: []
      },
      {
        field: 'pccName',
        shortName: 'PCC Name',
        longName: 'Primary Care Clinic',
        type: 'text'
      },
      {
        field: 'versionEndDate',
        shortName: 'Version End',
        longName: 'Version End Date',
        type: 'datetime'
      },
      {
        field: 'pccCareSystemCode',
        shortName: 'Care System Code',
        longName: 'Care System Code',
        type: 'text'
      },
      {
        field: 'eventType',
        shortName: 'EventType',
        longName: 'Event Type',
        type: 'text'
      },
      {
        field: 'livingStatusCode',
        shortName: 'Living Status',
        longName: 'Living Status Code',
        type: 'text'
      }
    ];

    const additionalColumns: ColumnDefinition[] = [
      { field: 'firstName', shortName: 'First Name', longName: 'First Name', type: 'text' },
      { field: 'middleName', shortName: 'Middle Name', longName: 'Middle Name', type: 'text' },
      { field: 'lastName', shortName: 'Last Name', longName: 'Last Name', type: 'text' },
      {
        field: 'gender',
        shortName: 'Gender',
        longName: 'Gender',
        type: 'text'
      },
      {
        field: 'dob',
        shortName: 'Dob',
        longName: 'Date of Birth',
        type: 'date'
      },
      {
        field: 'deathDate',
        shortName: 'DeathDate',
        longName: 'Death Date',
        type: 'date'
      },
      {
        field: 'address1',
        shortName: 'Address1',
        longName: 'Address 1',
        type: 'text'
      },
      {
        field: 'address2',
        shortName: 'Address2',
        longName: 'Address 2',
        type: 'text'
      },
      {
        field: 'city',
        shortName: 'City',
        longName: 'City',
        type: 'text'
      },
      {
        field: 'county',
        shortName: 'County',
        longName: 'County',
        type: 'text'
      },
      {
        field: 'state',
        shortName: 'State',
        longName: 'State',
        type: 'text'
      },
      {
        field: 'zip',
        shortName: 'Zip',
        longName: 'Zip',
        type: 'text'
      },
      {
        field: 'country',
        shortName: 'Country',
        longName: 'Country',
        type: 'text'
      },
      {
        field: 'memberAddressBeginDate',
        shortName: 'AddressBegin',
        longName: 'Address Begin Date',
        type: 'date'
      },
      {
        field: 'memberAddressEndDate',
        shortName: 'AddressEnd',
        longName: 'Address End Date',
        type: 'date'
      },
      {
        field: 'AltProvId',
        shortName: 'AltProvId',
        longName: 'AltProv Id',
        type: 'text'
      },
      {
        field: 'AltProvName',
        shortName: 'AltProv',
        longName: 'Alternate Provider Name',
        type: 'multiselect',
        options: []
      },
      {
        field: 'versionBeginDate',
        shortName: 'Version Begin',
        longName: 'Version Begin Date',
        type: 'datetime'
      },
      {
        field: 'altProvBeginDate',
        shortName: 'AltProv Begin',
        longName: 'AltProv Begin Date',
        type: 'datetime'
      },
      {
        field: 'altProvEndDate',
        shortName: 'AltProv End',
        longName: 'AltProv End Date',
        type: 'datetime'
      },
      {
        field: 'memberProductBeginDate',
        shortName: 'Coverage Begin',
        longName: 'Coverage Begin Date',
        type: 'date'
      },
      {
        field: 'eventSource',
        shortName: 'EventSource',
        longName: 'Event Source',
        type: 'text'
      },
      {
        field: 'eventTimeStamp',
        shortName: 'EventTimestamp',
        longName: 'Event Timestamp',
        type: 'datetime'
      },
      {
        field: 'updatedIntheUI',
        shortName: 'UpdatedIntheUI',
        longName: 'Updated In the UI',
        type: 'text'
      },
      {
        field: 'legacyAltProvAssignment',
        shortName: 'LegacyAltProvAssignment',
        longName: 'Legacy AltProv Assignment',
        type: 'text'
      },
      {
        field: 'livingStatusCodeBeginDate',
        shortName: 'LivingStatusBegin',
        longName: 'Living Status Code Begin Date',
        type: 'date'
      },
      {
        field: 'livingStatusCodeEndDate',
        shortName: 'LivingStatusEnd',
        longName: 'Living Status Code End Date',
        type: 'date'
      },
      {
        field: 'amisysId',
        shortName: 'Amisys ID',
        longName: 'Amisys ID',
        type: 'text'
      },
      {
        field: 'amisysIdType',
        shortName: 'AmisysIdType',
        longName: 'Amisys Id Type',
        type: 'text'
      },
      {
        field: 'healthRulesIdType',
        shortName: 'HealthRulesIdType',
        longName: 'Health Rules Id Type',
        type: 'text'
      },
      {
        field: 'pcc',
        shortName: 'PCC ID',
        longName: 'Primary Care Clinic ID',
        type: 'text'
      },
      {
        field: 'memberPCCBeginDate',
        shortName: 'PCC Begin',
        longName: 'PCC Begin Date',
        type: 'date'
      },
      {
        field: 'memberPCCEndDate',
        shortName: 'PCC End',
        longName: 'PCC End Date',
        type: 'date'
      },
      {
        field: 'pccAssignmentType',
        shortName: 'PCCAssignmentType',
        longName: 'PCC Assignment Type',
        type: 'text'
      },
      {
        field: 'pccAssignmentMethod',
        shortName: 'PCCAssignmentMethod',
        longName: 'PCC Assignment Method',
        type: 'text'
      },
      {
        field: 'memberHealthConditionType',
        shortName: 'HealthConditionType',
        longName: 'Health Condition Type',
        type: 'text'
      },
      {
        field: 'memberHealthStatusBeginDate',
        shortName: 'HealthStatusBegin',
        longName: 'Health Status Begin Date',
        type: 'date'
      },
      {
        field: 'memberHealthStatusEndDate',
        shortName: 'HealthStatusEnd',
        longName: 'Health Status End Date',
        type: 'date'
      },
      {
        field: 'assessmentDate',
        shortName: 'AssessmentDate',
        longName: 'Assessment Date',
        type: 'date'
      }
    ];

    this.selectedColumns = defaultColumns;
    this.allColumns = defaultColumns.concat(additionalColumns);
  }

  ngOnInit(): void {
    this.loadingTable = true;

    forkJoin([
      this.altProviderService.getAlternateProviders(),
      this.productService.getAllProducts()
    ])
      .pipe(
        tap(([altProvs, products]) => {
          this.altProvs = [
            ...new Set(
              altProvs
                .filter(x => x.active)
                .map(y => y.alternateProviderName)
                .sort()
            )
          ];
          this.setColumnOptions('altProvName', this.altProvs);

          this.altProvsAlias = [
            ...new Set(
              altProvs
                .filter(x => x.active)
                .map(y => y.alternateProviderNBR)
                .sort()
            )
          ];
          this.setColumnOptions('altProvAlias', this.altProvsAlias);

          this.allProducts = [
            ...new Set(
              products
                .filter(x => x.active)
                .map(y => y.productName ?? '')
                .sort()
            )
          ];
          this.setColumnOptions('product', this.allProducts);
        }),
        take(1)
      )
      .subscribe();

    this.route.params.subscribe(params => {
      this.getMemberHistory(params.id);
    });
  }

  getMemberHistory(memberId: string) {
    this.loadingTable = true;

    this.memberHistoryService.getMemberHistoryById(memberId).subscribe({
      next: memberHist => {
        this.memberRecord = memberHist;
        this.memberRecord.forEach(memberHist => {
          memberHist.versionBeginDate =  this.getDate(memberHist.versionBeginDate);
          memberHist.versionEndDate = this.getDate(memberHist.versionEndDate);
          memberHist.altProvBeginDate= this.getDate(memberHist.altProvBeginDate);
          memberHist.altProvEndDate = this.getDate(memberHist.altProvEndDate);
          memberHist.memberProductBeginDate = this.getDate(memberHist.memberProductBeginDate);
          memberHist.memberProductEndDate = this.getDate(memberHist.memberProductEndDate);
          memberHist.eventTimeStamp = this.getDate(memberHist.eventTimeStamp);
          memberHist.dob = this.getDate(memberHist.dob);
          memberHist.deathDate = this.getDate(memberHist.deathDate);
          memberHist.memberAddressBeginDate = this.getDate(memberHist.memberAddressBeginDate);
          memberHist.memberAddressEndDate = this.getDate(memberHist.memberAddressEndDate);
          memberHist.livingStatusCodeBeginDate = this.getDate(memberHist.livingStatusCodeBeginDate);
          memberHist.livingStatusCodeEndDate = this.getDate(memberHist.livingStatusCodeEndDate);
          memberHist.memberPCCBeginDate = this.getDate(memberHist.memberPCCBeginDate);
          memberHist.memberPCCEndDate = this.getDate(memberHist.memberPCCEndDate);
          memberHist.memberHealthStatusBeginDate = this.getDate(memberHist.memberHealthStatusBeginDate);
          memberHist.memberHealthStatusEndDate = this.getDate(memberHist.memberHealthStatusEndDate);
          memberHist.assessmentDate = this.getDate(memberHist.assessmentDate);
          
        });

        this.loadingTable = false;
      },
      error: (err: string) => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }
 
  getDate(dateValue: any): Date | null {
    if (!dateValue) {
      return null;
    }
    return new Date(dateValue)
  }

  private handleRouterEvent(event: Event): void {
    const alreadyLoading = this.loadingDetailsPage;

    if (event instanceof ResolveStart && !alreadyLoading) {
      this.loadingTable = true;
      this.loadingDetailsPage = true;
    }

    if (event instanceof ResolveEnd && alreadyLoading) {
      this.loadingTable = false;
      this.loadingDetailsPage = false;
    }
  }

  @Input() get selectedColumnsFunc(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunc(val: any[]) {
    //restore original order
    this.selectedColumns = this.allColumns.filter(col => val.includes(col));
  }

  onStateRestore(event: any) {
    const columnKeys = event.columnOrder;
    const columns = this.getFilterByKeyOrdered(columnKeys);

    this.selectedColumns = columns;
    this.cd.detectChanges();
  }

  onColumnReorder() {
    this.table.saveState();
  }

  private getFilterByKeyOrdered(keys: string[]) {
    const result: any[] = [];

    for (let key of keys) {
      const column = this.allColumns.find(f => f.field == key);
      result.push(column);
    }

    return result;
  }

  private setColumnOptions(field: string, options: Array<any>): void {
    const selectedColumnsIndex = this.selectedColumns.findIndex(x => x.field === field);
    const allColumnsIndex = this.allColumns.findIndex(x => x.field === field);

    if (selectedColumnsIndex > 0) {
      this.selectedColumns[selectedColumnsIndex].options = options;
    }

    if (allColumnsIndex > 0) {
      this.allColumns[allColumnsIndex].options = options;
    }
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
