import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  OnInit,
  Self
} from '@angular/core';
import { ColumnDefinition } from '@shared/models/column-definition';
import { FilterService } from '@shared/services/filter-service/filter.service';
import { QuerySessionManagerService } from '@shared/services/query-session-manager-service/query-session-manager.service';
import { FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { BaseView } from '@shared/components/Base/base-view';
import { ProductsService } from 'src/app/products/services/products.service';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { MemberMultiAssigned } from '@reports/models/member-multi-assigned';
import { MembersMultiAssignedService } from '@reports/services/member-multi-assigned.service';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';

@Component({
  selector: 'app-members-multi-assigned-list',
  templateUrl: './members-multi-assigned-list.component.html',
  styleUrls: ['./members-multi-assigned-list.component.scss'],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class MembersMultiAssignedListComponent extends BaseView implements OnInit, AfterViewInit {
  public readonly querySessionManager: QuerySessionManagerService;

  @ViewChild(Table) table!: Table;

  selectedColumns: ColumnDefinition[] = [];
  allColumns: ColumnDefinition[] = [];
  first = 0;

  pagingSizes = [20, 40, 60, 80, 100];

  loadingTable = true;
  membersMultiAssigned: MemberMultiAssigned[] = [];
  totalItems = 0;

  private previousLoadEvent: LazyLoadEvent | undefined = undefined;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly filterService: FilterService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private readonly productsService: ProductsService,
    private readonly memberMultiAssignedService: MembersMultiAssignedService,
    private readonly alternateProvidersService: AltProviderService
  ) {
    super();

    this.initColumnDefinitions();

    this.querySessionManager = new QuerySessionManagerService('member-multi-assigned-list');

    // Clear any cached sessions on page load
    this.querySessionManager.resetSession();
  }

  ngOnInit(): void {
    forkJoin([
      this.alternateProvidersService.getAlternateProviders(),
      this.productsService.getAllProducts()
    ])
      .pipe(
        tap(([altProvs, products]) => {
          const altProvOptions = altProvs.filter(x => x.active).map(y => y.alternateProviderName);
          const productOptions = products.filter(x => x.active).map(y => y.productName ?? '');

          this.setColumnOptions('alternateProvider', altProvOptions);
          this.setColumnOptions('product', productOptions);
        }),
        take(1)
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    const columnChangeSub = this.table.tableService.columnsSource$.subscribe(() => {
      this.table.saveState();
    });

    this.subs.add(columnChangeSub);
  }

  async loadMemberMultiAssignedLazy(event: LazyLoadEvent): Promise<void> {
    this.loadingTable = true;
    this.first = event.first ?? 0;

    // Blow away the query session every time unless we are paging forward/backward
    if (this.filterService.didChangePage(this.previousLoadEvent, event) === false) {
      this.querySessionManager.resetSession();
    }

    const clonedEvent = Object.assign({}, event);

    // tslint:disable-next-line: forin
    for (const filterKey in clonedEvent.filters) {
      const selectedColumn = this.selectedColumns.find(x => x.field === filterKey);

      if (!selectedColumn) {
        delete clonedEvent.filters[filterKey];
      } else {
        if (selectedColumn.filterMatchMode) {
          clonedEvent.filters[filterKey].matchMode = selectedColumn.filterMatchMode;

          if (
            selectedColumn.arrayDisplayValue &&
            selectedColumn.filterMatchMode === 'arrayContains'
          ) {
            clonedEvent.filters[filterKey].value = clonedEvent.filters[filterKey].value?.filter(
              (x: string) => x !== selectedColumn.arrayDisplayValue
            );

            if (clonedEvent.filters[filterKey].value?.length > 0) {
              clonedEvent.filters[filterKey].value.push(selectedColumn.arrayDisplayValue);
            }
          }
        }
      }
    }

    const fetchSub = this.memberMultiAssignedService
      .getAllMembersMultiAssigned(clonedEvent, this.querySessionManager.currentSession)
      .subscribe(
        response => {
          this.membersMultiAssigned = response.results;
          this.totalItems = response.totalResults;

          if (response.querySessionId) {
            this.querySessionManager.currentSession = response.querySessionId;
          }
        },
        (error: HttpErrorResponse) => {
          // If our query session ID was deleted,
          // clear it out and try again
          if (error.status === 404) {
            this.querySessionManager.resetSession();
            this.table._filter();
          }
        },
        () => {
          this.loadingTable = false;

          fetchSub.unsubscribe();
        }
      );

    this.previousLoadEvent = event;
  }

  getRoute(field: string, index: number): Array<string> {
    switch (field) {
      case 'id':
        return ['members', this.membersMultiAssigned[index].id];
      default: {
        return [];
      }
    }
  }

  onStateRestore(event: any): void {
    const columnKeys = event.columnOrder;
    const columns = this.getFilterByKeyOrdered(columnKeys);

    this.selectedColumns = columns;
    this.cd.detectChanges();
  }

  onColumnReorder(): void {
    this.table.saveState();
  }

  private getFilterByKeyOrdered(keys: string[]): Array<ColumnDefinition> {
    const result: any[] = [];

    for (const key of keys) {
      const column = this.allColumns.find(f => f.field === key);
      result.push(column);
    }

    return result;
  }

  private initColumnDefinitions(): void {
    const defaultColumns: ColumnDefinition[] = [
      {
        field: 'id',
        shortName: 'Member Id',
        longName: 'Member Id',
        type: 'text',
        routing: true,
        static: true
      },
      { field: 'firstName', shortName: 'First Name', longName: 'First Name', type: 'text' },
      { field: 'lastName', shortName: 'Last Name', longName: 'Last Name', type: 'text' },
      {
        field: 'effectiveDate',
        shortName: 'Begin Date',
        longName: 'Begin Date',
        type: 'date'
      },
      { field: 'endDate', shortName: 'End Date', longName: 'End Date', type: 'date' },
      {
        field: 'product',
        shortName: 'Product',
        longName: 'Product',
        type: 'text',
        options: [],
        filterMatchMode: 'in'
      },
      {
        field: 'alternateProvider',
        shortName: 'Alternate Provider',
        longName: 'Alternate Provider',
        type: 'text',
        arrayDisplayValue: 'alternateProviderName',
        options: [],
        filterMatchMode: 'arrayContains',
        static: true
      }
    ];

    const additionalColumns: ColumnDefinition[] = [
      {
        field: 'resourceType',
        shortName: 'Resource Type',
        longName: 'Resource Type',
        type: 'text'
      },
      { field: 'status', shortName: 'Status', longName: 'Status', type: 'text' },
      { field: 'relationship', shortName: 'Relationship', longName: 'Relationship', type: 'text' },
      {
        field: 'planIdentifier',
        shortName: 'Plan Identifier',
        longName: 'Plan Identifier',
        type: 'text'
      }
    ];

    this.selectedColumns = defaultColumns;
    this.allColumns = defaultColumns.concat(additionalColumns);
  }

  private setColumnOptions(field: string, options: Array<any>): void {
    const selectedColumnsIndex = this.selectedColumns.findIndex(x => x.field === field);
    const allColumnsIndex = this.allColumns.findIndex(x => x.field === field);

    if (selectedColumnsIndex > 0) {
      this.selectedColumns[selectedColumnsIndex].options = options;
    }

    if (allColumnsIndex > 0) {
      this.allColumns[allColumnsIndex].options = options;
    }
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
