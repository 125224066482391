import { formatDate } from '@angular/common';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const today = new Date();
const todayNoSeconds = new Date();
const minDate = new Date(today.getFullYear(), today.getMonth(), 1).getMonth();
const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, 1).getMonth();
const offset = today.getTimezoneOffset() * 60 * 1000;
const currentDay = new Date(today.getTime() - offset).getDate();

export class DateValidator {
  static greaterThan: ValidatorFn = DateValidator.greaterThanFunction;

  private static greaterThanFunction(control: AbstractControl): ValidationErrors | null {
    const startDateValue = control?.get('startDate')?.value;
    const endDateValue = control?.get('endDate')?.value;

    if (!startDateValue || !endDateValue) {
      return null;
    }

    let startDate, endDate: Date;

    try {
      startDate = new Date(startDateValue);
      endDate = new Date(endDateValue);
    } catch {
      return { invalidDates: true };
    }

    if (endDate >= startDate) {
      // TODO: Test case here for (invalid -> valid) (on both effective & end dates) will remove the invalid style
      // TODO: Test case for equal to
      control.get('startDate')?.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      control.get('endDate')?.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      return null;
    } else {
      // TODO: Test case here that checks the visual style is updated
      const error = { notGreater: true };
      control.get('startDate')?.setErrors(error);
      control.get('endDate')?.setErrors(error);
      return error;
    }
  }

  static ruleStartBetween: ValidatorFn = DateValidator.ruleStartNextMonth;

  private static ruleStartNextMonth(ac: AbstractControl, errorName: string = 'ruleStartNextMonth') {
    const fromDate = ac != null ? new Date(ac.value + 'T05:00:00.000Z').getMonth() : null;

    if (fromDate == null || !ac.dirty) return null;

    if (fromDate != null && fromDate < minDate + 1) {
      return { [errorName]: true };
    }
    return null;
  }

  static monthStartBetween: ValidatorFn = DateValidator.startNextMonth;

  private static startNextMonth(ac: AbstractControl, errorName: string = 'startNextMonth') {
    const fromDate = ac != null ? new Date(ac.value + 'T05:00:00.000Z').getMonth() : null;

    if (fromDate == null || !ac.dirty) return null;

    if ((fromDate !== null && fromDate > maxDate) || (fromDate !== null && fromDate < minDate)) {
      return { [errorName]: true };
    }

    return null;
  }

  static dayStartBetween: ValidatorFn = DateValidator.startDay;

  private static startDay(ac: AbstractControl, errorName: string = 'startDay') {
    const fromDay = ac != null ? new Date(ac.value).getDate() : null;

    if (fromDay == null || !ac.dirty) return null;

    if (fromDay != null && fromDay < currentDay - 1) {
      return { [errorName]: true };
    }
    return null;
  }

  static startNextTimestamp: ValidatorFn = DateValidator.nameStartNextTimeStamp;

  private static nameStartNextTimeStamp(
    ac: AbstractControl,
    errorName: string = 'nameStartNextTimeStamp'
  ) {
    const selectedTimestamp = ac != null ? ac.value : null;

    if (selectedTimestamp == null || !ac.dirty) return null;

    const hoursBehind = parseInt(selectedTimestamp.toString().split(':')[0], 10);

    if (today.getHours() > hoursBehind) {
      return { [errorName]: true };
    }
    return null;
  }
  
  static dateTimeNextStart: ValidatorFn = DateValidator.dateTimeLessThanToday;

  private static dateTimeLessThanToday(
    ac: AbstractControl,
    errorName: string = 'dateTimeLessThanToday'
  ) {
    const selectedDateTimestamp = ac != null ? ac.value : null;

    if (selectedDateTimestamp == null || !ac.dirty ) return null;

    var inputDate = new Date(selectedDateTimestamp);
    todayNoSeconds.setSeconds(0,0);

    if (inputDate < todayNoSeconds) {
      return { [errorName]: true };
    }

    return null;
  }


}
