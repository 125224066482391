import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DateValidator } from '@shared/validators/date-validator/date.validator';
import { ExceptionSourceCode } from '../../models/exception-source-code';
import { ExceptionSourceCodeViewModel } from '../../models/exception-source-view-model';
import { ExceptionSourcesService } from '../../services/exception-sources.service';
import { isEmpty } from '@shared/validators/isEmpty';
import { formatDateString } from '@shared/util/date-formatter';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-exception-sources-add',
  templateUrl: './exception-sources-add.component.html',
  styleUrls: ['./exception-sources-add.component.scss']
})
export class ExceptionSourcesAddComponent implements OnInit {
  inactiveCheck = true;
  isActive = true;

  @Input() visible = false;
  @Input() mySelectedexceptionSourceCodeData?: ExceptionSourceCode;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<ExceptionSourceCode>();

  loading = false;
  exceptionSourceCodeFormGroup!: FormGroup;
  errorMessage = '';
  private _seed = 100;
  get seed(): number {
    return this._seed++;
  }

  get exceptionSourceCode() {
    return this.exceptionSourceCodeFormGroup.get('exceptionSourceCode') as FormControl;
  }
  get exceptionSourceDescription() {
    return this.exceptionSourceCodeFormGroup.get('exceptionSourceDescription') as FormControl;
  }
  get active() {
    return this.exceptionSourceCodeFormGroup.get('active') as FormControl;
  }
  get notes() {
    return this.exceptionSourceCodeFormGroup.get('notes') as FormControl;
  }
  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private readonly fb: FormBuilder,
    private exceptionSourcesService: ExceptionSourcesService,
    private authService: MsalService
  ) {}

  ngOnInit(): void {
    this.exceptionSourceCodeFormGroup = this.fb.group(
      {
        id: '',
        exceptionSourceCode: ['', [Validators.required]],
        exceptionSourceDescription: ['', [Validators.required]],
        notes: '',
        active: [''],
        createdDate: '',
        createdUser: '',
        modifiedDate: '',
        modifiedUser: ''
      },
      <AbstractControlOptions>{
        validator: DateValidator.greaterThan
      }
    );

    if (!(this.mySelectedexceptionSourceCodeData?.id === '0')) {
      this.copyExceptionSourceCode();
    } else this.ClearForm();
  }

  toggleActiveCheckbox($event: { checked: boolean }) {
    if ($event.checked === true) this.isActive = true;
    else this.isActive = false;
  }

  ClearForm() {
    this.exceptionSourceCodeFormGroup.markAsUntouched();
    this.exceptionSourceCodeFormGroup.reset();
    this.initializeFormGroup();
  }

  private adjustDate() {
    return 'T12:34:56.7890123Z';
  }

  copyExceptionSourceCode() {
    this.isActive = this.mySelectedexceptionSourceCodeData
      ? this.mySelectedexceptionSourceCodeData.active
      : false;
    this.exceptionSourceCodeFormGroup.patchValue({
      id: this.mySelectedexceptionSourceCodeData?.id,
      exceptionSourceCode: this.mySelectedexceptionSourceCodeData?.exceptionSourceCode,
      exceptionSourceDescription:
        this.mySelectedexceptionSourceCodeData?.exceptionSourceDescription,
      active: status,
      notes: this.mySelectedexceptionSourceCodeData?.notes,
      createdDate:
        this.mySelectedexceptionSourceCodeData?.createdDate != null
          ? formatDateString(this.mySelectedexceptionSourceCodeData.createdDate.toLocaleString()) +
            this.adjustDate()
          : null,
      createdUser: this.mySelectedexceptionSourceCodeData?.createdUser,
      modifiedDate:
        this.mySelectedexceptionSourceCodeData?.modifiedDate != null
          ? formatDateString(this.mySelectedexceptionSourceCodeData?.modifiedDate.toLocaleString())
          : null,
      modifiedUser: this.mySelectedexceptionSourceCodeData?.modifiedUser
    });
  }

  initializeFormGroup() {
    this.exceptionSourceCodeFormGroup.setValue({
      id: '',
      exceptionSourceCode: '',
      exceptionSourceDescription: '',
      notes: '',
      active: '',
      createdDate: '',
      createdUser: '',
      modifiedDate: '',
      modifiedUser: ''
    });
  }

  onSubmit() {
    if (this.exceptionSourceCodeFormGroup.valid) {
      if (!this.exceptionSourceCodeFormGroup.get('id')?.value)
        this.insertExceptionSourceCode(this.exceptionSourceCodeFormGroup.value);
      else this.updateExceptionSourceCode(this.exceptionSourceCodeFormGroup.value);
    }
  }

  insertExceptionSourceCode(exceptionSourceCode: ExceptionSourceCodeViewModel) {
    exceptionSourceCode.active = this.isActive;
    const newExceptionSourceCode: ExceptionSourceCode = {
      exceptionSourceCode: exceptionSourceCode.exceptionSourceCode?.trim(),
      exceptionSourceDescription: exceptionSourceCode.exceptionSourceDescription?.trim(),
      active: exceptionSourceCode.active,
      notes: exceptionSourceCode.notes!.trim(),
      modifiedDate:
        exceptionSourceCode.modifiedDate != null ? exceptionSourceCode.modifiedDate : '',
      modifiedUser: this.userName,
      createdDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      createdUser: this.userName
    };

    this.exceptionSourcesService.createExceptionSourceCode(newExceptionSourceCode).subscribe();

    this.ClearForm();
    this.saveEvent.emit(newExceptionSourceCode);
  }

  updateExceptionSourceCode(exceptionSourceCode: ExceptionSourceCodeViewModel) {
    exceptionSourceCode.active = this.isActive;
    let updatedexceptionSourceCode: ExceptionSourceCode = {
      id: exceptionSourceCode.id,
      exceptionSourceCode: exceptionSourceCode.exceptionSourceCode?.trim(),
      exceptionSourceDescription: exceptionSourceCode.exceptionSourceDescription?.trim(),
      active: exceptionSourceCode.active,
      notes: exceptionSourceCode.notes!.trim(),
      modifiedDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      modifiedUser: this.userName,
      createdDate: exceptionSourceCode.createdDate,
      createdUser: exceptionSourceCode.createdUser
    };

    this.exceptionSourcesService.upsertExceptionSourceCode(updatedexceptionSourceCode).subscribe();

    this.ClearForm();
    this.saveEvent.emit(updatedexceptionSourceCode);
  }

  cancelModal() {
    this.ClearForm();
    this.cancelEvent.emit(this.visible);
  }
}
