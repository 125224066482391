<form [formGroup]="formGroup" class="criteria-container" (touched)="onTouched()">
  <p-table
    #dt
    [resizableColumns]="true"
    [value]="ruleCriteria"
    *ngIf="ruleCriteria.length > 0 && visible"
  >
    <ng-template pTemplate="header">
      <tr class="criterion-header-row">
        <th pResizableColumn class="delete-criterion-column"></th>
        <th pResizableColumn class="criterion-type-column ui-resizable-column">
          <span title="Criterion Type">Criterion Type</span>
        </th>
        <th pResizableColumn class="operator-column ui-resizable-column">
          <span title="Operator">Operator</span>
        </th>
        <th pResizableColumn class="criterion-values-column ui-resizable-column">
          <span title="Values">Values</span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-criterion let-criterionIndex="rowIndex">
      <tr formGroupName="{{ criterionIndex }}" class="criterion-row">
        <td>
          <button
            type="button"
            pButton
            pTooltip="Delete Criterion"
            (click)="deleteCriterion(criterionIndex)"
            tooltipPosition="bottom"
            icon="pi pi-times"
            class="icon-cell-button p-button-rounded p-button-text p-button-plain"
          ></button>
        </td>
        <td class="criterion-type">
          <mat-form-field appearance="outline">
            <mat-label>Rule Criterion Type</mat-label>
            <mat-select
              #currentCriterion
              [(value)]="criterion.criteriaName"
              formControlName="criterionTypeControl"
              required
            >
              <ng-container *ngFor="let criterionChoice of criteriaChoices | keyvalue">
                <mat-option
                  *ngIf="shouldRenderCriterion(criterionChoice.key)"
                  [disabled]="shouldDisableCriterion(criterionChoice.key)"
                  (onSelectionChange)="onCriterionTypeChange($event, criterionIndex)"
                  value="{{ criterionChoice.key }}"
                >
                  {{ criterionChoice.key }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error
              *ngIf="
                formGroup
                  .get(criterionIndex.toString())
                  ?.get('criterionTypeControl')
                  ?.hasError('required')
              "
              >Please select a criterion type</mat-error
            >
          </mat-form-field>
        </td>
        <td class="criterion-operator">
          <mat-form-field appearance="outline">
            <mat-label>Operator</mat-label>
            <mat-select
              [(value)]="criterion.operator"
              formControlName="criterionOperatorControl"
              required
            >
              <ng-container *ngFor="let operation of operators">
                <mat-option
                  [disabled]="shouldDisableOperation(criterion.criteriaName, operation)"
                  value="{{ operation }}"
                >
                  {{ operation }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error
              *ngIf="
                formGroup
                  .get(criterionIndex.toString())
                  ?.get('criterionOperatorControl')
                  ?.hasError('required')
              "
              >Please select an operator</mat-error
            >
          </mat-form-field>
        </td>
        <td>
          <mat-form-field
            class="criterion-values"
            [ngClass]="{
              'criterion-values--none-selected': criterion.values.length === 0
            }"
          >
            <mat-chip-list
              aria-label="Selected values"
              formControlName="criterionValuesControl"
              selectable="false"
            >
              <mat-chip
                selected
                color="primary"
                matTooltip="{{ criterionValue.description }}"
                *ngFor="let criterionValue of criterion.values"
                (removed)="removeCriterionValue(criterionIndex, criterion, criterionValue)"
              >
                {{ criterionValue.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </mat-form-field>
          <div>
            <button
              class="add-criterion-values"
              type="button"
              mat-raised-button
              aria-label="Choose values in selection dialog"
              color="accent"
              (click)="openDialog(criterionIndex, criterion)"
            >
              <mat-icon>add</mat-icon>
            </button>
            <mat-error
              *ngIf="
                formGroup
                  .get(criterionIndex.toString())
                  ?.get('criterionValuesControl')
                  ?.hasError('required') &&
                formGroup.get(criterionIndex.toString())?.get('criterionValuesControl')?.touched
              "
              >You must select some criterion values</mat-error
            >
          </div>
        </td>
      </tr></ng-template
    >
  </p-table>

  <div class="criterion-actions">
    <button
      [disabled]="products.size === 0"
      type="button"
      pButton
      icon="pi pi-plus"
      label="Add Rule Criteria"
      (click)="addRuleCriterion()"
    ></button>
    <mat-error *ngIf="showCriterionErrorMessage && !formGroup.valid"
      >Please fix the existing errors</mat-error
    >
    <mat-error *ngIf="showCriterionRequiredMessage && ruleCriteria.length < 1"
      >Please add criteria</mat-error
    >
  </div>
</form>
