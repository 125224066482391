import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private _msalService: MsalService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    try {
      const userRoles = (this._msalService.instance.getActiveAccount()?.idTokenClaims as any).roles;
      const allowedRoles = next.data["roles"];
      const matchingRoles = userRoles.filter((x: any) => allowedRoles.includes(x));
      return matchingRoles.length > 0;
    } catch (error) {
      this.router.navigate(['/home']);
    }
    return of(false);
  }
}