import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  formatDateString,
  getDateStringAsUTC,
  getLocalDateFromUTCDateString
} from '@shared/util/date-formatter';
import { DatePart } from '@shared/enums/date-part';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HealthStatusCodesService } from '../../services/health-status-codes.service';
import { assessmentStatuses } from '../../assets/assessment-statuses';
import { HealthStatusCode } from '@shared/models/health-status-code';
import { interpretationNeedLevels } from '../../assets/interpretation-need-levels';
import { interpretationNeedTypes } from '../../assets/interpretation-need-types';
import { observationStatusReasons } from '../../assets/observation-status-reasons';
import { BaseView } from '@shared/components/Base/base-view';
import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-health-status-code-dialog',
  templateUrl: './health-status-code-dialog.component.html',
  styleUrls: ['./health-status-code-dialog.component.scss']
})
export class HealthStatusCodeDialogComponent extends BaseView implements OnInit {
  mySelectedhealthStatusCodeData: HealthStatusCode;
  loading = false;
  showCheckboxError = false;
  healthStatusCodeFormGroup!: FormGroup;
  assessmentStatuses: Array<string> = assessmentStatuses;
  interpretationNeedLevels: Array<string> = interpretationNeedLevels;
  interpretationNeedTypes: Array<string> = interpretationNeedTypes;
  observationStatusReasons: Array<string> = observationStatusReasons;
  private thisSub?: Subscription;

  get healthStatusCode(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('healthStatusCode') as FormControl;
  }
  get healthStatusDescription(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('healthStatusDescription') as FormControl;
  }
  get assessmentStatus(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('assessmentStatus') as FormControl;
  }
  get interpretationNeedLevel(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('interpretationNeedLevel') as FormControl;
  }
  get interpretationNeedType(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('interpretationNeedType') as FormControl;
  }
  get observationStatusReason(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('observationStatusReason') as FormControl;
  }
  get isValidConnect(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('isValidConnect') as FormControl;
  }
  get isValidConnectPlus(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('isValidConnectPlus') as FormControl;
  }
  get periodBeginDate(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('periodBeginDate') as FormControl;
  }
  get periodBeginTime(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('periodBeginTime') as FormControl;
  }
  get periodEndDate(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('periodEndDate') as FormControl;
  }
  get periodEndTime(): AbstractControl {
    return this.healthStatusCodeFormGroup.get('periodEndTime') as FormControl;
  }
  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HealthStatusCodeDialogComponent>,
    private readonly fb: FormBuilder,
    private authService: MsalService,
    private healthStatusCodeService: HealthStatusCodesService
  ) {
    super();
    this.mySelectedhealthStatusCodeData = data;
  }

  ngOnInit(): void {
    const today = new Date().toISOString();

    this.healthStatusCodeFormGroup = this.fb.group({
      id: '',
      healthStatusCode: ['', [Validators.required]],
      healthStatusDescription: ['', [Validators.required]],
      assessmentStatus: ['', [Validators.required]],
      interpretationNeedLevel: ['', [Validators.required]],
      interpretationNeedType: ['', [Validators.required]],
      observationStatusReason: ['', [Validators.required]],
      isValidConnect: [false, []],
      isValidConnectPlus: [false, []],
      periodBeginDate: [getLocalDateFromUTCDateString(today, DatePart.Date), [Validators.required]],
      periodBeginTime: [getLocalDateFromUTCDateString(today, DatePart.Time), [Validators.required]],
      periodEndDate: ['9999-12-31', [Validators.required]],
      periodEndTime: ['00:00', [Validators.required]],
      createdDate: '',
      createdUser: '',
      modifiedDate: '',
      modifiedUser: ''
    });

    if (this.mySelectedhealthStatusCodeData?.id) {
      this.populateForm();
    } else {
      this.initializeFormGroup();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  populateForm(): void {
    this.healthStatusCodeFormGroup.patchValue({
      id: this.mySelectedhealthStatusCodeData?.id,
      healthStatusCode: this.mySelectedhealthStatusCodeData?.healthStatusCode,
      healthStatusDescription: this.mySelectedhealthStatusCodeData?.healthStatusDescription,
      assessmentStatus: this.mySelectedhealthStatusCodeData?.assessmentStatus,
      interpretationNeedLevel: this.mySelectedhealthStatusCodeData?.interpretationNeedLevel,
      interpretationNeedType: this.mySelectedhealthStatusCodeData?.interpretationNeedType,
      observationStatusReason: this.mySelectedhealthStatusCodeData?.observationStatusReason,
      isValidConnect: this.mySelectedhealthStatusCodeData?.isValidConnect,
      isValidConnectPlus: this.mySelectedhealthStatusCodeData?.isValidConnectPlus,
      createdDate: this.mySelectedhealthStatusCodeData?.createdDate,
      createdUser: this.mySelectedhealthStatusCodeData?.createdUser,
      modifiedDate: this.mySelectedhealthStatusCodeData?.modifiedDate
        ? formatDateString(this.mySelectedhealthStatusCodeData?.modifiedDate.toLocaleString())
        : null,
      modifiedUser: this.mySelectedhealthStatusCodeData?.modifiedUser,
      periodBeginDate:
        this.mySelectedhealthStatusCodeData?.periodBeginDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedhealthStatusCodeData.periodBeginDate.toLocaleString(),
              DatePart.Date
            )
          : null,
      periodBeginTime:
        this.mySelectedhealthStatusCodeData?.periodBeginDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedhealthStatusCodeData.periodBeginDate.toLocaleString(),
              DatePart.Time
            )
          : null,
      periodEndDate:
        this.mySelectedhealthStatusCodeData?.periodEndDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedhealthStatusCodeData.periodEndDate.toLocaleString(),
              DatePart.Date
            )
          : null,
      periodEndTime:
        this.mySelectedhealthStatusCodeData?.periodEndDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedhealthStatusCodeData.periodEndDate.toLocaleString(),
              DatePart.Time
            )
          : null
    });
  }

  initializeFormGroup(): void {
    const today = new Date().toISOString();

    this.healthStatusCodeFormGroup.controls.periodBeginDate.setValue(
      getLocalDateFromUTCDateString(today, DatePart.Date)
    );
    this.healthStatusCodeFormGroup.controls.periodBeginTime.setValue(
      getLocalDateFromUTCDateString(today, DatePart.Time)
    );
    this.healthStatusCodeFormGroup.controls.periodEndDate.setValue('9999-12-31');
    this.healthStatusCodeFormGroup.controls.periodEndTime.setValue('00:00');
  }

  validate(): boolean {
    return (
      this.healthStatusCodeFormGroup.valid &&
      (this.isValidConnect.value || this.isValidConnectPlus.value)
    );
  }

  onSubmit(): void {
    this.showCheckboxError = true;
    this.healthStatusCodeFormGroup.markAllAsTouched();

    if (this.validate()) {
      this.upsertHealthStatusCode(this.healthStatusCodeFormGroup.value);
    }
  }

  upsertHealthStatusCode(healthStatusCode: HealthStatusCode): void {
    const newHealthStatusCode = {
      ...(healthStatusCode.id && { id: healthStatusCode.id }),
      healthStatusCode: healthStatusCode.healthStatusCode.trim(),
      healthStatusDescription: healthStatusCode.healthStatusDescription?.trim(),
      assessmentStatus: healthStatusCode.assessmentStatus,
      interpretationNeedLevel: healthStatusCode.interpretationNeedLevel,
      interpretationNeedType: healthStatusCode.interpretationNeedType,
      observationStatusReason: healthStatusCode.observationStatusReason,
      isValidConnect: healthStatusCode.isValidConnect,
      isValidConnectPlus: healthStatusCode.isValidConnectPlus,
      modifiedDate: new Date().toISOString(),
      modifiedUser: this.userName,
      periodEndDate: getDateStringAsUTC(
        healthStatusCode.periodEndDate + 'T' + this.periodEndTime.value
      ),
      periodBeginDate: getDateStringAsUTC(
        healthStatusCode.periodBeginDate + 'T' + this.periodBeginTime.value
      ),
      createdDate: healthStatusCode.id ? healthStatusCode.createdDate : new Date().toISOString(),
      createdUser: healthStatusCode.id ?  healthStatusCode.createdUser || 'unknown' : this.userName
    } as HealthStatusCode;

    this.thisSub = this.healthStatusCodeService
      .upsertHealthStatusCode(newHealthStatusCode)
      .subscribe({
        complete: () => {
          this.subs.sink = this.thisSub;
          this.closeDialog();
        }
      });

  }

  ngOnDestroy() {
    // Unsubscribe when the component is destroyed, if needed
    this.thisSub?.unsubscribe();
  }
}
