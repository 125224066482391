import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { uuidv4 } from '@shared/util/generate-guid';
import { Table } from 'primeng/table';
import { UploadHealthStatusCode } from '../../models/upload-healthStatus-code';
import { HealthstatuscodesUploadService } from '../../services/healthstatuscodes-upload.service';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { RoleCheck } from '@shared/guards/roleCheck';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-healthstatuscodes-upload-list',
  templateUrl: './healthstatuscodes-upload-list.component.html',
  styleUrls: ['./healthstatuscodes-upload-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class HealthstatuscodesUploadListComponent implements OnInit {
  uploads!: UploadHealthStatusCode[];

  uploadedFiles: any[] = [];
  multiple = true;
  fileLimit = 1;
  maxFileSize = 1000000;
  accept = '.xlsx, .xls';
  customUpload = true;
  selectedValue: string = 'HealthRules';
  selectedYear: string = '2023';
  data!: Blob;

  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  @ViewChild('dt1') dataTable!: Table;

  selectedHsCodeUploadData?: UploadHealthStatusCode;

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private memberhscodesService: HealthstatuscodesUploadService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private authService: MsalService,
    private roleCheck: RoleCheck
  ) {}

  userHasEdit() {
    return this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvHealthStatusImport']);
  }

  ngOnInit(): void {
    this.memberhscodesService.refreshNeeded$.subscribe(() => {
      this.getAllMemberHsCodesUploads();
    });
    this.getAllMemberHsCodesUploads();
  }

  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  getAllMemberHsCodesUploads() {
    this.loadingTable = true;
    this.memberhscodesService.getHealthStatusCodeUploads().subscribe({
      next: uploads => {
        this.uploads = uploads;
        this.uploads.forEach(upload => {
          upload.uploadDate = new Date(upload.uploadDate!);
          upload.recordsProcessed = upload.recordsProcessed;
          upload.errors = upload.errors;
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  onUpload(event: { files: any }) {
    if (event.files.length === 0) return;

    var formData = new FormData();

    for (let file of event.files) {
      this.uploadedFiles.push(file);
      formData.append(file.name, file);
    }
    formData.append('processId', uuidv4());
    formData.append('status', 'Processing');
    formData.append('createdUser', this.userName);
    formData.append('MemberIdType', this.selectedValue);
    formData.append('templateYear', this.selectedYear);

    this.memberhscodesService.postFileToBlobStorage(formData).subscribe();

    // Clean up
    this.uploadedFiles = [];
    formData = new FormData();
  }

  downLoadFileDataFromStream(fileName: string) {
    this.memberhscodesService.downLoadFileFromBlobStorage(fileName).subscribe((result: any) => {
      if (result.type != 'text/plain' && result.type != 'application/json') {
        fileName = fileName || result.fileDownloadName;
        const contentType = result.contentType;
        this.data = base64StringToBlob(result.fileContents, contentType);
        const file = new File([this.data], fileName, { type: contentType });
        saveAs(file);
      } else {
        console.info('File data not found in Blob!');
      }
    }),
      (error: any) => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
    window.location.reload();
  }
}
