import { Component, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'BaseView',
  template: '<div>BaseView: No Show</div>'
})
export class BaseView implements OnDestroy {
  protected subs = new SubSink();
  constructor() {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
