import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MemberExceptionHistory } from '@memberExceptions/models/member-exception-history';

@Injectable({
  providedIn: 'root'
})
export class MemberExceptionHistoryService {
  private readonly getMemberExceptionHistoryEndPoint?: string;

  constructor(private http: HttpClient) {
    this.getMemberExceptionHistoryEndPoint = environment.apiUrls.getMemberExceptionHistory;
  }

  getMemberExceptionHistory(exceptionNumber: string): Observable<MemberExceptionHistory[]> {
    const url = `${this.getMemberExceptionHistoryEndPoint}/${exceptionNumber}`;
    return this.http.get<MemberExceptionHistory[]>(url, { headers: AzureSubscriptionHeader });
  }
}
