import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  public didChangePage(previousEvent?: LazyLoadEvent, currentEvent?: LazyLoadEvent): boolean | undefined {
    if (previousEvent?.first === undefined || currentEvent?.first === undefined) {
      return undefined;
    }

    const previousClone = Object.assign({}, previousEvent);
    const currentClone = Object.assign({}, currentEvent);

    const previousOffset = previousClone.first!;
    const currentOffset = currentClone.first!;

    delete previousClone.first;
    delete currentClone.first;

    const filtersDeepEqual = JSON.stringify(previousClone) === JSON.stringify(currentClone);
    const offsetsDiffer = previousOffset !== currentOffset;

    return filtersDeepEqual && offsetsDiffer;
  }
}
