import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { HealthStatusCodeHistory } from '@shared/models/health-status-code-history';

@Injectable({
  providedIn: 'root'
})
export class HealthStatusCodesHistoryService {
  private readonly getAllHealthStatusCodesHistoryEndPoint: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getAllHealthStatusCodesHistoryEndPoint = environment.apiUrls.getAllHealthStatusCodesHistory;
  }

  get refreshNeeded$(): Subject<void> {
    return this._refreshNeeded$;
  }

  getHealthStatusCodeHistory(healthStatusCode: string): Observable<HealthStatusCodeHistory[]> {
    const url = `${this.getAllHealthStatusCodesHistoryEndPoint}/${healthStatusCode}`;
    return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
      map((data: any) => {
        return data;
      }),
      shareReplay(1)
    );
  }
}
