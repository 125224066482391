export class QuerySessionManagerService {
  private readonly storage: Storage;

  private get storageKey() {
    return `${this.storagePrefix}-session`;
  }

  public get currentSession(): string | null {
    return this.storage.getItem(this.storageKey);
  }
  public set currentSession(sessionId: string | null) {
    if (!sessionId) {
      this.storage.removeItem(this.storageKey);
    } else {
      this.storage.setItem(this.storageKey, sessionId!);
    }
  }

  constructor(private readonly storagePrefix: string) {
    this.storage = sessionStorage;
  }

  public resetSession() {
    this.currentSession = null;
  }
}
