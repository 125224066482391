<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{ criteriaName }} Selection
</h2>
<mat-dialog-content>
  <p-table
    [value]="gridData"
    [(selection)]="selectedRows"
    [rows]="10"
    [paginator]="true"
    [loading]="loading"
    dataKey="identifier"
    styleClass="p-datatable-sm p-datatable-striped"
    scrollHeight="flex"
    [autoLayout]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="name">
          <div class="header-container-with-icons">
            Name
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="description">
          <div class="header-container-with-icons">
            Description
            <p-sortIcon field="description"></p-sortIcon>
            <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-choice>
      <tr>
        <td>
          <p-tableCheckbox [value]="choice"></p-tableCheckbox>
        </td>
        <td>
          <span>{{ choice.name }}</span>
        </td>
        <td>
          <span>{{ choice.description }}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="3">No values for {{ criteriaName }} were returned from the server.</td>
      </tr>
    </ng-template>
  </p-table>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="selectedRows">Save</button>
</mat-dialog-actions>
