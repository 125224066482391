import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DateValidator } from '@shared/validators/date-validator/date.validator';
import { AlternateProvider } from '../../models/alternate-provider';
import { AlternateProviderViewModel } from '../../models/alternate-provider-view-model';
import { AlternateProvidersService } from '../../services/alternate-providers.service';
import { isEmpty } from '@shared/validators/isEmpty';
import {
  formatDateString,
  getDateStringAsUTC,
  getLocalDateFromUTCDateString
} from '@shared/util/date-formatter';
import { DatePart } from '@shared/enums/date-part';
import { County } from '@shared/models/county';
import { CountiesService } from '@shared/services/counties-service/counties.service';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-alternate-providers-add',
  templateUrl: './alternate-providers-add.component.html',
  styleUrls: ['./alternate-providers-add.component.scss']
})
export class AlternateProvidersAddComponent implements OnInit {
  isActive = true;

  @Input() visible = false;
  @Input() mySelectedalternateProviderData?: AlternateProvider;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<AlternateProvider>();

  loading = false;
  alternateProviderFormGroup!: FormGroup;
  errorMessage = '';
  private _seed = 100;
  get seed(): number {
    return this._seed++;
  }

  types!: any[];
  idTypes!: any[];
  counties!: County[];
  altProvs: AlternateProvider[] = [];

  get alternateProviderNBR(): FormControl {
    return this.alternateProviderFormGroup.get('alternateProviderNBR') as FormControl; // OK
  }
  get alternateProviderIdentifier(): FormControl {
    return this.alternateProviderFormGroup.get('alternateProviderIdentifier') as FormControl; // OK
  }

  get alternateProviderName(): FormControl {
    return this.alternateProviderFormGroup.get('alternateProviderLongName') as FormControl;
  }
  get active(): FormControl {
    return this.alternateProviderFormGroup.get('active') as FormControl; // OK
  }

  get alternateProviderFIPSCode(): FormControl {
    return this.alternateProviderFormGroup.get('alternateProviderFIPSCode') as FormControl;
  }
  get alternateProviderStateCode(): FormControl {
    return this.alternateProviderFormGroup.get('alternateProviderStateCode') as FormControl;
  }
  get effectiveStartDate() {
    return this.alternateProviderFormGroup.get('effectiveStartDate') as FormControl;
  }
  get effectiveStartTime() {
    return this.alternateProviderFormGroup.get('effectiveStartTime') as FormControl;
  }
  get effectiveEndDate() {
    return this.alternateProviderFormGroup.get('effectiveEndDate') as FormControl;
  }
  get effectiveEndTime() {
    return this.alternateProviderFormGroup.get('effectiveEndTime') as FormControl;
  }

  get alternateProviderType(): FormControl {
    return this.alternateProviderFormGroup.get('alternateProviderType') as FormControl;
  }
  get alternateProviderIdType(): FormControl {
    return this.alternateProviderFormGroup.get('alternateProviderIdType') as FormControl;
  }

  get periodBeginDate() {
    return this.alternateProviderFormGroup.get('effectiveStartDate') as FormControl;
  }
  get periodBeginTime() {
    return this.alternateProviderFormGroup.get('effectiveStartTime') as FormControl;
  }
  get periodEndDate() {
    return this.alternateProviderFormGroup.get('effectiveEndDate') as FormControl;
  }
  get periodEndTime() {
    return this.alternateProviderFormGroup.get('effectiveEndTime') as FormControl;
  }
  get alternateProviderCategoryCode() {
    return this.alternateProviderFormGroup.get('alternateProviderCategoryCode') as FormControl;
  }
  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private readonly fb: FormBuilder,
    private alternateProvidersService: AlternateProvidersService,
    private authService: MsalService,
    private readonly countiesService: CountiesService
  ) {
    this.types = [
      { name: 'County', value: 'County' },
      { name: 'Private Organization', value: 'Private Organization' },
      { name: 'Health System', value: 'Health System' }
    ];
    this.idTypes = [
      { name: 'FIPS_CD', value: 'FIPSCD' },
      { name: 'UMPI', value: 'UMPI' },
      { name: 'TAX_ID', value: 'TAXID' },
      { name: 'NPI', value: 'NPI' }
    ];
  }

  ngOnInit(): void {
    const today = new Date().toISOString();

    this.alternateProviderFormGroup = this.fb.group(
      {
        id: '',
        alternateProviderNBR: ['', [Validators.required]],
        alternateProviderIdentifier: ['', [Validators.required]],
        alternateProviderLongName: ['', [Validators.required]],
        alternateProviderType: ['', [Validators.required]],
        alternateProviderIdType: ['', [Validators.required]],
        // alternateProviderName: ['', [Validators.required]], //Todo: this replaces LongName
        alternateProviderFIPSCode: [''], // County
        alternateProviderStateCode: [''], // State
        alternateProviderCategoryCode: '',
        effectiveStartDate: [
          getLocalDateFromUTCDateString(today, DatePart.Date),
          [Validators.required]
        ],
        effectiveStartTime: [
          getLocalDateFromUTCDateString(today, DatePart.Time),
          [Validators.required]
        ],
        effectiveEndDate: ['9999-12-31', [Validators.required]],
        effectiveEndTime: ['00:00', [Validators.required]],
        periodBeginDate: [
          getLocalDateFromUTCDateString(today, DatePart.Date),
          [Validators.required]
        ],
        periodBeginTime: [
          getLocalDateFromUTCDateString(today, DatePart.Time),
          [Validators.required]
        ],
        periodEndDate: ['9999-12-31', [Validators.required]],
        periodEndTime: ['00:00', [Validators.required]],
        active: [''],
        createdDate: '',
        createdUser: '',
        modifiedDate: '',
        modifiedUser: ''
      },
      <AbstractControlOptions>{
        validator: DateValidator.greaterThan
      }
    );

    forkJoin([
      this.countiesService.getAllCounties(),
      this.alternateProvidersService.getAllAlternateProviders()
    ])
      .pipe(
        tap(([counties, allAltProviders]) => {
          this.counties = counties.filter(x => x.active);
          this.altProvs = allAltProviders.filter(y => y.active);
        }),
        take(1)
      )
      .subscribe({
        error: err => {
          this.errorMessage = err;
        }
      });

    if (!(this.mySelectedalternateProviderData?.id === '0')) {
      this.copyAlternateProvider();
    } else {
      this.ClearForm();
    }
  }

  toggleActiveCheckbox($event: { checked: boolean }): void {
    if ($event.checked === true) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  ClearForm(): void {
    this.alternateProviderFormGroup.markAsUntouched();
    this.alternateProviderFormGroup.reset();
    this.initializeFormGroup();
  }

  private adjustDate(): string {
    return 'T12:34:56.7890123Z';
  }

  copyAlternateProvider(): void {
    this.isActive = this.mySelectedalternateProviderData
      ? this.mySelectedalternateProviderData.active
      : false;
    this.alternateProviderFormGroup.patchValue({
      id: this.mySelectedalternateProviderData?.id,
      alternateProviderNBR: this.mySelectedalternateProviderData?.alternateProviderNBR,
      alternateProviderLongName:
        this.mySelectedalternateProviderData?.alternateProviderLongName ??
        this.mySelectedalternateProviderData?.alternateProviderName,
      active: status,
      createdDate:
        this.mySelectedalternateProviderData?.createdDate != null
          ? formatDateString(this.mySelectedalternateProviderData.createdDate.toLocaleString()) +
            this.adjustDate()
          : null,
      createdUser: this.mySelectedalternateProviderData?.createdUser,
      modifiedDate:
        this.mySelectedalternateProviderData?.modifiedDate != null
          ? formatDateString(this.mySelectedalternateProviderData?.modifiedDate.toLocaleString())
          : null,
      modifiedUser: this.mySelectedalternateProviderData?.modifiedUser,
      effectiveEndDate:
        this.mySelectedalternateProviderData?.effectiveEndDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedalternateProviderData.effectiveEndDate.toLocaleString(),
              DatePart.Date
            )
          : this.mySelectedalternateProviderData?.periodEndDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedalternateProviderData.periodEndDate.toLocaleString(),
              DatePart.Date
            )
          : null,
      effectiveEndTime:
        this.mySelectedalternateProviderData?.effectiveEndDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedalternateProviderData.effectiveEndDate.toLocaleString(),
              DatePart.Time
            )
          : this.mySelectedalternateProviderData?.periodEndDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedalternateProviderData.periodEndDate.toLocaleString(),
              DatePart.Time
            )
          : null,
      effectiveStartDate:
        this.mySelectedalternateProviderData?.effectiveStartDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedalternateProviderData.effectiveStartDate.toLocaleString(),
              DatePart.Date
            )
          : this.mySelectedalternateProviderData?.periodBeginDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedalternateProviderData.periodBeginDate.toLocaleString(),
              DatePart.Date
            )
          : null,
      effectiveStartTime:
        this.mySelectedalternateProviderData?.effectiveStartDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedalternateProviderData.effectiveStartDate.toLocaleString(),
              DatePart.Time
            )
          : this.mySelectedalternateProviderData?.periodBeginDate != null
          ? getLocalDateFromUTCDateString(
              this.mySelectedalternateProviderData.periodBeginDate.toLocaleString(),
              DatePart.Time
            )
          : null,
      alternateProviderType: this.mySelectedalternateProviderData?.alternateProviderType,
      alternateProviderIdentifier:
        this.mySelectedalternateProviderData?.alternateProviderIdentifier,
      alternateProviderIdType: this.mySelectedalternateProviderData?.alternateProviderIdType,
      alternateProviderFIPSCode: this.mySelectedalternateProviderData?.alternateProviderFIPSCode,
      alternateProviderStateCode: this.mySelectedalternateProviderData?.alternateProviderStateCode,
      alternateProviderCategoryCode:
        this.mySelectedalternateProviderData?.alternateProviderCategoryCode
    });
  }

  initializeFormGroup(): void {
    const today = new Date().toISOString();

    this.alternateProviderFormGroup.setValue({
      id: '',
      alternateProviderNBR: '',
      alternateProviderIdentifier: '',
      alternateProviderLongName: '',
      alternateProviderType: '',
      // alternateProviderName: '', //Todo: this replaces LongName
      alternateProviderIdType: '',
      alternateProviderFIPSCode: '',
      alternateProviderStateCode: '',
      alternateProviderCategoryCode: '',
      effectiveStartDate: getLocalDateFromUTCDateString(today, DatePart.Date),
      effectiveStartTime: getLocalDateFromUTCDateString(today, DatePart.Time),
      effectiveEndDate: '9999-12-31',
      effectiveEndTime: '00:00',
      periodBeginDate: getLocalDateFromUTCDateString(today, DatePart.Date),
      periodBeginTime: getLocalDateFromUTCDateString(today, DatePart.Time),
      periodEndDate: '9999-12-31',
      periodEndTime: '00:00',
      active: '',
      createdDate: '',
      createdUser: '',
      modifiedDate: '',
      modifiedUser: ''
    });
  }

  onSubmit(): void {
    if (this.alternateProviderFormGroup.valid) {
      if (!this.alternateProviderFormGroup.get('id')?.value) {
        this.insertAlternateProvider(this.alternateProviderFormGroup.value);
      } else {
        this.updateAlternateProvider(this.alternateProviderFormGroup.value);
      }
    }
  }

  insertAlternateProvider(alternateProvider: AlternateProviderViewModel): void {
    alternateProvider.active = this.isActive;
    const newalternateProvider: AlternateProvider = {
      alternateProviderNBR: alternateProvider.alternateProviderNBR?.trim(),
      alternateProviderName: alternateProvider.alternateProviderLongName?.trim(),
      active: alternateProvider.active,
      modifiedDate: alternateProvider.modifiedDate != null ? alternateProvider.modifiedDate : '',
      modifiedUser: !isEmpty(alternateProvider.modifiedUser)
        ? alternateProvider.modifiedUser
        : this.userName,
      createdDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      createdUser: !isEmpty(alternateProvider.createdUser)
        ? alternateProvider.createdUser
        : this.userName,
      periodEndDate: getDateStringAsUTC(
        alternateProvider.effectiveEndDate + 'T' + this.effectiveEndTime.value
      ),
      periodBeginDate: getDateStringAsUTC(
        alternateProvider.effectiveStartDate + 'T' + this.effectiveStartTime.value
      ),
      alternateProviderCategoryCode: 'LA28865-6',
      alternateProviderFIPSCode: alternateProvider.alternateProviderFIPSCode,
      alternateProviderIdType: alternateProvider.alternateProviderIdType,
      alternateProviderIdentifier: alternateProvider.alternateProviderIdentifier,
      alternateProviderType: alternateProvider.alternateProviderType,
      alternateProviderStateCode: alternateProvider.alternateProviderStateCode
    };

    this.alternateProvidersService.createAlternateProvider(newalternateProvider).subscribe();

    this.ClearForm();
    this.saveEvent.emit(newalternateProvider);
  }

  updateAlternateProvider(alternateProvider: AlternateProviderViewModel): void {
    alternateProvider.active = this.isActive;
    const updatedalternateProvider: AlternateProvider = {
      id: alternateProvider.id,
      alternateProviderNBR: alternateProvider.alternateProviderNBR?.trim(),
      alternateProviderName: alternateProvider.alternateProviderLongName?.trim(),
      active: alternateProvider.active,
      modifiedDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      modifiedUser: !isEmpty(alternateProvider.modifiedUser)
        ? alternateProvider.modifiedUser
        : this.userName,
      createdDate: alternateProvider.createdDate,
      createdUser: alternateProvider.createdUser,
      effectiveEndDate: getDateStringAsUTC(
        this.effectiveEndDate.value + 'T' + this.effectiveEndTime.value
      ),
      effectiveStartDate: getDateStringAsUTC(
        this.effectiveStartDate.value + 'T' + this.effectiveStartTime.value
      ),
      periodEndDate: getDateStringAsUTC(this.periodEndDate.value + 'T' + this.periodEndTime.value),
      periodBeginDate: getDateStringAsUTC(
        this.periodBeginDate.value + 'T' + this.periodBeginTime.value
      ),
      alternateProviderCategoryCode: alternateProvider.alternateProviderCategoryCode,
      alternateProviderFIPSCode: alternateProvider.alternateProviderFIPSCode,
      alternateProviderIdType: alternateProvider.alternateProviderIdType,
      alternateProviderIdentifier: alternateProvider.alternateProviderIdentifier,
      alternateProviderType: alternateProvider.alternateProviderType,
      alternateProviderStateCode: alternateProvider.alternateProviderStateCode
    };

    this.alternateProvidersService.upsertAlternateProvider(updatedalternateProvider).subscribe();

    this.ClearForm();
    this.saveEvent.emit(updatedalternateProvider);
  }

  cancelModal(): void {
    this.ClearForm();
    this.cancelEvent.emit(this.visible);
  }
}
