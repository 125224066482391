import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { PccException } from '../models/pcc-exception';
import { environment } from 'src/environments/environment';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';

@Injectable({
  providedIn: 'root'
})
export class PccExceptionService {
  private readonly getAllPccExceptionEndPoint?: string;
  private readonly getPccExceptionByIdEndPoint?: string;
  private readonly upsertPccExceptionEndPoint?: string;
  private readonly deletePccExceptionEndPoint?: string;

  constructor(private http: HttpClient) {
    this.getAllPccExceptionEndPoint = environment.apiUrls.getAllPccExceptions;
    this.getPccExceptionByIdEndPoint = environment.apiUrls.getPccExceptionById;
    this.upsertPccExceptionEndPoint = environment.apiUrls.savePccException;
    this.deletePccExceptionEndPoint = environment.apiUrls.deletePccException;
  }

  getPccExceptions(): Observable<PccException[]> {
    return this.http.get<PccException[]>(this.getAllPccExceptionEndPoint!, {
      headers: AzureSubscriptionHeader
    });
  }

  getPccExceptionById(id: string): Observable<PccException> {
    const url = `${this.getPccExceptionByIdEndPoint!}${id}`;
    return this.http.get<PccException>(url, { headers: AzureSubscriptionHeader }).pipe(take(1));
  }

  upsertPccException(pccException: PccException) {
    const headers = AzureSubscriptionHeader;
    const url = `${this.upsertPccExceptionEndPoint!}`;
    return this.http.post<PccException>(url, pccException, { headers });
  }

  deletePccException(id: string) {
    const headers: HttpHeaders = AzureSubscriptionHeader;
    const body = {
      id: id
    };
    const url = `${this.deletePccExceptionEndPoint!}`;
    return this.http.post<PccException>(url, body, { headers });
  }
}
