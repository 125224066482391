import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberHistoryRecord } from '@memberMaintenance/models/member-history-record';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberHistoryService {
  private readonly getMemberHistoryByIdEndPoint: string;

  constructor(private http: HttpClient) {
    this.getMemberHistoryByIdEndPoint = environment.apiUrls.getMemberHistoryById;
  }

  getMemberHistoryById(memberId: string): Observable<MemberHistoryRecord[]> {
    let httpOptions = {
      headers: AzureSubscriptionHeader
    };
    return this.http
      .post<MemberHistoryRecord>(
        this.getMemberHistoryByIdEndPoint!,
        { memberId: memberId },
        httpOptions
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        shareReplay(1)
      );
  }
}
