import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { Observable, of, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LivingStatusCode } from '../../shared/models/living-status-code';

@Injectable({
  providedIn: 'root'
})
export class LivingStatusCodesService {
  private readonly getAllLivingStatusCodesEndPoint?: string;
  private readonly insertLivingStatusCodeEndPoint?: string;
  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getAllLivingStatusCodesEndPoint = environment.apiUrls.getAllLivingStatusCodes;
    this.insertLivingStatusCodeEndPoint = environment.apiUrls.saveLivingStatusCode;
  }

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  getAllLivingStatusCodes(): Observable<LivingStatusCode[]> {
    return this.http
      .get<LivingStatusCode[]>(this.getAllLivingStatusCodesEndPoint!, {
        headers: AzureSubscriptionHeader
      })
      .pipe(shareReplay(1));
  }

  getLivingStatusCode(id: string): Observable<LivingStatusCode> {
    if (id === '0') {
      return of(this.initializeLivingStatusCode());
    } else {
      const url = `${this.getAllLivingStatusCodesEndPoint!}/${id}`;
      return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
        map((data: any) => {
          return data;
        }),
        shareReplay(1)
      );
    }
  }

  createLivingStatusCode(livingStatusCode: LivingStatusCode): Observable<LivingStatusCode> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };
    return this.http
      .post<LivingStatusCode>(this.insertLivingStatusCodeEndPoint!, livingStatusCode, httpOptions)
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  upsertLivingStatusCode(livingStatusCode: LivingStatusCode): Observable<LivingStatusCode> {
    let httpOptions = {
      headers: AzureSubscriptionHeader,
      responseType: 'text' as 'json'
    };

    return this.http
      .post<LivingStatusCode>(this.insertLivingStatusCodeEndPoint!, livingStatusCode, httpOptions)
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  deleteLivingStatusCode(id: string): Observable<{}> {
    const headers = AzureSubscriptionHeader;
    const url = `${this.getAllLivingStatusCodesEndPoint!}/${id}`;
    return this.http.delete<LivingStatusCode>(url, { headers });
  }

  initializeLivingStatusCode(): LivingStatusCode {
    return {
      id: '0',
      livingStatusCode: null,
      livingStatusDescription: null,
      notes: null,
      active: true,
      createdDate: null,
      createdUser: null,
      modifiedDate: null,
      modifiedUser: null
    };
  }
}
