import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateValidator } from '../../../shared/validators/date-validator/date.validator';
import { County } from '../../models/county';
import { CountyViewModel } from '../../models/county-view-model';
import { CountiesService } from '../../services/counties.service';
import { isEmpty } from '@shared/validators/isEmpty';
import { formatDateString } from '@shared/util/date-formatter';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-county-edit',
  templateUrl: './county-edit.component.html',
  styleUrls: ['./county-edit.component.scss']
})
export class CountyEditComponent implements OnInit {
  inactiveCheck = true;
  isActive = true;
  isForPCC = true;
  isForMember = true;

  @Input() visible = false;
  @Input() mySelectedCountyData?: County;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<County>();

  loading = false;
  countyFormGroup!: FormGroup;
  errorMessage = '';
  private _seed = 100;
  get seed(): number {
    return this._seed++;
  }

  get forPCC() {
    return this.countyFormGroup.get('forPCC') as FormControl;
  }

  get forMember() {
    return this.countyFormGroup.get('forMember') as FormControl;
  }

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(private readonly fb: FormBuilder, private countiesService: CountiesService, private authService: MsalService) {}

  ngOnInit(): void {
    this.countyFormGroup = this.fb.group(
      {
        id: '',
        countyFIPSCode: '',
        countyCode: '',
        countyName: '',
        state: '',
        riskPop: '',
        forPCC: [''],
        forMember: [''],
        active: [''],
        createdDate: '',
        createdUser: '',
        modifiedDate: '',
        modifiedUser: ''
      },
      <AbstractControlOptions>{
        validator: DateValidator.greaterThan
      }
    );

    if (!(this.mySelectedCountyData?.id === '0')) {
      this.copyCounty();
    } else this.ClearForm();
  }

  toggleActiveCheckbox($event: { checked: boolean }) {
    if ($event.checked === true) this.isActive = true;
    else this.isActive = false;
  }

  toggleForPCCCheckbox($event: { checked: boolean }) {
    if ($event.checked === true) this.isForPCC = true;
    else this.isForPCC = false;
  }

  toggleForMemberCheckbox($event: { checked: boolean }) {
    if ($event.checked === true) this.isForMember = true;
    else this.isForMember = false;
  }

  ClearForm() {
    this.countyFormGroup.markAsUntouched();
    this.countyFormGroup.reset();
    this.initializeFormGroup();
  }

  private adjustDate() {
    return 'T12:34:56.7890123Z';
  }

  copyCounty() {
    this.isActive = this.mySelectedCountyData ? this.mySelectedCountyData.active : false;
    this.isForPCC = this.mySelectedCountyData ? this.mySelectedCountyData.forPCC : false;
    this.isForMember = this.mySelectedCountyData ? this.mySelectedCountyData.forMember : false;

    this.countyFormGroup.patchValue({
      id: this.mySelectedCountyData?.id,
      countyFIPSCode: this.mySelectedCountyData?.countyFIPSCode,
      countyCode: this.mySelectedCountyData?.countyCode,
      countyName: this.mySelectedCountyData?.countyName,
      state: this.mySelectedCountyData?.state,
      riskPop: this.mySelectedCountyData?.riskPop,
      forPCC: status,
      forMember: status,
      active: status,
      createdDate:
        this.mySelectedCountyData?.createdDate != null
          ? formatDateString(this.mySelectedCountyData?.createdDate.toLocaleString())
          : null,
      createdUser: this.mySelectedCountyData?.createdUser,
      modifiedDate:
        this.mySelectedCountyData?.modifiedDate != null
          ? formatDateString(this.mySelectedCountyData?.modifiedDate.toLocaleString())
          : null,
      modifiedUser: this.mySelectedCountyData?.modifiedUser
    });
  }

  initializeFormGroup() {
    this.countyFormGroup.setValue({
      id: '',
      countyFIPSCode: '',
      countyCode: '',
      countyName: '',
      state: '',
      riskPop: '',
      forPCC: '',
      forMember: '',
      active: '',
      createdDate: '',
      createdUser: '',
      modifiedDate: '',
      modifiedUser: ''
    });
  }

  onSubmit() {
    if (this.countyFormGroup.valid) {
      if (this.countyFormGroup.get('id')?.value) this.updateCounty(this.countyFormGroup.value);
    }
  }

  updateCounty(county: CountyViewModel) {
    county.active = this.isActive;
    county.forPCC = this.isForPCC;
    county.forMember = this.isForMember;
    let updatedCounty: County = {
      id: county.id,
      countyFIPSCode: county.countyFIPSCode,
      countyCode: county.countyCode,
      countyName: county.countyName,
      state: county.state,
      riskPop: county.riskPop,
      forPCC: county.forPCC,
      forMember: county.forMember,
      active: county.active,
      createdDate: county.createdDate,
      createdUser: county.createdUser,
      modifiedDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      modifiedUser: this.userName 
    };

    this.countiesService.upsertCounty(updatedCounty).subscribe();

    this.ClearForm();
    this.saveEvent.emit(updatedCounty);
  }

  cancelModal() {
    this.ClearForm();
    this.cancelEvent.emit(this.visible);
  }
}
