<p-table
  #dt1
  [value]="uploads"
  dataKey="id"
  stateStorage="local"
  stateKey="hscodesupload-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [globalFilterFields]="['fileName', 'status', 'errors', 'createdUser', 'modifiedUser']"
  [autoLayout]="true"
  responsiveLayout="scroll"
  sortField="uploadDate"
  [sortOrder]="-1"
>
  <ng-template pTemplate="caption">
    <H2>Member Health Status Code Uploads</H2>
    <div class="p-d-flex p-jc-between p-mb-3">
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-refresh"
        class="p-button-outlined p-mr-2"
        pTooltip="Reload Page"
        label="Manual Page Reload"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('hscodesupload-filters')"
      ></button>
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
    </div>
    <div class="card">
      <p-radioButton
        name="memberIdType"
        value="HealthRules"
        label="Health Rules"
        [(ngModel)]="selectedValue"
      ></p-radioButton>
      &nbsp;
      <p-radioButton
        name="memberIdType"
        value="Amisys"
        label="Amisys"
        [(ngModel)]="selectedValue"
      ></p-radioButton>
    </div>
    <br />
    <div class="card">
      <p-radioButton
        name="templateYear"
        value="2021"
        label="2021 Template"
        [(ngModel)]="selectedYear"
      ></p-radioButton>
      &nbsp;
      <p-radioButton
        name="templateYear"
        value="2022"
        label="2022 Template"
        [(ngModel)]="selectedYear"
      ></p-radioButton>
      &nbsp;
      <p-radioButton
        name="templateYear"
        value="2023"
        label="2023 MAL Template"
        [(ngModel)]="selectedYear"
      ></p-radioButton>
    </div>
    <br />

    <div class="p-formgroup-inline"></div>

    <div class="card">
      <h3>Upload File</h3>
      <p-fileUpload
        name="memberhsupload[]"
        (uploadHandler)="onUpload($event)"
        [customUpload]="customUpload"
        [multiple]="multiple"
        [fileLimit]="fileLimit"
        [accept]="accept"
        [maxFileSize]="maxFileSize"
        [disabled]="!userHasEdit()"
      >
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
          </ul>
        </ng-template>
      </p-fileUpload>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 2rem" [ngStyle]="{ width: '150px' }">
        <div class="p-d-flex p-jc-between p-ai-center">Analysis Log</div>
      </th>
      <!-- <th></th> -->
      <th pSortableColumn="fileName" [ngStyle]="{ width: '300px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          File Name
          <p-sortIcon field="fileName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="status" [ngStyle]="{ width: '150px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Status
          <p-sortIcon field="status"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="RecordsProcessed" [ngStyle]="{ width: '200px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Processed Records
          <p-sortIcon field="RecordsProcessed"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="errors" [ngStyle]="{ width: '150px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Errors
          <p-sortIcon field="errors"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="uploadDate">
        <div class="p-d-flex p-jc-between p-ai-center">
          Upload Date
          <p-sortIcon field="uploadDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="createdUser" [ngStyle]="{ width: '170px' }">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created User
          <p-sortIcon field="createdUser"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr>
      <th [ngStyle]="{ width: '50px !important' }">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <!-- <th></th> -->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="fileName"
            [showClearButton]="false"
            [ngStyle]="{ width: '250px' }"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="status"
            [showClearButton]="false"
            [ngStyle]="{ width: '150px' }"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="RecordsProcessed"
            [showClearButton]="false"
            [ngStyle]="{ width: '200px' }"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="errors"
            [showClearButton]="false"
            [ngStyle]="{ width: '150px' }"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            field="uploadDate"
            display="menu"
            matchMode="dateBefore"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="createdUser"
            [showClearButton]="false"
            [ngStyle]="{ width: '150px' }"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-upload let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="upload"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>

      <td>
        <button
          pButton
          type="button"
          class="p-button-raised p-button-sm p-button-outlined"
          pTooltip="Download file"
          tooltipPosition="right"
          (click)="downLoadFileDataFromStream(upload.fileName)"
          [disabled]="!userHasEdit()"
        >
          {{ upload.fileName }} &nbsp;<i class="fa fa-download"></i>
        </button>
      </td>
      <td>
        <div [ngClass]="{ failed: upload.status == 'Failed' }">
          {{ upload.status }}
        </div>
      </td>
      <td>{{ upload.recordsProcessed }}</td>

      <td>
        <span style="color: darkorange">{{
          upload.errors.length != null ? upload.errors.length : 0
        }}</span>
      </td>

      <td>
        <time [attr.datetime]="upload.uploadDate">{{
          upload?.uploadDate | date : 'MM/dd/yyyy, h:mm:ss a'
        }}</time>
      </td>
      <td>{{ upload.createdUser }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-upload>
    <tr style="background: ghostwhite">
      <td colspan="8">
        <div class="p-p-3">
          <p-table [value]="upload.errors" dataKey="id" sortField="errorAtRow" [sortOrder]="1">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="errorAtSheetName">
                  Sheet Name <p-sortIcon field="errorAtSheetName"></p-sortIcon>
                </th>
                <th pSortableColumn="errorAtRow">
                  Sheet Row ID<p-sortIcon field="errorAtRow"></p-sortIcon>
                </th>
                <th pSortableColumn="errorMemberId">
                  PMI Id <p-sortIcon field="pmiId"></p-sortIcon>
                </th>
                <th pSortableColumn="errorMemberId">
                  Member Id <p-sortIcon field="errorMemberId"></p-sortIcon>
                </th>
                <th pSortableColumn="memberStatusFlag">
                  Member <p-sortIcon field="memberStatusFlag"></p-sortIcon>
                </th>
                <th pSortableColumn="errorHSCode">
                  HS Code <p-sortIcon field="errorHSCode"></p-sortIcon>
                </th>

                <th pSortableColumn="errorAssessmentDate">
                  Assessment <p-sortIcon field="errorAssessmentDate"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-error>
              <tr>
                <td>{{ error.errorAtSheetName }}</td>
                <td>{{ error.errorAtRow }}</td>
                <td
                  *ngIf="
                    error.memberStatusFlag == 'Valid' &&
                      (error.errorHSCode == 'Termed' || error.errorAssessmentDate == 'Unknown');
                    else noLink
                  "
                >
                  <div class="p-button p-button-link" [routerLink]="['/members/', error.pmiId]">
                    {{ error.pmiId }}
                  </div>
                </td>
                <ng-template #noLink
                  ><td>{{ '--------------------' }}</td></ng-template
                >
                <td>{{ error.errorMemberId }}</td>
                <td>{{ error.memberStatusFlag }}</td>
                <td>{{ error.errorHSCode }}</td>
                <td>{{ error.errorAssessmentDate }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">There are no records yet.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
