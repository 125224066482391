<p-table
  #dt
  [value]="rule!"
  [loading]="loadingTable"
  dataKey="id"
  stateStorage="local"
  stateKey="rules-filters"
  [paginator]="true"
  [showPageLinks]="false"
  [showFirstLastIcon]="true"
  [rowsPerPageOptions]="pagingSizes"
  [rows]="rows"
  [totalRecords]="totalRules"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  styleClass="p-datatable-gridlines p-datatable-striped"
>
  <ng-template pTemplate="caption">
    <h2>Alt Prov Rule History</h2>
    <div class="p-d-flex p-jc-between">
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('rules-filters')"
      ></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="table-header">
    <tr>
      <!-- Expansion Chevron-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th pSortableColumn="ruleNumber">
        <span>
          <span title="Rule ID">Rule ID</span>
          <p-sortIcon field="ruleNumber"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="name">
        <span>
          <span title="Rule Name">Rule Name</span>
          <p-sortIcon field="name"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="priority">
        <span>
          <span title="Priority">Priority</span>
          <p-sortIcon field="priority"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="altProv">
        <span>
          <span title="Alternate Provider">Alt Prov</span>
          <p-sortIcon field="altProv"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="products">
        <span>
          <span title="Product Types">Products</span>
          <p-sortIcon field="products"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="effectiveStartDate">
        <span>
          <span title="Effective Start Date">Effective Date</span>
          <p-sortIcon field="effectiveStartDate"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="effectiveEndDate">
        <span>
          <span title="Effective End Date">End Date</span>
          <p-sortIcon field="effectiveEndDate"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="active">
        <span>
          <span title="Active">Active</span>
          <p-sortIcon field="active"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="modifiedUser">
        <span>
          <span title="Modified User">Modified User</span>
          <p-sortIcon field="modifiedUser"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="updatedDate">
        <span>
          <span title="Modified Date">Modified Date</span>
          <p-sortIcon field="updatedDate"></p-sortIcon>
        </span>
      </th>
    </tr>
    <tr>
      <th>
        <!-- Expand -->
      </th>
      <th>
        <p-columnFilter type="text" field="ruleNumber"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="name"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="numeric" field="priority" [maxFractionDigits]="0"></p-columnFilter>
      </th>
      <th [ngStyle]="{ width: '150px' }">
        <p-columnFilter field="altProv.alternateProviderName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="altProvs"
              (onChange)="filter($event.value, 'altProv', 'in')"
              placeholder="Any"
              appendTo="body"
              styleClass="{ width: '200px' }"
              [virtualScroll]="true"
              [itemSize]="30"
            >
              <ng-template let-option pTemplate="item"
                ><span [ngStyle]="{ width: '150px' }">{{ option }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th [ngStyle]="{ width: '250px' }">
        <p-columnFilter field="products" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="allProducts"
              (onChange)="filter($event.value, 'products', 'in')"
              placeholder="Any"
              appendTo="body"
              styleClass="{ width: '200px' }"
              [virtualScroll]="true"
              [itemSize]="30"
            >
              <ng-template let-option pTemplate="item"
                ><span [ngStyle]="{ width: '150px' }">{{ option }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="effectiveStartDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="effectiveEndDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="active" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="[
                { label: 'Active', value: true },
                { label: 'Inactive', value: false }
              ]"
              (onChange)="filter($event?.value, 'active', 'in')"
              placeholder="Any"
              appendTo="body"
            >
              <ng-template let-option pTemplate="item"
                ><span class="p-m1-1">{{ option.label }}</span></ng-template
              >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="modifiedUser"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="updatedDate" display="menu" matchMode="dateBefore"></p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rule let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          [pTooltip]="expanded ? 'Collapse Summary' : 'View Summary'"
          tooltipPosition="bottom"
          [pRowToggler]="rule"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          style="height: 100%; width: 100%"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
        ></button>
      </td>
      <td>{{ rule.ruleNumber }}</td>
      <td>{{ rule.name }}</td>
      <td>{{ rule.priority }}</td>
      <td>{{ rule.altProv.alternateProviderName }}</td>
      <td style="white-space: pre">
        {{ getFormattedProducts(rule.products) }}
      </td>
      <td>
        <time [attr.datetime]="rule.effectiveStartDate">{{
          rule.effectiveStartDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        <time [attr.datetime]="rule.effectiveEndDate">{{
          rule.effectiveEndDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>
        {{ rule.active ? 'Active' : 'Inactive' }}
      </td>
      <td>{{ rule.modifiedUser }}</td>
      <td>
        <time [attr.datetime]="rule.updatedDate">{{
          rule.updatedDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody" let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        <div>Loading</div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="10">There are no history to view.</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-rule>
    <tr>
      <td colspan="1" class="row-expansion-offset"></td>
      <td colspan="8">
        <div>
          <p-table [value]="rule.criteria" dataKey="id" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                <th>Criteria</th>
                <th>Operator</th>
                <th>Values</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-criteria>
              <tr>
                <td>
                  <span class="detail-values">{{ criteria.criteriaName }}</span>
                </td>
                <td>
                  <span class="detail-values">{{ criteria.operator }}</span>
                </td>
                <td>
                  <span class="detail-values">{{ getCriteriaValues(criteria) }}</span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="3">This rule is empty.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
