import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DateValidator } from '../../../shared/validators/date-validator/date.validator';
import { Product } from '../../models/product';
import { ProductViewModel } from '../../models/product-view-model';
import { ProductsService } from '../../services/products.service';
import { MutualExclusiveList } from '../../models/mutual-exclusive';
import { isEmpty, isArrayEmpty } from '@shared/validators/isEmpty';
import { formatDateString } from '@shared/util/date-formatter';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-products-add',
  templateUrl: './products-add.component.html',
  styleUrls: ['./products-add.component.scss']
})
export class ProductsAddComponent implements OnInit {
  inactiveCheck = true;
  isActive = true;
  mutualExclusivesList: any = [];
  matControlProducts: MutualExclusiveList[] = [];
  selectedControls: any[] = [];

  @Input() visible = false;
  @Input() myProductData?: Product;
  @Input() products!: Product[];
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<Product>();

  loading = false;
  productFormGroup!: FormGroup;
  errorMessage = '';
  private _seed = 100;
  get seed(): number {
    return this._seed++;
  }

  get productName() {
    return this.productFormGroup.get('productName') as FormControl;
  }
  get active() {
    return this.productFormGroup.get('active') as FormControl;
  }
  get mutualExclusives() {
    return this.productFormGroup.get('mutualExclusives') as FormControl;
  }
  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(private readonly fb: FormBuilder, private productsService: ProductsService, private authService: MsalService) {}

  ngOnInit(): void {
    this.productFormGroup = this.fb.group(
      {
        id: '',
        productName: ['', [Validators.required]],
        active: '',
        createdDate: '',
        createdUser: '',
        modifiedDate: '',
        modifiedUser: '',
        mutualExclusives: []
      },
      <AbstractControlOptions>{
        validator: DateValidator.greaterThan
      }
    );

    this.buildMatMultiSelectControlProducts();

    if (!(this.myProductData?.id === '0')) {
      this.copyProduct();
    } else this.ClearForm();
  }

  buildMatMultiSelectControlProducts() {
    this.mutualExclusivesList = this.myProductData?.mutualExclusives;
    let matched: boolean;
    this.products.forEach((product: Product) => {
      matched = false;
      this.mutualExclusivesList?.forEach((exclusionProductItem: string) => {
        if (
          !isEmpty(product.productName) &&
          !isEmpty(exclusionProductItem) &&
          product?.productName?.toUpperCase() === exclusionProductItem.toUpperCase()
        ) {
          matched = true;

          this.matControlProducts.push({
            label: exclusionProductItem.trim(),
            value: exclusionProductItem.trim(),
            selected: true
          });
        }
      });
      if (!matched) {
        this.matControlProducts.push({
          label: product.productName?.trim(),
          value: product.productName?.trim(),
          selected: false
        });
      }
    });
  }

  private adjustDate() {
    return 'T12:34:56.7890123Z';
  }

  onSubmit() {
    if (this.productFormGroup.valid) {
      if (!this.productFormGroup.get('id')?.value) this.insertProduct(this.productFormGroup.value);
      else this.updateProduct(this.productFormGroup.value);
    }
  }

  insertProduct(product: ProductViewModel) {
    product.active = this.isActive;
    const newProduct: Product = {
      productName: product.productName?.trim(),
      active: product.active,
      modifiedDate: product.modifiedDate != null ? product.modifiedDate : '',
      modifiedUser: !isEmpty(product.modifiedUser) ? product.modifiedUser : this.userName,
      createdDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      createdUser: !isEmpty(product.createdUser) ? product.createdUser : this.userName,
      mutualExclusives: !isArrayEmpty(product.mutualExclusives) ? product.mutualExclusives : []
    };

    this.productsService.createProduct(newProduct).subscribe();

    this.ClearForm();
    this.saveEvent.emit(newProduct);
  }

  copyProduct() {
    this.isActive = this.myProductData ? this.myProductData.active : false;
    this.productFormGroup.patchValue({
      id: this.myProductData?.id,
      productName: this.myProductData?.productName,
      active: status,
      createdDate:
        this.myProductData?.createdDate != null
          ? formatDateString(this.myProductData.createdDate.toLocaleString()) + this.adjustDate()
          : null,
      createdUser: this.myProductData?.createdUser,
      modifiedDate:
        this.myProductData?.createdDate != null
          ? formatDateString(this.myProductData.createdDate.toLocaleString())
          : null,
      modifiedUser: this.myProductData?.modifiedUser,
      mutualExclusives: this.myProductData?.mutualExclusives
    });
  }

  updateProduct(product: ProductViewModel) {
    product.active = this.isActive;
    let updatedProduct: Product = {
      id: product.id,
      productName: product.productName?.trim(),
      active: product.active,
      modifiedDate: formatDateString(new Date().toISOString()) + this.adjustDate(),
      modifiedUser: !isEmpty(product.modifiedUser) ? product.modifiedUser : this.userName,
      createdDate: product.createdDate,
      createdUser: product.createdUser,
      mutualExclusives: product.mutualExclusives
    };
    this.productsService.upsertProduct(updatedProduct).subscribe();

    this.ClearForm();
    this.saveEvent.emit(updatedProduct);
  }

  deleteProduct(id: string) {
    this.productsService.deleteProduct(id);
  }

  initializeFormGroup() {
    this.productFormGroup.setValue({
      id: '',
      productName: '',
      active: '',
      createdDate: '',
      createdUser: '',
      modifiedDate: '',
      modifiedUser: '',
      mutualExclusives: []
    });
  }

  ClearForm() {
    this.productFormGroup.markAsUntouched();
    this.productFormGroup.reset();
    this.initializeFormGroup();
  }

  cancelModal() {
    this.ClearForm();
    this.cancelEvent.emit(this.visible);
  }

  toggleActiveCheckbox($event: { checked: boolean }) {
    if ($event.checked === true) this.isActive = true;
    else this.isActive = false;
  }
}
