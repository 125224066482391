import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { VarianceReportDetail } from '@reports/models/variance-report-detail';
import { RosterVarianceService } from '@reports/services/roster-variance.service';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';

import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VarianceReportDetailResolver implements Resolve<Array<VarianceReportDetail>> {
  constructor(private rosterVarianceService: RosterVarianceService, private router: Router) {}

  varianceReportDetailList: Array<VarianceReportDetail> = [];

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Array<VarianceReportDetail>> {
    const fromID = route.params.id1 as string;
    const toID = route.params.id2 as string;
    const altProv = route.params.id3 as string;
    const product = route.params.id4 as string;

    let newEvent: LazyLoadEvent = {
      first: 0,
      rows: 80000,
      filters: {
        compareFromID: { matchMode: 'Equals', value: fromID },
        compareToID: { matchMode: 'Equals', value: toID },
        altProv: { matchMode: 'Is', value: altProv },
        product: { matchMode: 'Is', value: product }
      }
    };

    const result = this.rosterVarianceService
      .getAllRosterVarianceDetail(newEvent, null)
      .pipe(tap(result => this.handleNavigation(result)))
      .toPromise();

    return await result;
  }

  private handleNavigation(result: Array<VarianceReportDetail> | null): void {
    if (!result) {
      // Pop up an error too...
      this.router.navigate(['/rosterVarianceReportList']);
    }
  }
}
