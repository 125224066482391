import { ChangeDetectorRef, Component, OnInit, Self, ViewChild } from '@angular/core';
import { County } from '../../models/county';
import { CountiesService } from '../../services/counties.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CountyViewModel } from '../../models/county-view-model';
import { Table } from 'primeng/table';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-counties-list',
  templateUrl: './counties-list.component.html',
  styleUrls: ['./counties-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class CountiesListComponent implements OnInit {
  counties!: County[];

  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  @ViewChild('dt1') dataTable!: Table;

  selectedCountyData?: County;

  cols: any[] = [];
  allActiveStatuses = ['true', 'false'];
  allStatuses = this.allActiveStatuses.map(status => {
    return { label: status, value: status };
  });

  constructor(
    private countiesService: CountiesService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private cdRef: ChangeDetectorRef,
    private roleCheck: RoleCheck
  ) {}

  userHasEdit(){
    return this.roleCheck.userHasRole(['AltProvAdmin','AltProvListMaintenanceEdit']);
  }
  
  ngOnInit(): void {
    this.countiesService.refreshNeeded$.subscribe(() => {
      this.getAllCounties();
    });

    this.getAllCounties();
  }

  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  getAllCounties() {
    this.loadingTable = true;
    this.countiesService.getAllCounties().subscribe({
      next: counties => {
        this.counties = counties;
        this.counties.forEach(county => {
          county.createdDate = new Date(county.createdDate!);
          county.modifiedDate = new Date(county.modifiedDate!);
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  editCounty(countyData: CountyViewModel) {
    const editCounty: County = {
      id: countyData.id,
      countyFIPSCode: countyData.countyFIPSCode,
      countyCode: countyData.countyCode,
      countyName: countyData.countyName,
      state: countyData.state,
      riskPop: countyData.riskPop,
      forPCC: countyData.forPCC,
      forMember: countyData.forMember,
      active: countyData.active,
      createdUser: countyData.createdUser,
      createdDate: countyData.createdDate,
      modifiedDate: countyData.modifiedDate,
      modifiedUser: countyData.modifiedUser
    };

    this.selectedCountyData = { ...editCounty };
    this.displayEditDialog = true;
  }

  updateCounty(countyData: County) {
    this.displayEditDialog = false;
    this.getAllCounties();
    this.cdRef.detectChanges();
  }

  onSaveComplete(id: string): void {
    console.log('deleted id: ', id);
    this.counties = this.counties.filter(hc => hc.id !== id);
  }

  private dateformat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
    //hour: "2-digit",
    //minute: "2-digit",
    //second: "2-digit"
  };

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
  }
}
