<div class="header-container" mat-dialog-title>Add/Edit Health Status Code</div>
<mat-dialog-content>
  <form
    [formGroup]="healthStatusCodeFormGroup"
    (ngSubmit)="onSubmit()"
    class="health-status-code-dialog-form"
  >
    <div class="health-status-codes-attributes">
      <span class="first-row">
        <mat-form-field class="health-status-code" appearance="outline">
          <mat-label>Code</mat-label>
          <input matInput type="text" formControlName="healthStatusCode" />
          <mat-error *ngIf="healthStatusCode?.errors?.required">
            Health Status Code cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field class="health-status-code-description" appearance="outline">
          <mat-label>Description</mat-label>
          <input matInput type="text" formControlName="healthStatusDescription" />
          <mat-error *ngIf="healthStatusDescription?.errors?.required">
            Description cannot be blank
          </mat-error>
        </mat-form-field>
      </span>
      <span class="second-row">
        <mat-form-field appearance="outline" class="assessment-status">
          <mat-label>Assessment Status</mat-label>
          <mat-select formControlName="assessmentStatus">
            <mat-option *ngFor="let opt of this.assessmentStatuses" [value]="opt">{{
              opt
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="assessmentStatus?.errors?.required">
            Assessment Status is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="interpretation-need-level">
          <mat-label>Interpretation Need Level</mat-label>
          <mat-select formControlName="interpretationNeedLevel">
            <mat-option *ngFor="let opt of this.interpretationNeedLevels" [value]="opt">{{
              opt
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="interpretationNeedLevel?.errors?.required">
            Interpretation Need Level is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="interpretation-need-type">
          <mat-label>Interpretation Need Type</mat-label>
          <mat-select formControlName="interpretationNeedType">
            <mat-option *ngFor="let opt of this.interpretationNeedTypes" [value]="opt">{{
              opt
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="interpretationNeedType?.errors?.required">
            Interpretation Need Type is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="observation-status-reason">
          <mat-label>Observation Status Reason</mat-label>
          <mat-select formControlName="observationStatusReason">
            <mat-option *ngFor="let opt of this.observationStatusReasons" [value]="opt">{{
              opt
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="observationStatusReason?.errors?.required">
            Observation Status Reason is required
          </mat-error>
        </mat-form-field>
      </span>
      <span class="third-row">
        <mat-checkbox
          [checked]="isValidConnect.value"
          formControlName="isValidConnect"
          class="is-valid-connect"
          >Is Valid For Connect</mat-checkbox
        >
        <mat-checkbox
          [checked]="isValidConnectPlus.value"
          formControlName="isValidConnectPlus"
          class="is-valid-connect-plus"
          >Is Valid For Connect Plus</mat-checkbox
        >
        <mat-error
          *ngIf="
            !isValidConnectPlus.value &&
            !isValidConnect.value &&
            (isValidConnect.touched || isValidConnectPlus.touched || showCheckboxError)
          "
        >
          Please select a checkbox
        </mat-error>
      </span>
      <span class="fourth-row">
        <mat-form-field [style.width.px]="170" appearance="outline">
          <mat-label>Period Begin Date</mat-label>
          <input matInput type="date" formControlName="periodBeginDate" />
          <mat-error *ngIf="periodBeginDate?.errors?.required">
            Start Date cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field [style.width.px]="170" appearance="outline">
          <mat-label>Period Begin Time</mat-label>
          <input matInput type="time" formControlName="periodBeginTime" />
          <mat-error *ngIf="periodBeginTime?.errors?.required">
            Start Time cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field [style.width.px]="170" appearance="outline">
          <mat-label>Period End Date</mat-label>
          <input matInput type="date" formControlName="periodEndDate" />
          <mat-error
            *ngIf="
              healthStatusCodeFormGroup.errors?.notGreater &&
              (healthStatusCodeFormGroup.touched || healthStatusCodeFormGroup.dirty)
            "
          >
            End Date must take place after Start Date
          </mat-error>
          <mat-error *ngIf="periodEndDate?.errors?.required"> End Date cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field [style.width.px]="170" appearance="outline">
          <mat-label>Period End Time</mat-label>
          <input matInput type="time" formControlName="periodEndTime" />
          <mat-error *ngIf="periodEndTime?.errors?.required"> End Time cannot be blank </mat-error>
        </mat-form-field>
        <input type="hidden" formControlName="id" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="createdUser" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="modifiedUser" />
      </span>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button mat-raised-button (click)="closeDialog()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="loading">Save</button>
</mat-dialog-actions>
