<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <form [formGroup]="pccExceptionFormGroup" (ngSubmit)="onSubmit()" class="pccException-entry-form">
    <fieldset>
      <div class="pcc-attributes-container">
        <mat-form-field class="pcc-input-nopick" appearance="outline">
          <mat-label>Exception ID</mat-label>
          <input matInput type="text" formControlName="pccExceptionNumber" readonly />
          <mat-error *ngIf="pccExceptionNumber?.errors?.required">
            PCC Exception ID cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>AltProvider</mat-label>
          <mat-select color="primary" formControlName="altProv" [compareWith]="compareProperty">
            <mat-option *ngFor="let ap of alternateProviders" [value]="ap">{{
              ap.alternateProviderName
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="altProv?.errors?.required"> AltProvider cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Product</mat-label>
          <mat-select color="primary" formControlName="product" name="type">
            <mat-option *ngFor="let product of products" [value]="product">{{
              product
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="product?.errors?.required"> Must choose a product </mat-error>
        </mat-form-field>
      </div>
    </fieldset>
    <fieldset>
      <div class="pcc-attributes-container">
        <mat-form-field appearance="outline">
          <mat-label>Effective Start Date</mat-label>
          <input
            matInput
            type="date"
            formControlName="effectiveStartDate"
            min="{{ minDate | date: 'yyyy-MM-dd' }}"
            max="{{ maxDate | date: 'yyyy-MM-dd' }}"
          />
          <mat-error *ngIf="effectiveStartDate?.errors?.required">
            Start Date cannot be blank
          </mat-error>
          <mat-error *ngIf="effectiveStartDate?.errors?.startNextMonth">
            Start Date cannot begin before the current month.</mat-error
          >
          <mat-error *ngIf="effectiveStartDate?.errors?.startDay"
            >Start Day cannot begin before the current day.</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Effective Start Time</mat-label>
          <input matInput type="time" formControlName="effectiveStartTime" />
          <mat-error *ngIf="effectiveStartTime?.errors?.required">
            Start Time cannot be blank
          </mat-error>
          <mat-error *ngIf="effectiveStartTime?.errors?.nameStartNextTimeStamp">
            Start Time cannot before the current time
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Effective End Date</mat-label>
          <input matInput type="date" formControlName="effectiveEndDate" />
          <mat-error
            *ngIf="
              pccExceptionFormGroup.errors?.notGreater &&
              (pccExceptionFormGroup.touched || pccExceptionFormGroup.dirty)
            "
          >
            End Date must take place after Start Date
          </mat-error>
          <mat-error *ngIf="effectiveEndDate?.errors?.required">
            End Date cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Effective End Time</mat-label>
          <input matInput type="time" formControlName="effectiveEndTime" />
          <mat-error *ngIf="effectiveEndTime?.errors?.required">
            End Time cannot be blank
          </mat-error>
        </mat-form-field>
      </div>
    </fieldset>
    <fieldset>
      <legend>
        <h3>PCC Assignment</h3>
      </legend>
      <div class="pcc-attributes-container">
        <mat-form-field class="pcc-input-nopick" appearance="outline">
          <mat-label>PCC</mat-label>
          <input matInput type="text" formControlName="pccId" readonly />
          <mat-error *ngIf="pccId?.errors?.required"> PCC ID cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="mat-form-field-pccName pcc-input-nopick"
          [style.width.px]="600"
        >
          <mat-label>PCC Name</mat-label>
          <input matInput formControlName="pccName" readonly />
        </mat-form-field>
        <mat-icon class="pccTooltip" matTooltip="Please use the button below to choose a PCC"
          >question_answer
        </mat-icon>
        <div class="primary-row">
          <button type="button" mat-raised-button color="primary" (click)="openPCCPicker()">
            Select a PCC
          </button>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <div>
        <mat-form-field appearance="standard" class="example-full-width">
          <mat-label>Note</mat-label>
          <textarea
            matInput
            formControlName="note"
            matAutosizeMinRows="5"
            [maxLength]="1000"
          ></textarea>
        </mat-form-field>
      </div>
    </fieldset>
    <div class="form-action-buttons">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="!userHasEdit()">
        Save
      </button>
    </div>
  </form>
</div>
