import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { CriterionOperators } from '@rules/models/criterion-operator';
import { RuleCriterion } from '@rules/models/rule-criterion';
import { MatDialog } from '@angular/material/dialog';
import { RuleValueSelectionDialogComponent } from '@rules/components/rule-value-selection-dialog/rule-value-selection-dialog.component';
import { CriterionValue } from '@rules/models/criterion-value';
import { Product } from '@shared/models/product';
import { RulesService } from '@rules/services/rules-service/rules.service';
import { MatOptionSelectionChange } from '@angular/material/core/option';

interface DisplayTraits {
  isRendered: boolean;
  isEnabled: boolean;
}

@Component({
  selector: 'app-rule-criteria-selector',
  templateUrl: './rule-criteria-selector.component.html',
  styleUrls: ['./rule-criteria-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RuleCriteriaSelectorComponent),
      multi: true
    }
  ]
})
export class RuleCriteriaSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  readonly criteriaChoices: Map<string, DisplayTraits> = new Map();
  readonly renderTraits: Map<string, boolean> = new Map();
  readonly enabledTraits: Map<string, boolean> = new Map();
  readonly operators = CriterionOperators;

  formGroup!: FormGroup;
  visible = true;
  showCriterionErrorMessage = false;
  showCriterionRequiredMessage = false;

  @Input() products = new Set<Product>();

  private _touched = false;
  private _dirty = false;
  private _ruleCriteria: RuleCriterion[] = [];
  private _criteriaDefinitions: Map<string, Array<Product>> = new Map();
  private onChangeFunction: (rules: RuleCriterion[]) => void = rule => {};
  private onTouchedFunction: () => void = () => {};

  get ruleCriteria(): Array<RuleCriterion> {
    return this._ruleCriteria;
  }
  set ruleCriteria(ruleCriteria: RuleCriterion[]) {
    this._ruleCriteria = ruleCriteria;

    this.onRulesChange(ruleCriteria);
  }

  get touched(): boolean {
    return this._touched;
  }

  get dirty(): boolean {
    return this._dirty;
  }

  constructor(private readonly dialog: MatDialog, private readonly rulesService: RulesService) {}

  async ngOnInit(): Promise<void> {
    // Get definitive list of all criteria business logic
    this._criteriaDefinitions = this.rulesService.getAllCriteriaDefinitions();

    this._criteriaDefinitions.forEach((value, key) => {
      const isDisabled = this._ruleCriteria.map(rule => rule.criteriaName).includes(key);
      const isRendered = [...this.products].some(product => value.includes(product));

      this.criteriaChoices.set(key, {
        isRendered,
        isEnabled: !isDisabled
      } as DisplayTraits);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const productsChanges = changes.products;
    const previousValue = productsChanges.previousValue || new Set();
    const newValue = productsChanges.currentValue;

    if (productsChanges.firstChange) {
      this.onAddProducts(newValue);
      return;
    }

    const addedItems = new Set([...newValue].filter(x => !previousValue.has(x)));
    const removedItems = new Set([...previousValue].filter(x => !newValue.has(x)));

    if (addedItems.size > 0) {
      this.onAddProducts(addedItems);
    } else if (removedItems.size > 0) {
      this.onRemoveProducts(removedItems);
    }
  }

  validate(): boolean {
    return (
      this.formGroup.valid &&
      this.ruleCriteria.length > 0 &&
      this.ruleCriteria.every(x => x.values.length > 0)
    );
  }

  writeValue(obj: any): void {
    const val = obj as RuleCriterion[];

    if (val) {
      this.ruleCriteria = val;

      const group: any = {};

      this.ruleCriteria.forEach((criterion, criterionIndex) => {
        const criterionTypeControl = new FormControl(criterion.criteriaName, [Validators.required]);
        const criterionOperatorControl = new FormControl(criterion.operator, [Validators.required]);
        const criterionValuesControl = new FormControl(criterion.values, [Validators.required]);

        group[criterionIndex] = new FormGroup({
          criterionTypeControl,
          criterionOperatorControl,
          criterionValuesControl
        });
      });

      this.formGroup = new FormGroup(group);
    }
  }

  registerOnChange(fn: (rules: RuleCriterion[]) => void): void {
    if (fn) {
      this.onChangeFunction = fn;
    }
  }

  registerOnTouched(fn: () => void): void {
    if (fn) {
      this.onTouchedFunction = fn;
    }
  }

  openDialog(index: number, criterion: RuleCriterion): void {
    const dialogReference = this.dialog.open(RuleValueSelectionDialogComponent, {
      data: { criterion, selectedProducts: this.products },
      width: '40vw',
      height: 'auto'
    });

    dialogReference.afterClosed().subscribe(result => {
      this.handleDialogClose(index, result);
    });
  }

  private handleDialogClose(index: number, dialogResult: CriterionValue[]): void {
    if (!dialogResult) {
      return;
    }

    this.ruleCriteria[index].values = dialogResult;
    this.formGroup.get(index.toString())?.get('criterionValuesControl')?.setValue(dialogResult);
    this.formGroup.get(index.toString())?.get('criterionValuesControl')?.markAsTouched();
  }

  addRuleCriterion(): void {
    if (!this.validate() && this.ruleCriteria.length > 0) {
      this.showCriterionErrorMessage = true;
      this.formGroup.markAllAsTouched();
      return;
    } else {
      this.showCriterionRequiredMessage = false;
      this.showCriterionErrorMessage = false;
    }
    const criterionToAdd = {
      criteriaName: '',
      operator: undefined,
      values: []
    } as RuleCriterion;
    this.ruleCriteria.push(criterionToAdd);

    const criterionTypeControl = new FormControl('', [Validators.required]);
    const criterionOperatorControl = new FormControl('', [Validators.required]);
    const criterionValuesControl = new FormControl([], [Validators.required]);

    this.formGroup.addControl(
      (this.ruleCriteria.length - 1).toString(),
      new FormGroup({
        criterionTypeControl,
        criterionOperatorControl,
        criterionValuesControl
      })
    );
  }

  deleteCriterion(criterionIndex: number): void {
    this.ruleCriteria.splice(criterionIndex, 1);

    // Remove form group control, then shift the rest
    this.formGroup.removeControl(criterionIndex.toString());

    Object.keys(this.formGroup.controls).forEach((key, index) => {
      if (index >= criterionIndex) {
        const nextFormGroup = this.formGroup.controls[key] as FormGroup;
        this.formGroup.addControl(index.toString(), nextFormGroup);
        this.formGroup.removeControl(key);
      }
    });

    if (this.ruleCriteria.length < 1) {
      this.showCriterionRequiredMessage = true;
    }

    this.formGroup.updateValueAndValidity();
    this.updateVisibility();
  }

  updateVisibility(): void {
    // Hack to make the PrimeNg table refresh
    this.visible = false;
    setTimeout(() => (this.visible = true), 0);
  }

  removeCriterionValue(
    criterionIndex: number,
    criterion: RuleCriterion,
    criterionValue: CriterionValue
  ): void {
    const valueIndex = criterion.values.indexOf(criterionValue);

    this.ruleCriteria[criterionIndex].values.splice(valueIndex, 1);
    this.formGroup
      .get(criterionIndex.toString())
      ?.get('criterionValuesControl')
      ?.patchValue(this.ruleCriteria[criterionIndex].values);
    this.formGroup.get(criterionIndex.toString())?.get('criterionValuesControl')?.markAsTouched();
  }

  onCriterionTypeChange(e: MatOptionSelectionChange, criterionIndex: number): void {
    if (
      !this.ruleCriteria[criterionIndex].criteriaName ||
      e.source.value === this.ruleCriteria[criterionIndex].criteriaName
    ) {
      return;
    }

    this.ruleCriteria[criterionIndex].values = [];
    this.formGroup.get(criterionIndex.toString())?.get('criterionValuesControl')?.patchValue([]);
    this.formGroup.get(criterionIndex.toString())?.get('criterionValuesControl')?.markAsTouched();
  }

  onTouched(): void {
    this._touched = true;

    this.onTouchedFunction();
  }

  private onRulesChange(rules: RuleCriterion[]): void {
    this._dirty = true;

    rules.forEach(this.updateRuleTraitsForRule, this);

    this.onChangeFunction(rules);
  }

  private updateRuleTraitsForRule(newRule: RuleCriterion): void {
    const newTraits: DisplayTraits = { isRendered: true, isEnabled: false };

    this.criteriaChoices.set(newRule.criteriaName, newTraits);
  }

  private onAddProducts(addedItems: Set<string>): void {
    for (const newProduct of addedItems) {
      const displayTraits: DisplayTraits = {
        isEnabled: true,
        isRendered: true
      };

      this.criteriaChoices.set(newProduct, displayTraits);
    }
  }

  private onRemoveProducts(removedItems: Set<string>): void {
    for (const removedProduct of removedItems) {
      const displayTraits: DisplayTraits = {
        isEnabled: true,
        isRendered: false
      };

      this.criteriaChoices.set(removedProduct, displayTraits);
    }
  }

  shouldDisableCriterion(criterionName: string): boolean {
    return (
      this.ruleCriteria.filter(x => x.criteriaName.toLowerCase() === criterionName.toLowerCase())
        .length >= 2
    );
  }

  shouldRenderCriterion(criterionName: string): boolean | undefined {
    const allowedProducts = this._criteriaDefinitions.get(criterionName);

    return allowedProducts?.some(prod => this.products.has(prod));
  }

  shouldDisableOperation(criterionName: string, operation: string): boolean {
    return (
      !criterionName ||
      this.ruleCriteria.filter(
        x =>
          x.criteriaName.toLowerCase() === criterionName.toLowerCase() && x.operator === operation
      ).length >= 1
    );
  }
}
