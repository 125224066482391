import { Component, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { DateValidator } from '../../../healthStatusCodes/validators/date.validator';
import { DateValidator } from '@shared/validators/date-validator/date.validator';
import { PccPickerComponent } from '@shared/components/pcc-picker/pcc-picker.component';
import { PccException } from '@pccExceptions/models/pcc-exception';
import { AlternateProvider } from '@shared/models/alternate-provider';
import { ProductService } from '@shared/services/product-service/product.service';
import { PccExceptionService } from '@pccExceptions/services/pcc-exception.service';
import { SpinnerOverlayService } from '@shared/services/spinner-overlay-service/spinner-overlay.service';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { BaseView } from '@shared/components/Base/base-view';
import { getDateStringAsUTC, getLocalDateFromUTCDateString } from '@shared/util/date-formatter';
import { DatePart } from '@shared/enums/date-part';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import * as productData from '../../../../../data/products.json';
import { MsalService } from '@azure/msal-angular';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-pcc-exception-entry',
  templateUrl: './pcc-exception-entry.component.html',
  styleUrls: ['./pcc-exception-entry.component.scss']
})
export class PccExceptionEntryComponent extends BaseView implements OnInit {
  private _id?: string = '';
  routeId = '';
  pccExceptionFormGroup!: FormGroup;
  errorMessage = '';
  productOptions = JSON.parse(JSON.stringify(productData));

  _mySelectedId!: string;
  get mySelectedId() {
    return this._mySelectedId;
  }

  set mySelectedId(value: string) {
    this._mySelectedId = value;
  }

  products: Array<string> = [];
  alternateProviders: AlternateProvider[] = [];

  get pccExceptionNumber(): AbstractControl {
    return this.pccExceptionFormGroup.get('pccExceptionNumber') as AbstractControl;
  }
  get altProv(): AbstractControl {
    return this.pccExceptionFormGroup.get('altProv') as AbstractControl;
  }
  get pccId(): AbstractControl {
    return this.pccExceptionFormGroup.get('pccId') as AbstractControl;
  }
  get pccName(): AbstractControl {
    return this.pccExceptionFormGroup.get('pccName') as AbstractControl;
  }
  get product(): AbstractControl {
    return this.pccExceptionFormGroup.get('product') as AbstractControl;
  }
  get note(): AbstractControl {
    return this.pccExceptionFormGroup.get('note') as AbstractControl;
  }
  get effectiveStartDate(): AbstractControl {
    return this.pccExceptionFormGroup.get('effectiveStartDate') as AbstractControl;
  }
  get effectiveStartTime(): AbstractControl {
    return this.pccExceptionFormGroup.get('effectiveStartTime') as AbstractControl;
  }
  get effectiveEndDate(): AbstractControl {
    return this.pccExceptionFormGroup.get('effectiveEndDate') as AbstractControl;
  }
  get effectiveEndTime(): AbstractControl {
    return this.pccExceptionFormGroup.get('effectiveEndTime') as AbstractControl;
  }
  get createdDate(): AbstractControl {
    return this.pccExceptionFormGroup.get('createdDate') as AbstractControl;
  }
  get createdUser(): AbstractControl {
    return this.pccExceptionFormGroup.get('createdUser') as AbstractControl;
  }
  get lastModifiedDate(): AbstractControl {
    return this.pccExceptionFormGroup.get('lastModifiedDate') as AbstractControl;
  }
  get lastModifiedUser(): AbstractControl {
    return this.pccExceptionFormGroup.get('lastModifiedUser') as AbstractControl;
  }
  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  minDate: any;
  maxDate: any;

  constructor(
    private dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly productService: ProductService,
    private readonly spinnerService: SpinnerOverlayService,
    private readonly altProviderService: AltProviderService,
    private readonly pccExceptionService: PccExceptionService,
    public dialogRef: MatDialogRef<PccExceptionEntryComponent>,
    private roleCheck: RoleCheck,
    private authService: MsalService,
    @Inject(MAT_DIALOG_DATA) private readonly data: any
  ) {
    super();

    this.routeId = this.route.snapshot.params.id;
    this.mySelectedId = this.data.pccExceptionId ?? this.routeId;

    const today = new Date().toISOString();

    this.pccExceptionFormGroup = this.fb.group(
      {
        id: '',
        pccExceptionNumber: '',
        pccId: ['', [Validators.required]],
        pccName: '',
        altProv: ['', [Validators.required]],
        product: ['', [Validators.required]],
        note: '',
        effectiveStartDate: [
          getLocalDateFromUTCDateString(today, DatePart.Date),
          Validators.compose([
            Validators.required,
            DateValidator.monthStartBetween,
            DateValidator.dayStartBetween
          ])
        ],
        effectiveStartTime: [
          getLocalDateFromUTCDateString(today, DatePart.Time),
          Validators.compose([Validators.required, DateValidator.startNextTimestamp])
        ],
        effectiveEndDate: ['9999-12-31', [Validators.required]],
        effectiveEndTime: ['00:00', [Validators.required]],
        createdDate: '',
        createdUser: '',
        lastModifiedDate: '',
        lastModifiedUser: ''
      },
      {
        validator: DateValidator.greaterThan
      } as AbstractControlOptions
    );
  }

  userHasEdit() {
    return this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvEditPCCExceptions']);
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.subs.sink = forkJoin([
      this.altProviderService.getAlternateProviders(),
      this.productService.getAllProducts()
    ])
      .pipe(
        tap(([allAltProviders, allProducts]) => {
          this.alternateProviders = allAltProviders
            .filter(x => x.active)
            .map(activeAltProv => ({
              alternateProviderIdentifier: activeAltProv.alternateProviderIdentifier,
              alternateProviderIdType: activeAltProv.alternateProviderIdType,
              alternateProviderNBR: activeAltProv.alternateProviderNBR,
              alternateProviderName: activeAltProv.alternateProviderName,
              periodBeginDate: activeAltProv.periodBeginDate,
              periodEndDate: activeAltProv.periodEndDate
            }));
          this.products = allProducts
            .filter(
              x =>
                x.active &&
                this.productOptions.default.some(
                  (y: any) => y.productName === x.productName && y.isValidForPCCException
                )
            )
            .map(y => y.productName ?? '')
            .sort();
        }),
        take(1)
      )
      .subscribe({
        error: err => {
          this.spinnerService.hide();
          this.errorMessage = err;
        },
        complete: () => {
          this.spinnerService.hide();
        }
      });

    if (this.mySelectedId) {
      this.getPccExceptionById(this.mySelectedId);
    }
  }

  getPccExceptionById(id: string): void {
    this.subs.sink = this.pccExceptionService.getPccExceptionById(id).subscribe({
      next: pccException => {
        this.setFormPccException(pccException);
        this.spinnerService.hide();
      },
      error: err => {
        this.spinnerService.hide();
        this.errorMessage = err;
      }
    });
  }

  ClearForm(): void {
    this.pccExceptionFormGroup.reset();
    this.pccExceptionFormGroup.markAsUntouched();
  }

  private setFormPccException(pccException: PccException): void {
    this._id = pccException.id!;
    this.pccExceptionNumber.setValue(pccException.pccExceptionNumber);
    this.altProv.setValue(pccException.altProv);
    this.pccId.setValue(pccException.pccId);
    this.pccName.setValue(pccException.pccName);
    this.product.setValue(pccException.product);
    this.effectiveStartDate.setValue(
      getLocalDateFromUTCDateString(
        pccException.effectiveStartDate!.toLocaleString(),
        DatePart.Date
      )
    );
    this.effectiveStartTime.setValue(
      getLocalDateFromUTCDateString(
        pccException.effectiveStartDate!.toLocaleString(),
        DatePart.Time
      )
    );
    this.effectiveEndDate.setValue(
      getLocalDateFromUTCDateString(pccException.effectiveEndDate!.toLocaleString(), DatePart.Date)
    );
    this.effectiveEndTime.setValue(
      getLocalDateFromUTCDateString(pccException.effectiveEndDate!.toLocaleString(), DatePart.Time)
    );
    this.note.setValue(pccException.note);
    this.createdDate.setValue(pccException.createdDate);
    this.createdUser.setValue(pccException.createdUser);
    this.lastModifiedDate.setValue(pccException.lastModifiedDate);
    this.lastModifiedUser.setValue(pccException.lastModifiedUser);
  }

  onSubmit(): void {
    if (this.pccExceptionFormGroup.valid) {
      this.upsertPccException();
    }
  }

  private getPccException(): PccException {
    const today = new Date().toISOString();
    let tempObj = {};

    if (this._id) {
      tempObj = {
        id: this._id,
        pccExceptionNumber: this.pccExceptionNumber.value,
        createdDate: this.createdDate.value,
        createdUser: this.createdUser.value,
        lastModifiedDate: today,
        lastModifiedUser: this.userName
      };
    } else {
      tempObj = {
        createdDate: today,
        createdUser: this.userName,
        lastModifiedDate: today,
        lastModifiedUser: this.userName
      };
    }

    return {
      ...tempObj,
      pccId: this.pccId.value,
      pccName: this.pccName.value,
      altProv: this.altProv.value,
      product: this.product.value,
      note: this.note.value,
      effectiveStartDate: getDateStringAsUTC(
        this.effectiveStartDate.value + 'T' + this.effectiveStartTime.value
      ),
      effectiveEndDate: getDateStringAsUTC(
        this.effectiveEndDate.value + 'T' + this.effectiveEndTime.value
      )
    } as PccException;
  }

  upsertPccException(): void {
    this.spinnerService.show();
    const thisSubs = this.pccExceptionService.upsertPccException(this.getPccException()).subscribe({
      next: () => {
        if (!this.routeId) {
          this.ClearForm();
          this.dialogRef.close();
        }

        this.spinnerService.hide();
        this.subs.sink = thisSubs;
      },
      error: err => {
        this.spinnerService.hide();
        this.errorMessage = err;
      }
    });
  }

  cancelModal(): void {
    this.ClearForm();
  }

  compareProperty(x: any, y: any): boolean {
    return x && y ? x.alternateProviderName === y.alternateProviderName : false;
  }

  openPCCPicker(): void {
    const dialogRef = this.dialog.open(PccPickerComponent, {
      autoFocus: true,
      minHeight: '450px',
      minWidth: 600,
      data: { selectedPmiId: '', selectedProduct: '', termed: false }
    });
    this.subs.sink = dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.pccExceptionFormGroup.patchValue({
          pccId: result.pccId,
          pccName: result.pccName
        });
      }
    });
  }
}
