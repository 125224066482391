import { OnDestroy, Component, OnInit, Self, ViewChild } from '@angular/core';
import { PCC } from '@shared/models/pcc';
import { PccService } from '@shared/services/pcc-service/pcc.service';
import { Table } from 'primeng/table';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-pcc-maintenance',
  templateUrl: './pcc-maintenance.component.html',
  styleUrls: ['./pcc-maintenance.component.scss'],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class PccMaintenanceComponent implements OnInit, OnDestroy {
  pagingSizes = [20, 40, 60, 80, 100];

  loadingTable = true;
  pccs!: PCC[];
  cols: any[];
  totalPCCs = 0;
  selectedPCC!: PCC;
  isEditMode: boolean = false;
  displayEditDialog = false;
  @ViewChild('dt') table!: Table;

  constructor(
    private pccService: PccService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private roleCheck: RoleCheck
  ) {
    this.cols = [
      { propertyName: 'pccId', shortName: 'ID', longName: 'Provider ID', type: 'text' },
      { propertyName: 'pccName', shortName: 'Name', longName: 'PCC Name', type: 'text' },
      {
        propertyName: 'product',
        shortName: 'Product',
        longName: 'Product',
        type: 'text'
      },
      {
        propertyName: 'pccDescription',
        shortName: 'Care System',
        longName: 'Care System',
        type: 'text'
      },
      { propertyName: 'pccCode', shortName: 'Code', longName: 'Code', type: 'text' },
      {
        propertyName: 'siteSpecialty',
        shortName: 'Specialty',
        longName: 'Specialty',
        type: 'text'
      },
      { propertyName: 'county', shortName: 'County', longName: 'County', type: 'text' },
      { propertyName: 'taxId', shortName: 'Tax ID', longName: 'Tax ID', type: 'text' },
      { propertyName: 'pmiId', shortName: 'Site Addr ID', longName: 'Site Addr ID', type: 'text' },
      {
        propertyName: 'effectiveDate',
        shortName: 'Effective Date',
        longName: 'Effective Date',
        type: 'date'
      },
      {
        propertyName: 'termDate',
        shortName: 'Term Date',
        longName: 'Term Date',
        type: 'date'
      },
      {
        propertyName: 'pccExclusion',
        shortName: 'Exclusion Flag',
        longName: 'PCC Exclusion Flag',
        type: 'boolean'
      }
    ];
  }

  ngOnInit(): void {
    this.loadPCCs();
  }

  ngOnDestroy(): void {}

  loadPCCs() {
    this.loadingTable = true;

    this.pccService.getPCCList().subscribe(response => {
      this.pccs = response;
      this.pccs.forEach(pcc => {
        pcc.termDate = pcc.termDate != null ? new Date(pcc.termDate) : null;
        pcc.effectiveDate = pcc.effectiveDate != null ? new Date(pcc.effectiveDate) : null;
      });
      this.loadingTable = false;
    });
  }

  userHasEdit() {
    return this.roleCheck.userHasRole(['AltProvAdmin', 'AltProvEditPCCExceptions']);
  }

  editClinicDetails(pcc: PCC) {
    const editPCC: PCC = {
      pccId: pcc.pccId,
      pccName: pcc.pccName,
      product: pcc.product,
      pccDescription: pcc.pccDescription,
      pccCode: pcc.pccCode,
      careSystemDescription: pcc.careSystemDescription,
      careSystemCode: pcc.careSystemCode,
      siteSpecialty: pcc.siteSpecialty,
      county: pcc.county,
      taxId: pcc.taxId,
      pmiId: pcc.pmiId,
      termDate: pcc.termDate,
      effectiveDate: pcc.effectiveDate,
      pccExclusion: pcc.pccExclusion
    };
    this.selectedPCC = { ...editPCC };
    this.isEditMode = true;
    this.displayEditDialog = true;
  }

  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  updatePCC(selectedPCC: PCC) {
    this.displayEditDialog = false;
    this.loadPCCs();
  }

  public clearFilter(storageKey: string): void {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
