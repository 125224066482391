// tslint:disable: no-angle-bracket-type-assertion
// tslint:disable: whitespace

import { Injectable } from '@angular/core';
import { Rule } from '@rules/models/rule';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { Product } from '@shared/models/product';
import * as criteriaDefinitions from '../../../../../data/ruleCriteria.json';
import * as contractIds from '../../../../../data/contractIds.json';
import { CriterionDefinition } from '@rules/models/criterion-definition';
import { CriterionValue } from '@rules/models/criterion-value';
import { ChangeReason } from '@rules/models/change-reason';

@Injectable({
  providedIn: 'root'
})
export class RulesService {
  private readonly getRulesEndpoint: string;
  private readonly getRuleByIdEndpoint: string;
  private readonly getChangeReasonsEndpoint: string;
  private readonly saveRuleEndpoint: string;

  constructor(private readonly http: HttpClient) {
    this.getRulesEndpoint = environment.apiUrls.getRules;
    this.getRuleByIdEndpoint = environment.apiUrls.getRuleById;
    this.getChangeReasonsEndpoint = environment.apiUrls.getChangeReasons;
    this.saveRuleEndpoint = environment.apiUrls.saveRule;
  }

  getRules(): Observable<Array<Rule>> {
    return this.http.get<Array<Rule>>(this.getRulesEndpoint, {
      headers: AzureSubscriptionHeader
    });
  }

  getRuleById(id: string): Observable<Rule | null> {
    return this.http.post<Rule>(
      this.getRuleByIdEndpoint,
      { id },
      { headers: AzureSubscriptionHeader }
    );
  }

  getAllCriteriaDefinitions(): Map<string, Product[]> {
    const parseableData = (<any>(<unknown>criteriaDefinitions)).default;
    const parsedData = JSON.parse(JSON.stringify(parseableData));
    const typedData = parsedData as CriterionDefinition[];

    const mappedDefinitions = new Map<string, Product[]>();

    typedData.forEach(def => {
      mappedDefinitions.set(def.criteriaName, def.products);
    });

    return mappedDefinitions;
  }

  getContractIds(): Array<CriterionValue> {
    const parseableData = (<any>(<unknown>contractIds)).default;
    const parsedData = JSON.parse(JSON.stringify(parseableData));
    const typedData = parsedData as CriterionValue[];

    return typedData;
  }

  getChangeReasons(): Observable<Array<ChangeReason>> {
    return this.http.get<Array<ChangeReason>>(this.getChangeReasonsEndpoint, {
      headers: AzureSubscriptionHeader
    });
  }

  save(rule: Rule): Observable<any> {
    return this.http.post(this.saveRuleEndpoint, rule, {
      headers: AzureSubscriptionHeader,
      observe: 'response'
    });
  }
}
