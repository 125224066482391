import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rosterVarianceDifference'
})
export class RosterVarianceDifferencePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    var inputNumber = ~~value;
    var note = '';
    if (inputNumber > 0) {
      note = '(removed)';
    } else if (inputNumber < 0) {
      note = ' (added)';
    }

    return `${Math.abs(inputNumber)} ${note}`;
  }
}
