<p-table
  #dt1
  [value]="careSystems"
  dataKey="id"
  stateStorage="local"
  stateKey="caresystem-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [globalFilterFields]="['careSystemName', 'active', 'modifiedUser']"
  [autoLayout]="true"
>
  <ng-template pTemplate="caption">
    <H2>Care Systems</H2>
    <div class="p-formgroup-inline"></div>
    <div class="p-d-flex p-jc-between">
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('caresystem-filters')"
      ></button>
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="table-header">
    <tr>
      <th pSortableColumn="careSystemName" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Care System Name
          <p-sortIcon field="careSystemName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="careSystemDescription" class="medium-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Description
          <p-sortIcon field="careSystemDescription"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="active">
        <span class="p-d-flex p-jc-between p-ai-center">
          <span title="Active">Active</span>
          <p-sortIcon field="active"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="createdDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created Date
          <p-sortIcon field="createdDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="createdUser" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created User
          <p-sortIcon field="createdUser"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Modified Date
          <p-sortIcon field="modifiedDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedUser" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Modified User
          <p-sortIcon field="modifiedUser"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="careSystemName"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="careSystemDescription"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="active" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="[
                  { label: 'Active', value: true },
                  { label: 'Inactive', value: false }
                ]"
                (onChange)="filter($event?.value, 'active', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option.label }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="createdDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="createdUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="modifiedDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="modifiedUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-careSystem let-expanded="expanded">
    <tr>
      <td>{{ careSystem.careSystemName }}</td>
      <td
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px"
        pTooltip="{{ careSystem.careSystemDescription }}"
      >       
        {{ careSystem.careSystemDescription }}
      </td>
      <td>{{ careSystem.active ? 'Active' : 'Inactive' }}</td>

      <td>
        <time [attr.datetime]="careSystem.createdDate">{{
          careSystem?.createdDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ careSystem.createdUser }}</td>
      <td>
        <time [attr.datetime]="careSystem.modifiedDate">{{
          careSystem?.modifiedDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ careSystem.modifiedUser }}</td>
    </tr>
  </ng-template>
</p-table>
