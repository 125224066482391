import { Component, OnInit, ViewChild, Self } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MemberExceptionHistory } from '@memberExceptions/models/member-exception-history';
import { MemberExceptionHistoryService } from '@memberExceptions/services/member-exception-history.service';
import { Table } from 'primeng/table';
import { FilterService } from '@shared/services/filter-service/filter.service';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { ActivatedRoute } from '@angular/router';
import { ColumnDefinition } from '@shared/models/column-definition';

@Component({
  selector: 'app-member-exception-history',
  templateUrl: './member-exception-history.component.html',
  styleUrls: ['./member-exception-history.component.scss'],
  providers: [
    ClearFilterService,
    { provide: BROWSER_STORAGE, useFactory: () => localStorage },
    DatePipe
  ]
})
export class MemberExceptionHistoryComponent implements OnInit {
  @ViewChild('dt') table!: Table;

  pagingSizes = [20, 40, 60, 80, 100];
  loadingTable = true;
  memberExceptionHistory!: MemberExceptionHistory[];
  cols: any[];

  constructor(
    private memberExceptionHistoryService: MemberExceptionHistoryService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private route: ActivatedRoute,
    private datepipe: DatePipe
  ) {
    this.cols = [
      {
        field: 'memberExceptionNumber',
        shortName: 'Member Exception ID',
        longName: 'Member Exception ID',
        type: 'text'
      },
      {
        field: 'memberId',
        shortName: 'Member ID',
        longName: 'Member ID',
        type: 'text'
      },
      { field: 'firstName', shortName: 'First Name', longName: 'First Name', type: 'text' },
      { field: 'lastName', shortName: 'Last Name', longName: 'Last Name', type: 'text' },
      {
        field: 'altProv.alternateProviderNBR',
        shortName: 'AltProv ID',
        longName: 'Alternate Provider ID',
        type: 'text'
      },
      {
        field: 'altProv.alternateProviderName',
        shortName: 'AltProv',
        longName: 'Alternate Provider Name',
        type: 'text'
      },
      { field: 'productName', shortName: 'Product', longName: 'Product', type: 'text' },
      {
        field: 'pmiNumber',
        shortName: 'PMI Number',
        longName: 'Personal Master Index Number',
        type: 'text'
      },
      {
        field: 'pccId',
        shortName: 'PCC ID',
        longName: 'Primary Care Clinic ID',
        type: 'text'
      },
      {
        field: 'pccName',
        shortName: 'PCC',
        longName: 'Primary Care Clinic Name',
        type: 'text'
      },
      {
        field: 'sourceId',
        shortName: 'Source ID',
        longName: 'Source ID',
        type: 'text'
      },
      {
        field: 'sourceName',
        shortName: 'Source Name',
        longName: 'Source Name',
        type: 'text'
      },
      {
        field: 'effectiveStartDate',
        shortName: 'Effective Start Date',
        longName: 'Effective Start Date',
        type: 'date'
      },
      {
        field: 'effectiveEndDate',
        shortName: 'Effective End Date',
        longName: 'Effective End Date',
        type: 'date'
      },
      {
        field: 'modifiedDate',
        shortName: 'Modified Date',
        longName: 'Modified Date',
        type: 'date'
      },
      {
        field: 'modifiedUser',
        shortName: 'Modified User',
        longName: 'Modified User',
        type: 'text'
      }
    ];
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadMemberExceptionHistoryRecords(params.exceptionNumber);
    });
  }

  loadMemberExceptionHistoryRecords(exceptionNumber: string): void {
    this.loadingTable = true;

    this.memberExceptionHistoryService
      .getMemberExceptionHistory(exceptionNumber)
      .subscribe(response => {
        this.memberExceptionHistory = response;
        this.memberExceptionHistory.forEach(row => {
          row.modifiedDate = new Date(row.modifiedDate!);
          row.effectiveEndDate = new Date(row.effectiveEndDate!);
          row.effectiveStartDate = new Date(row.effectiveStartDate!);
        });
        this.loadingTable = false;
      });
  }

  public clearFilter(storageKey: string): void {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }

  public getValue(rowData: any, col: ColumnDefinition): string | Date | null {
    var parts = col.field!.split('.');
    if (parts.length > 1) 
      return rowData[parts[0]][parts[1]];
    if (col.type === 'date')
      return this.datepipe.transform(rowData[col.field!], 'MM/dd/yyyy');
    else 
      return rowData[parts[0]];
  }
}
