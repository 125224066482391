import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnDefinition } from '@shared/models/column-definition';
import { VarianceReportDetail } from '@reports/models/variance-report-detail';
import { MatDialog } from '@angular/material/dialog';
import { PccExceptionEntryComponent } from '@pccExceptions/components/pcc-exception-entry/pcc-exception-entry.component';
import { MemberExceptionDetailComponent } from '@memberExceptions/components/member-exception-detail/member-exception-detail.component';
import { SpinnerOverlayService } from '@shared/services/spinner-overlay-service/spinner-overlay.service';

@Component({
  selector: 'app-roster-variance-detail',
  templateUrl: './roster-variance-detail.component.html',
  styleUrls: ['./roster-variance-detail.component.scss']
})
export class RosterVarianceDetailComponent implements OnInit {
  selectedColumns: ColumnDefinition[] = [];
  allColumns: ColumnDefinition[] = [];

  isLoading = true;
  loadingTable = true;
  columns: any[];
  varianceReportDetailList: Array<VarianceReportDetail> = [];
  altProv: string | null = '';
  product: string | null = '';
  totalCount = 0;
  statuses: any[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly spinnerService: SpinnerOverlayService
  ) {
    this.columns = [
      {
        propertyName: 'memberId',
        shortName: 'memberId',
        longName: 'Member ID',
        type: 'text'
      },
      {
        propertyName: 'rule',
        shortName: 'ruleID',
        longName: 'Rule ID',
        type: 'text'
      },
      {
        propertyName: 'pccException',
        shortName: 'pccExceptionID',
        longName: 'PCC Exception ID',
        type: 'text'
      },
      {
        propertyName: 'memberException',
        shortName: 'memberExceptionID',
        longName: 'Member Exception ID',
        type: 'text'
      },
      {
        propertyName: 'change',
        shortName: 'changeStatus',
        longName: 'Change Status',
        options: [
          { label: 'Added', value: 'added' },
          { label: 'Removed', value: 'removed' }
        ],
        type: 'change'
      }
    ];
  }

  ngOnInit(): void {
    console.log('Activated route data', this.activatedRoute.data);

    //this.spinnerService.show();
    this.loadingTable = true;
    this.isLoading = true;
    this.activatedRoute.data.subscribe(
      (resp: any) => {
        let result = resp.rosterVarianceDetail.results as Array<VarianceReportDetail>;
        this.varianceReportDetailList = result;
        this.totalCount = result.length;
        console.log(result);
        this.loadingTable = false;
        this.isLoading = false;
      },
      error => {
        console.log(error);
      }
    );

    if (this.totalCount) {
      this.altProv = `Alternate Provider: ${this.activatedRoute.snapshot.paramMap.get('id3')}`;
      this.product = `Product: ${this.activatedRoute.snapshot.paramMap.get('id4')}`;
    }

    this.statuses = [
      { label: 'added', value: 'added' },
      { label: 'removed', value: 'removed' }
    ];

    //this.spinnerService.hide();
  }

  openPccExceptionDetail(pccExceptionId: string) {
    let dialogRef = this.dialog.open(PccExceptionEntryComponent, {
      autoFocus: true,
      width: '1000px',
      maxHeight: '900px',
      panelClass: 'custom-dialog-container',
      data: { pccExceptionId: pccExceptionId }
    });

    dialogRef.afterClosed().subscribe();
    this.cdRef.detectChanges();
  }

  openMemberExceptionDetail(memberExceptionId: string) {
    let dialogRef = this.dialog.open(MemberExceptionDetailComponent, {
      autoFocus: true,
      width: '1000px',
      maxHeight: '900px',
      panelClass: 'custom-dialog-container',
      data: { memberExceptionId: memberExceptionId }
    });

    dialogRef.afterClosed().subscribe();
    this.cdRef.detectChanges();
  }

  gotoLink(url: string) {
    window.open(url, 'blank');
  }
}
