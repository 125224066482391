import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Member } from '@memberMaintenance/models/member';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AzureSubscriptionHeader } from '../../shared/models/CosmosDB/azure-subscription-header';
import { QueryResponse } from '@shared/models/query-response';
import { FilterRequest } from '@shared/models/filter-request';
import { HealthStatusCode } from '@shared/models/health-status-code';
import { LivingStatusCode } from '@shared/models/living-status-code';

@Injectable({
  providedIn: 'root'
})
export class MemberMaintenanceService {
  private readonly getMemberEndPoint: string;
  private readonly getAllHealthStatusCodesEndPoint: string;
  private readonly getAllLivingStatusCodesEndpoint: string;
  private readonly saveMemberEndPoint: string;
  private readonly filterMembersEndpoint: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getMemberEndPoint = environment.apiUrls.getMemberById;
    this.getAllHealthStatusCodesEndPoint = environment.apiUrls.getAllHealthStatusCodes;
    this.getAllLivingStatusCodesEndpoint = environment.apiUrls.getAllLivingStatusCodes;
    this.saveMemberEndPoint = environment.apiUrls.saveMember;
    this.filterMembersEndpoint = environment.apiUrls.filterMembers;
  }

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  saveMember(body: Member): Observable<Member[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': environment.subscriptionKey
      }),
      responseType: 'text' as 'json'
    };

    return this.http.post<Member[]>(this.saveMemberEndPoint, body, httpOptions).pipe(
      tap(() => {
        this.refreshNeeded$.next();
      })
    );
  }

  getMember(id: string): Observable<Member> {
    const url = `${this.getMemberEndPoint}/${id}`;
    return this.http.get<Member>(url, { headers: AzureSubscriptionHeader });
  }

  getAllHealthStatusCodes(): Observable<HealthStatusCode[]> {
    return this.http.get<HealthStatusCode[]>(this.getAllHealthStatusCodesEndPoint, {
      headers: AzureSubscriptionHeader
    });
  }

  getAllLivingStatusCodes(): Observable<LivingStatusCode[]> {
    return this.http.get<LivingStatusCode[]>(this.getAllLivingStatusCodesEndpoint, {
      headers: AzureSubscriptionHeader
    });
  }

  getMemberList(
    filter: LazyLoadEvent,
    sessionId: string | null
  ): Observable<QueryResponse<Member>> {
    const request: FilterRequest = {
      querySessionId: sessionId,
      filter
    };

    return this.http.post<QueryResponse<Member>>(this.filterMembersEndpoint, request, {
      headers: AzureSubscriptionHeader
    });
  }

  getAllMemberLivingStatusCodes(): Observable<Array<LivingStatusCode>> {
    return this.http.get<Array<LivingStatusCode>>(this.getAllLivingStatusCodesEndpoint, {
      headers: AzureSubscriptionHeader
    });
  }
}
