<ng-container *ngIf="options.length === 0; else dropDownFilter">
  <p-columnFilter [type]="type" [field]="field" matchMode="contains"></p-columnFilter>
</ng-container>
<ng-template #dropDownFilter>
  <p-columnFilter field="{{ field }}" matchMode="{{ filterMatchMode || 'in' }}" [showMenu]="false">
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
      <p-multiSelect
        [ngModel]="value"
        [options]="options"
        (onChange)="filter($event.value, field, filterMatchMode || 'in')"
        placeholder="Any"
        appendTo="body"
      >
        <ng-template let-option pTemplate="item"
          ><span class="p-m1-1">{{ option }}</span></ng-template
        >
      </p-multiSelect>
    </ng-template>
  </p-columnFilter>
</ng-template>
