<form [formGroup]="membersFormGroup" (ngSubmit)="onSubmit()" class="member-details-form">
  <fieldset>
    <legend>
      <h2>Member Maintenance</h2>
    </legend>
    <div class="member-attributes-container">
      <mat-form-field appearance="outline">
        <mat-label>Member Id</mat-label>
        <input
          matInput
          type="text"
          formControlName="memberId"
          readonly="{{ editMember }}"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Health Rules Id</mat-label>
        <input matInput type="number" formControlName="healthRulesId" required />
      </mat-form-field>
    </div>
    <div class="member-attributes-container">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="firstName"
          required
          (change)="updateDates('name')"
        />
        <mat-error *ngIf="firstName?.errors?.required"> First Name cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Middle Name</mat-label>
        <input matInput type="text" formControlName="middleName" (change)="updateDates('name')" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="lastName"
          required
          (change)="updateDates('name')"
        />
        <mat-error *ngIf="lastName?.errors?.required"> Last Name cannot be blank </mat-error>
      </mat-form-field>
    </div>
    <div class="member-attributes-container" formGroupName="nameSpan">
      <mat-form-field appearance="outline">
        <mat-label>Name Start Date</mat-label>
        <input
          matInput
          iso
          type="datetime-local"
          min="{{ minDate | date: 'yyyy-MM-ddTHH:mm' }}"
          max="{{ maxDate | date: 'yyyy-MM-ddTHH:mm' }}"
          formControlName="startDate"
          required
        />
        <mat-error
          *ngIf="
            nameSpan.errors?.notGreater &&
            (nameSpan.touched || nameSpan.dirty)
          "
        >
          Start Date must take place before End Date
        </mat-error>
        <mat-error *ngIf="nameStartDate?.errors?.required">Start Date cannot be blank</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Name End Date</mat-label>
        <input matInput iso type="datetime-local" formControlName="endDate" required />
        <mat-error
          *ngIf="
            nameSpan.errors?.notGreater &&
            (nameSpan.touched || nameSpan.dirty)
          "
        >
          End Date must take place after Start Date
        </mat-error>
        <mat-error *ngIf="nameEndDate?.errors?.required">End Date cannot be blank</mat-error>
      </mat-form-field>
    </div>
    <div class="member-attributes-container">
      <mat-form-field appearance="outline">
        <mat-label>Date of Birth</mat-label>
        <input matInput iso type="datetime-local" formControlName="dob" required />
        <mat-error *ngIf="dob?.errors?.required"> Date of Birth Date cannot be blank </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Gender</mat-label>
        <mat-select color="primary" formControlName="gender" name="type" required>
          <mat-option value="Male"> Male </mat-option>
          <mat-option value="Female"> Female </mat-option>
        </mat-select>
        <mat-error *ngIf="gender?.errors?.required"> Gender cannot be blank </mat-error>
      </mat-form-field>
    </div>
    <fieldset>
      <legend>
        <h3>Address</h3>
      </legend>
      <div class="member-address-container">
        <mat-form-field appearance="outline" class="mat-form-field-address">
          <mat-label>Street Address</mat-label>
          <input
            matInput
            type="text"
            formControlName="address1"
            required
            (change)="updateDates('address')"
          />
          <mat-error *ngIf="address1?.errors?.required"> Address cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-form-field-address">
          <input
            matInput
            type="text"
            formControlName="address2"
            (change)="updateDates('address')"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input
            matInput
            type="text"
            formControlName="city"
            required
            (change)="updateDates('address')"
          />
          <mat-error *ngIf="city?.errors?.required"> City cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <mat-select
            color="primary"
            formControlName="state"
            name="type"
            (selectionChange)="onStateSelectionChange()"
            required
          >
            <mat-option *ngFor="let state of statesList" [value]="state">{{ state }}</mat-option>
          </mat-select>
          <mat-error *ngIf="state?.errors?.required"> State cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>County</mat-label>
          <mat-select
            color="primary"
            formControlName="county"
            name="type"
            required
            (selectionChange)="updateDates('address')"
          >
            <mat-option *ngFor="let county of countiesList" [value]="county">{{
              county
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="county?.errors?.required"> County cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>County Override</mat-label>
          <mat-select color="primary" formControlName="countyOverride" name="type">
            <mat-option *ngFor="let county of countiesList" [value]="county">{{
              county
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Zip</mat-label>
          <input
            matInput
            type="number"
            formControlName="zip"
            required
            (change)="updateDates('address')"
            maxlength="5"
          />
          <mat-error *ngIf="zip?.errors?.required">
            Zip cannot be blank and cannot exceed 5 digits
          </mat-error>
        </mat-form-field>
      </div>
      <div class="member-attributes-container" formGroupName="addressSpan">
        <mat-form-field appearance="outline">
          <mat-label>Address Start Date</mat-label>
          <input
            matInput
            iso
            type="datetime-local"
            min="{{ minDate | date: 'yyyy-MM-ddTHH:mm' }}"
            max="{{ maxDate | date: 'yyyy-MM-ddTHH:mm' }}"
            formControlName="startDate"
            required
          />
          <mat-error
            *ngIf="
            addressSpan.errors?.notGreater &&
              (addressSpan.touched || addressSpan.dirty)
            "
          >
            Start Date must take place before End Date
          </mat-error>
          <mat-error *ngIf="addressStartDate?.errors?.required">
            Start Date cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Address End Date</mat-label>
          <input matInput iso type="datetime-local" formControlName="endDate" required />
          <mat-error
            *ngIf="
            addressSpan.errors?.notGreater &&
              (addressSpan.touched || addressSpan.dirty)
            "
          >
            End Date must take place after Start Date
          </mat-error>
          <mat-error *ngIf="addressEndDate?.errors?.required"> End Date cannot be blank </mat-error>
        </mat-form-field>
      </div>
    </fieldset>
    <fieldset>
      <legend>
        <h3>Standardized Address</h3>
      </legend>
      <div class="member-address-container">
        <mat-form-field appearance="outline" class="mat-form-field-address">
          <input matInput type="text" formControlName="address3" readonly="true" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="city3" readonly="true" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <input matInput type="text" formControlName="state3" readonly="true" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>County</mat-label>
          <input matInput type="text" formControlName="county3" readonly="true" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Zip</mat-label>
          <input matInput type="text" formControlName="zip3" readonly="true" />
        </mat-form-field>
      </div>
    </fieldset>
    <fieldset>
      <legend>
        <h3>Product</h3>
      </legend>
      <div class="member-address-container">
        <mat-form-field appearance="outline">
          <mat-label>Product</mat-label>
          <mat-select
            color="primary"
            formControlName="product"
            name="type"
            (selectionChange)="updateDates('product'); productChanged()"
            required
          >
            <mat-option *ngFor="let product of products" [value]="product.productName">{{
              product.productName
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="product?.errors?.required"> Product cannot be blank </mat-error>
        </mat-form-field>
      </div>
      <div class="member-attributes-container" formGroupName="coverageSpan">
        <mat-form-field appearance="outline">
          <mat-label>Product Start Date</mat-label>
          <input
            matInput
            iso
            type="datetime-local"
            min="{{ minDate | date: 'yyyy-MM-ddTHH:mm' }}"
            max="{{ maxDate | date: 'yyyy-MM-ddTHH:mm' }}"
            formControlName="startDate"
            required
          />
          <mat-error
            *ngIf="
              coverageSpan.errors?.notGreater &&
              (coverageSpan.touched || coverageSpan.dirty)
            "
          >
            Start Date must take place before End Date
          </mat-error>
          <mat-error *ngIf="productStartDate?.errors?.required">
            Start Date cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Product End Date</mat-label>
          <input matInput iso type="datetime-local" formControlName="endDate" required />
          <mat-error
            *ngIf="
              coverageSpan.errors?.notGreater &&
              (coverageSpan.touched || coverageSpan.dirty)
            "
          >
            End Date must take place after Start Date
          </mat-error>
          <mat-error *ngIf="productEndDate?.errors?.required"> End Date cannot be blank </mat-error>
        </mat-form-field>
      </div>
    </fieldset>

    <fieldset>
      <legend>
        <h3>PCC Assignment</h3>
      </legend>
      <div class="member-address-container">
        <mat-form-field
          appearance="outline"
          matTooltip="Click the 'Select a PCC' button to edit this value."
          matTooltipPosition="above"
        >
          <mat-label>PCC</mat-label>
          <input
            matInput
            type="text"
            formControlName="pcc"
            readonly="true"
            (change)="updateDates('pcc')"
          />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="mat-form-field-pccName"
          matTooltip="Click the 'Select a PCC' button to edit this value."
          matTooltipPosition="above"
        >
          <mat-label>PCC Name</mat-label>
          <input matInput type="text" formControlName="pccName" readonly="true" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Care System Code</mat-label>
          <input matInput type="text" formControlName="careSystemCode" readonly="true" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="assessment-status">
          <mat-label>Assignment Method</mat-label>
          <mat-select formControlName="pccAssignmentMethod" [required]="assignmentMethodRequired()">
            <mat-option *ngFor="let opt of this.pccAssignmentMethods" [value]="opt">{{
              opt
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="pccAssignmentMethod?.errors?.required">
            Assignment Method is required
          </mat-error>
        </mat-form-field>
        <div class="pcc-button">
          <button type="button" mat-raised-button color="primary" (click)="openPCCPicker()">
            Select a PCC
          </button>
        </div>
      </div>
      <div class="member-attributes-container" formGroupName="pccSpan">
        <mat-form-field appearance="outline">
          <mat-label>PCC Start Date</mat-label>
          <input
            matInput
            iso
            type="datetime-local"
            min="{{ minDate | date: 'yyyy-MM-ddTHH:mm' }}"
            max="{{ maxDate | date: 'yyyy-MM-ddTHH:mm' }}"
            formControlName="startDate"
          />
          <mat-error
            *ngIf="
              pccSpan.errors?.notGreater &&
              (pccSpan.touched || pccSpan.dirty)
            "
          >
            Start Date must take place before End Date
          </mat-error>
          <mat-error *ngIf="pccStartDate?.errors?.required"> Start Date cannot be blank </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>PCC End Date</mat-label>
          <input matInput iso type="datetime-local" formControlName="endDate" />
          <mat-error
            *ngIf="
              pccSpan.errors?.notGreater &&
              (pccSpan.touched || pccSpan.dirty)
            "
          >
            End Date must take place after Start Date
          </mat-error>
          <mat-error *ngIf="pccEndDate?.errors?.required"> End Date cannot be blank </mat-error>
        </mat-form-field>
      </div>
    </fieldset>

    <fieldset>
      <legend>
        <h3>Health Status</h3>
      </legend>
      <div class="member-address-container">
        <mat-form-field appearance="outline">
          <mat-label>Health Status</mat-label>
          <mat-select
            color="primary"
            formControlName="healthStatus"
            name="type"
            (selectionChange)="updateDates('hscode')"
          >
            <mat-option
              matTooltip="{{ healthStatus.healthStatusDescription }}"
              [matTooltipPosition]="'right'"
              *ngFor="let healthStatus of healthStatusCodes"
              [value]="healthStatus.healthStatusCode"
              >{{ healthStatus.healthStatusCode }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Assessment Date</mat-label>
          <input matInput iso type="datetime-local" formControlName="assessmentDate" />
          <mat-error *ngIf="assessmentDate?.errors?.required">
            Assessment Date cannot be blank
          </mat-error>
        </mat-form-field>
      </div>
      <div class="member-attributes-container" formGroupName="healthStatusSpan">
        <mat-form-field appearance="outline">
          <mat-label>Health Status Start Date</mat-label>
          <input
            matInput
            iso
            type="datetime-local"
            min="{{ minDate | date: 'yyyy-MM-ddTHH:mm' }}"
            max="{{ maxDate | date: 'yyyy-MM-ddTHH:mm' }}"
            formControlName="startDate"
          />
          <mat-error
            *ngIf="
            healthStatusSpan.errors?.notGreater &&
              (healthStatusSpan.touched || healthStatusSpan.dirty)
            "
          >
            Start Date must take place before End Date
          </mat-error>
          <mat-error *ngIf="healthStatusStartDate?.errors?.required">
            Start Date cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Health Status End Date</mat-label>
          <input matInput iso type="datetime-local" formControlName="endDate" />
          <mat-error
            *ngIf="
            healthStatusSpan.errors?.notGreater &&
              (healthStatusSpan.touched || healthStatusSpan.dirty)
            "
          >
            End Date must take place after Start Date
          </mat-error>
          <mat-error *ngIf="healthStatusEndDate?.errors?.required">
            End Date cannot be blank
          </mat-error>
        </mat-form-field>
      </div>
    </fieldset>

    <fieldset>
      <legend>
        <h3>Living Status</h3>
      </legend>
      <div class="member-address-container">
        <mat-form-field appearance="outline">
          <mat-label>Living Status</mat-label>
          <mat-select
            color="primary"
            formControlName="livingStatus"
            name="type"
            (selectionChange)="updateDates('lscode')"
          >
            <mat-option
              matTooltip="{{ livingStatus.livingStatusDescription }}"
              [matTooltipPosition]="'right'"
              *ngFor="let livingStatus of livingStatusCodes"
              [value]="livingStatus.livingStatusCode"
              >{{ livingStatus.livingStatusCode }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="member-attributes-container" formGroupName="livingStatusSpan">
        <mat-form-field appearance="outline">
          <mat-label>Living Status Start Date</mat-label>
          <input
            matInput
            iso
            type="datetime-local"
            min="{{ minDate | date: 'yyyy-MM-ddTHH:mm' }}"
            max="{{ maxDate | date: 'yyyy-MM-ddTHH:mm' }}"
            formControlName="startDate"
          />
          <mat-error
            *ngIf="
              livingStatusSpan.errors?.notGreater &&
              (livingStatusSpan.touched || livingStatusSpan.dirty)
            "
          >
            Start Date must take place before End Date
          </mat-error>
          <mat-error *ngIf="livingStatusStartDate?.errors?.required">
            Start Date cannot be blank
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Living Status End Date</mat-label>
          <input matInput iso type="datetime-local" formControlName="endDate" />
          <mat-error
            *ngIf="
              livingStatusSpan.errors?.notGreater &&
              (livingStatusSpan.touched || livingStatusSpan.dirty)
            "
          >
            End Date must take place after Start Date
          </mat-error>
          <mat-error *ngIf="livingStatusEndDate?.errors?.required">
            End Date cannot be blank
          </mat-error>
        </mat-form-field>
      </div>
    </fieldset>
  </fieldset>
  <div class="form-action-buttons">
    <div class="primary-row">
      <button mat-raised-button type="button" [routerLink]="['/members/list']">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="loading && !userHasEdit()"
      >
        Save
      </button>
    </div>
  </div>
</form>
