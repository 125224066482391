import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { Product } from '../../models/product';
import { ProductsService } from '../../services/products.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ProductViewModel } from '../../models/product-view-model';
import { Table } from 'primeng/table';
import { isEmpty } from '@shared/validators/isEmpty';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { MsalService } from '@azure/msal-angular';
import { RoleCheck } from '@shared/guards/roleCheck';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class ProductsListComponent implements OnInit {
  products!: Product[];
  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  @ViewChild('dt1') dataTable!: Table;

  activeCheck: boolean = true;
  inactiveCheck: boolean = true;
  isEditMode: boolean = false;
  selectedProductData?: Product;

  cols: any[] = [];
  allActiveStatuses = ['true', 'false'];
  allStatuses = this.allActiveStatuses.map(status => {
    return { label: status, value: status };
  });

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private productsService: ProductsService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private authService: MsalService,
    private roleCheck: RoleCheck
  ) {}

  userHasEdit(){
    return this.roleCheck.userHasRole(['AltProvAdmin','AltProvListMaintenanceEdit']);
  }

  ngOnInit(): void {
    this.productsService.refreshNeeded$.subscribe(() => {
      this.getAllProducts();
    });
    this.getAllProducts();
  }

  getAllProducts() {
    this.loadingTable = true;
    this.productsService.getAllProducts().subscribe({
      next: products => {
        this.products = products;
        this.products.forEach(product => {
          product.createdDate = new Date(product.createdDate!);
          product.modifiedDate = new Date(product.modifiedDate!);
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  addProduct() {
    this.productsService.getProduct('0').subscribe(result => {
      this.selectedProductData = result;
    });
    this.isEditMode = false;
    this.displayEditDialog = true;
  }

  editProduct(productData: ProductViewModel) {
    const editProduct: Product = {
      id: productData.id,
      productName: !isEmpty(productData.productName) ? productData.productName : 'MSHO',
      active: productData.active,
      modifiedDate: productData.modifiedDate,
      modifiedUser: this.userName, 
      createdUser: productData.createdUser,
      createdDate: productData.createdDate,
      mutualExclusives: productData.mutualExclusives
    };

    this.selectedProductData = { ...editProduct };
    this.isEditMode = true;
    this.displayEditDialog = true;
  }

  updateProduct(selectedProductData: Product) {
    this.displayEditDialog = false;
    this.getAllProducts();
  }

  deleteProduct(productData: Product) {
    if (confirm(`Do you really want to delete this Product? ${productData.id}`)) {
      this.productsService.deleteProduct(productData.id!).subscribe({
        next: () => this.onSaveComplete(productData.id!),
        error: err => (this.errorMessage = err)
      });
      console.error(this.errorMessage);

      this.onSaveComplete(productData.id!);
    }
  }

  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  CheckActiveCheckToggle() {
    console.log(`checkActiveCheckToggle: ${this.activeCheck}, ${this.inactiveCheck}`);
  }

  onSaveComplete(id: string): void {
    console.log('deleted id: ', id);
    this.products = this.products.filter(hc => hc.id !== id);
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
  }
}
