import { formatDate } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[type=datetime-local][iso]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeLocalIsoDirective),
      multi: true
    }
  ]
})
export class DatetimeLocalIsoDirective implements ControlValueAccessor {

  @HostListener('input', ['$event.target.value']) onInput = (value?: string) => {
    const converted = this.getDateOrNull(value)?.toISOString();
    this.onChange(converted ?? '');
  }
  onChange: any = () => {};

  @HostListener('blur', []) onTouched = () => { };

  constructor(
    private readonly _elementRef: ElementRef<HTMLInputElement>,
    private readonly _renderer: Renderer2) { }

  writeValue(dateString: string): void {
    let date = '';

    if (dateString) {
      date = formatDate(dateString, 'yyyy-MM-ddTHH:mm', 'en-US');
    }

    this._renderer.setAttribute(
      this._elementRef.nativeElement,
      'value',
      date
    );
  }

  registerOnChange(fn: (_: any) => void): void {
    if (fn) {
      this.onChange = fn;
    }
  }

  registerOnTouched(fn: () => void): void {
    if (fn) {
      this.onTouched = fn;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  private getDateOrNull(input?: string | number) {
    if (input) {
      return new Date(input!);
    }
    return null;
  }
}
