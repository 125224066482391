import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { MsalService } from '@azure/msal-angular';
import { AlternateProviderHistory } from '../../models/alternate-provider-history';
import { AltProvHistoryService } from '../../services/alternate-providers-history-service/alternate-providers-history.service';
import { ResolveEnd, ResolveStart, Router, Event, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-alternate-providers-history',
  templateUrl: './alternate-providers-history.component.html',
  styleUrls: ['./alternate-providers-history.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class AlternateProvidersHistoryComponent implements OnInit {
  alternateProvidersHistory!: AlternateProviderHistory[];
  private loadingDetailsPage = false;
  loadingTable = true;
  displayEditDialog = false;
  errorMessage = '';

  @ViewChild('dt1') dataTable!: Table;

  activeCheck = true;
  inactiveCheck = true;

  cols: any[] = [];
  allActiveStatuses = ['true', 'false'];
  allStatuses = this.allActiveStatuses.map(status => {
    return { label: status, value: status };
  });

  get userName(): string {
    return this.authService.instance.getActiveAccount()?.username!;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private alternateProvidersHistoryService: AltProvHistoryService,
    private readonly router: Router,
    private authService: MsalService,
    @Self() private readonly clearFilterService: ClearFilterService
  ) {
    this.router.events.subscribe(this.handleRouterEvent.bind(this));
  }
  
  ngOnInit(): void {
    this.loadingTable = true;
    this.route.params.subscribe(params => {
      this.getAlternateProvidersHistory(params.id);
    }); 
  }

  cancelModal(isModalCancel: boolean) {
    this.displayEditDialog = false;
  }

  CheckActiveCheckToggle() {
    console.log(`checkActiveCheckToggle: ${this.activeCheck}, ${this.inactiveCheck}`);
  }

  getAlternateProvidersHistory(id : string) {
    this.loadingTable = true;
    this.alternateProvidersHistoryService.getAltProvHistoryById(id).subscribe({
      next: alternateProvidersHist => {
        this.alternateProvidersHistory = alternateProvidersHist;
        this.alternateProvidersHistory.forEach(alternateProvidersHist => {
          alternateProvidersHist.createdDate = new Date(
            alternateProvidersHist.createdDate!
          );
          alternateProvidersHist.modifiedDate = new Date(
            alternateProvidersHist.modifiedDate!
          );
          alternateProvidersHist.effectiveEndDate =
          alternateProvidersHist.effectiveEndDate != null
              ? new Date(alternateProvidersHist.effectiveEndDate)
              : alternateProvidersHist.periodBeginDate != null
              ? new Date(alternateProvidersHist.periodEndDate!)
              : null;
              alternateProvidersHist.effectiveStartDate =
              alternateProvidersHist.effectiveStartDate != null
              ? new Date(alternateProvidersHist.effectiveStartDate)
              : alternateProvidersHist.periodBeginDate != null
              ? new Date(alternateProvidersHist.periodBeginDate!)
              : null;
        });
        this.loadingTable = false;
      },
      error: err => {
        this.loadingTable = false;
        this.errorMessage = err;
      }
    });
  }

  private handleRouterEvent(event: Event): void {
    const alreadyLoading = this.loadingDetailsPage;

    if (event instanceof ResolveStart && !alreadyLoading) {
      this.loadingTable = true;
      this.loadingDetailsPage = true;
    }

    if (event instanceof ResolveEnd && alreadyLoading) {
      this.loadingTable = false;
      this.loadingDetailsPage = false;
    }
  }

  public clearFilter(storageKey: string) {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.dataTable);
  }
}
