<p-table
  #dt1
  [value]="counties"
  dataKey="id"
  stateStorage="local"
  stateKey="counties-filters"
  [rows]="20"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[20, 40, 60, 80, 100]"
  [loading]="loadingTable"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [globalFilterFields]="[
    'countyFIPSCode',
    'countyCode',
    'countyName',
    'state',
    'active',
    'createdUser',
    'modifiedUser'
  ]"
  [autoLayout]="true"
  responsiveLayout="scroll"
>
  <ng-template pTemplate="caption">
    <H2>Counties</H2>
    <div class="p-formgroup-inline"></div>
    <div class="p-d-flex p-jc-between">
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-undo"
        class="p-button-outlined p-mr-2"
        pTooltip="Clear all filters"
        label="Clear Filters"
        iconPos="right"
        tooltipPosition="bottom"
        (click)="clearFilter('counties-filters')"
      ></button>
      <button type="button" pButton pRipple class="p-button-text p-mr-2" hidden></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="table-header">
    <tr>
      <!-- Edit-->
      <th class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th pSortableColumn="countyFIPSCode" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          County FIPS Code
          <p-sortIcon field="countyFIPSCode"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="countyCode" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          County Code
          <p-sortIcon field="countyCode"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="countyName" class="medium-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          County Name
          <p-sortIcon field="countyName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="state" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          State
          <p-sortIcon field="state"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="riskPop" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Risk Pop
          <p-sortIcon field="riskPop"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="forPCC" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          For PCC
          <p-sortIcon field="forPCC"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="forMember" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          For Member
          <p-sortIcon field="forMember"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="active">
        <span class="p-d-flex p-jc-between p-ai-center">
          <span title="Active">Active</span>
          <p-sortIcon field="active"></p-sortIcon>
        </span>
      </th>
      <th pSortableColumn="createdDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created Date
          <p-sortIcon field="createdDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="createdUser" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Created User
          <p-sortIcon field="createdUser"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedDate" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Modified Date
          <p-sortIcon field="modifiedDate"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="modifiedUser" class="standard-column">
        <div class="p-d-flex p-jc-between p-ai-center">
          Updated User
          <p-sortIcon field="modifiedUser"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr>
      <!-- Edit -->
      <th>
        <div class="p-d-flex p-jc-between p-ai-center"></div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="countyFIPSCode"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="countyCode"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="countyName"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="state"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="riskPop"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="forPCC" matchMode="equals" [showMenu]="true">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown
                field="forPCC"
                [options]="allStatuses"
                (onChange)="dt1.filter($event.value, 'forPCC', 'equals')"
                styleClass="ui-column-filter"
                placeholder="Select Status"
                [showClear]="true"
                [style]="{ width: '80%' }"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item">
                  <span [class]="'status-' + option.value">{{
                    option.label === 'true' ? 'On' : 'Off'
                  }}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="forMember" matchMode="equals" [showMenu]="true">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown
                field="forMember"
                [options]="allStatuses"
                (onChange)="dt1.filter($event.value, 'forMember', 'equals')"
                styleClass="ui-column-filter"
                placeholder="Select Status"
                [showClear]="true"
                [style]="{ width: '80%' }"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item">
                  <span [class]="'status-' + option.value">{{
                    option.label === 'true' ? 'On' : 'Off'
                  }}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter field="active" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="[
                  { label: 'Active', value: true },
                  { label: 'Inactive', value: false }
                ]"
                (onChange)="filter($event?.value, 'active', 'in')"
                placeholder="Any"
                appendTo="body"
              >
                <ng-template let-option pTemplate="item"
                  ><span class="p-m1-1">{{ option.label }}</span></ng-template
                >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="createdDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="createdUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="date"
            matchMode="dateBefore"
            field="modifiedDate"
            display="menu"
            class="p-ml-auto"
          ></p-columnFilter>
        </div>
      </th>
      <th>
        <div class="p-d-flex p-jc-between p-ai-center">
          <p-columnFilter
            type="text"
            field="modifiedUser"
            [showClearButton]="false"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-county let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          title="Edit"
          icon="pi pi-pencil"
          class="icon-cell-button p-button-rounded p-button-text p-button-plain"
          (click)="editCounty(county)"
          [disabled]="!userHasEdit()"
        ></button>
      </td>

      <td>{{ county.countyFIPSCode }}</td>
      <td>{{ county.countyCode }}</td>
      <td
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px"
        pTooltip="{{ county.countyName }}"
      >
        {{ county.countyName }}
      </td>
      <td>{{ county.state }}</td>
      <td>{{ county.riskPop }}</td>
      <td>
        {{ county.forPCC === true && county.forPCC !== '' ? 'On' : 'Off' }}
      </td>
      <td>
        {{ county.forMember === true && county.forMember !== '' ? 'On' : 'Off' }}
      </td>
      <td>
        {{ county.active ? 'Active' : 'Inactive' }}
      </td>
      <td>
        <time [attr.datetime]="county.createdDate">{{
          county?.createdDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ county.createdUser }}</td>
      <td>
        <time [attr.datetime]="county.modifiedDate">{{
          county?.modifiedDate | date: 'MM/dd/yyyy'
        }}</time>
      </td>
      <td>{{ county.modifiedUser }}</td>
    </tr>
  </ng-template>
</p-table>
<div></div>
<app-county-edit
  *ngIf="displayEditDialog"
  [visible]="displayEditDialog"
  [mySelectedCountyData]="selectedCountyData"
  (cancelEvent)="cancelModal($event)"
  (saveEvent)="updateCounty($event)"
></app-county-edit>
