import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AzureSubscriptionHeader } from '@shared/models/CosmosDB/azure-subscription-header';
import { AlternateProviderHistory } from '../../models/alternate-provider-history';

@Injectable({
  providedIn: 'root'
})
export class AltProvHistoryService {
  private readonly getAltProvHistorybyIdEndPoint: string;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.getAltProvHistorybyIdEndPoint = environment.apiUrls.getAltProvHistory;
  }

  get refreshNeeded$(): Subject<void> {
    return this._refreshNeeded$;
  }

  getAltProvHistoryById(id: string): Observable<AlternateProviderHistory[]> {
    const url = `${this.getAltProvHistorybyIdEndPoint}/${id}`;
    return this.http.get(url, { headers: AzureSubscriptionHeader }).pipe(
      map((data: any) => {
        return data;
      }),
      shareReplay(1)
    );
  }
}
