import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  Self,
  ViewChild
} from '@angular/core';
import { UnassignedMember } from '@reports/models/unassigned-member';
import { UnassignedMembersService } from '@reports/services/unassigned-members.service';
import { exportDataToExcel } from '@shared/util/excel-exporter';
import { dateFormatForExport, getDateStringAsUTC } from '@shared/util/date-formatter';
import { FilterService as StorageFilterService } from '@shared/services/filter-service/filter.service';
import { FilterService, LazyLoadEvent } from 'primeng/api';
import { QuerySessionManagerService } from '@shared/services/query-session-manager-service/query-session-manager.service';
import { Table } from 'primeng/table';
import { HttpErrorResponse } from '@angular/common/http';
import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ProductsService } from 'src/app/products/services/products.service';

@Component({
  selector: 'app-unassigned-members-list',
  templateUrl: './unassigned-members-list.component.html',
  styleUrls: ['./unassigned-members-list.component.scss'],
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class UnassignedMembersListComponent {
  unassignedMembers!: UnassignedMember[];

  loadingTable = true;
  errorMessage = '';
  totalMembers = 0;
  pagingSizes = [20, 40, 60, 80, 100, 500];
  dateFilters: any;
  cols!: any[];

  @ViewChild(Table) table!: Table;

  private readonly querySessionManager!: QuerySessionManagerService;
  private previousLoadEvent: LazyLoadEvent | undefined = undefined;
  allProducts: string[] = [];

  constructor(
    private unassignedMembersService: UnassignedMembersService,
    private storageFilterService: StorageFilterService,
    private readonly productsService: ProductsService,
    private cd: ChangeDetectorRef,
    @Self() private readonly clearFilterService: ClearFilterService,
    private filterService: FilterService
  ) {
    this.querySessionManager = new QuerySessionManagerService('unassigned-member-list');

    // Clear any cached sessions on page load
    this.querySessionManager.resetSession();
  }

  async getAllUnassignedMembers(event: LazyLoadEvent): Promise<void> {
    this.loadingTable = true;

    // Blow away the query session every time unless we are paging forward/backward
    if (this.storageFilterService.didChangePage(this.previousLoadEvent, event) === false) {
      this.querySessionManager.resetSession();
    }

    forkJoin([
      this.unassignedMembersService
      .getUnassignedMembers(event),
      this.productsService.getAllProducts()
    ])
      .pipe(
        tap(([unassignedMembers, products]) => {
          this.unassignedMembers = unassignedMembers.results;
          this.totalMembers = unassignedMembers.totalResults;
          this.unassignedMembers.forEach(unassignedMember => {
            unassignedMember.effectiveDate =
              unassignedMember.effectiveDate.toString().length < 21 &&
              unassignedMember.effectiveDate !== null
                ? new Date(getDateStringAsUTC(unassignedMember.effectiveDate.toLocaleString()))
                : new Date('9999-12-31T00:00:00Z');
            unassignedMember.endDate =
              unassignedMember.endDate.toString().length < 21 && unassignedMember.endDate !== null
                ? new Date(getDateStringAsUTC(unassignedMember.endDate.toLocaleString()))
                : new Date('9999-12-31T00:00:00Z');
          });
          this.allProducts = products.filter(x => x.active).map(y => y.productName ?? '');
          if (unassignedMembers.querySessionId) {
            this.querySessionManager.currentSession = unassignedMembers.querySessionId;
          }
          this.loadingTable = false;
        }),
        take(1)
      )
      .subscribe();
    this.previousLoadEvent = event;
  }

  clear(table: Table): void {
    table.clear();
  }

  exportExcel(): void {
    const excelData: any[] = this.formatExcelData();

    exportDataToExcel(excelData, 'Unassigned Members');
  }

  private formatExcelData(): Array<any> {
    return this.unassignedMembers.map((unassignedMember: UnassignedMember) => ({      
      id: unassignedMember.id,                                                                                                                                                                                                                      
      programNBR: unassignedMember.programNBR,
      firstName: unassignedMember.firstName,
      lastName: unassignedMember.lastName,
      county: unassignedMember.county,
      careSystemCode: unassignedMember.careSystemCode,
      healthStatusCode: unassignedMember.healthStatusCode,
      pccId: unassignedMember.pccId,
      pccName: unassignedMember.pccName,
      effectiveDate:
        unassignedMember.effectiveDate != null
          ? new Date(unassignedMember.effectiveDate).toLocaleDateString(
              'en-US',
              dateFormatForExport
            )
          : '',
      endDate:
        unassignedMember.endDate != null
          ? new Date(unassignedMember.endDate).toLocaleDateString('en-US', dateFormatForExport)
          : ''
    }));
  }


  public clearFilter(storageKey: string): void {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
